import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useAdminCreateFacultyMutation } from '../../../../services/mutations';
import { useGetUniversityID } from '../../../../services/queries';
import {
  adminCreateFacultyDto,
  adminCreateFacultySchema,
} from '../../../../validation/admin-schema';

type Props = {};

export const CreateFaculty = (props: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const universityID = useGetUniversityID();

  const createFaculty = useAdminCreateFacultyMutation(universityID.data?.universityID);

  const handleCreateFaculty = async (values: adminCreateFacultyDto) => {
    setLoading(true);
    try {
      await createFaculty({
        name: values.name,
        shortName: values.shortName,
        universityID: universityID.data?.universityID ?? 0,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
      <Formik
        initialValues={{
          name: '',
          shortName: '',
          universityID: 0,
        }}
        validationSchema={adminCreateFacultySchema}
        onSubmit={async (values) => {
          console.log(values);
          handleCreateFaculty(values);
        }}
      >
        {() => (
          <Form>
            <Stack spacing={4}>
              <Box>
                <Field name='name'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='name'
                      isRequired
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel>Naziv</FormLabel>
                      <Input type='text' {...field} />
                      <ErrorMessage name='name'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box>
                <Field name='shortName'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='shortName'
                      isRequired
                      isInvalid={form.errors.shortName && form.touched.shortName}
                    >
                      <FormLabel>Skračenica</FormLabel>
                      <Input type='text' {...field} />
                      <ErrorMessage name='shortName'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>

              <Stack spacing={10} pt={2}>
                <Button
                  type='submit'
                  isLoading={isLoading}
                  loadingText='Kreiranje u toku...'
                  size='md'
                  bg='blue.400'
                  color='white'
                  _hover={{
                    bg: 'blue.500',
                  }}
                >
                  Kreiraj fakultet
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
