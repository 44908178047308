import React, { useRef, useEffect, useState } from 'react';
import jsQR from 'jsqr';
import { Box } from '@chakra-ui/react';

export const QRScanner: React.FC<{ onScan: (data: string) => void }> = ({ onScan }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const isScanningRef = useRef(true);

  // Stream setup
  useEffect(() => {
    async function enableStream() {
      try {
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });
        //console.log('Stream obtained');
        setStream(newStream);
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
          videoRef.current
            .play()
            .then(() => {
              //console.log('Video playing');
            })
            .catch((e) => {
              console.error('Error playing video:', e);
            });
        }
      } catch (err) {
        console.error('Error obtaining stream:', err);
      }
    }

    if (!stream) {
      enableStream();
    }
  }, []); // Empty dependency array to ensure this runs only once

  // Cleanup
  useEffect(() => {
    return () => {
      if (stream) {
        //console.log('Stream stopped');
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]); // Cleanup runs when the component unmounts

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      if (!ctx) return;

      const scan = () => {
        if (!isScanningRef.current) return;

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          //console.log('QR Code detected:', code.data);
          isScanningRef.current = false;
          onScan(code.data);
          if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            //console.log('Stream stopped after detection');
          }
        } else {
          setTimeout(scan, 200);
        }
      };

      scan();
    }
  }, [stream, onScan]);

  return (
    <Box overflow={'hidden'} borderRadius={'2xl'} border={'2px blue'}>
      <video ref={videoRef} style={{ width: '300px', height: '500px' }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </Box>
  );
};

/* import React, { useRef, useEffect, useState } from 'react';
import jsQR from 'jsqr';
import {
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';

type QRScannerProps = {
  onScan: (data: string) => void;
  onPermissionDenied: () => void;
};

const QRScanner: React.FC<QRScannerProps> = ({ onScan, onPermissionDenied }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    let active = true; // Flag to indicate if the component is still mounted

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then((stream) => {
        if (active && videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play().catch((err) => console.error('Error playing video:', err));
        }
      })
      .catch((err) => {
        console.error(err);
        setError('Unable to access the camera. Please grant camera permission.');
        if (err.name === 'NotAllowedError') {
          onPermissionDenied();
        }
      });

    return () => {
      active = false; // Set the flag to false when the component unmounts
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [onPermissionDenied]);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    const scan = () => {
      if (
        videoRef.current &&
        canvasRef.current &&
        ctx &&
        videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA
      ) {
        canvasRef.current.height = videoRef.current.videoHeight;
        canvasRef.current.width = videoRef.current.videoWidth;
        ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        const imageData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          onScan(code.data);
        } else {
          requestAnimationFrame(scan);
        }
      } else {
        requestAnimationFrame(scan);
      }
    };
    scan();
  }, [onScan]);

  return (
    <Box position='relative'>
      {error ? (
        <Alert status='error'>
          <AlertIcon />
          <Box flex='1'>
            <AlertTitle>Error</AlertTitle>
            <AlertDescription display='block'>{error}</AlertDescription>
          </Box>
          <CloseButton position='absolute' right='8px' top='8px' onClick={() => setError('')} />
        </Alert>
      ) : (
        <>
          <video ref={videoRef} style={{ display: 'flex' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </>
      )}
    </Box>
  );
};

export default QRScanner; */

/* import React, { useRef, useEffect, useState } from 'react';
import jsQR from 'jsqr';
import { Box } from '@chakra-ui/react';

const QRScanner: React.FC<{ onScan: (data: string) => void }> = ({ onScan }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isScanning, setIsScanning] = useState(true);

  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (!stream) {
      enableStream();
    } else {
      return function cleanup() {
        stream.getTracks().forEach((track) => track.stop());
      };
    }
  }, [stream]);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    video?.play();
    const ctx = canvas?.getContext('2d', { willReadFrequently: true });

    const scan = () => {
      if (video && canvas && ctx && isScanning) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

        if (code) {
          setIsScanning(false); // Stop scanning after successful detection
          onScan(code.data);
          stream?.getTracks().forEach((track) => track.stop()); // Stop the camera
        } else {
          // Scan every 500ms, adjust this value as needed
          setTimeout(scan, 100);
        }
      }
    };

    scan();
  }, [stream, onScan, isScanning]);

  return (
    <Box borderRadius={'xl'} border={'2px blue'}>
      <video ref={videoRef} style={{ width: '320px', height: '400px', borderRadius: '100px' }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </Box>
  );
};

export default QRScanner; */
