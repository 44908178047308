import {
  Card,
  Center,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { ExerciseGroup } from '../../../../models/subject';
import { useGetExamGroupDatePlaceInfo } from '../../../../services/queries';
import { GroupDateTimeInputModal } from './modals/GroupDateTimeInputModal';

type Props = {
  group: ExerciseGroup;
};

export const GroupDateTimeInput = ({ group }: Props) => {
  const { examID } = useParams();
  const colorScheme = useColorModeValue('gray.100', 'gray.900');

  const {
    isOpen: isEditGroupTimeOpen,
    onOpen: onEditGroupTimeOpen,
    onClose: onEditGroupTimeClose,
  } = useDisclosure();

  const groupsData = useGetExamGroupDatePlaceInfo(Number(examID), group.groupID);

  return groupsData.isLoading ? (
    <Center>
      <Spinner />
    </Center>
  ) : groupsData.data ? (
    <>
      <GroupDateTimeInputModal
        isOpen={isEditGroupTimeOpen}
        onClose={onEditGroupTimeClose}
        groupName={group.groupName}
        group={groupsData.data}
      />
      <Card w={'100%'} p={{ base: 2, md: 4 }} align={'center'} variant={'outline'} bg={colorScheme}>
        <Stack spacing={1} justifyContent={'center'} alignItems={'center'}>
          <Heading fontSize={'md'} pb={2}>
            Grupa {groupsData.data.groupName}
          </Heading>

          <HStack>
            <Text fontSize={'sm'}>Mjesto ispita:</Text>
            <Text fontSize={'sm'} fontWeight={'bold'}>
              {groupsData.data.place}
            </Text>
          </HStack>

          <HStack pb={5}>
            <Text fontSize={'sm'}>Datum i vrijeme:</Text>
            <Text fontSize={'sm'} fontWeight={'bold'}>
              {groupsData.data.dateTime}
            </Text>
          </HStack>

          <BlueButton
            buttonText='Izmijeni'
            height='25px'
            onClick={() => {
              onEditGroupTimeOpen();
            }}
          />
        </Stack>
      </Card>
    </>
  ) : (
    <Text>Nemate pristup informacijama</Text>
  );
};
