import { useEffect } from 'react';

// Custom hook to change the page title
export function useChangeTitle(title: string, defaultTitle: string = 'ISEUS') {
  useEffect(() => {
    document.title = title;

    // Reset to the default title on unmount
    return () => {
      document.title = defaultTitle;
    };
  }, [title, defaultTitle]);
}
