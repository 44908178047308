import { Heading, Stack } from '@chakra-ui/react';
import { Admin } from '../../../models/admin';
import BlogAuthor from './comp/Blog';

type Props = {};

const admin: Admin = {
  id: '0',
  firstName: 'Allen',
  lastName: 'Al-Shamali',
  profileImg: '/allen-profilna.jpg',
  email: 'allen@test.com',
};

const news = (props: Props) => {
  return (
    <>
      <Stack w={'90%'} mb={'3rem'} mt={'2rem'} align={'center'} mx={'auto'}>
        <Heading mx={'auto'} as='h1'>
          Novosti
        </Heading>
        <BlogAuthor
          key={'1'}
          tags={['Exams', 'Innovation', 'ISEUS', 'PTF', 'UNZE']}
          title='Automatizovani sistem za ispite'
          text='Automatizovani sistem za ispite je najnoviji dodatak koji omogućava profesorima i asistentima da efikasno organizuju ispite, prate prisustvo studenata, te vode evidenciju i upravljaju studentskim rezultatima.  '
          admin={admin}
          postImage='/novosti/exams-first-look.jpg'
          date='10.06.2024'
        />

        <BlogAuthor
          key={'2'}
          tags={['PTF', 'UNZE']}
          title='ISEUS'
          text='ISEUS aplikacija je svoju prvu upotrebu doživjela na Politehničkom fakultetu u Zenici, na predmetu Operativni sistemi i razvoj u oblaku. V. prof. dr. Denis Čeke, zajedno sa svojim saradnicima, koristio je aplikaciju u radu s preko 100 studenata. Ova inovativna aplikacija omogućila je efikasniju organizaciju ispita, praćenje prisustva, te evidenciju i upravljanje studentskim rezultatima, što je značajno doprinijelo poboljšanju nastavnog procesa.'
          admin={admin}
          postImage='/novosti/news1.png'
          date='01.02.2024'
        />

        {/* <Heading as='h2' marginTop='5'>
          Latest articles
        </Heading>
        <Divider marginTop='5' /> */}
      </Stack>

      {/* <Stack w={'80%'} m={'0 auto'} mb={'3rem'}>
        <LatestBlog />
      </Stack> */}
    </>
  );
};

export default news;
