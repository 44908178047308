import * as yup from 'yup';

export const staffCreateStudentSchema = yup.object({
  subjectID: yup.number().required(),
  indexNumber: yup.string().trim().required('Broj indexa je obavezan'),
  firstName: yup.string().trim().required('Ime je obavezno'),
  lastName: yup.string().trim().required('Prezime je obavezno'),
  email: yup.string().trim().email().required('Email je obavezan'),
});

export const staffAddStudentSchema = yup.object({
  indexNumber: yup.string().trim().required('Broj indexa je obavezan'),
  subjectID: yup.number().required(),
});

export const studentChangePasswordSchema = yup.object({
  oldPassword: yup.string().trim().required('Stara šifra je obavezna'),
  newPassword: yup
    .string()
    .trim()
    .min(8, 'Šifra mora sadržavati 8 znakova')
    .required('Nova šifra je obavezna'),
  repeatedPassword: yup
    .string()
    .trim()
    .min(8, 'Šifra mora sadržavati 8 znakova')
    .required('Nova šifra je obavezna')
    .oneOf([yup.ref('newPassword'), ''], 'Šifre se ne podudaraju'),
});

export const studentIDSchema = yup.object({
  studentID: yup.number().required(),
});

export type staffcreateStudentDto = yup.InferType<typeof staffCreateStudentSchema>;
export type staffAddStudentDto = yup.InferType<typeof staffAddStudentSchema>;
export type studentChangePasswordDto = yup.InferType<typeof studentChangePasswordSchema>;
export type studentIDDto = yup.InferType<typeof studentIDSchema>;
