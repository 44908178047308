import {
  Center,
  Flex,
  Heading,
  HStack,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetStudentResultsInfo } from '../../../services/queries';

export const CompletedSubjectStatPage = () => {
  const { subjectID } = useParams();
  const navigate = useNavigate();

  const results = useGetStudentResultsInfo(Number(subjectID));
  return (
    <Flex
      justify={'center'}
      align={'center'}
      flexDirection='column'
      p='5'
      bg={useColorModeValue('white', 'gray.900')}
      w='100%'
    >
      <Stack display={{ lg: 'none' }} pos={'absolute'} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student/polozeni-predmeti');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading textAlign={'center'} fontSize={'xl'} pb={5} w={'70%'}>
          {results.data?.subjectName ?? 'Zbir bodova'}
        </Heading>
      </HStack>
      {results.isLoading ? (
        <Center h={'20vh'}>
          <Spinner />
        </Center>
      ) : results.data ? (
        <Stack spacing={0} justify={'center'} align={'center'}>
          <TableContainer
            maxW={{ base: '100%', md: '350px' }}
            borderRadius={'xl'}
            border={'2px'}
            borderColor='blue.700'
          >
            <Table variant='striped' colorScheme='facebook' size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign='center' w={'5%'}>
                    naziv
                  </Th>
                  <Th textAlign='center' w={'30%'}>
                    <VStack spacing={0}>
                      <Text>Vaši</Text>
                      <Text>bodovi</Text>
                    </VStack>
                  </Th>
                  <Th textAlign='center' w={'5%'}>
                    <VStack spacing={0}>
                      <Text>max. </Text>
                      <Text>bodovi</Text>
                    </VStack>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td textAlign='center'>Predavanja</Td>
                  <Td textAlign='center'>{results.data.lecturesScore}</Td>
                  <Td textAlign='center'>{results.data.lecturesMaxScore}</Td>
                </Tr>
                <Tr>
                  <Td textAlign='center'>Vježbe</Td>
                  <Td textAlign='center'>{results.data.exercisesScore}</Td>
                  <Td textAlign='center'>{results.data.exercisesMaxScore}</Td>
                </Tr>
                <Tr>
                  <Td textAlign='center'>Dodatne aktivnosti</Td>
                  <Td textAlign='center'>{results.data.activitiesScore}</Td>
                  <Td textAlign='center'>{results.data.activitiesMaxScore}</Td>
                </Tr>
                <Tr>
                  <Td textAlign='center'>Ispiti</Td>
                  <Td textAlign='center'>{results.data.examScore}</Td>
                  <Td textAlign='center'>{results.data.examMaxScore}</Td>
                </Tr>
                {results.data.additionalPoints > 0 && (
                  <Tr>
                    <Td textAlign='center'>Dodatni bodovi</Td>
                    <Td textAlign='center'>{results.data.additionalPoints}</Td>
                    <Td textAlign='center'>-</Td>
                  </Tr>
                )}
                <Tr>
                  <Th textAlign='center'>Ukupno</Th>
                  <Th textAlign='center'>{results.data.totalScore}</Th>
                  <Th textAlign='center'>100</Th>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <HStack justify={'center'}>
            <Heading fontSize={'xl'} pt={10}>
              Konačna ocjena
            </Heading>
          </HStack>

          <Text fontSize={'5xl'} fontWeight={'bold'}>
            {results.data.grade}
          </Text>
        </Stack>
      ) : (
        <Heading textAlign={'center'} fontSize={'xl'}>
          Nema rezultata
        </Heading>
      )}
    </Flex>
  );
};
