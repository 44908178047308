import {
  Box,
  Card,
  Divider,
  HStack,
  Heading,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { subjectPost } from '../../../../models/posts';

interface BlogAuthorProps {
  date: string;
  name: string;
  title: string;
  img: string;
}

const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack justify={'space-between'} spacing='2' display='flex'>
      <HStack>
        <Image borderRadius='full' boxSize='45px' src={props.img} alt={`Avatar of ${props.name}`} />
        <VStack spacing={0} align={'start'} justify={'start'}>
          <Text fontSize={'14'} fontWeight='medium'>
            {props.title}
          </Text>
          <Text fontWeight='medium'>{props.name}</Text>
        </VStack>
      </HStack>

      <VStack spacing={0} align={'start'} justify={'start'}>
        <Text>{props.date}</Text>
        <Text alignSelf={'end'} fontSize={'14'}></Text>
      </VStack>
    </HStack>
  );
};

type Props = {
  post: subjectPost;
};

export const StudentPost = ({ post }: Props) => {
  return (
    <Card w={{ base: '85vw', md: '100%' }} p={{ base: 3, md: 5 }}>
      <Box display='flex' flex='1' flexDirection='column'>
        <BlogAuthor
          name={`${post.author.firstName} ${post.author.lastName}`}
          title={post.author.title}
          date={post.dateTimeCreated}
          img={post.author.profileImage}
        />

        <Heading py={2} textAlign={'center'} size={'md'} marginTop='1'>
          {post.title} {post.group && <> - grupa {post.group.name}</>}
        </Heading>
        <Text
          textAlign={'justify'}
          as='p'
          marginTop='2'
          color={useColorModeValue('gray.700', 'gray.200')}
          fontSize='lg'
          whiteSpace={'pre-line'}
        >
          {post.text}
        </Text>
      </Box>
    </Card>
  );
};
