import {
  Avatar,
  Box,
  Center,
  Checkbox,
  HStack,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetExercisesAttendanceStats } from '../../../../services/queries';
import { useNavigate } from 'react-router-dom';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

export const ExerciseAttendancesStatTable = () => {
  const navigate = useNavigate();
  const profContext = useProf();

  const groups = useGetExercisesAttendanceStats({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  return (
    <>
      {groups.isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        groups.data &&
        groups.data.map((group) => (
          <Box key={group.groupId} mb={10} maxW={'100%'}>
            <VStack align='stretch'>
              <Box p={4} boxShadow='md'>
                <Heading pb={4} fontSize={'l'}>
                  Grupa: {group.groupName}
                </Heading>
              </Box>
              <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
                <Table variant='striped' colorScheme='facebook' size='sm'>
                  <Thead>
                    <Tr>
                      <Th colSpan={4} textAlign='center'></Th>
                      <Th colSpan={group.numberOfExercises} textAlign='center'>
                        Datum i broj predavanja
                      </Th>
                    </Tr>
                    <Tr>
                      <Th colSpan={4} textAlign='center'>
                        podaci od studenta
                      </Th>
                      {group.exercisesDetails.map((lecture, index) => (
                        <Th
                          py={'5px'}
                          style={{
                            writingMode: 'vertical-rl',
                            transform: 'rotate(180deg)',
                          }}
                          key={`lecture-${index + 1}`}
                        >
                          {lecture.date}
                        </Th>
                      ))}
                    </Tr>
                    <Tr>
                      <Th textAlign='center'>RB</Th>
                      <Th textAlign='center'>Slika</Th>
                      <Th textAlign='center'>B. Indexa</Th>
                      <Th>Prezime i ime</Th>
                      {group.exercisesDetails.map((lecture, index) => (
                        <Th textAlign='center' key={`lecture-${index + 1}`}>{`V${index + 1}`}</Th>
                      ))}

                      <Th textAlign='center'>Br. dolazaka</Th>
                      <Th textAlign='center'>Uslov za potpis</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {group.students.length === 0 && (
                      <Tr>
                        <Td colSpan={6 + group.numberOfExercises}>
                          <Center>
                            <Text>Nema studenata u grupi</Text>
                          </Center>
                        </Td>
                      </Tr>
                    )}
                    {group.students.map((student, index) => (
                      <Tr key={student.id}>
                        <Td textAlign='center'>{index + 1}</Td>
                        <Td textAlign='center'>
                          <Avatar size='sm' src={student.profileImage} />
                        </Td>
                        <Td textAlign='center'>{student.indexNumber}</Td>
                        <Td>
                          <StudentNameSubjectStats
                            firstName={student.firstName}
                            lastName={student.lastName}
                            navigateLink={`/profesori/student/${student.id}`}
                            hasCompletedSubject={student.hasCompletedSubject}
                            repeater={student.repeater}
                          />
                        </Td>
                        {student.attendanceArray.map((attendance, index) => (
                          <Td textAlign='center' key={`lAttendanceChkBox-${index + 1}`}>
                            {/*  <Tooltip
                        key={`attendance-tooltip-${index}`}
                        label={lecturesQuery.data && lecturesQuery.data[index].date}
                      > */}
                            <Checkbox
                              size={'md'}
                              isChecked={attendance}
                              colorScheme='green'
                            ></Checkbox>
                            {/* </Tooltip> */}
                          </Td>
                        ))}
                        <Td
                          textAlign='center'
                          fontSize={'md'}
                          color={
                            group.numberOfExercises === student.attendedExercisesCount
                              ? 'green.400'
                              : student.fullfiledSignatureCondition
                              ? ''
                              : 'red.400'
                          }
                          fontWeight={'500'}
                        >
                          {`${student.attendedExercisesCount}/${group.numberOfExercises}`}
                        </Td>
                        <Td textAlign='center'>
                          <HStack justify={'center'}>
                            {student.fullfiledSignatureCondition ? (
                              <Text
                                fontWeight={'700'}
                                color={'green.400'}
                                border={'2px solid'}
                                borderRadius={'xl'}
                                width={'40px'}
                              >
                                DA
                              </Text>
                            ) : (
                              <Text
                                fontWeight={'700'}
                                color={'red.400'}
                                border={'2px solid'}
                                borderRadius={'xl'}
                                width={'40px'}
                              >
                                NE
                              </Text>
                            )}
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
          </Box>
        ))
      )}
    </>
  );
};
