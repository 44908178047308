import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useFacultysQuery, useGetUniversityID } from '../../../../services/queries';
import {
  adminCreateStudyProgramDto,
  adminCreateStudyProgramSchema,
} from '../../../../validation/admin-schema';
import { useAdminCreateStudyProgramMutation } from '../../../../services/mutations';

type Props = {};

export const CreateStudyProgram = (props: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [facultyID, setFacultyID] = useState(0);
  const universityID = useGetUniversityID();

  const facultyQuery = useFacultysQuery(universityID.data?.universityID);
  const createStudyProgram = useAdminCreateStudyProgramMutation();

  const handleCreateStudyProgram = async (values: adminCreateStudyProgramDto) => {
    setLoading(true);
    try {
      await createStudyProgram(values);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleFacultyChange = (facultyID: number) => {
    setFacultyID(facultyID);
  };

  return (
    <Box
      rounded={'lg'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      p={8}
      w='300px'
    >
      <Formik
        initialValues={{
          name: '',
          shortName: '',
          facultyID: facultyID,
        }}
        validationSchema={adminCreateStudyProgramSchema}
        onSubmit={async (values) => {
          handleCreateStudyProgram(values);
        }}
      >
        {() => (
          <Form>
            <Stack spacing={4}>
              {facultyQuery.data && facultyQuery.data?.length !== 0 ? (
                facultyQuery.isLoading ? (
                  <Spinner />
                ) : (
                  //  ****  Faculty selection field *****  //
                  <Field name={'facultyID'}>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id={'facultyID'}
                        isRequired
                        isInvalid={form.errors.facultyID && form.touched.facultyID}
                      >
                        <VStack w='100%' align={'start'}>
                          <FormLabel>Fakultet</FormLabel>
                          <VStack>
                            <Select
                              placeholder={'Izaberite Fakultet'}
                              w={'100%'}
                              {...field}
                              onChange={(e) => {
                                form.setFieldValue('facultyID', Number(e.target.value));
                                handleFacultyChange(Number(e.target.value));
                              }}
                            >
                              {facultyQuery.data.length > 0 &&
                                facultyQuery.data.map((data, index) => (
                                  <option
                                    value={data.id}
                                    key={index}
                                  >{`${data.name} -  ${data.shortName}`}</option>
                                ))}
                            </Select>
                          </VStack>

                          <ErrorMessage name={'facultyID'}>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </VStack>
                      </FormControl>
                    )}
                  </Field>
                )
              ) : (
                <Center w='100%'>
                  <Spinner />
                </Center>
              )}
              <Box>
                <Field name='name'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='name'
                      isRequired
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel>Naziv</FormLabel>
                      <Input type='text' {...field} />
                      <ErrorMessage name='name'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box>
                <Field name='shortName'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='shortName'
                      isRequired
                      isInvalid={form.errors.shortName && form.touched.shortName}
                    >
                      <FormLabel>Skračenica</FormLabel>
                      <Input type='text' {...field} />
                      <ErrorMessage name='shortName'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>

              <Stack spacing={10} pt={2}>
                <Button
                  type='submit'
                  isLoading={isLoading}
                  loadingText='Kreiranje u toku...'
                  size='md'
                  bg='blue.400'
                  color='white'
                  _hover={{
                    bg: 'blue.500',
                  }}
                >
                  Kreiraj studijski program
                </Button>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
