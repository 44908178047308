import { ProfessorAssistent } from '../../../../models/professorAssistent';
import { StaffAccessReqCard } from './StaffAccessReqCard';

type Props = {
  professors: ProfessorAssistent[] | undefined;
};

const ProfessorsWantToJoin = (props: Props) => {
  return (
    <>
      {props.professors?.map((staff) => (
        <StaffAccessReqCard key={staff.id} staff={staff} />
      ))}
    </>
  );
};

export default ProfessorsWantToJoin;
