import React from 'react';
import { useNavigate } from 'react-router-dom';
import useProf from '../../../../hooks/useProf.hooks';
import { Flex, HStack, Heading, useColorModeValue } from '@chakra-ui/react';
import { ArrowBackButton } from '../../../../components/general/ArrowBackButton';
import { ActiveStudentstable } from './ActiveStudentsTable';
import { FinishedStudentstable } from './FinishedStudentsTable';

type Props = {};

export const StudentsOnSubjectPage = (props: Props) => {
  const navigate = useNavigate();
  const professorContext = useProf();

  const handleBackClick = () => {
    navigate('/profesori/studenti');
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading pb={4} fontSize={'xl'} textAlign={'center'}>
          Pregled svih studenata na predmetu
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <Heading pt={4} pb={2} fontSize={'l'}>
        Svi aktivni studenti
      </Heading>
      <ActiveStudentstable />
      <Heading pt={6} pb={2} fontSize={'l'}>
        Studenti položili predmet
      </Heading>
      <FinishedStudentstable />
    </Flex>
  );
};
