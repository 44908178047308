import { Flex, HStack, Heading, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useGetExercisesPerGroupInfo } from '../../../services/queries';

import { ExerciseAttendancesTable } from './comp/ExerciseAttendancesTable';
import { ExerciseAttendancesInfo } from './comp/ExerciseAttendancesInfo';

type Props = {};

const ExerciseAttendances = (props: Props) => {
  const { groupID, exerciseNumber, exerciseID } = useParams();
  const navigate = useNavigate();

  const exercisesInfoQuery = useGetExercisesPerGroupInfo(Number(groupID));

  const handleBackClick = () => {
    navigate(`/profesori/vjezbe/${groupID}`);
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading textAlign={'center'} pb={4} fontSize={'xl'}>
          Prisustva na {exerciseNumber}. vježbama grupe {exercisesInfoQuery.data?.groupName}
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <ExerciseAttendancesInfo />
      <ExerciseAttendancesTable />
    </Flex>
  );
};

export default ExerciseAttendances;
