import { Outlet, useNavigate } from 'react-router-dom';

import Nav from './comp/Nav';
import Footer from './comp/Footer';
import useAuth from '../../hooks/useAuth.hooks';
import { Fragment, useEffect } from 'react';

type Props = {};

const Landing: React.FC = (props: Props) => {
  const authContext = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.user?.role === 'STUDENT') {
      navigate('/student');
    } else if (authContext.user?.role === 'PROFESSOR' || authContext.user?.role === 'ASSISTANT') {
      navigate('/profesori');
    } else if (authContext.user?.role === 'ADMIN') {
      navigate('/admin');
    }
  }, []);

  return (
    <>
      <Nav />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Landing;
