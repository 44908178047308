import {
  Center,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { LectureExercises } from '../../../../models/student';

type Props = {
  attendances: LectureExercises[];
};

export const StudLectureAttendancesTable = (props: Props) => {
  return (
    <>
      <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
        <Table variant='striped' colorScheme='facebook' size='md'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>

              <Th textAlign='center'>Datum</Th>
              <Th textAlign='center'>Prisustvo</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.attendances.length === 0 ? (
              <Tr>
                <Td colSpan={3}>
                  <Center>
                    <Text> Nema kreiranih predavanja </Text>
                  </Center>
                </Td>
              </Tr>
            ) : (
              props.attendances.map((attendance, index) => (
                <Tr key={`lecture-attendance-${index}`}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td textAlign='center'>{attendance.date}</Td>
                  <Td textAlign='center'>
                    <Checkbox
                      size={'md'}
                      isChecked={attendance.presence}
                      colorScheme='green'
                    ></Checkbox>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
