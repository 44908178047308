import {
  Avatar,
  Button,
  Center,
  HStack,
  Input,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetStudentsSignatureStats } from '../../../../services/queries';
import { useToggleStudentSignature } from '../../../../services/mutations';
import { StudentSignatureStat } from '../../../../models/subject';
import { ToggleSignatureModal } from './ToggleSignatureModal';
import useAuth from '../../../../hooks/useAuth.hooks';

interface LoadingStates {
  [key: number]: boolean;
}

export const SignatureStatsTable = () => {
  const authContext = useAuth();
  const profContext = useProf();
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();

  const {
    isOpen: isToggleSignatureModalOpen,
    onOpen: onToggleSignatureModalOpen,
    onClose: onToggleSignatureModalClose,
  } = useDisclosure();

  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const [isSetSignatureModalOpen, setIsSetSignatureModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<StudentSignatureStat | null>(null);

  const students = useGetStudentsSignatureStats(profContext.selectedSubjectID);
  const toggleSignatue = useToggleStudentSignature(profContext.selectedSubjectID);

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  const handleGiveSignature = async (student: StudentSignatureStat) => {
    setLoadingStates((prev) => ({ ...prev, [student.id]: true }));
    await toggleSignatue({
      studentID: student.id,
      subjectID: profContext.selectedSubjectID,
      comment: null,
    })
      .catch((err) => {})
      .finally(() => {
        setTimeout(() => {
          setLoadingStates((prev) => ({ ...prev, [student.id]: false }));
          setIsSetSignatureModalOpen(false);
        }, 1000);
      });
  };

  const filteredStudents = students.data?.filter(
    (student) =>
      student.indexNumber.includes(filter) ||
      student.lastName.toLowerCase().includes(filter.toLowerCase()) ||
      student.firstName.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <YesNoModal
        isOpen={isSetSignatureModalOpen}
        onClose={() => setIsSetSignatureModalOpen(false)}
        onConfirm={() => {
          if (selectedStudent) {
            handleGiveSignature(selectedStudent);
          }
        }}
        title='Promjena stanja potpisa'
        message={`Da li ste sigurni da želite promijeniti stanje potpisa studentu ${selectedStudent?.lastName} ${selectedStudent?.firstName} (${selectedStudent?.indexNumber})?`}
        isLodaing={loadingStates[selectedStudent?.id || -1]}
      />
      <ToggleSignatureModal
        student={selectedStudent}
        isOpen={isToggleSignatureModalOpen}
        onClose={onToggleSignatureModalClose}
      />
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', md: 'space-between' }}
        w='90%'
        align={'center'}
        spacing={2}
      >
        <Input
          w='250px'
          placeholder='Pretraži studente'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <Text w={'90%'} fontSize='sm' color='gray.500' textAlign={'center'}>
          Svi časovi od predavanja i vježbi moraju biti iskorišteni da bi mogli dodijeliti potpis
          studentima.
        </Text>
      </Stack>
      <TableContainer
        mt={'1rem'}
        w={'90%'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>Ime i prezime</Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>broj</Text>
                  <Text>indexa</Text>
                </VStack>
              </Th>
              <Th textAlign='center'>opcije</Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>uslov</Text>
                  <Text>za potpis</Text>
                </VStack>
              </Th>

              <Th textAlign='center'>Potpis</Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>predavanja</Text>
                  <Text fontSize={'2xs'}>prisustva / ukupno</Text>
                </VStack>
              </Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>uslov</Text>
                  <Text>predavanja</Text>
                </VStack>
              </Th>
              <Th textAlign='center'>Grupa</Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>vježbe</Text>
                  <Text fontSize={'2xs'}>prisustva / ukupno</Text>
                </VStack>
              </Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>uslov</Text>
                  <Text>vježbi</Text>
                </VStack>
              </Th>

              <Th textAlign='center'>Komentar</Th>
              <Th textAlign='center'>RB</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : students.isError ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <VStack>
                      <Text>{getErrorMessage(students.error)}</Text>
                      <Button
                        fontSize={'sm'}
                        bgColor={'blue.600'}
                        color={'white'}
                        onClick={() => {
                          navigate('/profesori/studenti/pregled-stanja-prisustva');
                        }}
                      >
                        Vrati se na prisustva
                      </Button>
                    </VStack>
                  </Center>
                </Td>
              </Tr>
            ) : filteredStudents && filteredStudents.length > 0 ? (
              filteredStudents.map((attendance, index) => (
                <Tr key={attendance.id}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={attendance.profileImage} />
                  </Td>
                  <Td textAlign='center'>
                    <VStack spacing={1}>
                      <Text
                        onClick={() => {
                          navigate(`/profesori/student/${attendance.id}`);
                        }}
                        cursor={'pointer'}
                      >
                        {attendance.lastName} {attendance.firstName}
                      </Text>
                      {attendance.repeater && (
                        <Text fontSize={'2xs'} border={'1px solid'} borderRadius={'lg'} px={1}>
                          Ponovac
                        </Text>
                      )}
                    </VStack>
                  </Td>
                  <Td textAlign='center'>{attendance.indexNumber}</Td>
                  <Td textAlign='center'>
                    <HStack
                      visibility={
                        attendance.repeater && attendance.signature ? 'hidden' : 'visible'
                      }
                    >
                      <BlueButton
                        height='25px'
                        width='110px'
                        buttonText={attendance.signature ? 'Ukloni potpis' : 'Dodijeli potpis'}
                        onClick={() => {
                          if (attendance.signatureRequirmentMet || attendance.signature) {
                            setSelectedStudent(attendance);
                            setIsSetSignatureModalOpen(true);
                          } else {
                            setSelectedStudent(attendance);
                            onToggleSignatureModalOpen();
                          }
                        }}
                        isLoading={loadingStates[attendance.id]}
                        isDisabled={authContext.user?.role !== 'PROFESSOR'}
                      />
                    </HStack>
                  </Td>
                  <Td textAlign='center'>
                    {attendance.signatureRequirmentMet ? (
                      <Text fontWeight={'bold'} color='green.400'>
                        Ispunjen
                      </Text>
                    ) : (
                      <Text fontWeight={'bold'} color='red.500'>
                        Nije ispunjen
                      </Text>
                    )}
                  </Td>

                  <Td textAlign='center'>
                    {attendance.signature ? (
                      <Text
                        fontWeight={'700'}
                        color={'green.400'}
                        border={'2px solid'}
                        borderRadius={'xl'}
                        width={'40px'}
                      >
                        DA
                      </Text>
                    ) : (
                      <Text
                        fontWeight={'700'}
                        color={'red.400'}
                        border={'2px solid'}
                        borderRadius={'xl'}
                        width={'40px'}
                      >
                        NE
                      </Text>
                    )}
                  </Td>
                  {attendance.repeater && attendance.signatureRequirmentMet ? (
                    <Td colSpan={5}>
                      <Center>
                        <Text> Student je ponovac i ima potpis.</Text>
                      </Center>
                    </Td>
                  ) : (
                    <>
                      <Td textAlign='center'>
                        {attendance.lecturesAttendanceSum} / {attendance.lecturesCreated}
                      </Td>
                      <Td textAlign='center'>
                        {attendance.lecturesRequirmentMet ? (
                          <Text fontWeight={'bold'} color='green.400'>
                            Ispunjen
                          </Text>
                        ) : (
                          <Text fontWeight={'bold'} color='red.500'>
                            Nije ispunjen
                          </Text>
                        )}
                      </Td>
                      <Td textAlign='center'>{attendance.groupName}</Td>
                      <Td textAlign='center'>
                        {attendance.exercisesAttendanceSum} / {attendance.createdExercises}
                      </Td>
                      <Td textAlign='center'>
                        {attendance.exercisesRequirmentMet ? (
                          <Text fontWeight={'bold'} color='green.400'>
                            Ispunjen
                          </Text>
                        ) : (
                          <Text fontWeight={'bold'} color='red.500'>
                            Nije ispunjen
                          </Text>
                        )}
                      </Td>

                      <Td textAlign='center'>
                        <Text>{attendance.comment}</Text>
                      </Td>
                      <Td textAlign='center'>{index + 1}</Td>
                    </>
                  )}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={14}>
                  <Center>
                    <Text> Nema studenata </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {filteredStudents && filteredStudents.length > 8 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>Ime i prezime</Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>broj</Text>
                    <Text>indexa</Text>
                  </VStack>
                </Th>
                <Th textAlign='center'>opcije</Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>uslov</Text>
                    <Text>za potpis</Text>
                  </VStack>
                </Th>

                <Th textAlign='center'>Potpis</Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>predavanja</Text>
                    <Text fontSize={'2xs'}>prisustva / ukupno</Text>
                  </VStack>
                </Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>uslov</Text>
                    <Text>predavanja</Text>
                  </VStack>
                </Th>
                <Th textAlign='center'>Grupa</Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>vježbe</Text>
                    <Text fontSize={'2xs'}>prisustva / ukupno</Text>
                  </VStack>
                </Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>uslov</Text>
                    <Text>vježbi</Text>
                  </VStack>
                </Th>

                <Th textAlign='center'>Komentar</Th>
                <Th textAlign='center'>RB</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
