import { Button } from '@chakra-ui/react';

type Props = {
  height?: string;
  width?: string;
  fontSize?: string;
  bgColor?: string;
  color?: string;
  onClick?: () => void;
  buttonText: string;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const RedButton = (props: Props) => {
  return (
    <Button
      w={props.width}
      h={props.height}
      fontSize={props.fontSize ?? 'sm'}
      bgColor={'red.600'}
      color={'white'}
      onClick={props.onClick}
      _hover={{
        bg: 'red.300',
        color: 'red.900',
      }}
      isLoading={props.isLoading ?? false}
      isDisabled={props.isDisabled ?? false}
    >
      {props.buttonText}
    </Button>
  );
};
