import { Button, Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { AcademicYearSelectionField } from '../../../../components/general/academicYearSelectionField';
import useProf from '../../../../hooks/useProf.hooks';
import {
  useGetAcademicYears,
  useGetLectures,
  useGetLecturesInfo,
} from '../../../../services/queries';

type Props = {};

export const LecturesInfo = (props: Props) => {
  const profContext = useProf();
  const years = useGetAcademicYears(profContext.selectedSubjectID);

  const lecturesInfoQuery = useGetLecturesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const lecturesQuery = useGetLectures({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
    lecturesQuery.refetch();
    lecturesInfoQuery.refetch();
  };

  return (
    <>
      <VStack>
        <AcademicYearSelectionField
          years={years}
          handleOnChangeAcademicYear={handleOnChangeAcademicYear}
          profContext={profContext}
        />

        {lecturesInfoQuery.isLoading ? (
          <Center h={'20vh'}>
            <Spinner />
          </Center>
        ) : lecturesInfoQuery.data ? (
          <VStack align={'start'} spacing={0} pt={2}>
            <HStack align={'center'}>
              <Text>Broj održanih predavanja: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {lecturesInfoQuery.data.numberOfLectures}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Preostali broj časova:</Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {lecturesInfoQuery.data.classHours - lecturesInfoQuery.data.finishedClassHours} /
                {lecturesInfoQuery.data.classHours}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Težinski faktor: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {lecturesInfoQuery.data.difficultyFactor}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Da li je prisustvo uslov za potpis: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {lecturesInfoQuery.data.attendanceReqForSignature ? 'DA' : 'NE'}
              </Text>
            </HStack>

            {lecturesInfoQuery.data.attendanceReqForSignature && (
              <HStack align={'center'}>
                <Text> Broj dozvoljenih izostanaka:</Text>
                <Text fontWeight={'700'} fontSize={'lg'}>
                  {lecturesInfoQuery.data.maxAllowedAbsence}
                </Text>
              </HStack>
            )}
          </VStack>
        ) : (
          <VStack align={'start'}>
            <Text>Kreirana je nova akademska godina.</Text>
            <Text>Da li želite mjenjati podatke o predavanjima?</Text>
            <Button w={'100%'} bgColor={'blue.600'}>
              Uvezi iz prošle godine
            </Button>
            <Button w={'100%'} bgColor={'blue.600'}>
              Unesi nove podatke
            </Button>
          </VStack>
        )}
      </VStack>
    </>
  );
};
