import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import useProf from '../../../../hooks/useProf.hooks';
import { useDeleteLectureMutation, useToggleLectureScanner } from '../../../../services/mutations';
import { useGetLectures } from '../../../../services/queries';
import { QRCodeModal } from '../../comp/QRCodeModal';
import { EditLectureModal } from './EditLectureModal';
import useAuth from '../../../../hooks/useAuth.hooks';

interface LoadingStates {
  [key: number]: boolean;
}

export const LecturesTable = () => {
  const authContext = useAuth();
  const profContext = useProf();
  const lecturesQuery = useGetLectures({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });
  const toast = useToast();

  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isQRCodeOpen, onOpen: onQRCodeOpen, onClose: onQRCodeClose } = useDisclosure();
  const [deleteLectureOpen, setDeleteLectureOpen] = useState(false);
  const [QRCode, setQRCode] = useState('');
  const [lectureID, setLectureID] = useState(Number || null);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

  const deleteLecture = useDeleteLectureMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const switchScanner = useToggleLectureScanner(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const handleOpenQRCodeModal = (QRCode: string) => {
    setQRCode(QRCode);
    onQRCodeOpen();
  };

  const handleOpenEditModal = (lectureID: number) => {
    setLectureID(lectureID);
    onEditOpen();
  };

  const deleteLectureHandler = (lectureID: number) => {
    setLectureID(lectureID);
    setDeleteLectureOpen(true);
  };

  return (
    <>
      <QRCodeModal
        isOpen={isQRCodeOpen}
        onClose={onQRCodeClose}
        QRCode={QRCode}
        title='QR kod predavanja'
        link='student/predavanja'
      />
      <EditLectureModal isOpen={isEditOpen} onClose={onEditClose} lectureID={lectureID} />
      <YesNoModal
        isOpen={deleteLectureOpen}
        onClose={() => setDeleteLectureOpen(false)}
        onConfirm={() => {
          deleteLecture(lectureID).catch((err) => {});
          setDeleteLectureOpen(false);
        }}
        title='Brisanje predavanja'
        message='Da li ste sigurni da želite izbrisati predavanje?'
      />
      <TableContainer
        mt={'3rem'}
        w={'1100px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center' w={'5%'}>
                RB
              </Th>
              <Th textAlign='center' w={'30%'}>
                Naslov predavanja
              </Th>
              <Th textAlign='center' w={'5%'}>
                Broj Časova
              </Th>
              <Th textAlign='center' w={'15%'}>
                Datum predavanja
              </Th>
              <Th textAlign='center' w={'10%'}>
                Skeniranje
              </Th>
              <Th textAlign='center' w={'10%'}>
                Prisustva
              </Th>
              <Th textAlign='center' w={'10%'}>
                QR
              </Th>
              <Th textAlign='center' w={'10%'}>
                Opcije
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {lecturesQuery.isLoading ? (
              <Tr>
                <Td colSpan={8}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : lecturesQuery.data && lecturesQuery.data.length > 0 ? (
              lecturesQuery.data.map((lecture, index) => (
                <Tr key={lecture.id}>
                  <Td textAlign='center' w={'5%'}>
                    {index + 1}
                  </Td>
                  <Td textAlign='center' w={'35%'}>
                    {lecture.name}
                  </Td>
                  <Td textAlign='center' w={'5%'}>
                    {lecture.classHours}
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    {lecture.date}
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <Switch
                      size='sm'
                      isDisabled={
                        authContext.user?.role === 'ASSISTANT' || loadingStates[lecture.id]
                      }
                      isChecked={lecture.scannerActive}
                      onChange={() => {
                        try {
                          setLoadingStates((prev) => ({ ...prev, [lecture.id]: true }));
                          switchScanner(lecture.id);
                        } catch (error) {
                        } finally {
                          setTimeout(() => {
                            setLoadingStates((prev) => ({ ...prev, [lecture.id]: false }));
                          }, 1000);
                        }
                      }}
                    />
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <Button
                      as={NavLink}
                      to={`${index + 1}/${lecture.id}`}
                      size='xs'
                      colorScheme='blue'
                    >
                      Prisustva
                    </Button>
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <Button
                      size='xs'
                      colorScheme='blue'
                      onClick={() => {
                        handleOpenQRCodeModal(lecture.QRCode);
                      }}
                    >
                      QR kod
                    </Button>
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <EditIcon
                      color={'white'}
                      boxSize={5}
                      mr={'10px'}
                      onClick={() => {
                        if (authContext.user?.role === 'PROFESSOR') {
                          handleOpenEditModal(lecture.id);
                        } else {
                          toast({
                            position: 'top',
                            title: 'Nemate ovlaštenje za ovu akciju',
                            status: 'warning',
                            duration: 3000,
                          });
                        }
                      }}
                      _hover={{ cursor: 'pointer', color: 'green.500' }}
                    />
                    <DeleteIcon
                      color={'white'}
                      boxSize={5}
                      onClick={() => {
                        if (authContext.user?.role === 'PROFESSOR') {
                          deleteLectureHandler(lecture.id);
                        } else {
                          toast({
                            position: 'top',
                            title: 'Nemate ovlaštenje za ovu akciju',
                            status: 'warning',
                            duration: 3000,
                          });
                        }
                      }}
                      _hover={{ cursor: 'pointer', color: 'red.500' }}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <Center>Nema predavanja</Center>
                </Td>
              </Tr>
            )}
          </Tbody>
          {lecturesQuery.data && lecturesQuery.data.length > 5 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center' w={'5%'}>
                  RB
                </Th>
                <Th textAlign='center' w={'35%'}>
                  Naslov predavanja
                </Th>
                <Th textAlign='center' w={'5%'}>
                  Broj Časova
                </Th>
                <Th textAlign='center' w={'10%'}>
                  Datum predavanja
                </Th>
                <Th textAlign='center' w={'10%'}>
                  Skeniranje
                </Th>
                <Th textAlign='center' w={'10%'}>
                  prisustva
                </Th>
                <Th textAlign='center' w={'10%'}>
                  QR
                </Th>
                <Th textAlign='center' w={'10%'}>
                  Opcije
                </Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
