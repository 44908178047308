import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  navigateLink: string;
  firstName: string;
  lastName: string;
  repeater: boolean;
  hasCompletedSubject: boolean;
};

export const StudentNameSubjectStats = ({
  navigateLink,
  firstName,
  lastName,
  repeater,
  hasCompletedSubject,
}: Props) => {
  const navigate = useNavigate();

  return (
    <VStack spacing={1}>
      <Text
        onClick={() => {
          navigate(navigateLink);
        }}
        cursor={'pointer'}
      >
        {lastName} {firstName}
      </Text>
      <HStack>
        {repeater && (
          <Text fontSize={'2xs'} border={'1px solid'} borderRadius={'lg'} px={1}>
            Ponovac
          </Text>
        )}
        {hasCompletedSubject && (
          <Text
            fontSize={'xs'}
            border={'1px solid'}
            borderRadius={'lg'}
            px={1}
            color={'green.400'}
            fontWeight={'700'}
            letterSpacing={'1px'}
          >
            PP
          </Text>
        )}
      </HStack>
    </VStack>
  );
};
