import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Divider,
  Center,
} from '@chakra-ui/react';
import { BlueButton } from './BlueButton';
import { RedButton } from './RedButton';

type UniversalModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  isLodaing?: boolean;
};

export const YesNoModal: React.FC<UniversalModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  isLodaing,
}) => {
  return (
    <Modal isCentered size={'lg'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{message}</Text>
        </ModalBody>

        <ModalFooter justifyContent={'center'}>
          <RedButton height='30px' width='60px' buttonText='NE' onClick={onClose} />
          <Center px={'20px'} height='30px'>
            <Divider orientation='vertical' />
          </Center>
          <BlueButton
            isLoading={isLodaing}
            height='30px'
            width='60px'
            buttonText='DA'
            onClick={onConfirm}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
