import {
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../../components/general/BlueSubmitButton';
import useProf from '../../../../../hooks/useProf.hooks';
import { useGetActiveStudentsOnSubject } from '../../../../../services/queries';
import { addStudentToExamSchema } from '../../../../../validation/exam-schema';
import SearchableSelect from '../../../comp/SearchableSelect';
import { useAddStudentToExam } from '../../../../../services/mutations';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  examID: number;
};

export const AddStudentToExamModal = ({ isOpen, onClose, examID }: Props) => {
  const profContext = useProf();
  const [isLoading, setIsLoading] = useState(false);

  const students = useGetActiveStudentsOnSubject(profContext.selectedSubjectID);
  const addStudent = useAddStudentToExam(examID);

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Dodaj studenta na ispit</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Formik
              initialValues={{
                examID,
                studentID: 0,
              }}
              validationSchema={addStudentToExamSchema}
              onSubmit={async (values, actions) => {
                setIsLoading(true);
                try {
                  await addStudent(values);
                } catch (error) {
                } finally {
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 1000);
                }
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Stack
                    spacing={4}
                    justifyContent={{ base: 'start', md: 'center' }}
                    alignItems={'center'}
                  >
                    {students.isLoading ? (
                      <Center>
                        <Spinner />
                      </Center>
                    ) : students.data && students.data.length > 0 ? (
                      <SearchableSelect
                        label='Izaberite studenta'
                        name='studentID'
                        options={students.data?.map((student) => ({
                          value: student.id,
                          label: `${student.firstName} ${student.lastName} (${student.indexNumber})`,
                        }))}
                        placeholder='Izaberite studenta'
                        onChange={(value) => setFieldValue('studentID', value)}
                      />
                    ) : (
                      <Text>Nema studenata na predmetu.</Text>
                    )}

                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Dodaj studenta'
                        loadingText='Dodavanje...'
                        isLoading={isLoading}
                      />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
