import {
  HStack,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { subjectActivityCR } from '../../../../models/student-complete-report';

type Props = {
  subjectActivity: subjectActivityCR;
};

export const SubjectActivities = ({ subjectActivity }: Props) => {
  return (
    <VStack justify={'center'}>
      <HStack justify={'center'}>
        <Heading fontSize={'lg'}>Dodatne aktivnosti</Heading>
      </HStack>
      <HStack spacing={4} justify={'flex-end'} w={'80%'}>
        <Text fontSize={'md'}>| Bodovi: {subjectActivity.sumScoreDF} |</Text>
      </HStack>
      <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>Ime aktivnosti</Th>
              <Th textAlign='center'>Bodovi</Th>
            </Tr>
          </Thead>
          <Tbody>
            {subjectActivity.subjectActivities.map((activity, index) => (
              <Tr key={`lecture-${index}`}>
                <Td textAlign='center'>{activity.name}</Td>
                <Td textAlign='center'>
                  {activity.scoreDF} / {activity.maxScore}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
