import {
  Checkbox,
  HStack,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { exercisesInfoCR } from '../../../../models/student-complete-report';

type Props = {
  exerciseInfo: exercisesInfoCR | null;
};

export const ExercisesInfo = ({ exerciseInfo }: Props) => {
  return (
    <VStack justify={'center'}>
      <HStack justify={'center'}>
        <Heading fontSize={'lg'}>Vježbe</Heading>
      </HStack>
      {exerciseInfo ? (
        <>
          <HStack spacing={4}>
            <Text fontSize={'md'}>
              Ukupno dolazaka: {exerciseInfo.attendedExercises} / {exerciseInfo.totalExercises}
            </Text>
            <Text fontSize={'md'}>| Bodovi: {exerciseInfo.scoreDF} |</Text>
          </HStack>
          <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
            <Table variant='striped' colorScheme='facebook' size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign='center'>RB</Th>
                  <Th textAlign='center'>Datum</Th>
                  <Th textAlign='center'>Prisustvo</Th>
                </Tr>
              </Thead>
              <Tbody>
                {exerciseInfo.exercises.map((attendance, index) => (
                  <Tr key={`lecture-${index}`}>
                    <Td textAlign='center'>{index + 1}</Td>
                    <Td textAlign='center'>{attendance.date}</Td>
                    <Td textAlign='center'>
                      <Checkbox
                        size={'md'}
                        isChecked={attendance.attendance}
                        colorScheme='green'
                      ></Checkbox>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Text>Student nije dodan u grupu</Text>
      )}
    </VStack>
  );
};
