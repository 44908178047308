import { PATH_PUBLIC, PATH_ADMIN, PATH_STAFF, PATH_STUDENT } from './path';

// URLS
export const HOST_API_KEY = `${process.env.REACT_APP_BACKEND_API}/api`;
export const REGISTER_URL = '/auth/register';
export const LOGIN_URL = '/auth/login';
export const ADMIN_LOGIN_URL = '/auth/adminlogin';
export const STUDENT_LOGIN_URL = '/auth/studentlogin';
export const REFRESH_URL = '/auth/refresh';

export const GET_SUBJECTS_URL = '/professor/get-subjects';
export const GET_STUDENT_SUBJECTS_URL = '/student/get-subjects';
export const GET_SELECTED_SUBJECT_INFO_URL = '/professor/get-selected-subject-info';
export const GET_ALL_UNIVERSITIES = '/organisation/all-universities';
export const GET_ACADEMIC_YEARS = 'organization/get-years';

// AUTH routes
export const PATH_AFTER_REGISTER = PATH_PUBLIC.login;

export const PATH_ADMIN_AFTER_LOGIN = PATH_ADMIN.home;
export const PATH_STAFF_AFTER_LOGIN = PATH_STAFF.home;
export const PATH_STUDENT_AFTER_LOGIN = PATH_STUDENT.home;

export const PATH_AFTER_LOGOUT = PATH_PUBLIC.home;

export interface AxiosErrorResponse {
  data: {
    message: string;
    name: string;
    status: number;
  };
  status: number;
  statusText: string;
}
