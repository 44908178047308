import {
  Avatar,
  Button,
  Center,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RedButton } from '../../../../components/general/RedButton';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetStudentsWantToJoinsubject } from '../../../../services/queries';
import {
  useApproveStudentToSubjectMutation,
  useRejectStudentToSubjectMutation,
} from '../../../../services/mutations';

interface LoadingStates {
  [key: number]: boolean;
}

export const StudentsWantToJoinSubjectTable = () => {
  const profContext = useProf();
  const authContext = useAuth();
  const navigate = useNavigate();
  const [approveLoadingStates, setApproveLoadingStates] = useState<LoadingStates>({});
  const [denyLoadingStates, setDenyLoadingStates] = useState<LoadingStates>({});

  const students = useGetStudentsWantToJoinsubject(profContext.selectedSubjectID);
  const approveStudent = useApproveStudentToSubjectMutation(profContext.selectedSubjectID);
  const rejectStudent = useRejectStudentToSubjectMutation(profContext.selectedSubjectID);

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  return (
    <>
      <TableContainer
        mt={'1rem'}
        w={'800px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>Ime i prezime</Th>
              <Th textAlign='center'>Broj Indexa</Th>
              <Th textAlign='center'>E-mail adresa</Th>
              <Th textAlign='center'>opcije</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={6}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : students.isError ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <VStack>
                      <Text>{getErrorMessage(students.error)}</Text>
                      <Button
                        fontSize={'sm'}
                        bgColor={'blue.600'}
                        color={'white'}
                        onClick={() => {
                          navigate('/profesori/predavanja');
                        }}
                      >
                        Vrati se na sva vježbe
                      </Button>
                    </VStack>
                  </Center>
                </Td>
              </Tr>
            ) : students.data && students.data.length > 0 ? (
              students.data.map((attendance, index) => (
                <Tr key={attendance.id}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={attendance.profileImage} />
                  </Td>
                  <Td textAlign='center'>
                    {attendance.lastName} {attendance.firstName}
                  </Td>
                  <Td textAlign='center'>{attendance.indexNumber}</Td>
                  <Td textAlign='center'>{attendance.email}</Td>
                  <Td textAlign='center'>
                    <HStack>
                      <Button
                        height='30px'
                        backgroundColor={'green.500'}
                        _hover={{
                          backgroundColor: 'green.300',
                          cursor: 'pointer',
                          color: 'gray.800',
                        }}
                        fontSize={'sm'}
                        color={'white'}
                        onClick={() => {
                          setApproveLoadingStates((prev) => ({ ...prev, [attendance.id]: true }));
                          approveStudent({
                            studentID: attendance.id,
                            subjectID: profContext.selectedSubjectID,
                          })
                            .catch((err) => {})
                            .finally(() => {
                              setTimeout(() => {
                                setApproveLoadingStates((prev) => ({
                                  ...prev,
                                  [attendance.id]: false,
                                }));
                              }, 1000);
                            });
                        }}
                        isLoading={approveLoadingStates[attendance.id]}
                      >
                        Odobri
                      </Button>
                      <RedButton
                        height='30px'
                        buttonText='Odbij'
                        onClick={() => {
                          setDenyLoadingStates((prev) => ({ ...prev, [attendance.id]: true }));
                          rejectStudent({
                            studentID: attendance.id,
                            subjectID: profContext.selectedSubjectID,
                          })
                            .catch((err) => {})
                            .finally(() => {
                              setTimeout(() => {
                                setDenyLoadingStates((prev) => ({
                                  ...prev,
                                  [attendance.id]: false,
                                }));
                              }, 1000);
                            });
                        }}
                        isLoading={denyLoadingStates[attendance.id]}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Text> Nema studenata koji žele pristupiti predmetu </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {students.data && students.data.length > 10 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>Ime i prezime</Th>
                <Th textAlign='center'>Broj Indexa</Th>
                <Th textAlign='center'>E-mail adresa</Th>
                <Th textAlign='center'>opcije</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
