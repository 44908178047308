import {
  AbsoluteCenter,
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  Img,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useStudentContext } from '../../../hooks/useStudent.hooks';

import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { NewAttendanceQRScan } from './comp/NewAttendanceQRScan';
import { NewAttendanceQRInput } from './comp/NewAttendanceQRInput';
import { BlueButton } from '../../../components/general/BlueButton';

type Props = {};

export const LecturesOptionsPage = (props: Props) => {
  const navigate = useNavigate();
  const studContext = useStudentContext();

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack display={{ lg: 'none' }} pos={'absolute'} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student/predavanja');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={'5vh'}>
          Novo Prisustvo
        </Heading>
      </HStack>
      <VStack justify={'center'}>
        <SimpleGrid
          w={{ base: '100%', md: '500px' }}
          columns={{ base: 1 }}
          spacing={'30px'}
          justifyContent={'center'}
        >
          <Center>
            <VStack spacing={3}>
              <Img h={'200px'} src={'/qr-code.png'} />
              <Text textAlign={'center'}>
                Skenirajte QR kod od predavanja preko vaše kamere ili neke aplikacije za čitanje QR
                kodova
              </Text>
            </VStack>
          </Center>
          <Box position='relative'>
            <Divider />
            <AbsoluteCenter bg={useColorModeValue('white', 'gray.900')} px='4'>
              ili
            </AbsoluteCenter>
          </Box>
          <Center>
            <BlueButton
              fontSize='xl'
              height='60px'
              width='85%'
              buttonText='Unesi šifru predavanja'
              onClick={() => {
                navigate('input');
              }}
            />
          </Center>
        </SimpleGrid>
      </VStack>
    </Flex>
  );
};
