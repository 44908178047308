import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { ExerciseGroup } from '../../../../models/subject';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Avatar,
  Box,
  Card,
  Center,
  Divider,
  HStack,
  Heading,
  Icon,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { AssignAssistantsToGroupsModal } from './AssignAssistantsGroupsModal';
import useAuth from '../../../../hooks/useAuth.hooks';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeleteGroupMutation } from '../../../../services/mutations';
import { useGetExercisesInfo } from '../../../../services/queries';
import useProf from '../../../../hooks/useProf.hooks';
import { useState } from 'react';
import { YesNoModal } from '../../../../components/general/YesNoModal';

type Props = {
  group: ExerciseGroup;
};

const Groups = ({ group }: Props) => {
  const [isDelGroupModalOpen, setIsDelGroupModalOpen] = useState(false);
  const authContext = useAuth();
  const profContext = useProf();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isAssignAssistantsOpen,
    onOpen: onAssignAssistantsOpen,
    onClose: onAssignAssistantsClose,
  } = useDisclosure();

  const exercisesInfoQuery = useGetExercisesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const deleteGroup = useDeleteGroupMutation(
    exercisesInfoQuery.data?.academicYearID,
    profContext.selectedSubjectID
  );

  const handleDeleteGroup = () => {
    setIsDelGroupModalOpen(true);
  };

  return (
    <Box position='relative'>
      <Card
        align={'center'}
        variant={'outline'}
        w={'300px'}
        h={'320px'}
        bg={useColorModeValue('white', 'gray.900')}
      >
        <VStack justify={'center'} w={'100%'} align={'center'} fontSize={'sm'}>
          <AssignAssistantsToGroupsModal
            isOpen={isAssignAssistantsOpen}
            onClose={onAssignAssistantsClose}
            groupID={group.groupID}
          />
          <VStack pt='10px'>
            <Text>Ime grupe</Text>
            <Text fontWeight={'700'} fontSize={'lg'}>
              {group.groupName}
            </Text>
          </VStack>
          <Center w={'100%'}>
            <Divider orientation='horizontal' />
          </Center>
          <VStack>
            <HStack>
              <Text>Broj Studenata u grupi: </Text>
              <Text fontWeight={'700'} fontSize={'md'}>
                {group.studentsNumber}
              </Text>
            </HStack>
            <HStack>
              <Text>Broj Održanih vježbi: </Text>
              <Text fontWeight={'700'} fontSize={'md'}>
                {group.exercisesHeld}
              </Text>
            </HStack>
          </VStack>
          <Center w={'100%'}>
            <Divider orientation='horizontal' />
          </Center>
          <VStack w='20%'>
            <Heading fontSize={'sm'}>Asistenti:</Heading>
            <HStack>
              {group.assistants.map((assistant) => (
                <Tooltip key={assistant.id} label={assistant.firstName + ' ' + assistant.lastName}>
                  <Avatar
                    size={'sm'}
                    name={assistant.firstName + ' ' + assistant.lastName}
                    src={assistant.profileImage}
                  />
                </Tooltip>
              ))}
            </HStack>
          </VStack>
          <Center w={'100%'}>
            <Divider orientation='horizontal' />
          </Center>
          <HStack align={'center'} py='10px'>
            <VStack>
              <BlueButton
                width='120px'
                height={'25px'}
                buttonText='Otvori vježbe'
                onClick={() => {
                  navigate(`${group.groupID}`);
                }}
              />
              <BlueButton
                width='120px'
                height={'25px'}
                buttonText='Dodaj studenta'
                onClick={() => {
                  navigate(`rasporedi-studente/${group.groupID}`);
                }}
              />
            </VStack>
            <VStack>
              <BlueButton width='120px' height={'25px'} buttonText='Uredi grupu' />
              <BlueButton
                width='120px'
                height={'25px'}
                buttonText='Uredi asistente'
                onClick={() => {
                  if (authContext.user?.role === 'ASSISTANT') {
                    onAssignAssistantsOpen();
                  } else {
                    toast({
                      position: 'top',
                      title: 'Nemate ovlaštenje za ovu akciju',
                      status: 'warning',
                      duration: 3000,
                    });
                  }
                }}
              />
            </VStack>
          </HStack>
        </VStack>
      </Card>
      <Box position='absolute' top='0' right='1' p='10px'>
        <YesNoModal
          isOpen={isDelGroupModalOpen}
          onClose={() => setIsDelGroupModalOpen(false)}
          onConfirm={() => {
            deleteGroup(group.groupID).catch((err) => {});
            setIsDelGroupModalOpen(false);
          }}
          title='Brisanje asistenta'
          message={`Da li ste sigurni da želite izbrisati grupu - ${group.groupName}?`}
        />
        <DeleteIcon
          color={'white'}
          boxSize={4}
          onClick={() => {
            if (authContext.user?.role === 'ASSISTANT') {
              handleDeleteGroup();
            } else {
              toast({
                position: 'top',
                title: 'Nemate ovlaštenje za ovu akciju',
                status: 'warning',
                duration: 3000,
              });
            }
          }}
          _hover={{ cursor: 'pointer', color: 'red.500' }}
        />
      </Box>
    </Box>
  );
};

export default Groups;
