import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import CreateExamMultiStepForm from "./CreateExamMultiStepForm";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  examScheduleID: number | null;
};

export const CreateExamModal = ({ isOpen, onClose, examScheduleID }: Props) => {
  return (
    <>
      <Modal isCentered size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Dodaj novi ispit</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            textAlign={"center"}
            justifyContent={"center"}
            alignContent={"center"}>
            <CreateExamMultiStepForm
              examScheduleID={examScheduleID}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
