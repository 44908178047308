import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useUniversitiesQuery } from '../../../../services/queries';
import { adminCreateAdminDto, adminCreateAdminSchema } from '../../../../validation/admin-schema';
import { useAdminCreateAdminMutation } from '../../../../services/mutations';

export default function CreateAdmin() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [universityID, setUniversityID] = useState(0);
  const universityQuery = useUniversitiesQuery();
  const createAdmin = useAdminCreateAdminMutation();

  const handleCreateAssistant = async (values: adminCreateAdminDto, actions: any) => {
    setLoading(true);
    try {
      await createAdmin(values);
      actions.resetForm();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleUniversityChange = (universityID: number) => {
    setUniversityID(universityID);
  };

  return (
    <Stack spacing={5} mx={'auto'} maxW={'lg'} py={4} px={6}>
      <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            universityID,
          }}
          validationSchema={adminCreateAdminSchema}
          onSubmit={async (values, actions) => {
            handleCreateAssistant(values, actions);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack spacing={4}>
                <HStack>
                  <Box>
                    <Field name='firstName'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='firstName'
                          isRequired
                          isInvalid={form.errors.firstName && form.touched.firstName}
                        >
                          <FormLabel>Ime</FormLabel>
                          <Input type='text' {...field} />
                          <ErrorMessage name='firstName'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name='lastName'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='lastName'
                          isRequired
                          isInvalid={form.errors.lastName && form.touched.lastName}
                        >
                          <FormLabel>Prezime</FormLabel>
                          <Input type='text' {...field} />
                          <ErrorMessage name='lastName'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                </HStack>
                <Field name='email'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='email'
                      isRequired
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel>E-mail adresa</FormLabel>
                      <Input type='email' {...field} />
                      <ErrorMessage name='email'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {universityQuery.data && universityQuery.data?.length !== 0 ? (
                  universityQuery.isLoading ? (
                    <Spinner />
                  ) : (
                    //  ****  University selection field *****  //
                    <Field name={'universityID'}>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id={'universityID'}
                          isRequired
                          isInvalid={form.errors.universityID && form.touched.universityID}
                        >
                          <VStack align={'start'}>
                            <FormLabel>Univerzitet</FormLabel>
                            <VStack>
                              <Select
                                w={'100%'}
                                placeholder={'Izaberite Univerzitet'}
                                {...field}
                                onChange={(e) => {
                                  form.setFieldValue('universityID', Number(e.target.value));
                                  handleUniversityChange(Number(e.target.value));
                                }}
                              >
                                {universityQuery.data.length > 0 &&
                                  universityQuery.data.map((data, index) => (
                                    <option
                                      value={data.id}
                                      key={index}
                                    >{`${data.name} -  ${data.shortName}`}</option>
                                  ))}
                              </Select>
                            </VStack>

                            <ErrorMessage name={'universityID'}>
                              {(msg) => (
                                <Text color='red' fontSize='sm'>
                                  {msg}
                                </Text>
                              )}
                            </ErrorMessage>
                          </VStack>
                        </FormControl>
                      )}
                    </Field>
                  )
                ) : (
                  <Center w='100%'>
                    <Text>Izaberite ili kreirajte fakultet</Text>
                  </Center>
                )}

                <Stack spacing={10} pt={2}>
                  <Button
                    type='submit'
                    isLoading={isLoading}
                    loadingText='Kreiranje u toku...'
                    size='lg'
                    bg='blue.400'
                    color='white'
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Kreiraj admina
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Stack>
  );
}
