import { createContext, useCallback, useEffect, useReducer } from 'react';
import {
  StudentContext,
  profReducerAction,
  profReducerActionType,
  studentContextState,
} from '../@types/profContext.types';
import { axiosInstance } from '../utils/axiosInstance';
import { GET_STUDENT_SUBJECTS_URL } from '../utils/globalConfig';

const studentReducer = (state: studentContextState, action: profReducerAction) => {
  switch (action.type) {
    case profReducerActionType.GET_SUBJECTS:
      return { ...state, subjects: action.payload };
    case profReducerActionType.SELECTED_SUBJECT:
      return { ...state, selectedSubjectID: action.payload };
    default:
      throw new Error();
  }
};

const initState: studentContextState = {
  subjects: [],
  selectedSubjectID: undefined,
};

type StudentContextProviderType = {
  children: React.ReactNode;
};

export const StudsContext = createContext<StudentContext>(initState as StudentContext);

export const StudentContextProvider = ({ children }: StudentContextProviderType) => {
  const [state, dispatch] = useReducer(studentReducer, initState);

  //initialize method
  const InitializeAuthContext = useCallback(async () => {
    try {
      const subjects = await axiosInstance.get(GET_STUDENT_SUBJECTS_URL);
      const subjectsArray = subjects.data;
      dispatch({ type: profReducerActionType.GET_SUBJECTS, payload: subjectsArray });
      if (subjects) {
        await setSelectedSubjectID(subjectsArray[0].id);
      }
    } catch (error) {
      console.log('initializeAuthContext error: ', error);
    }
  }, []);

  useEffect(() => {
    InitializeAuthContext()
      .then(() => {})
      .catch((error) => console.log(error));
  }, []);

  const getSubjects = useCallback(async () => {
    const response = await axiosInstance.get(GET_STUDENT_SUBJECTS_URL);
    dispatch({ type: profReducerActionType.GET_SUBJECTS, payload: response.data });
  }, []);

  const setSelectedSubjectID = (value: number) => {
    dispatch({ type: profReducerActionType.SELECTED_SUBJECT, payload: value });
  };

  const valuesObject = {
    selectedSubjectID: state.selectedSubjectID,
    subjects: state.subjects,
    setSelectedSubjectID,
    getSubjects,
  };

  return <StudsContext.Provider value={valuesObject}>{children}</StudsContext.Provider>;
};
