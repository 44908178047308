import React from 'react';
import { Box, Image, Text, VStack } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MotionBox = motion(Box);

interface ShowcaseItemProps {
  imgSrc: string;
  title: string;
  description: string;
}

const ShowcaseItem: React.FC<ShowcaseItemProps> = ({ imgSrc, title, description }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const variants = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, translateY: 100 },
  };

  return (
    <MotionBox
      ref={ref}
      animate={controls}
      initial='hidden'
      variants={variants}
      w='full'
      p={5}
      shadow='md'
      rounded='md'
      mb={5}
    >
      <VStack spacing={4}>
        <Text fontSize='xl' fontWeight='bold'>
          {title}
        </Text>
        <Image src={imgSrc} borderRadius='lg' />

        <Text whiteSpace={'pre-line'} textAlign={'justify'} fontSize='md'>
          {description}
        </Text>
      </VStack>
    </MotionBox>
  );
};

export default ShowcaseItem;
