import { Heading, Stack, VStack } from '@chakra-ui/react';
import Allen from './comp/Allen';
import Body from './comp/Body';
import Denis from './comp/Denis';
import { Harun } from './comp/Harun';
import { Jasmin } from './comp/Jasmin';

export default function SocialProfileWithImageHorizontal() {
  return (
    <Stack justify={'center'} align={'center'}>
      <Heading fontSize={'3xl'} mt={'2rem'}>
        O nama
      </Heading>
      <VStack p={5} justify={'center'} spacing={0}>
        <Stack direction={{ base: 'column', xl: 'row' }}>
          <Allen />
          <Denis />
        </Stack>
        <Stack direction={{ base: 'column', xl: 'row' }}>
          <Harun />
          <Jasmin />
        </Stack>
      </VStack>
      <Body />
    </Stack>
  );
}
