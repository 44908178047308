import {
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth.hooks';
import { SubjectActivitesAllInfo } from './comp/SubjectActivitesInfo';
import { SubjectActivitesGroupInfo } from './comp/SubjectActivitiesGroupInfo';
import useProf from '../../../hooks/useProf.hooks';
import { useGetSubjectActivitiesInfo } from '../../../services/queries';
import { SubjectActivitieGroups } from './comp/groups';
import SubjectActivitiesAllStudents from './comp/SubjectActivitiesAllStudents';

export function SubjectActivitiesPage() {
  const profContext = useProf();

  const subjectActivityGroups = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const navigate = useNavigate();

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <Heading fontSize={'xl'} pb={{ base: 2, md: 5 }}>
        Dodatne aktivnosti
      </Heading>
      <Tabs w={'100%'} isFitted>
        <TabList mb='1em'>
          <Tab fontWeight={'700'}>Za sve studente</Tab>
          <Tab fontWeight={'700'}>Za grupe</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
              <SubjectActivitesAllInfo />
              <SubjectActivitiesAllStudents />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
              <SubjectActivitesGroupInfo />

              {subjectActivityGroups.isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : subjectActivityGroups.data &&
                subjectActivityGroups.data?.groups &&
                subjectActivityGroups.data.groups.length === 0 ? (
                <Center>
                  <Heading fontSize={'xl'} py={5}>
                    Grupe nisu kreirane
                  </Heading>
                </Center>
              ) : (
                <SimpleGrid
                  columns={{
                    sm: 1,
                    md:
                      subjectActivityGroups.data && subjectActivityGroups.data?.groups.length >= 2
                        ? 2
                        : 1,
                    xl:
                      subjectActivityGroups.data && subjectActivityGroups.data?.groups.length === 2
                        ? 2
                        : subjectActivityGroups.data &&
                          subjectActivityGroups.data?.groups.length === 1
                        ? 1
                        : 3,
                  }}
                  spacing={'30px'}
                >
                  {subjectActivityGroups.data?.groups.map((exerciseGroup, index) => (
                    <Center key={exerciseGroup.id}>
                      <SubjectActivitieGroups
                        group={exerciseGroup}
                        difficultyFactor={subjectActivityGroups.data.difficultyFactorGroup}
                      />
                    </Center>
                  ))}
                </SimpleGrid>
              )}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
