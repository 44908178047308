import * as Yup from 'yup';

export const addStudentsExcelTableSchema = Yup.object().shape({
  // your existing fields,
  subjectID: Yup.number().required('Predmet je obavezan'),
  file: Yup.mixed()
    .nullable()
    .required('Datoteka je obavezna')
    .test('fileSize', 'Datoteka je prevelika! Maksimalna veličina je 1MB', (value) => {
      // Asserting the value as a File object
      const file = value as File | null;
      return file ? file.size <= 1024 * 1024 : true; // Example size check (1MB)
    })
    .test(
      'fileFormat',
      'Nedozvoljen format datoteke! Dozvoljeni formati su: .xls, .xlsx, .csv',
      (value) => {
        // Asserting the value as a File object
        const file = value as File | null;
        return file
          ? [
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'text/csv',
            ].includes(file.type)
          : true;
      }
    ),
});

export type addStudentsExcelTableDto = Yup.InferType<typeof addStudentsExcelTableSchema>;
