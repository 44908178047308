import {
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BluesubmitButton } from '../../../../../components/general/BlueSubmitButton';
import { DateTimeInput } from '../../../../../components/general/DateTimeInput';
import { ExamGroupDatePlaceDto } from '../../../../../models/exams';
import {
  useSetGroupExamDatePlaceMutation,
  useSetGroupPartExamDatePlaceMutation,
} from '../../../../../services/mutations';
import {
  setExamGroupDatePlaceSchema,
  setPartExamGroupDatePlaceSchema,
} from '../../../../../validation/exam-schema';
import { HorizontalTextField } from '../../../NewSubject/comp/HorzintalTextField';

type Props = {
  group: ExamGroupDatePlaceDto;
  groupName: string;
  isOpen: boolean;
  onClose: () => void;
  isExam?: boolean;
};

export const GroupDateTimeInputModal = ({
  group,
  isOpen,
  onClose,
  groupName,
  isExam = true,
}: Props) => {
  const { examID } = useParams();
  const { partialExamID } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const editGroupDatePlace = useSetGroupExamDatePlaceMutation(Number(examID), group.groupID);
  const editPartExamGroupDatePlace = useSetGroupPartExamDatePlaceMutation(
    Number(partialExamID),
    group.groupID
  );

  return (
    <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Izmjena datuma, vremena i mjesta ispita</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          <Formik
            initialValues={{
              examID: examID ? Number(examID) : 0,
              partialExamID: partialExamID ? Number(partialExamID) : 0,
              groupID: group.groupID,
              dateTime: group.dateTime,
              place: group.place,
            }}
            validationSchema={
              isExam ? setExamGroupDatePlaceSchema : setPartExamGroupDatePlaceSchema
            }
            onSubmit={(values, actions) => {
              setIsLoading(true);
              if (isExam) editGroupDatePlace(values).catch((error) => {});
              else editPartExamGroupDatePlace(values).catch((error) => {});
              setIsLoading(false);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Stack p={5} spacing={2} justifyContent={'center'} alignItems={'center'}>
                  <Heading fontSize={'sm'} pb={2}>
                    Grupa {groupName}
                  </Heading>
                  <HorizontalTextField name='place' type='text' label='Mjesto' width='150px' />

                  <DateTimeInput
                    name='dateTime'
                    label='Datum i vrijeme'
                    placeholder='Izaberite datum'
                    setFieldValue={setFieldValue}
                  />

                  <HStack justify={'center'}>
                    <BluesubmitButton
                      fontSize='sm'
                      height='25px'
                      buttonText='Sačuvaj promjene'
                      loadingText='u toku...'
                      isLoading={isLoading}
                      isDisabled={
                        values.place === group.place && values.dateTime === group.dateTime
                      }
                    />
                  </HStack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
