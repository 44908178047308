import { Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import ExerciseGroups from './comp/ExerciseGroups';
import { ExercisesInfo } from './comp/ExercisesInfo';

type Props = {};

const Exercises = (props: Props) => {
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <Heading fontSize={'xl'} pb={10}>
        Informacije o vježbama
      </Heading>

      <ExercisesInfo />

      <ExerciseGroups />
    </Flex>
  );
};

export default Exercises;
