import {
  Flex,
  HStack,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { BluesubmitButton } from '../../../components/general/BlueSubmitButton';
import SingleFileInput from '../../../components/general/SingleFileInput';
import useAuth from '../../../hooks/useAuth.hooks';
import useProf from '../../../hooks/useProf.hooks';
import { addStudentsExcelTable } from '../../../models/professorAssistent';
import { useAddStudentsExcelTableMutation } from '../../../services/mutations';
import { addStudentsExcelTableSchema } from '../../../validation/professor-schema';

type Props = {};

export const AddManyStudentsExcelPage = (props: Props) => {
  const navigate = useNavigate();
  const profContext = useProf();
  const authContext = useAuth();
  const [isManyLoading, setIsManyLoading] = useState(false);
  const [tableData, setTableData] = useState<addStudentsExcelTable[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const addStudents = useAddStudentsExcelTableMutation();

  const handleBackClick = () => {
    navigate('/profesori/studenti');
  };

  const handleSubmitManyStudents = async (values: any) => {
    setIsManyLoading(true);

    try {
      await addStudents(values).then((res) => {
        setTableData(res);
      });
    } catch (error) {
    } finally {
      setIsManyLoading(false);
    }
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      justify={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading pb={4} fontSize={'xl'} textAlign={'center'}>
          Dodajte više studenata
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <VStack w={'100%'}>
        <Formik
          initialValues={{
            subjectID: profContext.selectedSubjectID,
            file: null,
          }}
          validationSchema={addStudentsExcelTableSchema}
          onSubmit={async (values, actions) => {
            await handleSubmitManyStudents(values);
            actions.setFieldValue('file', null);
            actions.resetForm();
            setFileInputKey(Date.now());
            setFileName('');
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Stack pt={5} spacing={4} justifyContent={'center'} alignItems={'center'}>
                <Stack align={'center'}>
                  <Field name='file'>
                    {({ field, form }: { field: any; form: any }) => (
                      <SingleFileInput
                        key={fileInputKey}
                        fileName={fileName}
                        setFileName={setFileName}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Field>
                  <Heading py={3} size={'sm'}>
                    Primjer tabele
                  </Heading>
                  <TableContainer
                    w={{ base: '90vw', md: '100%' }}
                    borderRadius={'xl'}
                    border={'2px'}
                    borderColor='blue.700'
                  >
                    <Table variant='striped' colorScheme='blue' size={'sm'}>
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>index</Th>
                          <Th textAlign={'center'}>ime</Th>
                          <Th textAlign={'center'}>prezime</Th>
                          <Th textAlign={'center'}>email</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td textAlign={'center'}>265</Td>
                          <Td textAlign={'center'}>Allen</Td>
                          <Td textAlign={'center'}>Al-Shamali</Td>
                          <Td textAlign={'center'}>alshamali.allen.21@size.ba</Td>
                        </Tr>
                        <Tr>
                          <Td textAlign={'center'}>266</Td>
                          <Td textAlign={'center'}>Nikola</Td>
                          <Td textAlign={'center'}>Nikolić</Td>
                          <Td textAlign={'center'}>nikola.nikolic@gmail.com</Td>
                        </Tr>
                        <Tr>
                          <Td textAlign={'center'}>267</Td>
                          <Td textAlign={'center'}>Amra</Td>
                          <Td textAlign={'center'}>Kapo</Td>
                          <Td textAlign={'center'}>amra.kapo@size.ba</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Stack>

                <HStack py={5} justify={'center'}>
                  <BluesubmitButton
                    buttonText='Dodaj studente'
                    loadingText='Provjeravamo datoteku'
                    isLoading={isManyLoading}
                    isDisabled={values.file === null}
                  />
                </HStack>
              </Stack>
            </Form>
          )}
        </Formik>
        {tableData && tableData.length > 0 ? (
          <VStack w={'100%'}>
            <Heading py={5} size={'md'}>
              Rezultati tabelarnog unosa
            </Heading>
            <TableContainer
              w={{ base: '90vw', md: '80%' }}
              borderRadius={'xl'}
              border={'2px'}
              borderColor='blue.700'
            >
              <Table variant='striped' colorScheme='facebook' size='sm'>
                <Thead>
                  <Tr>
                    <Th textAlign='center'>RB</Th>
                    <Th textAlign='center'>Prezime i ime</Th>
                    <Th textAlign='center'>Broj Indexa</Th>
                    <Th textAlign='center'>E-mail adresa</Th>
                    <Th textAlign='center'>rezultat</Th>
                    <Th textAlign='center'>napomena</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData.map((student, index) => (
                    <Tr key={student.indexNumber}>
                      <Td textAlign='center'>{index + 2}</Td>
                      <Td textAlign='center'>
                        {student.lastName} {student.firstName}
                      </Td>
                      <Td textAlign='center'>{student.indexNumber}</Td>
                      <Td textAlign='center'>{student.email}</Td>
                      <Td textAlign='center'>
                        {student.success ? (
                          <Text fontWeight={'bold'} color='green.400'>
                            Uspješan
                          </Text>
                        ) : (
                          <Text fontWeight={'bold'} color='red.500'>
                            neuspješan
                          </Text>
                        )}
                      </Td>
                      <Td textAlign='center'>{student.note}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        ) : (
          ''
        )}
      </VStack>
    </Flex>
  );
};
