import { Flex, Heading, Spinner, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useProf from '../../../hooks/useProf.hooks';
import { useGetExamInfo, useGetExamSchedules } from '../../../services/queries';
import { ExamsInfo } from './comp/ExamsInfo';
import { ExamSchedules } from './comp/ExamSchedules';

export function ExamsPage() {
  const profContext = useProf();

  const examInfo = useGetExamInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const examSchedules = useGetExamSchedules(examInfo.data?.id);

  const navigate = useNavigate();

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <Heading fontSize={'xl'} pb={{ base: 2, md: 5 }}>
        Ispiti
      </Heading>
      <ExamsInfo />
      <VStack w={{ base: '99vw', md: '70%', lg: '800px' }} align={'center'} justify={'center'}>
        {examSchedules.isLoading ? (
          <Spinner />
        ) : (
          examInfo.data &&
          examSchedules.data &&
          examSchedules.data.length > 0 &&
          examSchedules.data.map((examSchedule) => (
            <ExamSchedules key={`ExamSchedule-${examSchedule.id}`} examSchedule={examSchedule} />
          ))
        )}
        {examSchedules.data && examSchedules.data.length === 0 && (
          <Text>Nema kreiranih rokova</Text>
        )}
      </VStack>
    </Flex>
  );
}
