import { Button } from "@chakra-ui/react";
import React from "react";

type Props = {
  height?: string;
  width?: string;
  fontSize?: string;
  bgColor?: string;
  color?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  buttonText: string;
  isLoading?: boolean;
  loadingText: string;
  stepModal?: boolean;
};

export const BluesubmitButton = (props: Props) => {
  return (
    <Button
      type="submit"
      isLoading={props.isLoading ?? false}
      loadingText={props.loadingText}
      w={props.width}
      h={props.height}
      fontSize={props.fontSize ?? "sm"}
      bgColor={"blue.600"}
      color={"white"}
      onClick={props.onClick}
      isDisabled={props.isDisabled ?? false}
      _hover={
        props.isDisabled
          ? {}
          : {
              bg: "blue.300",
              color: "blue.900",
            }
      }
      position={props.stepModal ? "absolute" : "relative"}
      bottom={props.stepModal ? "0" : ""}>
      {props.buttonText}
    </Button>
  );
};
