import {
  Avatar,
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';
import useProf from '../../../../hooks/useProf.hooks';
import { StudentSignatureStat } from '../../../../models/subject';
import { useToggleStudentSignature } from '../../../../services/mutations';
import { ToggleStudentSignatureSchema } from '../../../../validation/subject-schema';

type Props = {
  student: StudentSignatureStat | null;
  isOpen: boolean;
  onClose: () => void;
};

export const ToggleSignatureModal = ({ isOpen, onClose, student }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();

  const toggleSignatue = useToggleStudentSignature(profContext.selectedSubjectID);

  return (
    <>
      <Modal isCentered size={'md'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Izabrani student nema uslov</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <VStack w={{ base: '100%' }} align={'center'} justify={'center'} pb={5}>
              <Heading pb={2} size={'md'}>
                Student
              </Heading>
              <HStack spacing={5}>
                <Avatar size={'lg'} src={student?.profileImage} />
                <VStack spacing={0}>
                  <Text>
                    {student?.firstName} {student?.lastName}
                  </Text>
                  <Text fontSize={'lg'} fontWeight={'bold'}>
                    {student?.indexNumber}
                  </Text>
                </VStack>
              </HStack>
            </VStack>
            <Formik
              initialValues={{
                comment: '',
                studentID: student?.id ?? 0,
                subjectID: profContext.selectedSubjectID,
              }}
              validationSchema={ToggleStudentSignatureSchema}
              onSubmit={(values, actions) => {
                console.log(values);
                setIsLoading(true);
                toggleSignatue(values)
                  .catch((error) => {})
                  .finally(() => {
                    setTimeout(() => {
                      setIsLoading(false);
                      onClose();
                    }, 1000);
                  });
              }}
            >
              {() => (
                <Form>
                  <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                    <Box w={{ base: '250px', md: '80%' }}>
                      <Field name='comment'>
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            id='comment'
                            isRequired
                            isInvalid={form.errors.comment && form.touched.comment}
                          >
                            <FormLabel>Komentar</FormLabel>
                            <Textarea type='text' {...field} />
                            <ErrorMessage name='comment'>
                              {(msg) => (
                                <Text color='red' fontSize='sm'>
                                  {msg}
                                </Text>
                              )}
                            </ErrorMessage>
                            <Text pt={5} fontSize='sm' color='gray.500'>
                              Ako želite dodijeliti potpis studentu koji nema uslov, morate ostaviti
                              komentar.
                            </Text>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Dodaj potpis'
                        loadingText='Provjeravamo...'
                        isLoading={isLoading}
                      />
                      <RedButton buttonText='Odustani' onClick={onClose} />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
