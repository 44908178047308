import * as yup from 'yup';

export const newSubjectSchema = yup.object().shape({
  subjectMainInfo: yup.object().shape({
    subjectName: yup
      .string()
      .trim()
      .required('Naziv predmeta je obavezan')
      .max(45, 'Ime predmeta ne može imati više od 45 karaktera!'),
    ECTSpoints: yup
      .number()
      .integer('ECTS bodovi moraju biti cijeli broj')
      .required('ECTS bodovi su obavezni')
      .positive('ECTS bodovi moraju biti pozitivan broj!')
      .max(15, 'ECTS bodovi ne mogu biti veći od 15!'),
    Semester: yup
      .number()
      .required('Semestar je obavezan')
      .min(1, 'Semestar mora biti pozitivan broj broj')
      .max(12, 'Semestar ne može biti veći od 12')
      .integer('Semestar mora biti cijeli broj'),
    universityID: yup
      .number()
      .required('Univerzitet je obavezan')
      .positive('Univerzitet je obavezan'),
    facultyID: yup.number().required('Fakultet je obavezan').positive('Fakultet je obavezan'),
    studyProgramID: yup
      .number()
      .required('Studijski program je obavezan')
      .positive('Studijski program je obavezan'),
  }),

  lecturesInfo: yup.object().shape({
    classHours: yup
      .number()
      .required('Broj sati je obavezan')
      .min(0, 'Broj sati ne može biti manji od 0')
      .max(60, 'Broj sati ne može biti veći od 60!')
      .integer('Broj sati mora biti cijeli broj'),
    difficultyFactor: yup
      .number()
      .required('Težinski faktor je obavezan')
      .min(0, 'Težinski ne može biti manji od 0.')
      .max(100, 'Težinski faktor ne može biti veći od 100!')
      .integer('Težinski faktor mora biti cijeli broj'),
    attendanceReqForSignature: yup.bool(),
    maxAllowedAbsence: yup
      .number()
      .min(0, 'Maksimalan broj izostanaka mora biti pozitivan broj!')
      .max(7, 'Ako želite više od 7 izostanaka, postavite da dolazak nije obavezan!')
      .nullable()
      .integer('Maksimalan broj izostanaka mora biti cijeli broj'),
  }),

  exercisesInfo: yup.object().shape({
    classHours: yup
      .number()
      .required('Broj sati je obavezan')
      .min(0, 'Broj sati ne može biti manji od 0')
      .max(60, 'Broj sati ne može biti veći od 60!')
      .integer('Broj sati mora biti cijeli broj'),
    difficultyFactor: yup
      .number()
      .required('Težinski faktor je obavezan')
      .min(0, 'Težinski ne može biti manji od 0.')
      .max(100, 'Težinski faktor ne može biti veći od 100!')
      .integer('Težinski faktor mora biti cijeli broj'),
    attendanceReqForSignature: yup.bool(),
    maxAllowedAbsence: yup
      .number()
      .min(0, 'Maksimalan broj izostanaka mora biti pozitivan broj!')
      .max(7, 'Ako želite više od 7 izostanaka, postavite da dolazak nije obavezan!')
      .nullable()
      .integer('Maksimalan broj izostanaka mora biti cijeli broj'),
  }),

  subjectActivitiesInfo: yup.object().shape({
    difficultyFactor: yup
      .number()
      .required('Težinski faktor je obavezan')
      .min(0, 'Težinski ne može biti manji od 0.')
      .max(100, 'Težinski faktor ne može biti veći od 100!')
      .integer('Težinski faktor mora biti cijeli broj'),
    difficultyFactorGroup: yup
      .number()
      .required('Težinski faktor je obavezan')
      .min(0, 'Težinski ne može biti manji od 0.')
      .max(100, 'Težinski faktor ne može biti veći od 100!')
      .integer('Težinski faktor mora biti cijeli broj'),
  }),

  examInfo: yup.object().shape({
    difficultyFactor: yup
      .number()
      .required('Težinski faktor je obavezan')
      .min(0, 'Težinski faktor mora biti pozitivan broj!')
      .max(100, 'Težinski faktor ne može biti veći od 100!')
      .integer('Težinski faktor mora biti cijeli broj'),
  }),
});

export type newSubjectDto = yup.InferType<typeof newSubjectSchema>;
