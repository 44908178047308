import {
  Center,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { useGetStudentResultsInfo } from '../../../services/queries';

type Props = {};

export const StudentResultsPage = (props: Props) => {
  const navigate = useNavigate();
  const studentContext = useStudentContext();

  const results = useGetStudentResultsInfo(studentContext.selectedSubjectID);

  return (
    <Flex
      flexDirection='column'
      p='5'
      bg={useColorModeValue('white', 'gray.900')}
      w='100%'
      pos={'relative'}
    >
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'20px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={10}>
          Zbir bodova
        </Heading>
      </HStack>
      {results.isLoading ? (
        <Center h={'20vh'}>
          <Spinner />
        </Center>
      ) : results.data ? (
        <Stack spacing={0} justify={'center'} align={'center'}>
          <TableContainer
            maxW={{ base: '95%', md: '350px' }}
            borderRadius={'xl'}
            border={'2px'}
            borderColor='blue.700'
          >
            <Table variant='striped' colorScheme='facebook' size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign='center' w={'5%'}>
                    naziv
                  </Th>
                  <Th textAlign='center' w={'30%'}>
                    <VStack spacing={0}>
                      <Text>Vaši</Text>
                      <Text>bodovi</Text>
                    </VStack>
                  </Th>
                  <Th textAlign='center' w={'5%'}>
                    <VStack spacing={0}>
                      <Text>max. </Text>
                      <Text>bodovi</Text>
                    </VStack>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td textAlign='center'>Predavanja</Td>
                  <Td textAlign='center'>{results.data.lecturesScore}</Td>
                  <Td textAlign='center'>{results.data.lecturesMaxScore}</Td>
                </Tr>
                <Tr>
                  <Td textAlign='center'>Vježbe</Td>
                  <Td textAlign='center'>{results.data.exercisesScore}</Td>
                  <Td textAlign='center'>{results.data.exercisesMaxScore}</Td>
                </Tr>
                <Tr>
                  <Td textAlign='center'>Dodatne aktivnosti</Td>
                  <Td textAlign='center'>{results.data.activitiesScore}</Td>
                  <Td textAlign='center'>{results.data.activitiesMaxScore}</Td>
                </Tr>
                <Tr>
                  <Td textAlign='center'>Ispiti</Td>
                  <Td textAlign='center'>{results.data.examScore}</Td>
                  <Td textAlign='center'>{results.data.examMaxScore}</Td>
                </Tr>
                {results.data.additionalPoints > 0 && (
                  <Tr>
                    <Td textAlign='center'>Dodatni bodovi</Td>
                    <Td textAlign='center'>{results.data.additionalPoints}</Td>
                    <Td textAlign='center'>-</Td>
                  </Tr>
                )}
                <Tr>
                  <Th textAlign='center'>Ukupno</Th>
                  <Th textAlign='center'>{results.data.totalScore}</Th>
                  <Th textAlign='center'>100</Th>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <HStack justify={'center'}>
            <Heading fontSize={'xl'} py={10}>
              Konačna ocjena
            </Heading>
          </HStack>
          <Text textAlign={'center'} w={{ base: '95vw', md: '100%' }}>
            Preporučena konačna ocjena
          </Text>
          <Text textAlign={'center'} w={{ base: '95vw', md: '100%' }}>
            za rezultat: {results.data.totalScore} / 100
          </Text>
          <Text fontSize={'5xl'} fontWeight={'bold'}>
            {results.data.grade}
          </Text>
        </Stack>
      ) : (
        <Heading textAlign={'center'} fontSize={'xl'}>
          Nema rezultata
        </Heading>
      )}
    </Flex>
  );
};
