import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Divider,
  Center,
  VStack,
  FormControl,
  HStack,
  FormLabel,
  Input,
  FormHelperText,
} from '@chakra-ui/react';
import { BlueButton } from './BlueButton';
import { RedButton } from './RedButton';

type UniversalModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText: string;
  title: string;
  message: string;
  isLodaing?: boolean;
};

export const YesNoInputConfirmModal: React.FC<UniversalModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  isLodaing,
  confirmText,
}) => {
  const [userInput, setUserInput] = useState('');

  return (
    <Modal isCentered size={'lg'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <Text>{message}</Text>
            <FormControl isRequired>
              <HStack justify={'space-around'}>
                <VStack>
                  <VStack spacing={0} pt={5}>
                    <FormLabel alignSelf={'center'}>
                      Da biste potvrdili brisanje, upišite frazu ispod:
                    </FormLabel>
                    <Text alignSelf={'center'}>{confirmText}</Text>
                  </VStack>
                  <HStack justify={'space-around'}>
                    <Input
                      w={'280px'}
                      type='text'
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                    />
                  </HStack>

                  <FormHelperText>
                    Ukucajte frazu iznad da bi ste mogli izvršiti traženu radnju.
                  </FormHelperText>
                </VStack>
              </HStack>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent={'center'}>
          <BlueButton
            isLoading={isLodaing}
            height='30px'
            width='60px'
            buttonText='NE'
            onClick={onClose}
          />
          <Center px={'20px'} height='30px'>
            <Divider orientation='vertical' />
          </Center>
          <RedButton
            isDisabled={userInput !== confirmText}
            height='30px'
            width='60px'
            buttonText='DA'
            onClick={onConfirm}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
