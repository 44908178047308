import {
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  Stack,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { NewSubjectQRScan } from './comp/NewSubjectQRScan';
import { NewSubjectQRInput } from './comp/QRInput';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';

type Props = {};

export const NewSubjectPage = (props: Props) => {
  const navigate = useNavigate();
  const studContext = useStudentContext();

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Novi predmet
        </Heading>
      </HStack>

      <VStack>
        <Heading fontSize={'lg'} pt={5} pb={5}>
          Skenirajte QR kod
        </Heading>
        <NewSubjectQRScan />
      </VStack>
      <Divider pb={'25px'} />
      <Stack direction={{ base: 'column' }}>
        <VStack>
          <Heading fontSize={'lg'} py={5}>
            Unesite QR kod
          </Heading>
          <NewSubjectQRInput />
        </VStack>
      </Stack>
    </Flex>
  );
};
