import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth.hooks';
import { useJoinSubjectMutation } from '../../../services/mutations';

type Props = {
  onClose: () => void;
};

function NewSubjectModal({ onClose }: Props) {
  const authContext = useAuth();
  const [subjectCode, setSubjectCode] = useState('');
  const joinSubject = useJoinSubjectMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const handleJoinSubject = () => {
    if (subjectCode.trim().length === 0) {
      toast({
        position: 'top',
        title: 'Morate napisati QR code predmeta.',
        status: 'warning',
        duration: 2000,
      });
    } else if (subjectCode.trim().length > 8 || subjectCode.trim().length < 8) {
      toast({
        position: 'top',
        title: 'QR code mora imati 8 karaktera.',
        status: 'warning',
        duration: 2000,
      });
    } else {
      joinSubject(subjectCode)
        .then(() => {
          setSubjectCode('');
          onClose();
        })
        .catch((err) => {});
    }
  };

  const handleCreateSubject = () => {
    if (authContext.user?.role === 'PROFESSOR') navigate('novipredmet');
    else {
      toast({
        position: 'top',
        title: 'Morate imati ulogu profesora da bi kreirali novi predmet.',
        status: 'warning',
        duration: 2000,
      });
    }
  };

  return (
    <>
      <ModalOverlay />
      <ModalContent minH={'30vh'}>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb={4}>
            <Heading mt={6} fontSize={'xl'}>
              Pridružite se predmetu
            </Heading>
            <HStack w={'100%'} py={'30px'}>
              <FormControl isRequired>
                <HStack justify={'space-around'}>
                  <VStack>
                    <FormLabel alignSelf={'start'}>Šifra predmeta</FormLabel>
                    <HStack justify={'space-around'}>
                      <Input
                        w={'150px'}
                        type='text'
                        value={subjectCode}
                        onChange={(e) => setSubjectCode(e.target.value)}
                      />
                      <Button fontSize={'xs'} mt={4} colorScheme='blue' onClick={handleJoinSubject}>
                        Pridruži se
                      </Button>
                    </HStack>

                    <FormHelperText>
                      Unesite šifru predmeta kojem se želite pridružiti.
                    </FormHelperText>
                  </VStack>
                </HStack>
              </FormControl>
            </HStack>

            {authContext.user?.role === 'PROFESSOR' && (
              <>
                <Divider />

                <Heading py={3} fontSize={'sm'}>
                  ili
                </Heading>

                <Button
                  fontSize='sm'
                  size='md'
                  height='40px'
                  border='2px'
                  borderColor='blue.500'
                  //bg={useColorModeValue('white', 'grey.800')}
                  onClick={handleCreateSubject}
                >
                  Kreiraj novi predmet
                </Button>
              </>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </>
  );
}

export default NewSubjectModal;
