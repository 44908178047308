import {
  Avatar,
  Box,
  Center,
  Checkbox,
  HStack,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useGetAllStudentsAdmin } from '../../../../services/queries';
import { BlueButton } from '../../../../components/general/BlueButton';
import { useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useAdminResetStudentPasswordMutation } from '../../../../services/mutations';
import { Student } from '../../../../models/student';
import { EditStudentModal } from './EditStudentModal';

interface LoadingStates {
  [key: number]: boolean;
}

export const StudentsTable = () => {
  const [BlockLoadingStates, setBlockLoadingStates] = useState<LoadingStates>({});
  const [resetLoadingStates, setResetLoadingStates] = useState<LoadingStates>({});
  const [student, setStudent] = useState<null | Student>(null);
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { data, isLoading } = useGetAllStudentsAdmin();
  const resetPassword = useAdminResetStudentPasswordMutation();
  const toast = useToast();

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack spacing={8}>
      <EditStudentModal isOpen={isEditOpen} onClose={onEditClose} student={student} />
      {data?.map((faculty) => (
        <Box key={faculty.faculty}>
          <Heading size='md' mb={4}>
            {faculty.faculty}
          </Heading>
          {faculty.studyPrograms.length === 0 && (
            <Center>
              <Heading size='sm'>Nema studijskih programa</Heading>
            </Center>
          )}
          {faculty.studyPrograms.map((program) => (
            <Box w={{ base: '90vw', md: '100%' }} key={program.studyProgram} mb={6}>
              <Heading size='sm' mb={2}>
                {program.studyProgram}
              </Heading>
              <TableContainer mt={'0rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
                <Table variant='striped' colorScheme='facebook' size='sm'>
                  <Thead>
                    <Tr>
                      <Th textAlign='center'>Slika</Th>
                      <Th textAlign='center'>B. Indexa</Th>
                      <Th textAlign='center'>Prezime i ime </Th>
                      <Th textAlign='center'>E-mail</Th>
                      <Th textAlign='center'>Odobren</Th>
                      <Th textAlign='center'>Opcije</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {program.students.length === 0 && (
                      <Tr>
                        <Td colSpan={6}>
                          <Center>
                            <Heading size='xs'>Nema studenata</Heading>
                          </Center>
                        </Td>
                      </Tr>
                    )}
                    {program.students.map((student) => (
                      <Tr key={student.id}>
                        <Td textAlign='center'>
                          <Avatar size='sm' src={student.profileImage} />
                        </Td>
                        <Td textAlign='center'>{student.indexNumber}</Td>
                        <Td>
                          {student.lastName} {student.firstName}
                        </Td>
                        <Td>{student.email}</Td>
                        <Td textAlign='center'>
                          <Checkbox isChecked={student.approved} colorScheme='green'></Checkbox>
                        </Td>
                        <Td textAlign='center'>
                          <HStack spacing={5}>
                            <BlueButton
                              width='55px'
                              height='25px'
                              buttonText={student.approved ? 'blokiraj' : 'odobri'}
                              isLoading={BlockLoadingStates[student.id]}
                              onClick={() => {
                                toast({
                                  position: 'top',
                                  title: 'Ova funkcionalnost još nije implementirana',
                                  status: 'warning',
                                  duration: 3000,
                                });
                                setBlockLoadingStates((prev) => ({
                                  ...prev,
                                  [student.id]: false,
                                }));
                                /* setLoadingStates((prev) => ({ ...prev, [student.id]: true }));
                                toggleProfessorApproved({
                                  staffID: professor.id,
                                })
                                  .catch((err) => {})
                                  .finally(() => {
                                    setTimeout(() => {
                                      setLoadingStates((prev) => ({
                                        ...prev,
                                        [professor.id]: false,
                                      }));
                                    }, 1000);
                                  }); */
                              }}
                            />
                            <BlueButton
                              width='75px'
                              height='25px'
                              buttonText={'reset šifre'}
                              isLoading={resetLoadingStates[student.id]}
                              onClick={() => {
                                setResetLoadingStates((prev) => ({ ...prev, [student.id]: true }));
                                resetPassword({
                                  studentID: student.id,
                                })
                                  .catch((err) => {})
                                  .finally(() => {
                                    setTimeout(() => {
                                      setResetLoadingStates((prev) => ({
                                        ...prev,
                                        [student.id]: false,
                                      }));
                                    }, 1000);
                                  });
                              }}
                            />
                            <HStack spacing={0}>
                              <EditIcon
                                color={'white'}
                                boxSize={5}
                                ml='5px'
                                mr={'5px'}
                                onClick={() => {
                                  setStudent(student);
                                  onEditOpen();
                                }}
                                _hover={{ cursor: 'pointer', color: 'green.500' }}
                              />
                              <DeleteIcon
                                color={'white'}
                                boxSize={5}
                                onClick={() => {
                                  toast({
                                    position: 'top',
                                    title: 'Ova funkcionalnost još nije implementirana',
                                    status: 'warning',
                                    duration: 3000,
                                  });
                                }}
                                _hover={{ cursor: 'pointer', color: 'red.500' }}
                              />
                            </HStack>
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Box>
      ))}
    </VStack>
  );
};
