import { Box, Flex, HStack, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../../components/general/ArrowBackButton';
import { useStudentContext } from '../../../../hooks/useStudent.hooks';
import { QRScanner } from '../../../../services/QRScanner';
import { useStudentAttendExercise } from '../../../../services/mutations';

export const NewAttendanceExQRScan = () => {
  const navigate = useNavigate();
  const studentContext = useStudentContext();
  const joinExercise = useStudentAttendExercise(studentContext.selectedSubjectID);

  const handleScan = async (data: string) => {
    try {
      await joinExercise(data);
    } catch (error: any) {
    } finally {
      navigate('/student/vjezbe');
    }
  };

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={'absolute'} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student/vjezbe/opcije');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={'10vh'}>
          Skeniraj QR kod
        </Heading>
      </HStack>
      <HStack justify={'center'}>
        <Box>
          <QRScanner onScan={handleScan} />
        </Box>
      </HStack>
    </Flex>
  );
};
