import {
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { SpecialPermission } from './SpecialPermision';

type Props = {};

export const SpecialPermissionPage = (props: Props) => {
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w={'100%'}
    >
      <Tabs w={'100%'} isFitted variant='enclosed'>
        <TabList mb='1em'>
          <Tab fontWeight={'700'}>Organizacije</Tab>
          <Tab fontWeight={'700'}>Akademska godina</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
              <Heading fontSize={'xl'} pb={2}>
                Organizacije
              </Heading>
              <SpecialPermission />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
              <Heading fontSize={'xl'} pb={2}>
                Akademska godina
              </Heading>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
