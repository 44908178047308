import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

type Props = {
  onClick?: () => void;
};

export const ArrowBackButton = (props: Props) => {
  return (
    <Button
      h={'25px'}
      w={'40px'}
      onClick={props.onClick}
      bgColor={'blue.600'}
      color={'white'}
      _hover={{
        bg: 'blue.300',
        color: 'blue.900',
      }}
    >
      <ArrowBackIcon color={'inherit'} />
    </Button>
  );
};
