import * as yup from 'yup';

export const adminCreateStaffSchema = yup.object({
  firstName: yup.string().trim().required('Ime je obavezno'),
  lastName: yup.string().trim().required('Prezime je obavezno'),
  email: yup.string().trim().email().required('Email je obavezan'),
  title: yup.string().trim().required('Titula je obavezna'),
});

export const adminCreateAdminSchema = yup.object({
  firstName: yup.string().trim().required('Ime je obavezno'),
  lastName: yup.string().trim().required('Prezime je obavezno'),
  email: yup.string().trim().email().required('Email je obavezan'),
  universityID: yup.number().required('Univerzitet je obavezan'),
});

export const adminCreateStudentSchema = yup.object().shape({
  firstName: yup.string().trim().required('Ime je obavezno polje'),
  lastName: yup.string().trim().required('Prezime je obavezno polje'),
  indexNumber: yup.string().trim().required('Broj indeksa je obavezno polje'),
  email: yup
    .string()
    .trim()
    .email('Unesite ispravnu E-mail adresu')
    .transform((value) => value.toLowerCase())
    .required('E-mail je obavezno polje'),
  studyProgramID: yup.number().required('Morate izabrati studijski program'),
});

export const adminCreateUniversitySchema = yup.object().shape({
  name: yup.string().trim().required('Naziv je obavezno polje'),
  shortName: yup.string().trim().required('Srkačenica je obavezno polje'),
});

export const adminCreateFacultySchema = yup.object().shape({
  name: yup.string().trim().required('Naziv je obavezno polje'),
  shortName: yup.string().trim().required('Srkačenica je obavezno polje'),
  universityID: yup.number().required('Morate izabrati univerzitet'),
});

export const adminCreateStudyProgramSchema = yup.object().shape({
  name: yup.string().trim().required('Naziv je obavezno polje'),
  shortName: yup.string().trim().required('Srkačenica je obavezno polje'),
  facultyID: yup.number().required('Morate izabrati fakultet'),
});

export const staffIDSchema = yup.object({
  staffID: yup.number().required(),
});

export const editStudentSchema = yup.object().shape({
  studentID: yup.number().required(),
  firstName: yup.string().trim().required('Ime je obavezno polje'),
  lastName: yup.string().trim().required('Prezime je obavezno polje'),
  indexNumber: yup.string().trim().required('Broj indeksa je obavezno polje'),
  email: yup
    .string()
    .trim()
    .email('Unesite ispravnu E-mail adresu')
    .transform((value) => value.toLowerCase())
    .required('E-mail je obavezno polje'),
});

export const createStudentsExcelTableSchema = yup.object().shape({
  // your existing fields,
  studyProgramID: yup.number().required('studijski program je obavezan'),
  file: yup
    .mixed()
    .nullable()
    .required('Datoteka je obavezna')
    .test('fileSize', 'Datoteka je prevelika! Maksimalna veličina je 1MB', (value) => {
      // Asserting the value as a File object
      const file = value as File | null;
      return file ? file.size <= 1024 * 1024 : true; // Example size check (1MB)
    })
    .test(
      'fileFormat',
      'Nedozvoljen format datoteke! Dozvoljeni formati su: .xls, .xlsx, .csv',
      (value) => {
        // Asserting the value as a File object
        const file = value as File | null;
        return file
          ? [
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'text/csv',
            ].includes(file.type)
          : true;
      }
    ),
});

export type adminCreateStaffDto = yup.InferType<typeof adminCreateStaffSchema>;
export type staffIDDto = yup.InferType<typeof staffIDSchema>;
export type adminCreateStudentDto = yup.InferType<typeof adminCreateStudentSchema>;
export type adminCreateFacultyDto = yup.InferType<typeof adminCreateFacultySchema>;
export type adminCreateStudyProgramDto = yup.InferType<typeof adminCreateStudyProgramSchema>;
export type adminCreateUniversityDto = yup.InferType<typeof adminCreateUniversitySchema>;
export type adminCreateAdminDto = yup.InferType<typeof adminCreateAdminSchema>;
export type editStudentDto = yup.InferType<typeof editStudentSchema>;
