import {
  Button,
  Center,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLectureAttendances } from '../../../../services/queries';
import { useToggleStudentPresence } from '../../../../services/mutations';
import useAuth from '../../../../hooks/useAuth.hooks';
import { useState } from 'react';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

interface LoadingStates {
  [key: number]: boolean;
}

export const LectureAttendancesTable = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const { lectureID } = useParams();

  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

  const lecturesAttendancesQuery = useGetLectureAttendances(Number(lectureID));
  const toggleStudentPresence = useToggleStudentPresence(Number(lectureID));

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  return (
    <TableContainer
      mt={'1rem'}
      w={{ base: '100%', lg: '70%' }}
      borderRadius={'xl'}
      border={'2px'}
      borderColor='blue.700'
    >
      <Table variant='striped' colorScheme='facebook' size='sm'>
        <Thead>
          <Tr>
            <Th textAlign='center' w={'10%'}>
              RB
            </Th>
            <Th textAlign='center' w={'35%'}>
              Ime i prezime
            </Th>
            <Th textAlign='center' w={'10%'}>
              Broj Indexa
            </Th>
            <Th textAlign='center' w={'10%'}>
              prisutan
            </Th>
            <Th textAlign='center' w={'35%'}>
              opcije
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {lecturesAttendancesQuery.isLoading ? (
            <Tr>
              <Td colSpan={7}>
                <Center>
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          ) : lecturesAttendancesQuery.isError ? (
            <Tr>
              <Td colSpan={7}>
                <Center>
                  <VStack>
                    <Text>{getErrorMessage(lecturesAttendancesQuery.error)}</Text>
                    <Button
                      fontSize={'sm'}
                      bgColor={'blue.600'}
                      color={'white'}
                      onClick={() => {
                        navigate('/profesori/predavanja');
                      }}
                    >
                      Vrati se na sva predavanja
                    </Button>
                  </VStack>
                </Center>
              </Td>
            </Tr>
          ) : lecturesAttendancesQuery.data && lecturesAttendancesQuery.data.length > 0 ? (
            lecturesAttendancesQuery.data.map((attendance, index) => (
              <Tr key={attendance.id}>
                <Td textAlign='center' w={'10%'}>
                  {index + 1}
                </Td>
                <Td textAlign='center' w={'40%'}>
                  <StudentNameSubjectStats
                    firstName={attendance.firstName}
                    lastName={attendance.lastName}
                    repeater={attendance.repeater}
                    hasCompletedSubject={attendance.hasCompletedSubject}
                    navigateLink={`/profesori/student/${attendance.id}`}
                  />
                  {/* <VStack spacing={1}>
                    <HStack>
                      <Text
                        onClick={() => {
                          navigate(`/profesori/student/${attendance.id}`);
                        }}
                        cursor={'pointer'}
                      >
                        {attendance.lastName} {attendance.firstName}
                      </Text>
                      {attendance.hasCompletedSubject && (
                        <Text
                          fontSize={'xs'}
                          border={'1px solid'}
                          borderRadius={'lg'}
                          px={1}
                          color={'green.400'}
                          fontWeight={'700'}
                          letterSpacing={'1px'}
                        >
                          PP
                        </Text>
                      )}
                    </HStack>
                    {attendance.repeater && (
                      <Text fontSize={'2xs'} border={'1px solid'} borderRadius={'lg'} px={1}>
                        Ponovac
                      </Text>
                    )}
                  </VStack> */}
                </Td>
                <Td textAlign='center' w={'10%'}>
                  {attendance.indexNumber}
                </Td>
                <Td w='10%' textAlign={'center'}>
                  <HStack justify={'center'}>
                    {attendance.presence ? (
                      <Text
                        fontWeight={'700'}
                        color={'green.400'}
                        border={'2px solid'}
                        borderRadius={'xl'}
                        width={'40px'}
                      >
                        DA
                      </Text>
                    ) : (
                      <Text
                        fontWeight={'700'}
                        color={'red.400'}
                        border={'2px solid'}
                        borderRadius={'xl'}
                        width={'40px'}
                      >
                        NE
                      </Text>
                    )}
                  </HStack>
                </Td>
                <Td textAlign='center' w={'30%'}>
                  <Button
                    size='xs'
                    colorScheme='blue'
                    onClick={() => {
                      setLoadingStates((prev) => ({ ...prev, [attendance.id]: true }));
                      toggleStudentPresence({
                        lectureID: Number(lectureID),
                        studentID: attendance.id,
                      })
                        .catch((err) => {})
                        .finally(() => {
                          setTimeout(() => {
                            setLoadingStates((prev) => ({ ...prev, [attendance.id]: false }));
                          }, 1000);
                        });
                    }}
                    isDisabled={authContext.user?.role === 'PROFESSOR' ? false : true}
                    isLoading={loadingStates[attendance.id]}
                  >
                    {attendance.presence ? 'Označi kao odsutan' : 'Označi kao prisutan'}
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>
                <Center>
                  <Text> Nema prisutnih studenata na predavanju </Text>
                </Center>
              </Td>
            </Tr>
          )}
        </Tbody>

        {lecturesAttendancesQuery.data && lecturesAttendancesQuery.data.length > 5 && (
          <Tfoot>
            <Tr>
              <Th textAlign='center' w={'5%'}>
                RB
              </Th>
              <Th textAlign='center' w={'30%'}>
                Ime i prezime
              </Th>
              <Th textAlign='center' w={'5%'}>
                Broj Indexa
              </Th>
              <Th textAlign='center' w={'20%'}>
                prisutan
              </Th>
              <Th textAlign='center' w={'35%'}>
                opcije
              </Th>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableContainer>
  );
};
