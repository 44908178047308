import { Flex, HStack, Heading, Spinner, Stack, VStack, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { useGetStudentSubjectPosts } from '../../../services/queries';
import { StudentPost } from './comp/Post';

type Props = {};

export const StudentsPostsPage = (props: Props) => {
  const navigate = useNavigate();
  const studContext = useStudentContext();
  const postsData = useGetStudentSubjectPosts(studContext.selectedSubjectID);

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={10}>
          Objave
        </Heading>
      </HStack>
      <VStack mx={'auto'} pt={{ base: 0, md: 5 }} w={{ base: '90vw', md: '40%' }}>
        {postsData.isLoading ? (
          <HStack w={'100%'} justify={'center'}>
            <Spinner />
          </HStack>
        ) : postsData.data && postsData.data.length > 0 ? (
          <VStack w={'100%'} align={'center'} spacing={5}>
            {postsData.data.map((post) => (
              <StudentPost key={`studentPost-${post.id}`} post={post} />
            ))}
          </VStack>
        ) : (
          <p>Nema objava</p>
        )}
      </VStack>
    </Flex>
  );
};
