import { Center, HStack, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import useProf from '../../../../hooks/useProf.hooks';
import { useEditSubjectMainInfo } from '../../../../services/mutations';
import { useGetSelectedSubjectInfo } from '../../../../services/queries';
import { editSubjectMainInfoSchema } from '../../../../validation/subject-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {};

export const EditSubjectInfo = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();

  const subjectInfo = useGetSelectedSubjectInfo(profContext.selectedSubjectID);
  const editSubjectInfo = useEditSubjectMainInfo(profContext.selectedSubjectID);

  return subjectInfo.isLoading ? (
    <Center>
      <Spinner />
    </Center>
  ) : subjectInfo.data ? (
    <VStack pt={5} w={'100%'}>
      <Formik
        initialValues={{
          subjectID: profContext.selectedSubjectID,
          name: subjectInfo.data.name,
          ECTSpoints: subjectInfo.data.ECTSpoints,
          semester: subjectInfo.data.semester,
        }}
        validationSchema={editSubjectMainInfoSchema}
        onSubmit={(values, actions) => {
          setIsLoading(true);
          editSubjectInfo(values).catch((error) => {});
          setIsLoading(false);
        }}
      >
        {({ values }) => (
          <Form>
            <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
              <HorizontalTextField name='name' type='text' label='Ime predmeta' width='250px' />
              <HorizontalTextField
                name='ECTSpoints'
                type='number'
                label='ECTS bodovi'
                width='150px'
              />
              <HorizontalTextField name='semester' type='number' label='Semestar' width='150px' />

              <HStack py={5} justify={'center'}>
                <BluesubmitButton
                  buttonText='Sačuvaj promjene'
                  loadingText='u toku...'
                  isLoading={isLoading}
                  isDisabled={
                    values.name === subjectInfo.data.name &&
                    values.ECTSpoints === subjectInfo.data.ECTSpoints &&
                    values.semester === subjectInfo.data.semester
                  }
                />
              </HStack>
            </Stack>
          </Form>
        )}
      </Formik>
    </VStack>
  ) : (
    <Text>Greška prilikom učitavanja podataka</Text>
  );
};
