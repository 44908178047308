import {
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ProfessorAssistent } from '../../../../models/professorAssistent';
import useProf from '../../../../hooks/useProf.hooks';
import {
  useApproveAssistantSubject,
  useApproveProfessorSubject,
  useDeleteAssistantMutation,
  useDeleteProfessorMutation,
} from '../../../../services/mutations';

type Props = {
  staff: ProfessorAssistent;
};

export const StaffAccessReqCard = ({ staff }: Props) => {
  const profContext = useProf();

  const approveProfessor = useApproveProfessorSubject(profContext.selectedSubjectID);
  const approveAssistant = useApproveAssistantSubject(profContext.selectedSubjectID);
  const deleteProfessor = useDeleteProfessorMutation(profContext.selectedSubjectID);
  const deleteAssistant = useDeleteAssistantMutation(profContext.selectedSubjectID);

  const handleApproveProfessor = () => {
    approveProfessor({ professorID: staff.id, subjectID: profContext.selectedSubjectID }).catch(
      (err) => {}
    );
  };

  const handleApproveAssistant = () => {
    approveAssistant({ assistantID: staff.id, subjectID: profContext.selectedSubjectID }).catch(
      (err) => {}
    );
  };

  const handleDeleteProfessor = () => {
    deleteProfessor({ professorID: staff.id, subjectID: profContext.selectedSubjectID }).catch(
      (err) => {}
    );
  };

  const handleDeleteAssistant = () => {
    deleteAssistant({ assistantID: staff.id, subjectID: profContext.selectedSubjectID }).catch(
      (err) => {}
    );
  };

  return (
    <Center pt={1} w='100%'>
      <Stack
        borderWidth='1px'
        borderRadius='lg'
        w={{ base: '90vw', md: '320px' }}
        height={'5rem'}
        direction={'row'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={useColorModeValue('xl', '')}
        padding={0}
      >
        <Flex flex={1} bg='blue.200' borderTopLeftRadius={'md'} borderBottomLeftRadius={'md'}>
          <Image
            objectFit='cover'
            boxSize='100%'
            src={staff.profileImage}
            alt='#'
            borderTopLeftRadius={'md'}
            borderBottomLeftRadius={'md'}
          />
        </Flex>
        <Stack flex={4} flexDirection='row' justifyContent='center' alignItems='center'>
          <Stack flex={3} flexDir={'column'} ml={3} w={'60%'}>
            <Heading fontSize={'sm'} fontFamily={'body'}>
              {`${staff.firstName} ${staff.lastName}`}
            </Heading>
            <Heading fontWeight={400} color={'gray.500'} fontSize='sm' m={0}>
              {staff.title}
            </Heading>
          </Stack>
          <HStack flex={2} pb={'8px'} pr={3}>
            {staff.role === 'PROFESSOR' && (
              <>
                <Button
                  w={'50px'}
                  h={'30px'}
                  fontSize={'xs'}
                  rounded={'md'}
                  bg={'green.500'}
                  onClick={handleApproveProfessor}
                >
                  odobri
                </Button>
                <Button
                  w={'50px'}
                  h={'30px'}
                  fontSize={'xs'}
                  rounded={'md'}
                  bg={'red.500'}
                  onClick={handleDeleteProfessor}
                >
                  odbij
                </Button>
              </>
            )}
            {staff.role === 'ASSISTANT' && (
              <>
                <Button
                  w={'50px'}
                  h={'30px'}
                  fontSize={'xs'}
                  rounded={'md'}
                  bg={'green.500'}
                  onClick={handleApproveAssistant}
                >
                  odobri
                </Button>
                <Button
                  w={'50px'}
                  h={'30px'}
                  fontSize={'xs'}
                  rounded={'md'}
                  bg={'red.500'}
                  onClick={handleDeleteAssistant}
                >
                  odbij
                </Button>
              </>
            )}
          </HStack>
        </Stack>
      </Stack>
    </Center>
  );
};
