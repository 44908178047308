import { DeleteIcon, EditIcon, HamburgerIcon, StarIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { YesNoInputConfirmModal } from '../../../../components/general/YesNoInputConfirmModal';
import useProf from '../../../../hooks/useProf.hooks';
import { ExamSchedule } from '../../../../models/exams';
import { useDeleteExamScheduleMutation } from '../../../../services/mutations';
import { useGetExamInfo, useGetExamsPerSchedule } from '../../../../services/queries';
import { ExamsPreviewComp } from './ExamsPreview';
import { CreateExamModal } from './modals/CreateExamModal';
import { EditExamScheduleModal } from './modals/EditExamScheduleModal';

type Props = {
  examSchedule: ExamSchedule;
};

export const ExamSchedules = ({ examSchedule }: Props) => {
  const navigate = useNavigate();
  const profContext = useProf();

  const examInfoQuery = useGetExamInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });
  const exams = useGetExamsPerSchedule(examSchedule.id);

  const deleteExamSchedule = useDeleteExamScheduleMutation(
    examInfoQuery.data?.id,
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const {
    isOpen: isCreateExamOpen,
    onOpen: onCreateExamOpen,
    onClose: onCreateExamClose,
  } = useDisclosure();

  const {
    isOpen: isEditExamScheduleOpen,
    onOpen: onEditExamScheduleOpen,
    onClose: onEditExamScheduleClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteExamScheduleOpen,
    onOpen: onDeleteExamScheduleOpen,
    onClose: onDeleteExamScheduleClose,
  } = useDisclosure();

  return (
    <>
      <CreateExamModal
        isOpen={isCreateExamOpen}
        onClose={onCreateExamClose}
        examScheduleID={examSchedule.id}
      />
      <EditExamScheduleModal
        examSchedule={examSchedule}
        isOpen={isEditExamScheduleOpen}
        onClose={onEditExamScheduleClose}
      />
      <YesNoInputConfirmModal
        isOpen={isDeleteExamScheduleOpen}
        onClose={onDeleteExamScheduleClose}
        title={'Brisanje roka'}
        message={`Da li ste sigurni da želite obrisati rok ${examSchedule.name}?  Svi ispiti zajedno s rezultatima će biti obrisani. Ovu akciju nije moguće povratiti.`}
        onConfirm={() => {
          deleteExamSchedule({ examScheduleID: examSchedule.id });
          onDeleteExamScheduleClose();
        }}
        confirmText={'Siguran sam za gubitak podataka'}
      />
      <Accordion w={'100%'} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <HStack w={'100%'} justify={'space-between'}>
              <Box as='span' flex='1' textAlign='left'>
                <Heading fontSize={'md'}>{examSchedule.name}</Heading>
                <Text fontSize={'xs'}>
                  Raspoloživi težinski faktor:{' '}
                  {examSchedule.difficultyFactor - examSchedule.usedDifficultyFactor} /{' '}
                  {examSchedule.difficultyFactor}
                </Text>
              </Box>
              <HStack pr={3}>
                <Text display={{ base: 'none', md: 'flex' }} fontSize={'xs'}>
                  {examSchedule.startingDate} - {examSchedule.endingDate}
                </Text>
                <VStack display={{ base: 'flex', md: 'none' }} spacing={0}>
                  <Text fontSize={'xs'}>od {examSchedule.startingDate}</Text>
                  <Text fontSize={'xs'}>do {examSchedule.endingDate}</Text>
                </VStack>
              </HStack>
            </HStack>

            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <VStack w={'100%'}>
              <Stack w={'100%'} direction={'row'} justify={{ base: 'end' }} spacing={1}>
                <BlueButton
                  fontSize='xs'
                  height='25px'
                  width='100px'
                  buttonText='Dodaj ispit'
                  onClick={() => {
                    onCreateExamOpen();
                  }}
                />

                <Menu>
                  <MenuButton
                    color={'blue.300'}
                    as={IconButton}
                    aria-label='Options'
                    icon={<HamburgerIcon />}
                    width={'25px'}
                    height={'25px'}
                  />
                  <MenuList>
                    <MenuItem
                      icon={<EditIcon />}
                      onClick={() => {
                        onEditExamScheduleOpen();
                      }}
                    >
                      Uredi rok
                    </MenuItem>
                    <MenuItem
                      icon={<StarIcon />}
                      onClick={() => {
                        navigate(`rezultati-roka/${examSchedule.id}`);
                      }}
                    >
                      Rezultati roka
                    </MenuItem>
                    <MenuItem
                      icon={<DeleteIcon />}
                      onClick={() => {
                        onDeleteExamScheduleOpen();
                      }}
                    >
                      Izbriši rok
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Stack>
              <VStack fontSize={'sm'} w={'100%'} spacing={0}>
                {exams.isLoading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : exams.data && exams.data.length > 0 ? (
                  <VStack w={'100%'} pt={5} spacing={5}>
                    {exams.data.map((exam) => (
                      <ExamsPreviewComp
                        key={`exam-preview-${exam.id}`}
                        exam={exam}
                        examScheduleID={examSchedule.id}
                      />
                    ))}
                  </VStack>
                ) : (
                  <Center>
                    <Text>Nema kreiranih ispita</Text>
                  </Center>
                )}
              </VStack>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
