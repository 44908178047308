import { ProfessorAssistent } from '../../../../models/professorAssistent';
import { StaffOnSubjectCard } from './StaffCard';

type Props = {
  professors: ProfessorAssistent[];
};

const ProfessorsAndAsistents = (props: Props) => {
  return (
    <>
      {props.professors.map((professor) => {
        return <StaffOnSubjectCard key={professor.id} staff={professor} />;
      })}
    </>
  );
};

export default ProfessorsAndAsistents;
