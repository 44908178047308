import {
  Card,
  Center,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Exam } from '../../../../models/exams';
import { ExamGroupDateTime } from './ExamGroupDateTime';
import { BlueButton } from '../../../../components/general/BlueButton';
import { EditExamDatePlaceModal } from './modals/EditExamDatePlaceModal';
import { useGetExamStudentsStats } from '../../../../services/queries';

interface Props {
  exam: Exam;
}

export const SingleExam = ({ exam }: Props) => {
  const colorScheme = useColorModeValue('gray.100', 'gray.900');

  const {
    isOpen: editDatePlaceOpen,
    onOpen: onEditDatePlaceOpen,
    onClose: onEditDatePlaceClose,
  } = useDisclosure();

  const examStats = useGetExamStudentsStats(exam.id);

  return (
    <VStack w={'100%'}>
      <Stack
        pb={5}
        w={'100%'}
        direction={{ base: 'column', md: 'row' }}
        spacing={8}
        justify={'center'}
      >
        <EditExamDatePlaceModal
          dateTime={exam.rawDateTime ?? ''}
          place={exam.place ?? ''}
          isOpen={editDatePlaceOpen}
          onClose={onEditDatePlaceClose}
        />
        <VStack align={'center'}>
          <Heading pb={5} fontSize={'md'}>
            Opšti podaci
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            <VStack align={'start'} spacing={0}>
              <HStack>
                <Text fontSize={'sm'}>Ime ispita:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.name}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Tip ispita:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.type}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Broj parcijala:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.partialExamNumber}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Broj bodova:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.maxScore}
                </Text>
              </HStack>

              <HStack>
                <Text fontSize={'sm'}>Bodovi za prolaz:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.minPassingScore}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Težinski faktor:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.difficultyFactor}
                </Text>
              </HStack>
            </VStack>

            <VStack align={'start'} spacing={0}>
              <HStack>
                {exam.conditionExamID ? (
                  <>
                    <Text fontSize={'sm'}>Uslovni Ispit:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {exam.conditionExamName}
                    </Text>
                  </>
                ) : (
                  <Text fontSize={'sm'}>Nema uslovnog ispita</Text>
                )}
              </HStack>
              <HStack>
                <Tooltip label='Skriven označava da li je ispit vidljiv kod studenata.'>
                  <Text fontSize={'sm'}>Skriven:</Text>
                </Tooltip>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.visible ? 'NE' : 'DA'}
                </Text>
              </HStack>

              <HStack>
                <Text fontSize={'sm'}>Rok poništavanja:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.nullificationDeadline}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Rok prijave:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.registrationDeadline}
                </Text>
              </HStack>
              <HStack>
                {exam.validUntil ? (
                  <>
                    <Text fontSize={'sm'}>položen ispit važi do:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {exam.validUntil}
                    </Text>
                  </>
                ) : (
                  <Text fontSize={'sm'}>Ispit važi neograničeno. </Text>
                )}
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Kreiran:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.createdDateTime}
                </Text>
              </HStack>
              {exam.edited && (
                <HStack>
                  <Text fontSize={'sm'}>Uređen:</Text>
                  <Text fontSize={'sm'} fontWeight={'bold'}>
                    {exam.editedDateTime}
                  </Text>
                </HStack>
              )}
            </VStack>
            <VStack align={'start'} spacing={0}>
              {examStats.isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : examStats.data ? (
                <>
                  <HStack>
                    <Text fontSize={'sm'}>Prijavljeni studenti:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {examStats.data.totalRegisteredStudents}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'sm'}>Prisutni studenti:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {examStats.data.attendedStudents}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'sm'}>Položili: </Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {examStats.data.passedStudents}
                    </Text>
                  </HStack>

                  <HStack>
                    <Text fontSize={'sm'}>Nisu položili: </Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {examStats.data.failedButAttendedStudents}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'sm'}>Nisu prisustvovali: </Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {examStats.data.notAttendedStudents}
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'sm'}>Procenat prolaznosti:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {examStats.data.percentagePassed} %
                    </Text>
                  </HStack>
                  <HStack>
                    <Text fontSize={'sm'}>Prosjek bodova:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {examStats.data.averageScore}
                    </Text>
                  </HStack>
                </>
              ) : (
                <Text>Statistika nije dostupna</Text>
              )}
            </VStack>
          </SimpleGrid>
        </VStack>
      </Stack>
      <Stack align={'center'} direction={{ base: 'column', md: 'row' }}>
        <VStack>
          <Heading pb={5} fontSize={'md'}>
            Datum i mjesto održavanja
          </Heading>
          {exam.sameGroupsDateTime ? (
            <Card
              w={'100%'}
              p={{ base: 2, md: 4 }}
              align={'center'}
              variant={'outline'}
              bg={colorScheme}
            >
              <VStack justify={'center'} spacing={0}>
                <Text fontSize={'sm'}>Za sve studente</Text>
                {exam.dateTime && exam.place ? (
                  <>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {exam.dateTime}
                    </Text>
                    <Text pb={2} fontSize={'sm'} fontWeight={'bold'}>
                      {exam.place}
                    </Text>
                    <BlueButton
                      buttonText='Uredi'
                      height='25px'
                      onClick={() => {
                        onEditDatePlaceOpen();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Text pb={2} fontSize={'sm'}>
                      Nije zakazano
                    </Text>
                    <BlueButton
                      buttonText='Zakaži'
                      height='25px'
                      onClick={() => {
                        onEditDatePlaceOpen();
                      }}
                    />
                  </>
                )}
              </VStack>
            </Card>
          ) : (
            <ExamGroupDateTime />
          )}
        </VStack>
      </Stack>
    </VStack>
  );
};
