import {
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../../components/general/RedButton';
import useProf from '../../../../../hooks/useProf.hooks';
import { useCreateExamScheduleMutation } from '../../../../../services/mutations';
import { useGetExamInfo } from '../../../../../services/queries';
import { createExamScheduleSchema } from '../../../../../validation/exam-schema';
import { HorizontalTextField } from '../../../NewSubject/comp/HorzintalTextField';
import { toISOStringLocal } from '../../../../../utils/utilityFunctions';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateExamScheduleModal = ({ isOpen, onClose }: Props) => {
  const profContext = useProf();
  const [isLoading, setIsLoading] = useState(false);

  const examInfo = useGetExamInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const createExamSchedule = useCreateExamScheduleMutation(examInfo.data?.id);

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Kreiraj novi rok</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Formik
              initialValues={{
                examInfoID: examInfo.data?.id,
                name: '',
                startingDate: '',
                endingDate: '',
              }}
              validationSchema={createExamScheduleSchema}
              onSubmit={async (values, actions) => {
                setIsLoading(true);
                try {
                  await createExamSchedule(values);
                  onClose();
                } catch (error) {
                } finally {
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 1000);
                }
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Stack
                    spacing={4}
                    justifyContent={{ base: 'start', md: 'center' }}
                    alignItems={'center'}
                  >
                    <HorizontalTextField name='name' type='text' label='Naziv roka' width='250px' />

                    <Heading pt={5} size={'sm'}>
                      Period roka
                    </Heading>

                    <Field name='startingDate'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='startingDate'
                          isInvalid={form.errors.startingDate && form.touched.startingDate}
                          isRequired
                        >
                          <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={{ base: 0, md: 5 }}
                            justify={'center'}
                            pl={{ base: 8, md: 0 }}
                          >
                            <VStack
                              w={{ base: '280px', md: '50%' }}
                              align={{ base: 'start', md: 'end' }}
                              justify={'center'}
                            >
                              <FormLabel htmlFor='startingDate'>Od</FormLabel>
                            </VStack>
                            <VStack
                              w={{ base: '280px', md: '50%' }}
                              align={'start'}
                              justify={'center'}
                            >
                              <DatePicker
                                {...field}
                                selected={field.value && new Date(field.value)}
                                onChange={(date: Date) => setFieldValue('startingDate', date)}
                                dateFormat='dd. MM. yyyy.'
                                placeholderText='Izaberite datum.'
                              />

                              <ErrorMessage name='startingDate'>
                                {(msg) => <Text color='red.400'>{msg}</Text>}
                              </ErrorMessage>
                            </VStack>
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='endingDate'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='endingDate'
                          isInvalid={form.errors.endingDate && form.touched.endingDate}
                          isRequired
                        >
                          <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={{ base: 0, md: 5 }}
                            justify={'center'}
                            pl={{ base: 8, md: 0 }}
                          >
                            <VStack
                              w={{ base: '280px', md: '50%' }}
                              align={{ base: 'start', md: 'end' }}
                              justify={'center'}
                            >
                              <FormLabel htmlFor='endingDate'>Do</FormLabel>
                            </VStack>
                            <VStack
                              w={{ base: '280px', md: '50%' }}
                              align={'start'}
                              justify={'center'}
                            >
                              <DatePicker
                                {...field}
                                selected={field.value && new Date(field.value)}
                                onChange={(date: Date) => setFieldValue('endingDate', date)}
                                dateFormat='dd. MM. yyyy.'
                                placeholderText='Izaberite datum.'
                              />
                              <ErrorMessage name='endingDate'>
                                {(msg) => <Text color='red.400'>{msg}</Text>}
                              </ErrorMessage>
                            </VStack>
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                    <Text fontSize='sm' color='gray.500'>
                      Ispite unutar roka se mogu samo kreirati u okviru perioda roka.
                    </Text>
                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Kreiraj rok'
                        loadingText='Rok se kreira.'
                        isLoading={isLoading}
                      />
                      <RedButton buttonText='Odustani' onClick={onClose} />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
