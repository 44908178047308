import Hero from './comp/Hero';
import Features from './comp/Features';
import Testimonials from './comp/Testimonials';
import useAuth from '../../../hooks/useAuth.hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const authContext = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.user?.role === 'STUDENT') {
      navigate('/student');
    } else if (authContext.user?.role === 'PROFESSOR' || authContext.user?.role === 'ASSISTANT') {
      navigate('/profesori');
    } else if (authContext.user?.role === 'ADMIN') {
      navigate('/admin');
    }
  }, [authContext, navigate]);

  return (
    <>
      <Hero />
      <Features />
      <Testimonials />
    </>
  );
}
