import {
  Avatar,
  Box,
  Card,
  Center,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlueButton } from '../../../../../components/general/BlueButton';
import { QRScanner } from '../../../../../services/QRScanner';
import {
  useCheckStudentExamStatus,
  useCheckStudentPartialExamStatus,
} from '../../../../../services/queries';
import useProf from '../../../../../hooks/useProf.hooks';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useStaffRegisterStudentExam } from '../../../../../services/mutations';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isExam: boolean;
};

export const CheckExamConditionModal = ({ isOpen, onClose, isExam }: Props) => {
  const profContext = useProf();
  const colorScheme = useColorModeValue('gray.100', 'gray.900');
  const { examID } = useParams();
  const { partialExamID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [scanON, setScanON] = useState(false);
  const [studentID, setStudentID] = useState(null as number | null);
  const queryClient = useQueryClient();

  const studentExamData = useCheckStudentExamStatus(
    Number(examID),
    studentID,
    profContext.selectedSubjectID
  );

  const registerStudent = useStaffRegisterStudentExam(
    Number(examID),
    studentID,
    profContext.selectedSubjectID
  );

  const studentPartialExamData = useCheckStudentPartialExamStatus(
    Number(partialExamID),
    studentID,
    profContext.selectedSubjectID
  );

  const studentData = isExam ? studentExamData : studentPartialExamData;

  const handleScan = async (data: string) => {
    try {
      setStudentID(Number(data));
      setScanON(false);
    } catch (error: any) {
    } finally {
      setScanON(false);
    }
  };

  return (
    <Modal
      isCentered
      size={'full'}
      isOpen={isOpen}
      onClose={async () => {
        onClose();
        setStudentID(null);

        await queryClient.invalidateQueries({
          queryKey: [Number(partialExamID), 'getPartialExamResults'],
        });
        await queryClient.invalidateQueries({
          queryKey: [Number(examID), 'getExamResults'],
        });
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Provjera uslova studenta</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          {studentData.isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : studentData.data ? (
            <Card w={'100%'} p={5} align={'center'} variant={'outline'} bg={colorScheme}>
              {studentData.data.student ? (
                <>
                  <VStack spacing={0} pb={5}>
                    <Avatar
                      size='xl'
                      name={studentData.data.student.firstName}
                      src={studentData.data.student.profileImage}
                    />
                    <Text>Ime: {studentData.data.student.firstName}</Text>
                    <Text>Prezime: {studentData.data.student.lastName}</Text>
                    <Text>Broj indeksa: {studentData.data.student.indexNumber}</Text>
                  </VStack>
                </>
              ) : (
                ''
              )}

              {studentData.data.error ? (
                <>
                  <Center>
                    <Icon as={CloseIcon} w={16} h={16} color='red.500' />
                  </Center>
                  <HStack pt={3} pb={5}>
                    <Text fontSize={'xl'} fontWeight={'bold'} color='red.500'>
                      {studentData.data.message}{' '}
                      {studentData.data.eligable && !studentData.data.registered
                        ? ', ali ima sve uslove za izlazak.'
                        : ''}
                    </Text>
                  </HStack>
                </>
              ) : (
                <>
                  <Center>
                    <Icon as={CheckIcon} w={16} h={16} color='green.500' />
                  </Center>
                  <HStack pt={3} pb={5}>
                    <Text fontSize={'xl'} fontWeight={'bold'} color='green.500'>
                      {studentData.data.message}
                    </Text>
                  </HStack>
                </>
              )}

              <VStack spacing={3} justify={'center'}>
                <BlueButton
                  width='200px'
                  height='30px'
                  buttonText='Skeniraj sljedećeg studenta'
                  onClick={() => {
                    setStudentID(null);
                    setScanON(true);
                  }}
                />
                {studentData.data.eligable && !studentData.data.registered && (
                  <BlueButton
                    width='200px'
                    height='30px'
                    buttonText='Prijavi studenta na ispit'
                    onClick={async () => {
                      if (isExam) {
                        await registerStudent({
                          examID: Number(examID),
                          studentID: studentID,
                        }).catch(() => {});
                      }
                    }}
                  />
                )}
              </VStack>
            </Card>
          ) : (
            ''
          )}

          <VStack justify={'center'} visibility={studentID ? 'hidden' : 'visible'}>
            {scanON && (
              <HStack justify={'center'}>
                <Box>
                  <QRScanner onScan={handleScan} />
                </Box>
              </HStack>
            )}
            <BlueButton
              buttonText={scanON ? 'Zatvori skener' : 'Skeniraj studenta'}
              onClick={() => {
                setScanON(!scanON);
              }}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
