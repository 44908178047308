import { createContext, useReducer, useCallback, useEffect } from 'react';
import {
  ProfContext,
  SubjectInfoResponse,
  profContextState,
  profReducerAction,
  profReducerActionType,
} from '../@types/profContext.types';
import { axiosInstance } from '../utils/axiosInstance';
import {
  GET_ACADEMIC_YEARS,
  GET_ALL_UNIVERSITIES,
  GET_SELECTED_SUBJECT_INFO_URL,
  GET_SUBJECTS_URL,
} from '../utils/globalConfig';

const profReducer = (state: profContextState, action: profReducerAction) => {
  switch (action.type) {
    //      ****   /profesori HOME    ****
    case profReducerActionType.GET_SUBJECTS:
      return { ...state, subjects: action.payload };
    case profReducerActionType.SELECTED_SUBJECT:
      return { ...state, selectedSubjectID: action.payload };
    case profReducerActionType.GET_SELECTED_SUBJECT_INFO:
      return { ...state, selectedSubjectInfo: action.payload };
    case profReducerActionType.GET_ALL_UNIVERSITIES:
      return { ...state, universities: action.payload };
    case profReducerActionType.GET_SELECTED_ACADEMIC_YEAR:
      return { ...state, selectedAcademicYearID: action.payload };
    default:
      throw new Error();
  }
};

const initState: profContextState = {
  subjects: [],
  selectedSubjectID: undefined,
  selectedAcademicYearID: undefined,
  selectedSubjectInfo: {
    id: 0,
    studyProgramID: '',
    name: '',
    ECTSpoints: 0,
    semester: 0,
    QRCode: '',
    latinSemester: '',
    studyProgramName: '',
    facultyName: '',
    universityName: '',
  },
  universities: [],
};

type ProfContextProviderType = {
  children: React.ReactNode;
};

export const ProfessorContext = createContext<ProfContext>(initState as ProfContext);

export const ProfContextProvider = ({ children }: ProfContextProviderType) => {
  const [state, dispatch] = useReducer(profReducer, initState);

  //initialize method
  const InitializeAuthContext = useCallback(async () => {
    try {
      const subjects = await axiosInstance.get(GET_SUBJECTS_URL);
      const subjectsArray = subjects.data ?? [];
      dispatch({ type: profReducerActionType.GET_SUBJECTS, payload: subjectsArray });
      if (subjectsArray.length > 0) {
        await setSelectedSubjectID(subjectsArray[0].id);
        await getSelectedSubjectInfo(subjectsArray[0].id);
      }
      const academicYears = await axiosInstance.get(GET_ACADEMIC_YEARS);
      for (const year of academicYears.data) {
        if (year.currentYear) {
          //dispatch({ type: profReducerActionType.GET_SELECTED_ACADEMIC_YEAR, payload: year.id });
          setSelectedAcademicYearID(year.id);
        }
      }
    } catch (error) {
      console.log('initializeAuthContext error: ', error);
    }
  }, []);

  useEffect(() => {
    InitializeAuthContext()
      .then(() => {})
      .catch((error) => console.log(error));
  }, []);

  const getSubjects = useCallback(async () => {
    const response = await axiosInstance.get(GET_SUBJECTS_URL);
    dispatch({ type: profReducerActionType.GET_SUBJECTS, payload: response.data });
  }, []);

  const setSelectedSubjectID = (value: number) => {
    dispatch({ type: profReducerActionType.SELECTED_SUBJECT, payload: value });
  };

  const getSelectedSubjectInfo = useCallback(async (subjectID: number) => {
    const response = await axiosInstance.post<SubjectInfoResponse>(GET_SELECTED_SUBJECT_INFO_URL, {
      subjectID,
    });
    dispatch({ type: profReducerActionType.GET_SELECTED_SUBJECT_INFO, payload: response.data });
  }, []);

  const getUniversities = useCallback(async () => {
    const response = await axiosInstance.get(GET_ALL_UNIVERSITIES);
    dispatch({ type: profReducerActionType.GET_ALL_UNIVERSITIES, payload: response.data });
  }, []);

  const setSelectedAcademicYearID = (value: number | undefined) => {
    dispatch({ type: profReducerActionType.GET_SELECTED_ACADEMIC_YEAR, payload: value });
  };

  const valuesObject = {
    subjects: state.subjects,
    selectedSubjectID: state.selectedSubjectID,
    selectedSubjectInfo: state.selectedSubjectInfo,
    universities: state.universities,
    selectedAcademicYearID: state.selectedAcademicYearID,
    getSubjects,
    setSelectedSubjectID,
    getSelectedSubjectInfo,
    getUniversities,
    setSelectedAcademicYearID,
  };

  return <ProfessorContext.Provider value={valuesObject}>{children}</ProfessorContext.Provider>;
};
