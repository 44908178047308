import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import useProf from '../../../../hooks/useProf.hooks';
import { deleteSubjectSchema } from '../../../../validation/subject-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';
import { useDeleteSubjectMutation } from '../../../../services/mutations';
import { useGetSubjectsQuery } from '../../../../services/queries';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteSubjectModal = ({ isOpen, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const profContext = useProf();
  const toast = useToast();
  const navigate = useNavigate();

  const deleteSubject = useDeleteSubjectMutation();
  const subjects = useGetSubjectsQuery();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    if (!areYouSure) {
      toast({
        description: 'Morate označiti da ste sigurni da želite izbrisati predmet!',
        status: 'warning',
        duration: 3000,
        position: 'top',
      });
      setIsLoading(false);
      return;
    }
    try {
      await deleteSubject(values);
      if (subjects.data && subjects.data.length > 0) {
        profContext.setSelectedSubjectID(subjects.data[0].id);
        profContext.getSelectedSubjectInfo(subjects.data[0].id);
      }
      navigate('/profesori');
      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Modal isCentered size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Brisanje predmeta</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          <Formik
            initialValues={{
              subjectID: profContext.selectedSubjectID,
              QRCode: '',
            }}
            validationSchema={deleteSubjectSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values);
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                  <HorizontalTextField
                    name='QRCode'
                    type='text'
                    label='Šifra predmeta'
                    width='150px'
                  />
                  <HStack spacing={8} justify={'center'}>
                    <VStack spacing={0}>
                      <Text>Da li ste sigurni da želite izbrisati predmet</Text>
                      <Text>{profContext.selectedSubjectInfo.name}?</Text>
                    </VStack>

                    <HStack>
                      <Checkbox
                        isChecked={areYouSure}
                        onChange={() => {
                          areYouSure ? setAreYouSure(false) : setAreYouSure(true);
                        }}
                      />
                      <FormLabel> DA </FormLabel>
                    </HStack>
                  </HStack>
                  <FormControl>
                    <FormHelperText>
                      Ovu akciju nije moguće poništiti. Svi podaci vezani za ovaj predmet će biti
                      izbrisani. Molimo vas da koristite ovu opciju sa oprezom.
                    </FormHelperText>
                  </FormControl>

                  <HStack py={5} justify={'center'}>
                    <BluesubmitButton
                      buttonText='Izbriši predmet'
                      loadingText='Brišemo predmet...'
                      isLoading={isLoading}
                    />
                  </HStack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
