import { useState } from "react";
import {
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Stack,
  Switch,
  Text,
  VStack,
  Progress,
  Box,
  ButtonGroup,
  Button,
  Flex,
} from "@chakra-ui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { BluesubmitButton } from "../../../../../components/general/BlueSubmitButton";
import { DateTimeInput } from "../../../../../components/general/DateTimeInput";
import { HorizontalTextField } from "../../../NewSubject/comp/HorzintalTextField";
import "react-datepicker/dist/react-datepicker.css";
import { partialExamPreview } from "../../../../../models/exams";
import { createPartialExamSchema } from "../../../../../validation/exam-schema";
import { useCreatePartialExamMutation } from "../../../../../services/mutations";

type Props = {
  examID: number | null;
  examScheduleID: number | null;
  partialExams: partialExamPreview[];
  onClose: () => void;
};
export default function CreatePartialExamMultiStepForm({
  examID,
  partialExams,
  examScheduleID,
  onClose,
}: Props) {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);
  const [isLoading, setIsLoading] = useState(false);
  const [sameGroupsDateTime, setSameGroupsDateTime] = useState(false);
  const [visible, setVisible] = useState(false);
  const [signatureNeeded, setSignatureNeeded] = useState(false);

  const createPartialExam = useCreatePartialExamMutation(
    Number(examScheduleID)
  );

  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto">
        <Progress
          hasStripe
          value={progress}
          mb="5%"
          mx="5%"
          isAnimated></Progress>
        <Formik
          initialValues={{
            examID,
            name: "",
            type: "",
            dateTime: null,
            place: "",
            deadlineDate: "",
            maxScore: 0,
            minPassingScore: 0,
            difficultyFactor: 0,
            registrationDeadline: null,
            nullificationDeadline: null,
            validUntil: null,
          }}
          validationSchema={createPartialExamSchema}
          onSubmit={async (values, actions) => {
            setIsLoading(true);
            try {
              await createPartialExam({
                ...values,
                visible,
                sameGroupsDateTime,
                signatureNeeded,
              });
              onClose();
            } catch (error) {
            } finally {
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
            }
          }}>
          {({ setFieldValue }) => (
            <Form>
              <Stack
                spacing={4}
                position="relative"
                justifyContent={{ base: "start", md: "center" }}
                alignItems={"center"}>
                {step === 1 ? (
                  <>
                    <HorizontalTextField
                      name="name"
                      type="text"
                      label="Naziv parcijalnog ispita"
                      width="250px"
                    />
                    <HorizontalTextField
                      name="type"
                      type="text"
                      label="Tip parcijalnog ispita"
                      width="250px"
                    />

                    <Divider />
                    <HStack>
                      <Text>
                        Da li će ispit biti u isto vrijeme za sve studente?
                      </Text>
                      <Switch
                        isChecked={sameGroupsDateTime}
                        onChange={() => {
                          setSameGroupsDateTime(!sameGroupsDateTime);
                        }}
                      />
                    </HStack>
                    {sameGroupsDateTime ? (
                      <VStack>
                        <DateTimeInput
                          name="dateTime"
                          label="Datum i vrijeme ispita"
                          setFieldValue={setFieldValue}
                          placeholder="izaberite datum i vrijeme"
                        />
                        <HorizontalTextField
                          name="place"
                          type="text"
                          label="Mjesto ispita"
                          width="250px"
                          isRequired={false}
                        />
                      </VStack>
                    ) : (
                      <Text fontSize="sm" color="gray.500">
                        Nakon kreiranja parcijalnog ispita, moći će te odrediti
                        datum i vrijeme za svaku grupu posebno.
                      </Text>
                    )}
                    <Divider />
                    {partialExams.length > 0 && (
                      <Field name={"conditionPartialExamID"}>
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            id={"conditionPartialExamID"}
                            isInvalid={
                              form.errors["conditionPartialExamID"] &&
                              form.touched["conditionPartialExamID"]
                            }>
                            <VStack justify={"center"}>
                              <Stack
                                align={"center"}
                                direction={{ base: "column", md: "row" }}
                                spacing={{ base: 0, md: 5 }}>
                                <VStack
                                  w={{ base: "100%", md: "50%" }}
                                  align={{ base: "start", md: "end" }}>
                                  <FormLabel>
                                    Uslovni parcijalni ispit
                                  </FormLabel>
                                </VStack>
                                <VStack
                                  w={{ base: "100%", md: "50%" }}
                                  align={"start"}>
                                  <Select
                                    placeholder={"Izaberite ispit"}
                                    w={"250px"}
                                    {...field}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "conditionPartialExamID",
                                        Number(e.target.value)
                                      );
                                    }}>
                                    <option value={-1}>Nema</option>
                                    {partialExams.map((data, index) => (
                                      <option
                                        value={data.id}
                                        key={index}>{`${data.name}`}</option>
                                    ))}
                                  </Select>
                                </VStack>
                              </Stack>
                              <ErrorMessage name={"conditionPartialExamID"}>
                                {(msg) => (
                                  <Text color="red" fontSize="sm">
                                    {msg}
                                  </Text>
                                )}
                              </ErrorMessage>
                            </VStack>
                          </FormControl>
                        )}
                      </Field>
                    )}
                  </>
                ) : step === 2 ? (
                  <>
                    <HorizontalTextField
                      name="maxScore"
                      type="number"
                      label="Maximalni broj bodova"
                      width="250px"
                    />
                    <HorizontalTextField
                      name="minPassingScore"
                      type="number"
                      label="Broj bodova za prolaz"
                      width="250px"
                    />
                    <HorizontalTextField
                      name="difficultyFactor"
                      type="number"
                      label="Težinski faktor"
                      width="250px"
                    />
                  </>
                ) : (
                  <>
                    <DateTimeInput
                      name="registrationDeadline"
                      label="Rok za prijavu ispita"
                      setFieldValue={setFieldValue}
                      placeholder="izaberite datum i vrijeme"
                      isRequired={true}
                    />
                    <DateTimeInput
                      name="nullificationDeadline"
                      label="Rok za poništavanje ispita"
                      setFieldValue={setFieldValue}
                      placeholder="izaberite datum i vrijeme"
                      isRequired={true}
                    />
                    <DateTimeInput
                      name="validUntil"
                      label="Položen ispit važi do"
                      setFieldValue={setFieldValue}
                      placeholder="izaberite datum i vrijeme"
                    />
                    <HStack>
                      <Text>Ispit vidljiv studentima?</Text>
                      <Checkbox
                        isChecked={visible}
                        onChange={() => {
                          setVisible(!visible);
                        }}
                      />
                    </HStack>
                    <HStack>
                      <Text>Potreban potpis?</Text>
                      <Checkbox
                        isChecked={signatureNeeded}
                        onChange={() => {
                          setSignatureNeeded(!signatureNeeded);
                        }}
                      />
                    </HStack>

                    <HStack py={5} justifyContent="flex-end" w="100%">
                      <BluesubmitButton
                        buttonText="Dodaj parcijalni ispit"
                        loadingText="parcijalni ispit se kreira."
                        isLoading={isLoading}
                        stepModal={true}
                      />
                    </HStack>
                  </>
                )}
                <ButtonGroup mt="5%" w="100%">
                  <Flex w="100%" justifyContent="space-between">
                    <Flex>
                      <Button
                        onClick={() => {
                          setStep(step - 1);
                          setProgress(progress - 33.33);
                        }}
                        isDisabled={step === 1}
                        bgColor={"blue.600"}
                        color={"white"}
                        variant="solid"
                        w="7rem"
                        mr="5%"
                        _hover={{
                          bg: "blue.300",
                          color: "blue.900",
                        }}>
                        Nazad
                      </Button>
                      <Button
                        w="7rem"
                        isDisabled={step === 3}
                        onClick={() => {
                          setStep(step + 1);
                          if (step === 3) {
                            setProgress(100);
                          } else {
                            setProgress(progress + 33.33);
                          }
                        }}
                        color={"white"}
                        borderColor={"blue.600"}
                        variant="outline">
                        Naprijed
                      </Button>
                    </Flex>
                  </Flex>
                </ButtonGroup>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
