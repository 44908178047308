import { Outlet, createBrowserRouter } from 'react-router-dom';

import LandingLayout from '../layouts/Landing/Landing';
import NewSubjectLayout from '../layouts/NewSubject/NewSubject';
import ProfessorsLayout from '../layouts/professor/Professors';

//
import ErrorPage from '../pages/Error';
import Auth from '../pages/Landing/Auth/Login';
import AboutUsPage from '../pages/Landing/aboutus/AboutUs';
import ContactUsPage from '../pages/Landing/contactUs/ContactUs';
import HomePage from '../pages/Landing/home/Home';
import NewsPage from '../pages/Landing/news/news';

import AuthGuard, { AllRoles } from '../auth/AuthGuard';
import { AuthContextProvider } from '../auth/auth.context';
import { ProfContextProvider } from '../context/professorContext';
import { StudentContextProvider } from '../context/student.context';
import { AdminLayout } from '../layouts/Admin/AdminLayout';
import { StudentLayout } from '../layouts/Student/StudentLayout';
import { AdminLogin } from '../pages/Admin/Auth/AdminLogin';
import { AdminHome } from '../pages/Admin/home/AdminHome';
import PostsPage from '../pages/Admin/posts/Posts';
import { StaffCreation } from '../pages/Admin/professors/StaffCreation';
import UniversitiesPage from '../pages/Admin/universitys/Universities';
import { Unauthorized } from '../pages/Landing/Auth/Unauthorized';

import ExerciseAttendances from '../pages/Professor/Exercises/ExerciseAttendances';
import { ExercisePerGroup } from '../pages/Professor/Exercises/ExercisePerGroup';
import ExercisesPage from '../pages/Professor/Exercises/Exercises';
import { StudentsGroupAllocation } from '../pages/Professor/Exercises/StudentsGroupAllocation';
import LecturesPage from '../pages/Professor/Lectures/Lectures';
import { LecturesAttendance } from '../pages/Professor/Lectures/LecturesAttendance';
import AddSubject from '../pages/Professor/NewSubject/AddSubject';
import NewSubject from '../pages/Professor/NewSubject/NewSubject';
import ProfessorsPage from '../pages/Professor/Professors/Professors';
import { AttendanceStatsPage } from '../pages/Professor/Students/AttendanceStats';
import StudentsPage from '../pages/Professor/Students/Students';
import { StudentsOnSubjectPage } from '../pages/Professor/Students/comp/StudentsOnSubject';
import { StudentLoginPage } from '../pages/Student/Auth/StudentLoginPage';
import { NewSubjectPage } from '../pages/Student/NewSubject/NewSubjectPage';
import { StudentHome } from '../pages/Student/StudentHome';
import { StudentLecturesPage } from '../pages/Student/Lectures/LecturesPage';
import { NewAttendanceQRScan } from '../pages/Student/Lectures/comp/NewAttendanceQRScan';
import { NewAttendanceQRInput } from '../pages/Student/Lectures/comp/NewAttendanceQRInput';
import { LecturesOptionsPage } from '../pages/Student/Lectures/LecturesScanPage';
import { StudentProfilePage } from '../pages/Student/Profile/StudentProfilePage';
import { StudentPasswordPage } from '../pages/Student/Profile/StudentPasswordPage';
import { StudentExercisePage } from '../pages/Student/Exercises/ExercisePage';
import { NewSubjectOptionsPage } from '../pages/Student/NewSubject/NewSubjectOptionsPage';
import { NewSubjectQRScan } from '../pages/Student/NewSubject/comp/NewSubjectQRScan';
import { NewSubjectQRInput } from '../pages/Student/NewSubject/comp/QRInput';
import { ExercisesOptionsPage } from '../pages/Student/Exercises/ExercisesScanPage';
import { NewAttendanceExQRScan } from '../pages/Student/Exercises/comp/NewAttendanceExQrScan';
import { NewAttendanceExQRInput } from '../pages/Student/Exercises/comp/NewAttendancesExQRInput';
import { ProfessorProfilePage } from '../pages/Professor/Profil/ProfessorProfilePage';
import { ProfessorPasswordPage } from '../pages/Professor/Profil/ProfessorsPasswordPage';
import { GreetingPage } from '../pages/Professor/GreetingPage';
import { ProfPostsPage } from '../pages/Professor/Students/PostPage';
import { StudentsPostsPage } from '../pages/Student/Post/PostsPage';
import { ApproveStudentsToSubjects } from '../pages/Professor/Students/ApproveStudentsToSubject';
import { ForgotenPasswordPage } from '../pages/Landing/ForgotenPassword';
import { SubjectActivitiesPage } from '../pages/Professor/SubjectActivities/SubjectActivites';
import { ExamsPage } from '../pages/Professor/Exams/Exams';
import { SubjectActivityScores } from '../pages/Professor/SubjectActivities/subjectActivityScores';
import { SubjectActivitiesPerGroupPage } from '../pages/Professor/SubjectActivities/SubjectActivitiesPerGroup';
import { QRCodeAutoScanExercisePage } from '../pages/Student/Exercises/QRCodeAutoScanExercise';
import { SubjectActivitiesScoresPage } from '../pages/Professor/Students/SubjectActivitiesScores';
import { QRCodeAutoScanLecturePage } from '../pages/Student/Lectures/QRCodeAutoScanLecture';
import { QRCodeAutoScanAddSubjectPage } from '../pages/Student/NewSubject/QRCodeAutoAddSubject';
import { StudentSubjectActivitiesPage } from '../pages/Student/SubjectActivities/SubjectActivitiesPage';
import { StudentResultsPage } from '../pages/Student/Results/StudentResultsPage';
import { StudentSubjectInfoPage } from '../pages/Student/SubjectInfo/StudentSubjectInfoPage';
import { AddManyStudentsExcelPage } from '../pages/Professor/Students/AddManyStudentsExcel';
import { CreateManyStudentsExcelPage } from '../pages/Admin/professors/CreateManyStudentsExcel';
import { OptionsPage } from '../pages/Professor/Options/OptionsPage';
import { QRCodeAutoScanStaffJoinSubjectPage } from '../pages/Professor/NewSubject/comp/QRCodeAutoScanJoinSubject';
import { ExamResults } from '../pages/Professor/Exams/ExamResults';
import { StudentsExamsPage } from '../pages/Student/Exams/ExamsPage';
import { SignaturePage } from '../pages/Professor/Students/SignaturePage';
import { PartialExamResultsPage } from '../pages/Professor/Exams/PartialExamResults';
import { StudentQRPage } from '../pages/Student/Profile/StudentQRPage';
import { ExamScheduleResultsPage } from '../pages/Professor/Exams/ExamScheduleResults';
import { FinalGradePage } from '../pages/Professor/Students/FinalGradePage';
import { StudentProfProfilePage } from '../pages/Professor/StudentProfile/StudentProfile';
import { ShowCasePage } from '../pages/Landing/showCase/ShowCase';
import { CompletedSubjectsPage } from '../pages/Student/Archive/CompletedSubjects';
import { CompletedSubjectStatPage } from '../pages/Student/Archive/CompletedSubjectStat';
import { SpecialPermissionPage } from '../pages/Admin/special-permisions/SpecialPermitionsPage';

const AuthLayout = () => (
  <AuthContextProvider>
    <Outlet />
  </AuthContextProvider>
);

const ProfContextLayout = () => (
  <ProfContextProvider>
    <Outlet />
  </ProfContextProvider>
);

const StudentContextLayout = () => (
  <StudentContextProvider>
    <Outlet />
  </StudentContextProvider>
);

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <LandingLayout />,
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <HomePage /> },
          { path: 'onama', element: <AboutUsPage /> },
          { path: 'kontakt', element: <ContactUsPage /> },
          { path: 'o-aplikaciji', element: <ShowCasePage /> },
          { path: 'novosti', element: <NewsPage /> },
          //{ path: 'prijavagresaka', element: <BugReportPage /> },
          { path: 'prijava', element: <Auth /> },
          //{ path: 'registracija', element: <RegisterPage /> },
          { path: 'adminlogin', element: <AdminLogin /> },
          { path: 'prijava-student', element: <StudentLoginPage /> },
          { path: 'neovlastenpristup', element: <Unauthorized /> },
          { path: 'zaboravljena-sifra', element: <ForgotenPasswordPage /> },
        ],
      },
      {
        element: <AuthGuard roles={[AllRoles.PROFESSOR, AllRoles.ASSISTANT]} />,
        children: [
          {
            element: <ProfContextLayout />,
            children: [
              {
                path: 'profesori',
                element: <ProfessorsLayout />,
                children: [
                  { index: true, element: <ProfessorsPage /> },
                  {
                    path: 'novi-predmet/scan/:QRCode',
                    element: <QRCodeAutoScanStaffJoinSubjectPage />,
                  },
                  //**** PREDAVANJA */
                  {
                    path: 'predavanja',
                    element: <LecturesPage />,
                  },
                  { path: 'predavanja/:lectureNumber/:lectureID', element: <LecturesAttendance /> },
                  //**** VJEŽBE */
                  {
                    path: 'vjezbe',
                    element: <ExercisesPage />,
                  },
                  { path: 'vjezbe/:groupID', element: <ExercisePerGroup /> },
                  {
                    path: 'vjezbe/:groupID/:exerciseNumber/:exerciseID',
                    element: <ExerciseAttendances />,
                  },
                  {
                    path: 'vjezbe/rasporedi-studente/:groupID',
                    element: <StudentsGroupAllocation />,
                  },
                  //**** DODATNE AKTIVNOSTI */
                  { path: 'dodatne-aktivnosti', element: <SubjectActivitiesPage /> },
                  {
                    path: 'dodatne-aktivnosti/:subjectActivityID',
                    element: <SubjectActivityScores />,
                  },
                  {
                    path: 'dodatne-aktivnosti/grupa/:groupID',
                    element: <SubjectActivitiesPerGroupPage />,
                  },

                  //**** ISPITI */
                  { path: 'ispiti', element: <ExamsPage /> },
                  { path: 'ispiti/ispit/:examID', element: <ExamResults /> },
                  {
                    path: 'ispiti/parcijalni-ispit/:partialExamID',
                    element: <PartialExamResultsPage />,
                  },
                  {
                    path: 'ispiti/rezultati-roka/:examScheduleID',
                    element: <ExamScheduleResultsPage />,
                  },

                  // **** STUDENT PROFILE FOR STAFF****//

                  {
                    path: 'student/:studentID',
                    element: <StudentProfProfilePage />,
                  },

                  //**** STUDENTI */

                  {
                    path: 'studenti',
                    element: <StudentsPage />,
                  },
                  {
                    path: 'studenti/dodaj-studente',
                    element: <AddManyStudentsExcelPage />,
                  },
                  { path: 'studenti/svi-studenti', element: <StudentsOnSubjectPage /> },
                  { path: 'studenti/pregled-stanja-prisustva', element: <AttendanceStatsPage /> },
                  {
                    path: 'studenti/pregled-stanja-prisustva/podjela-potpisa',
                    element: <SignaturePage />,
                  },
                  {
                    path: 'studenti/bodovi-predmetne-aktivnosti',
                    element: <SubjectActivitiesScoresPage />,
                  },
                  {
                    path: 'studenti/odobri-studente-na-predmet',
                    element: <ApproveStudentsToSubjects />,
                  },
                  { path: 'studenti/objave', element: <ProfPostsPage /> },
                  { path: 'studenti/konacna-ocjena', element: <FinalGradePage /> },

                  //**** PROFIL */
                  { path: 'profil', element: <ProfessorProfilePage /> },
                  { path: 'profil/sifra', element: <ProfessorPasswordPage /> },

                  { path: 'dobrodosli', element: <GreetingPage /> },

                  //**** OPCIJE */
                  { path: 'postavke', element: <OptionsPage /> },
                ],
              },
              {
                path: 'profesori/novipredmet',
                element: <NewSubjectLayout />,
                children: [
                  { index: true, element: <NewSubject /> },
                  { path: 'kreirajpredmet', element: <AddSubject /> },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <AuthGuard roles={[AllRoles.ADMIN]} />,
        children: [
          {
            path: 'admin',
            element: <AdminLayout />,
            children: [
              { index: true, element: <AdminHome /> },
              { path: 'korisnici', element: <StaffCreation /> },
              { path: 'korisnici/dodaj-studente', element: <CreateManyStudentsExcelPage /> },
              { path: 'ustanove', element: <UniversitiesPage /> },
              { path: 'objave', element: <PostsPage /> },
              { path: 'specijalne-permisije', element: <SpecialPermissionPage /> },
            ],
          },
        ],
      },
      {
        element: <AuthGuard roles={[AllRoles.STUDENT]} />,
        children: [
          {
            element: <StudentContextLayout />,
            children: [
              {
                path: 'student',
                element: <StudentLayout />,
                children: [
                  { index: true, element: <StudentHome /> },
                  { path: 'novi-predmet', element: <NewSubjectPage /> },
                  { path: 'novi-predmet/scan/:QRCode', element: <QRCodeAutoScanAddSubjectPage /> },
                  { path: 'novi-predmet/opcije', element: <NewSubjectOptionsPage /> },
                  { path: 'novi-predmet/opcije/scan', element: <NewSubjectQRScan /> },
                  { path: 'novi-predmet/opcije/input', element: <NewSubjectQRInput /> },

                  { path: 'profil', element: <StudentProfilePage /> },
                  { path: 'profil/sifra', element: <StudentPasswordPage /> },

                  { path: 'objave', element: <StudentsPostsPage /> },

                  { path: 'predavanja', element: <StudentLecturesPage /> },
                  { path: 'predavanja/scan/:QRCode', element: <QRCodeAutoScanLecturePage /> },
                  { path: 'predavanja/opcije', element: <LecturesOptionsPage /> },
                  { path: 'predavanja/opcije/scan', element: <NewAttendanceQRScan /> },
                  { path: 'predavanja/opcije/input', element: <NewAttendanceQRInput /> },

                  { path: 'vjezbe', element: <StudentExercisePage /> },
                  { path: 'vjezbe/scan/:QRCode', element: <QRCodeAutoScanExercisePage /> },
                  { path: 'vjezbe/opcije', element: <ExercisesOptionsPage /> },
                  { path: 'vjezbe/opcije/scan', element: <NewAttendanceExQRScan /> },
                  { path: 'vjezbe/opcije/input', element: <NewAttendanceExQRInput /> },

                  { path: 'dodatne-aktivnosti', element: <StudentSubjectActivitiesPage /> },

                  { path: 'ispiti', element: <StudentsExamsPage /> },

                  { path: 'rezultati', element: <StudentResultsPage /> },

                  { path: 'podaci-od-predmeta', element: <StudentSubjectInfoPage /> },

                  { path: 'moj-qr-code', element: <StudentQRPage /> },

                  { path: 'polozeni-predmeti', element: <CompletedSubjectsPage /> },
                  { path: 'polozeni-predmeti/:subjectID', element: <CompletedSubjectStatPage /> },
                  /*  
              { path: 'ustanove', element: <UniversitiesPage /> },
              { path: 'objave', element: <PostsPage /> },
              { path: 'specijalne-permisije', element: <SpecialPermision /> }, */
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
