import { Center, HStack, Spinner, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import useProf from '../../../../hooks/useProf.hooks';
import { SubjectDifficultyFactors } from '../../../../models/subject';
import { useEditDifficultyFactors } from '../../../../services/mutations';
import { useGetSubjectDifficultyFactors } from '../../../../services/queries';
import { editDifficultyFactorsSchema } from '../../../../validation/subject-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {};

export const EditDifficultyFactor = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();
  const toast = useToast();

  const difficultyFactors = useGetSubjectDifficultyFactors(profContext.selectedSubjectID);
  const editDifficultyFactors = useEditDifficultyFactors(profContext.selectedSubjectID);

  const calculateSumDF = (values: SubjectDifficultyFactors) => {
    let sum = Object.values(values).reduce((acc, current) => acc + parseFloat(current || 0), 0);
    sum -= profContext.selectedSubjectID;
    return sum;
  };

  return difficultyFactors.isLoading ? (
    <Center>
      <Spinner />
    </Center>
  ) : difficultyFactors.data ? (
    <VStack pt={5} w={'100%'}>
      <Formik
        initialValues={{
          subjectID: profContext.selectedSubjectID,
          lecturesDF: difficultyFactors.data.lecturesDF,
          exercisesDF: difficultyFactors.data.exercisesDF,
          subjectActivitiesAllDF: difficultyFactors.data.subjectActivitiesAllDF,
          subjectActivitiesGroupDF: difficultyFactors.data.subjectActivitiesGroupDF,
          examsDF: difficultyFactors.data.examsDF,
        }}
        validationSchema={editDifficultyFactorsSchema}
        onSubmit={(values, actions) => {
          if (calculateSumDF(values) !== 100) {
            toast({
              position: 'top',
              description: 'Suma težinskog faktora mora biti 100',
              status: 'warning',
              duration: 3000,
              isClosable: true,
            });
          } else {
            setIsLoading(true);
            editDifficultyFactors(values).catch((error) => {});
            setIsLoading(false);
          }
        }}
      >
        {({ values }) => (
          <Form>
            <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
              <HorizontalTextField
                name='lecturesDF'
                type='number'
                label='Predavanja'
                width='200px'
              />
              <HorizontalTextField name='exercisesDF' type='number' label='Vježbe' width='200px' />
              <HorizontalTextField
                name='subjectActivitiesAllDF'
                type='number'
                label='Aktivnosti za sve'
                width='200px'
              />
              <HorizontalTextField
                name='subjectActivitiesGroupDF'
                type='number'
                label='Aktivnosti po grupi'
                width='200px'
              />
              <HorizontalTextField name='examsDF' type='number' label='Ispiti' width='200px' />
              <Text
                fontSize={'lg'}
                color={calculateSumDF(values) === 100 ? 'green.400' : 'red.400'}
              >
                Suma: {calculateSumDF(values)}
              </Text>

              <HStack py={5} justify={'center'}>
                <BluesubmitButton
                  buttonText='Sačuvaj promjene'
                  loadingText='u toku...'
                  isLoading={isLoading}
                  isDisabled={
                    calculateSumDF(values) !== 100 ||
                    (values.examsDF === difficultyFactors.data.examsDF &&
                      values.lecturesDF === difficultyFactors.data.lecturesDF &&
                      values.exercisesDF === difficultyFactors.data.exercisesDF &&
                      values.subjectActivitiesAllDF ===
                        difficultyFactors.data.subjectActivitiesAllDF &&
                      values.subjectActivitiesGroupDF ===
                        difficultyFactors.data.subjectActivitiesGroupDF)
                  }
                />
              </HStack>
            </Stack>
          </Form>
        )}
      </Formik>
    </VStack>
  ) : (
    <Text>Greška prilikom učitavanja podataka</Text>
  );
};
