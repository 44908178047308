import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Card,
  Center,
  Flex,
  GridItem,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { useGetStudentsSubjectActivitiesInfo } from '../../../services/queries';

type Props = {};

export const StudentSubjectActivitiesPage = (props: Props) => {
  const navigate = useNavigate();
  const studentContext = useStudentContext();

  const subjectActivities = useGetStudentsSubjectActivitiesInfo(studentContext.selectedSubjectID);

  return (
    <Flex
      flexDirection='column'
      p='5'
      bg={useColorModeValue('white', 'gray.900')}
      w='100%'
      pos={'relative'}
    >
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'20px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={10}>
          Dodatne aktivnosti
        </Heading>
      </HStack>
      {subjectActivities.isLoading ? (
        <Center h={'20vh'}>
          <Spinner />
        </Center>
      ) : subjectActivities.data ? (
        <Stack spacing={0} justify={'center'} align={'center'}>
          <SimpleGrid pb={5} columns={3} spacing={2}>
            <Card p={2}>
              <VStack spacing={0}>
                <Text fontSize={'xl'}>{subjectActivities.data.numberOfActivities}</Text>
                <Text textAlign={'center'} fontSize={'xs'}>
                  Broj aktivnosti
                </Text>
              </VStack>
            </Card>
            <Card p={2}>
              <VStack spacing={0}>
                <Text fontSize={'xl'}>{subjectActivities.data.maxScore}</Text>
                <Text fontSize={'xs'}>Ukupno Bodova</Text>
              </VStack>
            </Card>
            <Card p={2}>
              <VStack spacing={0}>
                <Text fontSize={'xl'}>{subjectActivities.data.scoreAchieved}</Text>
                <Text fontSize={'xs'}>Osvojenih Bodova</Text>
              </VStack>
            </Card>
            {/* <Card p={2}>
              <VStack spacing={0}>
                <Text fontSize={'xl'}>{subjectActivities.data.difficultyFactor}</Text>
                <Text fontSize={'xs'}>Težinski faktor</Text>
              </VStack>
            </Card>
            <GridItem colSpan={2}>
              <Card p={2}>
                <VStack spacing={0}>
                  <Text fontSize={'xl'}>
                    {subjectActivities.data.scoreAchieved} / {subjectActivities.data.maxScore} x{' '}
                    {subjectActivities.data.usedDifficultyFactor} ={' '}
                    {(
                      (subjectActivities.data.scoreAchieved / subjectActivities.data.maxScore) *
                      subjectActivities.data.usedDifficultyFactor
                    ).toFixed(2)}
                  </Text>
                  <Text fontSize={'xs'}>Broj osvojenih predmetnih bodova</Text>
                </VStack>
              </Card>
            </GridItem> */}
          </SimpleGrid>

          <VStack w={{ base: '90vw', md: '70%', lg: '800px' }} pt={5}>
            {subjectActivities.data.subjectActivities.map((activity) => (
              <Accordion key={`subjectActivityAll-${activity.id}`} w={'100%'} allowMultiple>
                <AccordionItem>
                  <AccordionButton>
                    <HStack w={'100%'} justify={'space-between'}>
                      <Box as='span' flex='1' textAlign='left'>
                        <Heading fontSize={'md'}>{activity.name}</Heading>
                        <Text fontSize={'xs'}>
                          Vaši bodovi: {activity.studentScore} / {activity.maxScore}
                        </Text>
                        {activity.deadlineDate && (
                          <Text fontSize={'xs'}>Rok predaje: {activity.deadlineDate}</Text>
                        )}
                        {activity.dateTime && (
                          <Text fontSize={'xs'}>Datum održavanja: {activity.dateTime}</Text>
                        )}
                      </Box>
                      <HStack pr={3}>
                        <Avatar size={'sm'} name={'Profesor'} src={activity.creator.profileImage} />
                        <Text fontSize={'xs'}>
                          {activity.creator.lastName} {activity.creator.firstName}
                        </Text>
                      </HStack>
                    </HStack>

                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <VStack w={'100%'}>
                      <VStack fontSize={'sm'} w={'100%'} align={'start'} spacing={0}>
                        <Text>Datum kreiranja: {activity.createdAt}</Text>
                        {activity.edited ? <Text>Datum editovanja: {activity.editedAt}</Text> : ''}
                        <Text>Broj Bodova: {activity.maxScore}</Text>
                        <Text>Broj Bodova za prolaz: {activity.minPassingScore}</Text>
                        <Text>Tip aktivnosti: {activity.type}</Text>
                      </VStack>
                      <Heading size={'md'}>Opis aktivnosti</Heading>
                      <Text
                        w={{ base: '100%', md: '80%' }}
                        whiteSpace={'pre-line'}
                        textAlign={'justify'}
                      >
                        {activity.text}
                      </Text>
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ))}
          </VStack>
        </Stack>
      ) : (
        <Text textAlign={'center'}>Nema kreiranih aktivnosti</Text>
      )}
    </Flex>
  );
};
