import { Box, Flex, HStack, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import useAuth from '../../../hooks/useAuth.hooks';

type Props = {};

export const StudentQRPage = (props: Props) => {
  const studContext = useStudentContext();
  const authContext = useAuth();
  const navigate = useNavigate();
  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Vaš QR kod
        </Heading>
      </HStack>{' '}
      <HStack justify={'center'} pt={10}>
        <Box borderRadius='3xl' overflow='hidden'>
          <QRCodeSVG
            /* value={
                      basePath + `/student/${predavanja ? 'predavanja' : 'vjezbe'}/scan/` + QRCode
                    } */
            value={authContext.user?.sub.toString() ?? ''}
            size={280}
            fgColor={'#000000'}
            level={'L'}
            includeMargin={true}
          />
        </Box>
      </HStack>
      <HStack justify={'center'}>
        <Heading fontSize={{ base: '6xl', md: '6xl', lg: '8xl' }} pl={{ base: 0, md: 10 }}>
          {authContext.user?.sub ?? ''}
        </Heading>
      </HStack>
    </Flex>
  );
};
