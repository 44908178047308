import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth.hooks';
import { PATH_PUBLIC } from '../utils/path';
import { StaffEnum } from '../@types/auth.types';
import { Box, Spinner, Flex } from '@chakra-ui/react';

// We need an interface for our props.
// We receive a roles array and we will decide the next step based on this array
export enum AllRoles {
  PROFESSOR = 'PROFESSOR',
  ASSISTANT = 'ASSISTANT',
  STUDENT = 'STUDENT',
  ADMIN = 'ADMIN',
}

interface IProps {
  roles: AllRoles[];
}

const AuthGuard = ({ roles }: IProps) => {
  const { isAuthenticated, user, isAuthLoading } = useAuth();
  // Do we have access to the requested page(the page will be rendered in <Outlet />)
  const hasAccess = isAuthenticated && user?.role && roles.includes(user.role as AllRoles);
  if (isAuthLoading) {
    return (
      <Flex width='100vw' height='100vh' justifyContent='center' alignItems='center'>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
      </Flex>
    );
  }
  return hasAccess ? <Outlet /> : <Navigate to={PATH_PUBLIC.unauthorized} />;
};
export default AuthGuard;
