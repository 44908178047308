import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, Flex, HStack, Heading, VStack, useColorModeValue } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import LecturesAttendancesInfo from './comp/LecturesAttendancesInfo';
import { LectureAttendancesTable } from './comp/LectureAttendancesTable';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';

type Props = {};

export const LecturesAttendance = (props: Props) => {
  const navigate = useNavigate();
  const { lectureID } = useParams();
  const { lectureNumber } = useParams();

  const handleBackClick = () => {
    navigate('/profesori/predavanja');
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading pb={4} fontSize={'xl'}>
          Prisustvo na {lectureNumber}. predavanju
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <LecturesAttendancesInfo />
      <VStack pt={4} w={'90%'}>
        <LectureAttendancesTable />
      </VStack>
    </Flex>
  );
};
