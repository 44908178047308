import React from 'react';
import {
  Avatar,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  Th,
  VStack,
} from '@chakra-ui/react';

import useProf from '../../../../hooks/useProf.hooks';
import { useGetSubjectActivitiesScoresPerGroupTable } from '../../../../services/queries';
import { useNavigate } from 'react-router-dom';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

export const SubjectActivitiesScorePerGroupTables = () => {
  const profContext = useProf();
  const navigate = useNavigate();

  const subjectActivities = useGetSubjectActivitiesScoresPerGroupTable({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  if (subjectActivities.isLoading) {
    return (
      <Center h={'30vh'}>
        <Spinner />
      </Center>
    );
  }

  if (subjectActivities.error) {
    return (
      <Center h={'30vh'}>
        <Text>Desila se greška, ili nemate pristup podacima.</Text>
      </Center>
    );
  }

  return (
    <>
      {subjectActivities.data &&
        subjectActivities.data.length > 0 &&
        subjectActivities.data.map((groupData, groupIndex) => (
          <VStack pt={5} w={{ base: '90vw', md: '80%', lg: '60%' }} key={`group-${groupIndex}`}>
            <Text fontSize='xl' mb='4'>
              Ime Grupe: {groupData.groupName}
            </Text>

            <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
              <Table variant='striped' colorScheme='facebook' size='sm'>
                <Thead>
                  <Tr>
                    <Th colSpan={4} textAlign='center'>
                      podaci od studenta
                    </Th>
                    {groupData.subjectActivities && groupData.subjectActivities.length > 0
                      ? groupData.subjectActivities.map((subjectActivity, index) => (
                          <Th textAlign='center' py={'5px'} key={`subjectA-${index + 1}`}>
                            {subjectActivity.name}
                          </Th>
                        ))
                      : ''}
                    <Th colSpan={1} textAlign='center'>
                      Ukupno
                    </Th>
                  </Tr>
                  <Tr>
                    <Th textAlign='center'>RB</Th>
                    <Th textAlign='center'>Slika</Th>
                    <Th textAlign='center'>B. Indexa</Th>
                    <Th>Prezime i ime</Th>
                    {groupData.subjectActivities && groupData.subjectActivities.length > 0
                      ? groupData.subjectActivities.map((subjectActivity, index) => (
                          <Th textAlign='center' key={`subjectA-${index + 1}`}>
                            max: {subjectActivity.maxScore}
                          </Th>
                        ))
                      : ''}
                    <Th textAlign='center'>max: {groupData.maxScore}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {groupData.students.map((student, index) => (
                    <Tr key={student.id}>
                      <Td textAlign='center'>{index + 1}</Td>
                      <Td textAlign='center'>
                        <Avatar size={'sm'} src={student.profileImage} />
                      </Td>
                      <Td textAlign='center'>{student.indexNumber}</Td>
                      <Td>
                        <StudentNameSubjectStats
                          firstName={student.firstName}
                          lastName={student.lastName}
                          navigateLink={`/profesori/student/${student.id}`}
                          hasCompletedSubject={student.hasCompletedSubject}
                          repeater={student.repeater}
                        />
                      </Td>
                      {student.scores.map((score, scoreIndex) => (
                        <Td textAlign='center' key={`score-${scoreIndex + 1}`}>
                          {score}
                        </Td>
                      ))}
                      <Td textAlign='center'>{student.sumScores}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </VStack>
        ))}
    </>
  );
};
