import {
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { ExercisesTable } from './comp/ExercisesTable';
import { ExercisePerGroupInfo } from './comp/ExercisePerGroupInfo';
import { BlueButton } from '../../../components/general/BlueButton';
import useAuth from '../../../hooks/useAuth.hooks';
import { useGetExercisesPerGroupInfo } from '../../../services/queries';
import { CreateExerciseModal } from './comp/CreateExerciseModal';

type Props = {};

export const ExercisePerGroup = (props: Props) => {
  const authContext = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { groupID } = useParams();

  const exercisesInfoQuery = useGetExercisesPerGroupInfo(Number(groupID));

  const handleBackClick = () => {
    navigate('/profesori/vjezbe');
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading pb={4} fontSize={'xl'}>
          Vježbe od grupe {exercisesInfoQuery.data?.groupName}
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 3, md: 0 }}
        mt={'2rem'}
        justifyContent={'space-around'}
        align={'center'}
        w={{ base: '100%', md: '800px' }}
      >
        <CreateExerciseModal isOpen={isOpen} onClose={onClose} />
        <ExercisePerGroupInfo />
        <BlueButton
          buttonText='Dodaj vježbe'
          onClick={onOpen}
          isDisabled={
            exercisesInfoQuery.data ? (authContext.user?.role === 'ASSISTANT' ? false : true) : true
          }
        />
      </Stack>

      <ExercisesTable />
    </Flex>
  );
};
