import {
  Button,
  Center,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetExerciseAttendances, useGetLectureAttendances } from '../../../../services/queries';
import {
  useToggleStudentExercisePresence,
  useToggleStudentPresence,
} from '../../../../services/mutations';
import useAuth from '../../../../hooks/useAuth.hooks';
import { useState } from 'react';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

interface LoadingStates {
  [key: number]: boolean;
}

export const ExerciseAttendancesTable = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const { groupID, exerciseNumber, exerciseID } = useParams();
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

  const exerciseAttendancesQuery = useGetExerciseAttendances(Number(exerciseID));

  //const toggleStudentPresence = useToggleStudentPresence(Number(exerciseID), 0);
  const toggleStudentPresence = useToggleStudentExercisePresence(Number(exerciseID));

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  return (
    <>
      <TableContainer
        mt={'1rem'}
        w={'800px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center' w={'10%'}>
                RB
              </Th>
              <Th textAlign='center' w={'35%'}>
                Ime i prezime
              </Th>
              <Th textAlign='center' w={'10%'}>
                Broj Indexa
              </Th>
              <Th textAlign='center' w={'10%'}>
                prisutan
              </Th>
              <Th textAlign='center' w={'35%'}>
                opcije
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {exerciseAttendancesQuery.isLoading ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : exerciseAttendancesQuery.isError ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <VStack>
                      <Text>{getErrorMessage(exerciseAttendancesQuery.error)}</Text>
                      <Button
                        fontSize={'sm'}
                        bgColor={'blue.600'}
                        color={'white'}
                        onClick={() => {
                          navigate('/profesori/predavanja');
                        }}
                      >
                        Vrati se na sva vježbe
                      </Button>
                    </VStack>
                  </Center>
                </Td>
              </Tr>
            ) : exerciseAttendancesQuery.data && exerciseAttendancesQuery.data.length > 0 ? (
              exerciseAttendancesQuery.data.map((attendance, index) => (
                <Tr key={attendance.id}>
                  <Td textAlign='center' w={'10%'}>
                    {index + 1}
                  </Td>
                  <Td textAlign='center' w={'40%'}>
                    <StudentNameSubjectStats
                      firstName={attendance.firstName}
                      lastName={attendance.lastName}
                      repeater={attendance.repeater}
                      hasCompletedSubject={attendance.hasCompletedSubject}
                      navigateLink={`/profesori/student/${attendance.id}`}
                    />
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    {attendance.indexNumber}
                  </Td>
                  <Td w='10%' textAlign={'center'}>
                    <HStack justify={'center'}>
                      {attendance.presence ? (
                        <Text
                          fontWeight={'700'}
                          color={'green.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          DA
                        </Text>
                      ) : (
                        <Text
                          fontWeight={'700'}
                          color={'red.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          NE
                        </Text>
                      )}
                    </HStack>
                  </Td>
                  <Td textAlign='center' w={'30%'}>
                    <Button
                      size='xs'
                      colorScheme='blue'
                      onClick={() => {
                        setLoadingStates((prev) => ({ ...prev, [attendance.id]: true }));
                        toggleStudentPresence({
                          exerciseID: Number(exerciseID),
                          studentID: attendance.id,
                        })
                          .catch((err) => {})
                          .finally(() => {
                            setTimeout(() => {
                              setLoadingStates((prev) => ({ ...prev, [attendance.id]: false }));
                            }, 1000);
                          });
                      }}
                      isDisabled={authContext.user?.role === 'ASSISTANT' ? false : true}
                      isLoading={loadingStates[attendance.id]}
                    >
                      {attendance.presence ? 'Označi kao odsutan' : 'Označi kao prisutan'}
                    </Button>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Text> Nema prisutnih studenata na predavanju </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {exerciseAttendancesQuery.data && exerciseAttendancesQuery.data.length > 5 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center' w={'5%'}>
                  RB
                </Th>
                <Th textAlign='center' w={'30%'}>
                  Ime i prezime
                </Th>
                <Th textAlign='center' w={'5%'}>
                  Broj Indexa
                </Th>
                <Th textAlign='center' w={'20%'}>
                  prisutan
                </Th>
                <Th textAlign='center' w={'35%'}>
                  opcije
                </Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
