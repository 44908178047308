import { Box, Flex, HStack, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useStudentJoinSubject } from '../../../../services/mutations';
import { useNavigate } from 'react-router-dom';
import { QRScanner } from '../../../../services/QRScanner';
import { ArrowBackButton } from '../../../../components/general/ArrowBackButton';

export const NewSubjectQRScan = () => {
  const navigate = useNavigate();
  const joinSubject = useStudentJoinSubject();
  const handleScan = async (scannedCode: string) => {
    try {
      await joinSubject(scannedCode);
      navigate('/student');
    } catch (error) {}
  };

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={'absolute'} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student/novi-predmet/opcije');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={'10vh'}>
          Skeniraj QR kod
        </Heading>
      </HStack>
      <HStack justify={'center'}>
        <Box borderRadius={'lg'}>
          <QRScanner onScan={handleScan} />
        </Box>
      </HStack>
    </Flex>
  );
};
