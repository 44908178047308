import {
  Avatar,
  Box,
  Card,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { BlueButton } from '../../../components/general/BlueButton';
import { ImageUploadModal } from '../../../components/general/ImageUpload';
import useAuth from '../../../hooks/useAuth.hooks';

export function ProfessorProfilePage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authContext = useAuth();
  const professorsData = authContext.user;
  const navigate = useNavigate();

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'160px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/profesori');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Profil
        </Heading>
      </HStack>
      <ImageUploadModal isOpen={isOpen} onClose={onClose} />
      <Box w={{ base: '90vw', md: '500px' }} mx='auto' justifyContent={'center'}>
        <Card p={3} w={'100%'}>
          <Flex direction='column' align='center' mb={5}>
            <Avatar
              w={'170px'}
              h={'170px'}
              name={professorsData?.firstName + ' ' + professorsData?.lastName}
              src={professorsData?.profileImage + '&sz=w1000'}
              mb={3}
            />

            <Text fontSize='xl' fontWeight='bold'>
              {professorsData?.firstName + ' ' + professorsData?.lastName}
            </Text>
            <Text mb={2} fontSize='md' fontWeight='light'>
              {professorsData?.title}
            </Text>
            <Text pb={5} fontSize='md'>
              {professorsData?.email}
            </Text>

            <VStack>
              <BlueButton
                fontSize='lg'
                height='40px'
                width='230px'
                buttonText='Promjeni šifru'
                onClick={() => {
                  navigate('sifra');
                }}
              />
              <BlueButton
                fontSize='lg'
                height='40px'
                width='230px'
                buttonText='Promjeni profilnu sliku'
                onClick={() => {
                  onOpen();
                }}
              />
            </VStack>
          </Flex>
        </Card>{' '}
      </Box>
    </Flex>
  );
}
