import {
  Avatar,
  Box,
  Card,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { BlueButton } from '../../../components/general/BlueButton';
import useAuth from '../../../hooks/useAuth.hooks';
import { ImageUploadModal } from '../../../components/general/ImageUpload';

export function StudentProfilePage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const authContext = useAuth();
  const studentData = authContext.user;
  const navigate = useNavigate();

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Profil
        </Heading>
      </HStack>
      <ImageUploadModal isOpen={isOpen} onClose={onClose} />
      <Box w={{ base: '90vw', md: '500px' }} mx='auto' justifyContent={'center'}>
        <Card p={3} w={'100%'}>
          <Flex direction='column' align='center' mb={5}>
            <Avatar
              w={'170px'}
              h={'170px'}
              name={studentData?.firstName + ' ' + studentData?.lastName}
              src={authContext.user?.profileImage}
              mb={3}
            />
            <Text mb={2} fontSize='xl' fontWeight='bold'>
              {studentData?.firstName + ' ' + studentData?.lastName}
            </Text>
            <Text pb={5} fontSize='md'>
              {studentData?.email}
            </Text>
            <HStack mb={10}>
              <VStack align={'end'} mr='2' fontWeight='bold' spacing='0'>
                <p>Broj indexa: </p>
                {/* <p>Email: </p> */}
              </VStack>
              <VStack align='start' ml='2' spacing='0'>
                <p>{studentData?.indexNumber}</p>
                {/* <p>{studentData?.email}</p> */}
              </VStack>
            </HStack>
            <VStack>
              <BlueButton
                fontSize='lg'
                height='40px'
                width='230px'
                buttonText='Promjeni šifru'
                onClick={() => {
                  navigate('sifra');
                }}
              />
              <BlueButton
                fontSize='lg'
                height='40px'
                width='230px'
                buttonText='Promjeni profilnu sliku'
                onClick={() => {
                  onOpen();
                }}
              />
            </VStack>
          </Flex>
        </Card>
      </Box>
    </Flex>
  );
}

/* import { Flex, HStack, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';

type Props = {};

export const StudentProfilePage = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={10}>
          Profil
        </Heading>
      </HStack>
    </Flex>
  );
};
 */
