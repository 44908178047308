// SingleFileInput.tsx
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage } from 'formik';
import React, { useRef, useState } from 'react';

interface SingleFileInputProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  fileName: string;
  setFileName: (fileName: string) => void;
}

const SingleFileInput: React.FC<SingleFileInputProps> = ({
  setFieldValue,
  fileName,
  setFileName,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setFileName(file.name);
      setFieldValue('file', file);
    }
  };

  return (
    <FormControl isRequired>
      <FormLabel textAlign={'center'}> Excel tabela </FormLabel>
      <Input id='file' type='file' onChange={handleFileChange} ref={fileInputRef} hidden />
      <VStack spacing={4}>
        <Button
          onClick={() => fileInputRef.current?.click()}
          bgColor={'blue.600'}
          color={'white'}
          fontSize={'sm'}
          _hover={{
            bg: 'blue.300',
            color: 'blue.900',
          }}
          w={'200px'}
          h={'25px'}
        >
          {fileName || 'Izaberite fajl'}
        </Button>
        <ErrorMessage name='file'>
          {(msg) => (
            <Text color='red' fontSize='sm'>
              {msg}
            </Text>
          )}
        </ErrorMessage>
        <FormHelperText textAlign={'center'} w={'270px'}>
          Ovdje možete izabrati fajl sa studentima. <br /> Tabela mora sadržavati min. ove 4 kolone{' '}
          <br /> (index, ime, prezime, email) <br /> Podržani formati: .csv, .xlsx, .xls
        </FormHelperText>
      </VStack>
    </FormControl>
  );
};

export default SingleFileInput;
