import { Center, Flex, Heading, VStack, useColorModeValue } from '@chakra-ui/react';
import ShowcaseItem from './comp/ShowCaseItem';
import ScrollToTopButton from '../../Professor/comp/ScrollToTop';

export function ShowCasePage() {
  return (
    <Flex
      minH={{ base: '80vh', md: '87vh' }}
      justify={'center'}
      align={'center'}
      direction={'column'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Heading fontSize={'3xl'} mt={'2rem'}>
        O aplikaciji
      </Heading>

      <VStack w={{ base: '95%', md: '800px' }} p={5} justify={'center'}>
        <Heading fontSize={'2xl'} mt={'1rem'}>
          Prisustva
        </Heading>
        <ShowcaseItem
          key={'sh1'}
          imgSrc={'/showCase/1.jpg'}
          description={`ISEUS omogućava automatizovanu evidenciju prisustva na predavanjima i vježbama. Jednostavno dopustite studentima da skeniraju QR kod na svakom predavanju ili vježbi. Skeniranjem QR koda ili unošenjem šifre, student se digitalno potpisuje i prisustvuje.

Profesori i asistenti imaju pregled prisustva studenata na predavanjima i vježbama te mogu označiti studente kao prisutne ili odsutne. Ovo omogućava praćenje i dodatnu provjeru prisutnosti uz smanjenje potrošnje papira. Čuvajmo okoliš!
`}
          title='Automatizovana evidencija prisustva'
        />

        <ShowcaseItem
          key={'sh2'}
          imgSrc={'/showCase/2.jpg'}
          description={`ISEUS omogućuje asistentima kreiranje grupa i dodavanje studenata na predmet u te grupe. Svaki student može biti član samo jedne grupe na predmetu tokom jedne akademske godine, što osigurava tačnost i ažurnost informacija. Ako student obnovi godinu, može se dodati u drugu grupu u sljedećoj akademskoj godini.

Asistenti mogu delegirati administraciju grupa drugim asistentima, što omogućava efikasnije vođenje grupa kada je to potrebno.

Grupe se kasnije koriste za personalizovane objave, kreiranje vježbi, dodatnih aktivnosti i određivanje datuma i mjesta ispita i parcijala, sve odvojeno po grupama.
`}
          title='Administracija studenata kroz grupe'
        />

        <ShowcaseItem
          key={'sh3'}
          imgSrc={'/showCase/3.jpg'}
          description={`Preko ISEUS-a imate detaljan pregled svih prisustava na svakom predavanju i vježbama, organizovano po grupama. Ovaj pregled omogućava profesorima i asistentima da lako prate prisustvo svih studenata tokom semestra.

Profesori i asistenti mogu pregledati sve studente i njihove prisustva na jednom mestu, što omogućava brzu identifikaciju studenata sa slabim prisustvom. Ako žele, profesori i asistenti mogu prozivati studente i vršiti dodatnu provjeru prisustva, osiguravajući tačnost podataka.

ISEUS takođe omogućava preuzimanje izvještaja u PDF i Excel formatima. Ovi izvještaji sadrže sve informacije o prisustvu studenata na predavanjima i vježbama, uključujući datume i vremena prisustva. Ova funkcionalnost olakšava administraciju i pruža sveobuhvatan pregled koji se može koristiti za internu evidenciju ili dijeljenje sa administrativnim osobljem fakulteta.

Pored ovoga, profesori mogu koristiti sistem za brzo pretraživanje studenata, unosom imena, prezimena ili broja indeksa, čime se dodatno ubrzava proces provjere i evidencije prisustva. Ovaj sistem osigurava transparentnost i motiviše studente da redovno prisustvuju predavanjima i vježbama.

ISEUS-ov sistem za evidenciju prisustva ne samo da štedi vrijeme profesorima i asistentima, već i doprinosi očuvanju okoliša smanjujući potrebu za papirnim evidencijama.`}
          title='Pregled prisustva na vježbama i predavanjima'
        />

        <ShowcaseItem
          key={'sh4'}
          imgSrc={'/showCase/4.jpg'}
          description={`ISEUS nudi mogućnost generiranja izvještaja o prisustvu studenata na predavanjima i vježbama u PDF i Excel formatima, što donosi brojne benefite za profesore i asistente.

Kroz ove izvještaje, profesori i asistenti imaju jasan i organizovan pregled svih prisustava, što olakšava praćenje i administraciju studenata. Ovi izvještaji ne samo da štede vrijeme, već i moderniziraju način na koji se podaci o prisustvu prikupljaju i analiziraju.

Modernizacija i Efikasnost

Generiranjem izvještaja u PDF i Excel formatima, ISEUS omogućava lako dijeljenje i pregled podataka. PDF izvještaji pružaju stabilan i nepromjenljiv format za službenu evidenciju, dok Excel izvještaji nude fleksibilnost za dodatne analize i prilagodbe. Profesori mogu jednostavno uređivati Excel fajlove, dodavati bilješke, filtrirati podatke i koristiti ih za daljnje statističke analize.

Očuvanje Okoliša

Prelaskom na digitalne izvještaje, ISEUS značajno smanjuje potrebu za papirnim evidencijama, što direktno doprinosi očuvanju okoliša. Manje papira znači manje sječe šuma, manje otpada i manji ugljični otisak univerziteta. Na ovaj način, ISEUS ne samo da unapređuje administrativne procese, već i podržava održivi razvoj i odgovorno upravljanje resursima.

Univerzalnost i Prilagodljivost

Excel izvještaji mogu se dalje koristiti za razne svrhe. Bilo da je riječ o pripremi za akreditaciju, analizi prisustva ili planiranju dodatnih aktivnosti, profesori i asistenti mogu lako prilagoditi podatke svojim potrebama. Ova univerzalnost i prilagodljivost čine ISEUS izuzetno korisnim alatom za sve obrazovne institucije.

Korištenjem ISEUS-a, univerziteti ne samo da moderniziraju svoje administrativne procese, već i doprinosu očuvanju okoliša, pružajući studentima i osoblju efikasan i održiv način upravljanja akademskim obavezama.`}
          title='Izvještaji'
        />

        <ShowcaseItem
          key={'sh5'}
          imgSrc={'/showCase/5.jpg'}
          description={`ISEUS pruža sveobuhvatan pregled prisustva svih studenata na predavanjima i vježbama. Na jednom ekranu možete vidjeti sve informacije o prisustvu studenata, organizovane po grupama i datumima.

Profesori i asistenti mogu lako pratiti prisustvo studenata na svakom predavanju i vježbama. Sistem omogućava ručnu provjeru i unos prisustva, kao i prozivku kako bi se osigurala tačnost podataka.

Jedna od ključnih prednosti ISEUS-a je mogućnost preuzimanja izvještaja u PDF i Excel formatima. PDF izvještaji su idealni za arhiviranje, dok Excel izvještaji omogućavaju daljnju obradu podataka i prilagođavanje specifičnim potrebama profesora i asistenata.

Profesori mogu pregledati zbir svih dolazaka i lako identificirati studente koji ispunjavaju uslove za potpis. Studenti koji su redovno prisustvovali svim predavanjima i vježbama biće istaknuti, što omogućava dodatno nagrađivanje i motivisanje za redovno prisustvo.

Koristeći ISEUS, značajno se smanjuje upotreba papira, što doprinosi očuvanju okoliša i modernizaciji administrativnih procesa na univerzitetima. Sve informacije o prisustvu studenata su transparentne, lako dostupne i uvijek ažurirane, čime se olakšava rad profesorima i asistentima.`}
          title='Pregled svih prisustava na jednom mjestu'
        />

        <ShowcaseItem
          key={'sh6'}
          imgSrc={'/showCase/6.jpg'}
          description={`ISEUS omogućava detaljan prikaz ukupnog broja dolazaka na predavanja i vježbe, zajedno sa prikazom grupe kojoj studenti pripadaju. Na ovoj stranici profesori mogu pregledati sve prisutne podatke i dodijeliti potpise studentima koji ispunjavaju sve uslove za izlazak na ispit.

Profesori imaju mogućnost dodjeljivanja potpisa i studentima koji nisu ispunili uslov, uz dodavanje komentara koji objašnjava razloge za odobravanje potpisa. Ovo može uključivati opravdanja ili slične napomene, što dodatno osigurava transparentnost i pravdu u procesu evaluacije.

Ova funkcionalnost omogućava studentima prijavu na ispite i osigurava da sistem za automatsku detekciju ispita može provjeriti da li studenti imaju potpis, jedan od ključnih uslova za izlazak na ispit.

Korištenjem ove opcije, profesori mogu efikasno upravljati evidencijom prisustva i osigurati da studenti imaju sve potrebne potpise za nastavak svojih akademskih obaveza.
`}
          title='Podjela potpisa studentima'
        />

        <ShowcaseItem
          key={'sh10'}
          imgSrc={'/showCase/10.jpg'}
          description={`Na ISEUS-u, profesori i asistenti imaju pristup detaljnim izvještajima za svakog studenta. Klikom na bilo koje ime studenta na bilo kojoj tabeli unutar aplikacije, sistem će vas automatski navigirati na stranicu sa kompletnim izvještajem za izabranog studenta.

Na ovoj stranici možete vidjeti sve relevantne podatke o studentu, uključujući:

 - Prisustva na predavanjima i vježbama: Detaljan pregled svih prisustava i osvojenih bodova za prisustvo.
 - Dodatne aktivnosti: Sve seminarske radove, projekte, zadaće i testove koje je student obavio, zajedno sa osvojenim bodovima. 
 - Ispiti i parcijale: Pregled svih ispita i parcijala koje je student položio, uključujući ocjene i statuse ispita.

 Osim toga, profesori i asistenti imaju opciju da dodaju dodatne bodove studentima. Ovi bodovi će se automatski uračunati u ukupnu preporučenu ocjenu studenta. Ova funkcionalnost omogućava dodjelu nagradnih bodova tokom semestra, motivišući studente da se još više trude i postižu bolje rezultate.

Prednosti kompletnog studentskog izvještaja:

 - Centralizovani podaci: Svi relevantni podaci o studentu na jednom mjestu, omogućavajući brzi i jednostavan pregled.
 - Precizno praćenje: Detaljni pregledi prisustava, dodatnih aktivnosti i ispita omogućavaju precizno praćenje napretka svakog studenta.
 - Fleksibilnost i motivacija: Dodavanje dodatnih bodova pruža fleksibilnost profesorima i asistentima da nagrade trud i zalaganje studenata tokom semestra.
 - Povećana transparentnost: Omogućava studentima da imaju jasan uvid u svoj napredak i postignuća, što povećava transparentnost i povjerenje u sistem.`}
          title='Kompletan studentski izvještaj'
        />
        <Heading fontSize={'2xl'} mt={'1rem'}>
          Ispiti
        </Heading>
        <ShowcaseItem
          key={'sh7'}
          imgSrc={'/showCase/7.jpg'}
          description={`ISEUS pruža univerzalni sistem za organizaciju ispita, omogućujući profesorima potpunu fleksibilnost i kontrolu nad procesom. Na ovoj stranici, profesori mogu pregledati jedan kreiran rok, zajedno s nekoliko ispita i parcijala unutar tog roka.

Jedna od ključnih prednosti ISEUS-a je mogućnost određivanja težinskog faktora za svaki ispit. Profesori mogu sami postaviti ukupni broj bodova koji se može osvojiti na ispitu i dalje raspodijeliti taj težinski faktor na različite parcijale unutar istog ispita. Ovo omogućava precizno praćenje napretka studenata i osigurava da svaki ispit i parcijala budu fer i transparentno vrednovani.

Sistem je dizajniran tako da profesori imaju potpunu slobodu u kreiranju i organizaciji ispita. Mogu odrediti vrste ispita, broj parcijala, rokove za prijavu, poništavanje i važenje ispita. Ova fleksibilnost omogućava profesorima da prilagode ispite specifičnim potrebama predmeta i studentima, osiguravajući pravednu i efikasnu evaluaciju.

ISEUS također omogućava praćenje prijava studenata na ispite, automatsku provjeru uslova za izlazak na ispit, te jednostavno skeniranje QR kodova za evidentiranje prisustva na ispitu. Sve ove funkcionalnosti doprinose modernizaciji i unapređenju procesa evaluacije na univerzitetima.
`}
          title='Kreiranje rokova, ispita i parcijala'
        />

        <ShowcaseItem
          key={'sh8'}
          imgSrc={'/showCase/8.jpg'}
          description={`Kada otvorite ispit ili parcijalni ispit u ISEUS-u, dobijate sveobuhvatan pregled svih relevantnih informacija. Ova stranica omogućava profesorima i asistentima da pregledaju sve prijavljene studente, što im omogućava efikasno planiranje i organizaciju ispita.

Prijave i organizacija
Studenti se mogu prijaviti na ispite putem aplikacije, a osoblje može pregledati te prijave i na osnovu njih organizirati održavanje ispita. Na primjer, mogu odlučiti hoće li ispit biti podijeljen na grupe, koliko materijala treba pripremiti za ispit, te planirati sve logističke detalje unaprijed. Ova funkcionalnost značajno smanjuje administrativno opterećenje i omogućava osoblju da se fokusira na kvalitetnu pripremu ispita.

Praćenje prisustva
Sistem prati prisustvo čak i na ispitima, osiguravajući da student koji je već izašao na ispit ne može ponovo pristupiti istom ispitu kod drugog nastavnika. Ovo sprječava dvostruko polaganje i osigurava fer i transparentan proces evaluacije.

Unos ocjena i administracija
Osoblje može unositi ocjene direktno u sistem, što omogućava brzu i jednostavnu evaluaciju. Također, u slučaju potrebe, ispit se može poništiti ili prijava izbrisati. Ove opcije pružaju fleksibilnost i osiguravaju da svi podaci budu ažurni i tačni.

Benefiti za osoblje

 - Efikasna organizacija ispita: Na temelju prijava studenata, osoblje može optimalno planirati ispitne termine i resurse.
 - Automatizacija prisustva: Osigurava da studenti ne mogu dvaput pristupiti istom ispitu, smanjujući mogućnosti za nepravilnosti.
 - Jednostavan unos ocjena: Pojednostavljuje proces ocjenjivanja i osigurava da su ocjene odmah dostupne studentima.
 - Fleksibilnost i kontrola: Mogućnost poništavanja ispita ili brisanja prijava pruža dodatnu sigurnost i fleksibilnost u upravljanju ispitima.

 ISEUS modernizuje proces evaluacije, osiguravajući transparentnost, efikasnost i uštedu vremena za profesore i asistente, dok studentima pruža jasnu i pouzdanu evidenciju njihovih akademskih postignuća.`}
          title='Detaljan prikaz ispita i parcijala'
        />

        <ShowcaseItem
          key={'sh9'}
          imgSrc={'/showCase/9.jpg'}
          description={`ISEUS nudi jedinstveno iskustvo tokom organizacije ispita. Zaboravite na klasično prozivanje i ručno provjeravanje uslova – poput validnosti potpisa ili ispitnog statusa studenta.

Jednostavnim skeniranjem QR koda sa uređaja studenta, možete odmah provjeriti sve relevantne informacije. Sistem će vam odmah vratiti obavijest da li student ispunjava sve uslove za izlazak na ispit, čime se značajno ubrzava i olakšava proces administracije ispita.

Ova funkcionalnost osigurava efikasnost, preciznost i transparentnost, omogućavajući vam da se fokusirate na vođenje ispita bez dodatnih logističkih komplikacija.

Pored toga, studenti se ne mogu prijaviti na ispite na kojima nemaju ispunjene uslove. Sistem automatski detektira i sprječava prijavu studenata koji nemaju potpis, nisu položili uslovne ispite ili nemaju dovoljno prisustva.

Prednosti skeniranja studenata na ispitu:
 
 - Brza i pouzdana provjera: Eliminira potrebu za ručnim provjeravanjem prisustva i uslova, štedeći vrijeme i smanjujući mogućnost grešaka.
 - Automatizacija administracije: Omogućava profesorima i asistentima da se fokusiraju na vođenje ispita, bez dodatnih administrativnih opterećenja.
 - Transparentnost i sigurnost: Sistem osigurava da svi studenti ispunjavaju uslove za izlazak na ispit, čime se povećava fer i transparentan proces evaluacije.
 - Povećana efikasnost: Smanjuje vrijeme potrebno za provjeru i evidentiranje prisustva, omogućavajući efikasniji tok ispita.
`}
          title='Skeniranje studenata na ispitu'
        />
        <ScrollToTopButton />
      </VStack>
    </Flex>
  );
}
