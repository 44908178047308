import {
  Flex,
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import useProf from '../../../hooks/useProf.hooks';
import { useGetAcademicYears } from '../../../services/queries';

import { AcademicYearSelectionField } from '../../../components/general/academicYearSelectionField';
import { SubjectActivitiesScorePerGroupTables } from './comp/SubjectActivitiesScorePerGroupTable';
import { SubjectActivitiesAllStudentsTable } from './comp/SubjectActivitiesScoreTable';

export const SubjectActivitiesScoresPage = () => {
  const navigate = useNavigate();
  const profContext = useProf();
  const years = useGetAcademicYears(profContext.selectedSubjectID);

  const handleBackClick = () => {
    navigate('/profesori/studenti');
  };

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      justify={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading pb={4} fontSize={'xl'}>
          Bodovi na dodatnim aktivnostima
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <Tabs w={'100%'} isFitted>
        <TabList>
          <Tab fontWeight={'700'}>Za sve studente</Tab>
          <Tab fontWeight={'700'}>Grupne aktivnosti</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={3} mx={'auto'} w={'100%'} justify={'center'} pt={4}>
              <VStack
                spacing={1}
                w={{ base: '95%', md: '95%', lg: '50%' }}
                align={'center'}
                justify={'center'}
                mb={4}
              >
                <AcademicYearSelectionField
                  years={years}
                  handleOnChangeAcademicYear={handleOnChangeAcademicYear}
                  profContext={profContext}
                />
              </VStack>
              <SubjectActivitiesAllStudentsTable />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={3} mx={'auto'} w={'95%'} justify={'center'} pt={4}>
              <VStack
                spacing={1}
                w={{ base: '95%', md: '95%', lg: '50%' }}
                align={'center'}
                justify={'center'}
              >
                <AcademicYearSelectionField
                  years={years}
                  handleOnChangeAcademicYear={handleOnChangeAcademicYear}
                  profContext={profContext}
                />
              </VStack>
              <SubjectActivitiesScorePerGroupTables />
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
