import {
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { LecturesTable } from './comp/LecturesTable';
import { LecturesInfo } from './comp/LecturesInfo';
import { CreateLectureModal } from './comp/CreateLectureModal';
import { useGetLecturesInfo } from '../../../services/queries';
import useProf from '../../../hooks/useProf.hooks';
import useAuth from '../../../hooks/useAuth.hooks';
import { BlueButton } from '../../../components/general/BlueButton';
import { EditLectureModal } from './comp/EditLectureModal';

function Lectures() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const profContext = useProf();
  const authContext = useAuth();
  const lecturesInfoQuery = useGetLecturesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  return (
    <>
      <Flex
        flexDirection='column'
        align={'center'}
        p='5'
        bg={useColorModeValue('gray.100', 'gray.900')}
        w='100%'
      >
        <Heading fontSize={'xl'}>Predavanja</Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          mt={'2rem'}
          justifyContent={'space-around'}
          align={'center'}
          w={{ base: '95%', md: '85%', lg: '700px' }}
        >
          <CreateLectureModal isOpen={isOpen} onClose={onClose} />
          <LecturesInfo />
          <VStack pt={{ base: 5, md: 0 }}>
            <BlueButton
              buttonText='Dodaj predavanje'
              onClick={onOpen}
              isDisabled={
                lecturesInfoQuery.data
                  ? authContext.user?.role === 'PROFESSOR'
                    ? false
                    : true
                  : true
              }
            />
          </VStack>
        </Stack>
        <LecturesTable />
      </Flex>
    </>
  );
}

export default Lectures;
