import {
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../../components/general/BlueSubmitButton';
import { DateTimeInput } from '../../../../../components/general/DateTimeInput';
import { RedButton } from '../../../../../components/general/RedButton';
import {
  useEditPartialExamDatesInfoMutation,
  useEditPartialExamMainInfoMutation,
  useEditPartialExamScoreInfoMutation,
} from '../../../../../services/mutations';
import { useGetSinglePartialExam } from '../../../../../services/queries';
import {
  editPartialExamDatesInfoSchema,
  editPartialExamMainInfoSchema,
  editPartialExamScoreInfoSchema,
} from '../../../../../validation/exam-schema';
import { HorizontalTextField } from '../../../NewSubject/comp/HorzintalTextField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  partialExamID: number;
};

export const EditPartialExamModal = ({ isOpen, onClose, partialExamID }: Props) => {
  const partialExamInfo = useGetSinglePartialExam(partialExamID);
  const [isMainInfoLoading, setIsMainInfoLoading] = useState(false);
  const [isScoreInfoLoading, setIsScoreInfoLoading] = useState(false);
  const [isDatesInfoLoading, setIsDatesInfoLoading] = useState(false);

  const [sameGroupsDateTime, setSameGroupsDateTime] = useState(
    partialExamInfo.data?.sameGroupsDateTime ?? false
  );
  const [signatureNeeded, setSignatureNeeded] = useState(
    partialExamInfo.data?.signatureNeeded ?? false
  );

  const editPartialExamMainInfo = useEditPartialExamMainInfoMutation(partialExamID);
  const editPartialExamScoreInfo = useEditPartialExamScoreInfoMutation(partialExamID);
  const editPartialExamDatesInfo = useEditPartialExamDatesInfoMutation(partialExamID);

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>
            Uredi parcijalni ispit - {partialExamInfo.data?.name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            {partialExamInfo.isLoading ? (
              <Spinner />
            ) : partialExamInfo.data ? (
              <Tabs isFitted>
                <TabList mb='1em'>
                  <Tab>Podatke</Tab>
                  <Tab>Bodovanje</Tab>
                  <Tab>Datumi</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Formik
                      initialValues={{
                        partialExamID,
                        name: partialExamInfo.data.name,
                        type: partialExamInfo.data.type,
                        sameGroupsDateTime: partialExamInfo.data.sameGroupsDateTime,
                        dateTime: partialExamInfo.data.rawDateTime
                          ? new Date(partialExamInfo.data.rawDateTime)
                          : null,
                        place: partialExamInfo.data.place,
                      }}
                      validationSchema={editPartialExamMainInfoSchema}
                      onSubmit={async (values, actions) => {
                        setIsMainInfoLoading(true);
                        try {
                          await editPartialExamMainInfo({
                            ...values,
                            sameGroupsDateTime,
                            signatureNeeded,
                          });
                          onClose();
                        } catch (error) {
                        } finally {
                          setTimeout(() => {
                            setIsMainInfoLoading(false);
                          }, 1000);
                        }
                      }}
                    >
                      {({ setFieldValue, values }) => (
                        <Form>
                          <Stack
                            spacing={4}
                            justifyContent={{ base: 'start', md: 'center' }}
                            alignItems={'center'}
                          >
                            <HorizontalTextField
                              name='name'
                              type='text'
                              label='Naziv ispita'
                              width='250px'
                            />
                            <HorizontalTextField
                              name='type'
                              type='text'
                              label='Tip ispita'
                              width='250px'
                            />

                            <HStack>
                              <Text>Da li je potreban potpis?</Text>
                              <Switch
                                isChecked={signatureNeeded}
                                onChange={() => {
                                  setSignatureNeeded(!signatureNeeded);
                                }}
                              />
                            </HStack>

                            <Divider />
                            <HStack>
                              <Text>Da li će ispit biti u isto vrijeme za sve studente?</Text>
                              <Switch
                                isChecked={sameGroupsDateTime}
                                onChange={() => {
                                  setSameGroupsDateTime(!sameGroupsDateTime);
                                }}
                              />
                            </HStack>

                            {sameGroupsDateTime ? (
                              <VStack>
                                <DateTimeInput
                                  name='dateTime'
                                  label='Datum i vrijeme ispita'
                                  setFieldValue={setFieldValue}
                                  placeholder='izaberite datum i vrijeme'
                                />
                                <HorizontalTextField
                                  name='place'
                                  type='text'
                                  label='Mjesto ispita'
                                  width='250px'
                                />
                              </VStack>
                            ) : (
                              <Text fontSize='sm' color='gray.500'>
                                Nakon kreiranja ispita, moći će te odrediti datum i vrijeme za svaku
                                grupu posebno.
                              </Text>
                            )}
                            <Divider />

                            <HStack py={5} justify={'center'}>
                              <BluesubmitButton
                                buttonText='Sačuvaj promjene'
                                loadingText='Čuvanje..'
                                isLoading={isMainInfoLoading}
                              />
                              <RedButton buttonText='Odustani' onClick={onClose} />
                            </HStack>
                          </Stack>
                        </Form>
                      )}
                    </Formik>
                  </TabPanel>
                  <TabPanel>
                    <Formik
                      initialValues={{
                        partialExamID,
                        maxScore: partialExamInfo.data.maxScore,
                        minPassingScore: partialExamInfo.data.minPassingScore,
                        difficultyFactor: partialExamInfo.data.difficultyFactor,
                      }}
                      validationSchema={editPartialExamScoreInfoSchema}
                      onSubmit={async (values, actions) => {
                        setIsScoreInfoLoading(true);
                        try {
                          await editPartialExamScoreInfo({ ...values });
                          onClose();
                        } catch (error) {
                        } finally {
                          setTimeout(() => {
                            setIsScoreInfoLoading(false);
                          }, 1000);
                        }
                      }}
                    >
                      {() => (
                        <Form>
                          <Stack
                            spacing={4}
                            justifyContent={{ base: 'start', md: 'center' }}
                            alignItems={'center'}
                          >
                            <HorizontalTextField
                              name='maxScore'
                              type='number'
                              label='Maximalni broj bodova'
                              width='250px'
                            />
                            <HorizontalTextField
                              name='minPassingScore'
                              type='number'
                              label='Broj bodova za prolaz'
                              width='250px'
                            />
                            <HorizontalTextField
                              name='difficultyFactor'
                              type='number'
                              label='Težinski faktor'
                              width='250px'
                            />

                            <HStack py={5} justify={'center'}>
                              <BluesubmitButton
                                buttonText='Sačuvaj promjene'
                                loadingText='Čuvanje..'
                                isLoading={isScoreInfoLoading}
                              />
                              <RedButton buttonText='Odustani' onClick={onClose} />
                            </HStack>
                          </Stack>
                        </Form>
                      )}
                    </Formik>
                  </TabPanel>
                  <TabPanel>
                    <Formik
                      initialValues={{
                        partialExamID,
                        registrationDeadline: new Date(
                          partialExamInfo.data.rawRegistrationDeadline
                        ),
                        nullificationDeadline: new Date(
                          partialExamInfo.data.rawNullificationDeadline
                        ),
                        validUntil: partialExamInfo.data.rawValidUntil
                          ? new Date(partialExamInfo.data.rawValidUntil)
                          : null,
                      }}
                      validationSchema={editPartialExamDatesInfoSchema}
                      onSubmit={async (values, actions) => {
                        setIsDatesInfoLoading(true);
                        try {
                          await editPartialExamDatesInfo({ ...values });
                          onClose();
                        } catch (error) {
                        } finally {
                          setTimeout(() => {
                            setIsDatesInfoLoading(false);
                          }, 1000);
                        }
                      }}
                    >
                      {({ setFieldValue, values }) => (
                        <Form>
                          <Stack
                            spacing={4}
                            justifyContent={{ base: 'start', md: 'center' }}
                            alignItems={'center'}
                          >
                            <DateTimeInput
                              name='registrationDeadline'
                              label='Rok za prijavu ispita'
                              setFieldValue={setFieldValue}
                              placeholder='izaberite datum i vrijeme'
                              isRequired={true}
                            />
                            <DateTimeInput
                              name='nullificationDeadline'
                              label='Rok za poništavanje ispita'
                              setFieldValue={setFieldValue}
                              placeholder='izaberite datum i vrijeme'
                              isRequired={true}
                            />
                            <DateTimeInput
                              name='validUntil'
                              label='Položen ispit važi do'
                              setFieldValue={setFieldValue}
                              placeholder='Neograničeno'
                            />

                            <HStack py={5} justify={'center'}>
                              <BluesubmitButton
                                buttonText='Sačuvaj promjene'
                                loadingText='Čuvanje..'
                                isLoading={isDatesInfoLoading}
                                /*  isDisabled={
                                  values.nullificationDeadline ===
                                  new Date(examInfo.data.rawNullificationDeadline)
                                } */
                              />
                              <RedButton buttonText='Odustani' onClick={onClose} />
                            </HStack>
                          </Stack>
                        </Form>
                      )}
                    </Formik>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <Text>Nedostupni podaci</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
