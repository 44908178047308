import { useQuery } from '@tanstack/react-query';
import {
  AllOrganizations,
  AssBySubjectAndGroup,
  FacultysWithPrograms,
  GetAllStudentsAdmin,
  ProfessorAssistent,
  UniversityWithAdmins,
  adminProfessorsAssistants,
  universityID,
} from '../models/professorAssistent';
import {
  AcademicYear,
  Exercise,
  ExerciseAttendance,
  ExerciseAttendanceInfo,
  ExerciseGroup,
  ExerciseInfoAcademicYearID,
  ExercisesInfo,
  ExercisesPerGrupInfo,
  Faculty,
  Lecture,
  LectureAttendance,
  LectureAttendanceInfo,
  LecturesInfo,
  StudentSignatureStat,
  StudyProgram,
  Subject,
  SubjectDifficultyFactors,
  SubjectGroupID,
  SubjectInfo,
  SubjectYearID,
  University,
  subjectExerciseInfoID,
} from '../models/subject';
import {
  adminGetAllOrganizations,
  adminGetAllStudents,
  adminGetAllUniversitiesWithAdmins,
  adminGetFacultysWithPrograms,
  checkStudentExamStatus,
  checkStudentPartialExamStatus,
  getAcademicYears,
  getActiveStudentsOnSubject,
  getAssistants,
  getAssistantsByGroup,
  getAssistantsBySubjectAndGroup,
  getAssistantsOnSubject,
  getAttendancesOnExercise,
  getAttendancesOnLecture,
  getCompletedSubjects,
  getCurrentExercisesInfo,
  getCurrentLecturesInfo,
  getExamGroupsDatePlaceInfo,
  getExamInfo,
  getExamResults,
  getExamScheduleResults,
  getExamSchedules,
  getExamStudentsStats,
  getExamsPerSchedule,
  getExerciseGroups,
  getExercises,
  getExercisesAttendancesStats,
  getExercisesInfo,
  getExercisesPerGroupInfo,
  getFacultys,
  getFinishedStudentsOnSubject,
  getLatestExamScheduleInfo,
  getLectures,
  getLecturesAttendanceStats,
  getLecturesInfo,
  getPartExamGroupsDatePlaceInfo,
  getPartialExamResults,
  getProfessors,
  getProfessorsOnSubject,
  getSelectedSubjectInfo,
  getSignatureStats,
  getSingleExamInfo,
  getSingleExercise,
  getSingleExerciseInfo,
  getSingleLecture,
  getSingleLectureInfo,
  getSinglePartialExamInfo,
  getStaffWantsToJoinSubject,
  getStudentSubjectPosts,
  getStudentsCompleteReport,
  getStudentsExamPreviews,
  getStudentsExerciseAttendances,
  getStudentsForFinalGrade,
  getStudentsLectureAttendances,
  getStudentsResultInfo,
  getStudentsSubjectActivitieInfo,
  getStudentsSubjectInfo,
  getStudentsWantToJoinSubject,
  getStudentsWithGroup,
  getStudentsWithNoGroup,
  getStudyPrograms,
  getSubjectActivitiesAllStudents,
  getSubjectActivitiesInfo,
  getSubjectActivitiesPerGroup,
  getSubjectActivitiesScoreMainTable,
  getSubjectActivitiesScorePerGroupTable,
  getSubjectActivitiesWithStudentScores,
  getSubjectDifficultyFactors,
  getSubjectPostsProf,
  getSubjects,
  getSubjectsForStudents,
  getUniversities,
  getUniversityID,
} from './api';
import {
  GroupExerciseStats,
  Student,
  StudentAttendanceData,
  StudentCompletedSubjects,
  StudentLectureAttandanceStats,
  StudentSubjectInfo,
} from '../models/student';
import { subjectPost } from '../models/posts';
import {
  StudentSubjectActivitiesInfo,
  SubjectActivitiesInfo,
  SubjectActivitiesPerGroup,
  SubjectActivitiesScorePerGroupTable,
  SubjectActivitiesScoreTable,
  SubjectActivity,
  SubjectActivityWithStudentScore,
} from '../models/subjectActivities';
import { StudentResultInfo } from '../models/results';
import {
  editDifficultyFactorsDto,
  editLecturesExercisesInfoDto,
} from '../validation/subject-schema';
import {
  CheckStudentExamStatus,
  Exam,
  ExamGroupDatePlaceDto,
  ExamInfo,
  ExamResult,
  ExamSchedule,
  ExamScheduleResults,
  ExamsPreview,
  ExamStats,
  PartialExam,
  StudentExamPreview,
} from '../models/exams';
import { studentCompleteReport } from '../models/student-complete-report';
import { AxiosError } from 'axios';

//              **** ORGANIZATION ****            //

export const useUniversitiesQuery = () => {
  const universityQuery = useQuery<University[]>({
    queryKey: ['AllUniversities'],
    queryFn: getUniversities,
  });
  return universityQuery;
};

export const useFacultysQuery = (universityID: number | undefined) => {
  const query = useQuery<Faculty[]>({
    queryKey: [universityID, 'AllFacultys'],
    queryFn: getFacultys,
    enabled: universityID !== null,
  });
  return query;
};

export const useStudyProgramsQuery = (facultyID: number | null) => {
  const query = useQuery<StudyProgram[]>({
    queryKey: [facultyID, 'AllStudyPrograms'],
    queryFn: getStudyPrograms,
    enabled: facultyID !== null,
  });
  return query;
};

//              **** SUBJECT ****            //
export const useGetSubjectDifficultyFactors = (subjectID: number | null) => {
  const query = useQuery<SubjectDifficultyFactors>({
    queryKey: [subjectID, 'getSubjectDifficultyFactors'],
    queryFn: getSubjectDifficultyFactors,
    enabled: !!subjectID,
  });
  return query;
};

export const useGetCurrentLecturesInfo = (subjectID: number | null) => {
  const query = useQuery<editLecturesExercisesInfoDto>({
    queryKey: [subjectID, 'getCurrentLecturesInfo'],
    queryFn: getCurrentLecturesInfo,
    enabled: !!subjectID,
  });
  return query;
};

export const useGetCurrentExercisesInfo = (subjectID: number | null) => {
  const query = useQuery<editLecturesExercisesInfoDto>({
    queryKey: [subjectID, 'getCurrentExercisesInfo'],
    queryFn: getCurrentExercisesInfo,
    enabled: !!subjectID,
  });
  return query;
};

export const useGetStudentsSignatureStats = (subjectID: number | null) => {
  const query = useQuery<StudentSignatureStat[]>({
    queryKey: [subjectID, 'getSignatureStats'],
    queryFn: getSignatureStats,
    enabled: !!subjectID,
  });
  return query;
};

//              **** PROFESSOR ****            //

export const useGetSubjectsQuery = () => {
  const query = useQuery<Subject[]>({
    queryKey: ['getSubjects'],
    queryFn: getSubjects,
  });
  return query;
};

export const useGetSelectedSubjectInfo = (subjectID: number) => {
  const query = useQuery<SubjectInfo>({
    queryKey: [subjectID, 'getSelectedSubjectInfo'],
    queryFn: getSelectedSubjectInfo,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetProfessorsOnSubjectQuery = (subjectID: number | undefined) => {
  const query = useQuery<ProfessorAssistent[]>({
    queryKey: [subjectID, 'getProfessorsOnSubject'],
    queryFn: getProfessorsOnSubject,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetAssistantsOnSubjectQuery = (subjectID: number | undefined) => {
  const query = useQuery<ProfessorAssistent[]>({
    queryKey: [subjectID, 'getAssistantsOnSubject'],
    queryFn: getAssistantsOnSubject,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetStaffWantsToJoinQuery = (subjectID: number | undefined) => {
  const query = useQuery<ProfessorAssistent[]>({
    queryKey: [subjectID, 'getStaffWantsToJoinSubject'],
    queryFn: getStaffWantsToJoinSubject,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetAcademicYears = (subjectID: number) => {
  const query = useQuery<AcademicYear[]>({
    queryKey: [subjectID, 'getAcademicYears'],
    queryFn: getAcademicYears,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetAssistantsBySubjectAndGroup = (subject: SubjectGroupID) => {
  const query = useQuery<AssBySubjectAndGroup[]>({
    queryKey: [subject, 'getAssistantsBySubjectAndGroup'],
    queryFn: getAssistantsBySubjectAndGroup,
    enabled: !!subject.subjectID && !!subject.groupID,
  });
  return query;
};

export const useGetAssistantsByGroup = (groupID: number) => {
  const query = useQuery<AssBySubjectAndGroup[]>({
    queryKey: [groupID, 'getAssistantsByGroup'],
    queryFn: getAssistantsByGroup,
    enabled: !!groupID,
  });
  return query;
};

export const useGetStudentsForFinalGrade = (subjectID: number | undefined) => {
  const query = useQuery<Student[]>({
    queryKey: [subjectID, 'getStudentsForFinalGrade'],
    queryFn: getStudentsForFinalGrade,
    enabled: !!subjectID,
  });
  return query;
};

export const useGetStudentCompleteReport = (subjectID: number, studentID: number) => {
  const query = useQuery<studentCompleteReport, AxiosError>({
    queryKey: [{ subjectID, studentID }, 'getStudentCompleteReport'],
    queryFn: getStudentsCompleteReport,
    enabled: subjectID !== undefined && studentID !== undefined,
  });
  return query;
};
//              **** LECTURES ****            //

export const useGetLecturesInfo = (subject: SubjectYearID) => {
  const query = useQuery<LecturesInfo>({
    queryKey: [subject, 'getLecturesInfo'],
    queryFn: getLecturesInfo,
    enabled: !!subject.subjectID && !!subject.yearID,
  });
  return query;
};

export const useGetLectures = (subject: SubjectYearID) => {
  const query = useQuery<Lecture[]>({
    queryKey: [subject, 'getLectures'],
    queryFn: getLectures,
    enabled: !!subject.subjectID && !!subject.yearID,
  });
  return query;
};

export const useGetSingleLecture = (lectureID: number) => {
  const query = useQuery<Lecture>({
    queryKey: [lectureID, 'getSingleLecture'],
    queryFn: getSingleLecture,
    enabled: lectureID !== undefined,
  });
  return query;
};

export const useGetLectureAttendances = (lectureID: number) => {
  const query = useQuery<LectureAttendance[]>({
    queryKey: [lectureID, 'getLectureAttendances'],
    queryFn: getAttendancesOnLecture,
    enabled: lectureID !== undefined,
  });
  return query;
};

export const useGetSingleLectureInfo = (lectureID: number) => {
  const query = useQuery<LectureAttendanceInfo>({
    queryKey: [lectureID, 'getSingleLecturesInfo'],
    queryFn: getSingleLectureInfo,
    enabled: lectureID !== undefined,
  });
  return query;
};

//              **** EXERCISES ****            //

export const useGetExercisesInfo = (subject: SubjectYearID) => {
  const query = useQuery<ExercisesInfo>({
    queryKey: [subject, 'getExerciseInfo'],
    queryFn: getExercisesInfo,
    enabled: !!subject.subjectID && !!subject.yearID,
  });
  return query;
};

export const useGetExerciseGroups = (group: ExerciseInfoAcademicYearID) => {
  const query = useQuery<ExerciseGroup[]>({
    queryKey: [group, 'getExerciseGroups'],
    queryFn: getExerciseGroups,
    enabled: !!group.academicYearID && !!group.exerciseInfoID,
  });
  return query;
};

export const useGetExercises = (groupID: number) => {
  const query = useQuery<Exercise[]>({
    queryKey: [groupID, 'getExercises'],
    queryFn: getExercises,
    enabled: !!groupID,
  });
  return query;
};

export const useGetExercisesPerGroupInfo = (groupID: number) => {
  const query = useQuery<ExercisesPerGrupInfo>({
    queryKey: [groupID, 'getExercisesPerGroupInfo'],
    queryFn: getExercisesPerGroupInfo,
    enabled: !!groupID,
  });
  return query;
};

export const useGetExerciseAttendances = (exerciseID: number) => {
  const query = useQuery<ExerciseAttendance[]>({
    queryKey: [exerciseID, 'getExerciseAttendances'],
    queryFn: getAttendancesOnExercise,
    enabled: exerciseID !== undefined,
  });
  return query;
};

export const useGetSingleExerciseInfo = (exerciseID: number) => {
  const query = useQuery<ExerciseAttendanceInfo>({
    queryKey: [exerciseID, 'getSingleExerciseInfo'],
    queryFn: getSingleExerciseInfo,
    enabled: exerciseID !== undefined,
  });
  return query;
};

export const useGetStudentsWithNoGroup = (params: subjectExerciseInfoID) => {
  const query = useQuery<Student[]>({
    queryKey: [params, 'getStudentsWithNoGroup'],
    queryFn: getStudentsWithNoGroup,
    enabled: !!params.academicYearID && !!params.subjectID,
  });
  return query;
};

export const useGetStudentsWithGroup = (groupID: Number) => {
  const query = useQuery<Student[]>({
    queryKey: [groupID, 'getStudentsWithGroup'],
    queryFn: getStudentsWithGroup,
    enabled: !!groupID,
  });
  return query;
};

export const useGetSingleExercise = (exerciseID: number) => {
  const query = useQuery<Exercise>({
    queryKey: [exerciseID, 'getSingleExercise'],
    queryFn: getSingleExercise,
    enabled: exerciseID !== undefined,
  });
  return query;
};

//              **** STUDENTS ****            //

export const useGetActiveStudentsOnSubject = (subjectID: number) => {
  const query = useQuery<Student[]>({
    queryKey: [subjectID, 'getActiveStudentsOnSubject'],
    queryFn: getActiveStudentsOnSubject,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetFinishedStudentsOnSubject = (subjectID: number) => {
  const query = useQuery<Student[]>({
    queryKey: [subjectID, 'getFinishedStudentsOnSubject'],
    queryFn: getFinishedStudentsOnSubject,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetLecturesAttendanceStats = (params: SubjectYearID) => {
  const query = useQuery<StudentLectureAttandanceStats[]>({
    queryKey: [params, 'getLecturesAttendanceStats'],
    queryFn: getLecturesAttendanceStats,
    enabled: !!params.yearID && !!params.subjectID,
  });
  return query;
};

export const useGetExercisesAttendanceStats = (params: SubjectYearID) => {
  const query = useQuery<GroupExerciseStats[]>({
    queryKey: [params, 'getExerciseAttendanceStats'],
    queryFn: getExercisesAttendancesStats,
    enabled: !!params.yearID && !!params.subjectID,
  });
  return query;
};

export const useGetSubjectForStudents = () => {
  const query = useQuery<Subject[]>({
    queryKey: ['getSubjectsForStudents'],
    queryFn: getSubjectsForStudents,
  });
  return query;
};

export const useGetStudentsLectureInfo = (subjectID: number) => {
  const query = useQuery<StudentAttendanceData>({
    queryKey: [subjectID, 'getStudentsLectureAttendanceInfo'],
    queryFn: getStudentsLectureAttendances,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetStudentsExerciseInfo = (subjectID: number) => {
  const query = useQuery<StudentAttendanceData>({
    queryKey: [subjectID, 'getStudentsExerciseAttendanceInfo'],
    queryFn: getStudentsExerciseAttendances,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetStudentsSubjectActivitiesInfo = (subjectID: number) => {
  const query = useQuery<StudentSubjectActivitiesInfo>({
    queryKey: [subjectID, 'getStudentsSubjectActivitiesInfo'],
    queryFn: getStudentsSubjectActivitieInfo,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetStudentResultsInfo = (subjectID: number) => {
  const query = useQuery<StudentResultInfo>({
    queryKey: [subjectID, 'getStudentResultsInfo'],
    queryFn: getStudentsResultInfo,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetSubjectInfo = (subjectID: number) => {
  const query = useQuery<StudentSubjectInfo>({
    queryKey: [subjectID, 'getSubjectInfo'],
    queryFn: getStudentsSubjectInfo,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetStudentsWantToJoinsubject = (subjectID: number) => {
  const query = useQuery<Student[]>({
    queryKey: [subjectID, 'getStudentsWantToJoinsubject'],
    queryFn: getStudentsWantToJoinSubject,
    enabled: subjectID !== undefined,
  });
  return query;
};

export const useGetStudentsExamPreview = (subjectID: number) => {
  const query = useQuery<StudentExamPreview[]>({
    queryKey: [subjectID, 'getStudentsExamPreview'],
    queryFn: getStudentsExamPreviews,
    enabled: subjectID !== undefined,
  });
  return query;
};

//              **** ADMINS ****            //

export const useGetProfessors = () => {
  const query = useQuery<adminProfessorsAssistants[]>({
    queryKey: ['getProfessors'],
    queryFn: getProfessors,
  });
  return query;
};

export const useGetAssistants = () => {
  const query = useQuery<adminProfessorsAssistants[]>({
    queryKey: ['getAssistants'],
    queryFn: getAssistants,
  });
  return query;
};

export const useGetUniversityID = () => {
  const query = useQuery<universityID>({
    queryKey: ['getUniversityID'],
    queryFn: getUniversityID,
  });
  return query;
};

export const useGetAllStudentsAdmin = () => {
  const query = useQuery<GetAllStudentsAdmin[]>({
    queryKey: ['getAllStudentsAdmin'],
    queryFn: adminGetAllStudents,
  });
  return query;
};

export const useGetFacultysWithPrograms = () => {
  const query = useQuery<FacultysWithPrograms[]>({
    queryKey: ['getFacultysWithPrograms'],
    queryFn: adminGetFacultysWithPrograms,
  });
  return query;
};

export const useGetAllOrganizations = () => {
  const query = useQuery<AllOrganizations[]>({
    queryKey: ['getAllOrganizations'],
    queryFn: adminGetAllOrganizations,
  });
  return query;
};

export const useGetUniversitiesWithAdmins = () => {
  const query = useQuery<UniversityWithAdmins[]>({
    queryKey: ['getUniversitiesWithAdmins'],
    queryFn: adminGetAllUniversitiesWithAdmins,
  });
  return query;
};

//              **** POSTS ****            //

export const useGetSubjectPostsProf = (subjectID: number, yearID: number) => {
  const query = useQuery<subjectPost[]>({
    queryKey: [{ subjectID, yearID }, 'getSubjectPostsProf'],
    queryFn: getSubjectPostsProf,
    enabled: subjectID !== undefined && yearID !== undefined,
  });
  return query;
};

export const useGetStudentSubjectPosts = (subjectID: number) => {
  const query = useQuery<subjectPost[]>({
    queryKey: [subjectID, 'getStudentSubjectPosts'],
    queryFn: getStudentSubjectPosts,
    enabled: subjectID !== undefined,
  });
  return query;
};

//              **** SUBJECT ACTIVITIES ****            //

export const useGetSubjectActivitiesInfo = (subject: SubjectYearID) => {
  const query = useQuery<SubjectActivitiesInfo>({
    queryKey: [subject, 'getSubjectActivitiesInfo'],
    queryFn: getSubjectActivitiesInfo,
    enabled: !!subject.subjectID && !!subject.yearID,
  });
  return query;
};

export const useGetSubjectActivitiesAllStudents = (subjectActivityInfoID: number | null) => {
  const query = useQuery<SubjectActivity[]>({
    queryKey: [subjectActivityInfoID, 'getSubjectActivitiesAllStudents'],
    queryFn: getSubjectActivitiesAllStudents,
    enabled: !!subjectActivityInfoID,
  });
  return query;
};

export const useGetSubjectActivitiesPerGroup = (
  subjectActivityInfoID: number | undefined,
  groupID: number | null
) => {
  const query = useQuery<SubjectActivitiesPerGroup>({
    queryKey: [{ subjectActivityInfoID, groupID }, 'getSubjectActivitiesPerGroup'],
    queryFn: getSubjectActivitiesPerGroup,
    enabled: !!subjectActivityInfoID && !!groupID,
  });
  return query;
};

export const useGetSubjectActivitiesWithStudentScores = (subjectActivityID: number | null) => {
  const query = useQuery<SubjectActivityWithStudentScore>({
    queryKey: [subjectActivityID, 'getSubjectActivitiesWithStudentScores'],
    queryFn: getSubjectActivitiesWithStudentScores,
    enabled: !!subjectActivityID,
  });
  return query;
};

export const useGetSubjectActivitiesScoresMainTable = (subject: SubjectYearID) => {
  const query = useQuery<SubjectActivitiesScoreTable>({
    queryKey: [subject, 'getSubjectActivitiesScoresMainTable'],
    queryFn: getSubjectActivitiesScoreMainTable,
    enabled: !!subject.subjectID && !!subject.yearID,
  });
  return query;
};

export const useGetSubjectActivitiesScoresPerGroupTable = (subject: SubjectYearID) => {
  const query = useQuery<SubjectActivitiesScorePerGroupTable[]>({
    queryKey: [subject, 'getSubjectActivitiesScoresPerGroupTable'],
    queryFn: getSubjectActivitiesScorePerGroupTable,
    enabled: !!subject.subjectID && !!subject.yearID,
  });
  return query;
};

//              **** EXAMS ****            //

export const useGetExamInfo = (subject: SubjectYearID) => {
  const query = useQuery<ExamInfo>({
    queryKey: [subject, 'getExamInfo'],
    queryFn: getExamInfo,
    enabled: !!subject.subjectID && !!subject.yearID,
  });
  return query;
};

export const useGetExamSchedules = (examInfoID: number | undefined) => {
  const query = useQuery<ExamSchedule[]>({
    queryKey: [examInfoID, 'getExamSchedules'],
    queryFn: getExamSchedules,
    enabled: !!examInfoID,
  });
  return query;
};

export const useGetExamsPerSchedule = (examScheduleID: number | undefined) => {
  const query = useQuery<ExamsPreview[]>({
    queryKey: [examScheduleID, 'getExamsPreview'],
    queryFn: getExamsPerSchedule,
    enabled: !!examScheduleID,
  });
  return query;
};

export const useGetSingleExam = (examID: number | undefined) => {
  const query = useQuery<Exam>({
    queryKey: [examID, 'getSingleExam'],
    queryFn: getSingleExamInfo,
    enabled: !!examID,
  });
  return query;
};

export const useGetSinglePartialExam = (partialExamID: number | undefined) => {
  const query = useQuery<PartialExam>({
    queryKey: [partialExamID, 'getSinglePartialExam'],
    queryFn: getSinglePartialExamInfo,
    enabled: !!partialExamID,
  });
  return query;
};

export const useGetExamResults = (examID: number | undefined) => {
  const query = useQuery<ExamResult[]>({
    queryKey: [examID, 'getExamResults'],
    queryFn: getExamResults,
    enabled: !!examID,
  });
  return query;
};

export const useGetPartialExamResults = (partialExamID: number | undefined) => {
  const query = useQuery<ExamResult[]>({
    queryKey: [partialExamID, 'getPartialExamResults'],
    queryFn: getPartialExamResults,
    enabled: !!partialExamID,
  });
  return query;
};

export const useGetExamGroupDatePlaceInfo = (
  examID: number | undefined,
  groupID: number | undefined
) => {
  const query = useQuery<ExamGroupDatePlaceDto>({
    queryKey: [{ examID, groupID }, 'getExamGroupDatePlaceInfo'],
    queryFn: getExamGroupsDatePlaceInfo,
    enabled: !!examID && !!groupID,
  });
  return query;
};

export const useGetPartExamGroupDatePlaceInfo = (
  partialExamID: number | undefined,
  groupID: number | undefined
) => {
  const query = useQuery<ExamGroupDatePlaceDto>({
    queryKey: [{ partialExamID, groupID }, 'getPartExamGroupDatePlaceInfo'],
    queryFn: getPartExamGroupsDatePlaceInfo,
    enabled: !!partialExamID && !!groupID,
  });
  return query;
};

export const useCheckStudentExamStatus = (
  examID: number | undefined,
  studentID: number | null,
  subjectID: number | undefined
) => {
  const query = useQuery<CheckStudentExamStatus>({
    queryKey: [{ examID, studentID, subjectID }, 'checkStudentExamStatus'],
    queryFn: checkStudentExamStatus,
    enabled: !!examID && !!studentID && !!subjectID,
  });
  return query;
};
export const useCheckStudentPartialExamStatus = (
  partialExamID: number | undefined,
  studentID: number | null,
  subjectID: number | undefined
) => {
  const query = useQuery<CheckStudentExamStatus>({
    queryKey: [{ partialExamID, studentID, subjectID }, 'checkStudentPartialExamStatus'],
    queryFn: checkStudentPartialExamStatus,
    enabled: !!partialExamID && !!studentID && !!subjectID,
  });
  return query;
};

export const useGetExamScheduleResults = (examScheduleID: number | undefined) => {
  const query = useQuery<ExamScheduleResults>({
    queryKey: [examScheduleID, 'getExamScheduleResults'],
    queryFn: getExamScheduleResults,
    enabled: !!examScheduleID,
  });
  return query;
};

export const useGetLatestExamScheduleInfo = (examInfoID: number | undefined, examID: number) => {
  const query = useQuery<ExamSchedule[]>({
    queryKey: [{ examInfoID, examID }, 'getLatestExamScheduleInfo'],
    queryFn: getLatestExamScheduleInfo,
    enabled: !!examInfoID,
  });
  return query;
};

export const useGetExamStudentsStats = (examID: number | undefined) => {
  const query = useQuery<ExamStats>({
    queryKey: [examID, 'getExamStudentsStats'],
    queryFn: getExamStudentsStats,
    enabled: !!examID,
  });
  return query;
};

export const useGetCompletedSubjects = () => {
  const query = useQuery<StudentCompletedSubjects[]>({
    queryKey: ['getCompletedSubjects'],
    queryFn: getCompletedSubjects,
  });
  return query;
};
