import {
  Divider,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { HelperText } from '../../../../components/general/HelperText';
import useProf from '../../../../hooks/useProf.hooks';
import { useStaffAddStudentMutation } from '../../../../services/mutations';
import { staffAddStudentSchema } from '../../../../validation/student-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddStudentModal = ({ isOpen, onClose }: Props) => {
  const profContext = useProf();
  const navigate = useNavigate();
  const [isOneLoading, setIsOneLoading] = useState(false);

  const staffAddStudent = useStaffAddStudentMutation();

  const handleSubmitOneStudent = async (values: any) => {
    setIsOneLoading(true);

    try {
      await staffAddStudent(values);
      onClose();
    } catch (error) {
    } finally {
      setIsOneLoading(false);
    }
  };

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Dodaj postojeće studente na predmet</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Stack justify={'center'} w={'100%'} direction={'column'} align={'center'}>
              <Formik
                initialValues={{
                  indexNumber: '',
                  subjectID: profContext.selectedSubjectID,
                }}
                validationSchema={staffAddStudentSchema}
                onSubmit={(values, actions) => {
                  handleSubmitOneStudent(values);
                }}
              >
                {() => (
                  <Form>
                    <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                      <Heading mb={3} size={'sm'}>
                        Dodaj jednog studenta
                      </Heading>
                      <HorizontalTextField
                        name='indexNumber'
                        type='text'
                        label='Broj Indexa'
                        width='150px'
                      />

                      <HStack pt={5} justify={'center'}>
                        <BluesubmitButton
                          buttonText='Dodaj studenta'
                          loadingText='Provjeravamo datoteku'
                          isLoading={isOneLoading}
                        />
                      </HStack>
                    </Stack>
                  </Form>
                )}
              </Formik>

              <Divider p={3} orientation={'horizontal'} />
              <Stack align={'center'} py={5}>
                <BlueButton
                  buttonText='Dodaj više studenata'
                  onClick={() => {
                    navigate('dodaj-studente');
                  }}
                  width='200px'
                />
                <HelperText text='Ovdje možete uploadati excel tabelu sa studentima, gdje će sistem automatski izvuči podatke i dodati studente na predmet' />
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
