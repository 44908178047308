import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { StaffEnum } from '../../../../@types/auth.types';
import { useAdminCreateAssistantMutation } from '../../../../services/mutations';
import { CreateStaffDto, createStaffSchema } from '../../../../validation/auth-schema';

export default function CreateAssistant() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const createAssistant = useAdminCreateAssistantMutation();

  const handleCreateAssistant = async (values: CreateStaffDto, actions: any) => {
    setLoading(true);
    try {
      await createAssistant(values);
      actions.resetForm();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={5} mx={'auto'} maxW={'lg'} py={4} px={6}>
      <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            title: '',
            role: StaffEnum.ASSISTANT,
          }}
          validationSchema={createStaffSchema}
          onSubmit={async (values, actions) => {
            handleCreateAssistant(values, actions);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack spacing={4}>
                <HStack>
                  <Box>
                    <Field name='firstName'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='firstName'
                          isRequired
                          isInvalid={form.errors.firstName && form.touched.firstName}
                        >
                          <FormLabel>Ime</FormLabel>
                          <Input type='text' {...field} />
                          <ErrorMessage name='firstName'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name='lastName'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='lastName'
                          isRequired
                          isInvalid={form.errors.lastName && form.touched.lastName}
                        >
                          <FormLabel>Prezime</FormLabel>
                          <Input type='text' {...field} />
                          <ErrorMessage name='lastName'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                </HStack>
                <Field name='email'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='email'
                      isRequired
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel>E-mail adresa</FormLabel>
                      <Input type='email' {...field} />
                      <ErrorMessage name='email'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Box>
                  <Field name='title'>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id='title'
                        isRequired
                        isInvalid={form.errors.firstName && form.touched.firstName}
                      >
                        <FormLabel>Titula</FormLabel>
                        <Input type='text' {...field} />
                        <ErrorMessage name='title'>
                          {(msg) => (
                            <Text color='red' fontSize='sm'>
                              {msg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>

                <Stack spacing={10} pt={2}>
                  <Button
                    type='submit'
                    isLoading={isLoading}
                    loadingText='Kreiranje u toku...'
                    size='lg'
                    bg='blue.400'
                    color='white'
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Kreiraj asistenta
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Stack>
  );
}
