import {
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import useProf from '../../../hooks/useProf.hooks';
import { useGetStudentCompleteReport } from '../../../services/queries';
import { ExamsInfo } from './comp/ExamsInfo';
import { ExercisesInfo } from './comp/ExercisesInfo';
import { StudentInfo } from './comp/StudentInfo';
import { SubjectActivities } from './comp/SubjectActivities';
import { LecturesInfo } from './comp/lecturesInfo';

export const StudentProfProfilePage = () => {
  const profContext = useProf();
  const { studentID } = useParams();
  const navigate = useNavigate();

  const studentInfo = useGetStudentCompleteReport(profContext.selectedSubjectID, Number(studentID));

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return `Desila se greška: ${error.data.message}`;
    }
    return 'Desila se nepoznata greška';
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <Stack pos={'absolute'} top={'160px'} left={{ base: 5, md: '260px' }}>
        <ArrowBackButton
          onClick={() => {
            navigate(-1);
          }}
        />
      </Stack>
      <HStack justify={'center'} pb={8}>
        <Heading fontSize={'xl'}>Kompletan studentski izvještaj</Heading>
      </HStack>
      {studentInfo.isLoading ? (
        <Center h={'20vh'}>
          <Spinner />
        </Center>
      ) : studentInfo.isError ? (
        <Center>
          <Text>{getErrorMessage(studentInfo.error)}</Text>
        </Center>
      ) : (
        studentInfo.data && (
          <Center>
            <Grid
              templateRows={{ base: 'auto', lg: '2' }}
              templateColumns={{ base: '1', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
              gap={4}
            >
              <GridItem rowSpan={1} colSpan={1}>
                <LecturesInfo lectureInfo={studentInfo.data.lecturesInfo} />
              </GridItem>
              <GridItem rowSpan={2} colSpan={1}>
                <StudentInfo
                  student={studentInfo.data.studentInfo}
                  studentComplete={studentInfo.data}
                />
              </GridItem>
              <GridItem rowSpan={1} colSpan={1}>
                <ExercisesInfo exerciseInfo={studentInfo.data.exercisesInfo} />
              </GridItem>

              <SubjectActivities subjectActivity={studentInfo.data.subjectActivities} />
              <ExamsInfo exams={studentInfo.data.examResults} />
            </Grid>
          </Center>
        )
      )}
    </Flex>
  );
};
