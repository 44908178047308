import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';
import useProf from '../../../../hooks/useProf.hooks';
import { SubjectActivity } from '../../../../models/subjectActivities';
import { useGetSubjectActivitiesInfo } from '../../../../services/queries';
import { editSubjectActivitySchema } from '../../../../validation/subjectActivities';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';
import { parse, format } from 'date-fns';
import { useEditSubjectActivityMutation } from '../../../../services/mutations';
import { DateTimeInput } from '../../../../components/general/DateTimeInput';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  subjectActivity: SubjectActivity | null;
  selectedGroupID: number | null;
};

export const EditSubjectActivityAllModal = ({
  isOpen,
  onClose,
  subjectActivity,
  selectedGroupID,
}: Props) => {
  const profContext = useProf();
  const [isLoading, setIsLoading] = useState(false);

  const subjectAtivitiesInfo = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const editActivity = useEditSubjectActivityMutation(
    subjectAtivitiesInfo.data?.id,
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID,
    subjectActivity?.groupID || null
  );

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Uredi aktivnost</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Formik
              initialValues={{
                subjectActivityID: subjectActivity?.id,
                name: subjectActivity?.name,
                text: subjectActivity?.text,
                type: subjectActivity?.type,
                deadlineDate: subjectActivity?.rawDeadlineDate
                  ? new Date(subjectActivity?.rawDeadlineDate)
                  : null,
                dateTime: subjectActivity?.rawDateTime
                  ? new Date(subjectActivity?.rawDateTime)
                  : null,
                maxScore: subjectActivity?.maxScore,
                minPassingScore: subjectActivity?.minPassingScore,
                difficultyFactor: subjectActivity?.difficultyFactor,
              }}
              validationSchema={editSubjectActivitySchema}
              onSubmit={async (values, actions) => {
                setIsLoading(true);
                try {
                  await editActivity(values);
                  onClose();
                } catch (error) {
                } finally {
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 1000);
                }
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Stack
                    spacing={4}
                    justifyContent={{ base: 'start', md: 'center' }}
                    alignItems={'center'}
                  >
                    <HorizontalTextField
                      name='name'
                      type='text'
                      label='Naziv aktivnosti'
                      width='250px'
                    />
                    <HorizontalTextField
                      name='type'
                      type='text'
                      label='Tip aktivnosti'
                      width='250px'
                    />
                    <Box w={{ base: '250px', md: '80%' }}>
                      <Field name='text'>
                        {({ field, form }: { field: any; form: any }) => (
                          <FormControl
                            id='text'
                            isRequired
                            isInvalid={form.errors.text && form.touched.text}
                          >
                            <FormLabel>Opis aktivnosti</FormLabel>
                            <Textarea type='text' {...field} />
                            <ErrorMessage name='text'>
                              {(msg) => (
                                <Text color='red' fontSize='sm'>
                                  {msg}
                                </Text>
                              )}
                            </ErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>

                    <DateTimeInput
                      label='Rok predaje'
                      name='deadlineDate'
                      setFieldValue={setFieldValue}
                      isRequired={false}
                      placeholder='Izaberite datum'
                    />

                    <DateTimeInput
                      label='Datum održavanja'
                      name='dateTime'
                      setFieldValue={setFieldValue}
                      isRequired={false}
                      placeholder='Izaberite datum'
                    />
                    <HorizontalTextField
                      name='maxScore'
                      type='number'
                      label='Maximalni broj bodova'
                      width='250px'
                    />
                    <HorizontalTextField
                      name='minPassingScore'
                      type='number'
                      label='Broj bodova za prolaz'
                      width='250px'
                    />
                    <HorizontalTextField
                      name='difficultyFactor'
                      type='number'
                      label='Težinski faktor'
                      width='250px'
                    />
                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Uredi aktivnost'
                        loadingText='Aktivnost se kreira.'
                        isLoading={isLoading}
                      />
                      <RedButton buttonText='Odustani' onClick={onClose} />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
