import { useGetExerciseGroups, useGetExercisesInfo } from '../../../../services/queries';
import useProf from '../../../../hooks/useProf.hooks';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { GroupDateTimeInput } from './GroupDateTimeInput';
import { PartExamGroupDateTimeInput } from './PartialExamGroupDateTimeInput';

export const PartialExamGroupDateTime = () => {
  const profContext = useProf();

  const exerciseInfo = useGetExercisesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const groups = useGetExerciseGroups({
    academicYearID: exerciseInfo.data?.academicYearID,
    exerciseInfoID: exerciseInfo.data?.id,
  });

  return (
    <Accordion w={'100%'} defaultIndex={[0]} allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left'>
              Datum i mjesto po grupama
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel overflow={'visible'} pb={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
            {groups.isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : groups.data && groups.data.length > 0 ? (
              groups.data.map((group) => (
                <PartExamGroupDateTimeInput key={`groupInput-${group.groupID}`} group={group} />
              ))
            ) : (
              <Text>Nema kreiranih grupa</Text>
            )}
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
