import { FormControl, FormLabel, Stack, Text, VStack } from '@chakra-ui/react';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

type Props = {
  setFieldValue: (field: string, value: any) => void;
  name: string;
  label: string;
  isRequired?: boolean;
  placeholder: string;
};

export const DateInput = ({ setFieldValue, name, label, isRequired, placeholder }: Props) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: any }) => (
        <FormControl
          id={name}
          isInvalid={form.errors.date && form.touched.date}
          isRequired={isRequired}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 0, md: 5 }}
            justify={'center'}
            pl={{ base: 8, md: 0 }}
          >
            <VStack
              w={{ base: '280px', md: '50%' }}
              align={{ base: 'start', md: 'end' }}
              justify={'center'}
            >
              <FormLabel htmlFor={name}>{label}</FormLabel>
            </VStack>
            <VStack w={{ base: '280px', md: '50%' }} align={'start'} justify={'center'}>
              <ReactDatePicker
                {...field}
                selected={field.value && new Date(field.value)}
                onChange={(date: Date) => setFieldValue(name, date)}
                dateFormat='dd. MM. yyyy.'
                placeholderText={placeholder}
              />
              <ErrorMessage name={name}>{(msg) => <Text color='red'>{msg}</Text>}</ErrorMessage>
            </VStack>
          </Stack>
        </FormControl>
      )}
    </Field>
  );
};
