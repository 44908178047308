import {
  Badge,
  Box,
  Card,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Feature } from '../../Landing/home/comp/Features';
import { MdGroupAdd } from 'react-icons/md';
import { FaUniversity } from 'react-icons/fa';
import { FaUserCheck } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';
import { FaHandPaper } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import { FaProjectDiagram } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { CreateStudentModal } from './comp/CreateStudentModal';
import { AddStudentModal } from './comp/AddStudentModal';
import { useNavigate } from 'react-router-dom';
import { ChatIcon } from '@chakra-ui/icons';
import { useGetStudentsWantToJoinsubject } from '../../../services/queries';
import useProf from '../../../hooks/useProf.hooks';

function Students() {
  const navigate = useNavigate();
  const profContext = useProf();
  const {
    isOpen: isCreateStudentOpen,
    onOpen: onCreateStudentOpen,
    onClose: onCreateStudentClose,
  } = useDisclosure();
  const {
    isOpen: isAddStudentOpen,
    onOpen: onAddStudentOpen,
    onClose: onAddStudentClose,
  } = useDisclosure();

  const studentsWantToJoin = useGetStudentsWantToJoinsubject(profContext.selectedSubjectID);

  return (
    <VStack justify={'center'} p='5' bg={useColorModeValue('gray.100', 'gray.900')} w='100%'>
      <CreateStudentModal isOpen={isCreateStudentOpen} onClose={onCreateStudentClose} />
      <AddStudentModal isOpen={isAddStudentOpen} onClose={onAddStudentClose} />
      <Heading pb={10} fontSize={'xl'}>
        Studenti
      </Heading>
      <SimpleGrid justifyContent={'center'} columns={{ sm: 1, md: 2, xl: 3 }} spacing={'15px'}>
        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => onAddStudentOpen()}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={MdGroupAdd} w={10} h={10} color={'blue.600'} />}
            title={'Dodaj studenta na predmet'}
            text={
              'Ovdje možete dodati studenta, preko broja Indexa, na predmet koji već ima profil na platformi iseus.'
            }
          />
        </Card>
        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => onCreateStudentOpen()}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={FaUniversity} w={10} h={10} color={'blue.600'} />}
            title={'Dodaj studenta na platformu'}
            text={
              'Ovdje možete kreirati novog studenta na platformi ISEUS. Student će biti automatski dodan na ovaj predmet.'
            }
          />
        </Card>
        <Box position='relative' w={'330px'} h={'200px'}>
          <Card
            align={'center'}
            variant={'outline'}
            w={{ base: '95vw', md: '370px' }}
            h={'220px'}
            bg={useColorModeValue('white', 'gray.900')}
            p='10px'
            pt='25px'
            onClick={() => navigate('odobri-studente-na-predmet')}
            cursor={'pointer'}
          >
            <Feature
              icon={<Icon as={FaUserCheck} w={10} h={10} color={'blue.600'} />}
              title={'Odobri studenta na predmet'}
              text={
                'Ovdje možete odobriti studente na predmet koji skeniraju ili ukucaju kod ovog predmeta.'
              }
            />
          </Card>
          <Badge
            w={'32px'}
            h={'27px'}
            textAlign={'center'}
            variant='outline'
            bg={'red.700'}
            color={'white'}
            position='absolute'
            top={'15px'}
            right={'-20px'}
            borderRadius='full'
            fontWeight={'800'}
            fontSize={'lg'}
          >
            <Text justifySelf={'center'} alignSelf={'center'}>
              {studentsWantToJoin.data?.length || 0}
            </Text>
          </Badge>
        </Box>
      </SimpleGrid>
      <Heading py={10} fontSize={'xl'}>
        Tabelarni pregledi
      </Heading>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing={'15px'}>
        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => navigate('svi-studenti')}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={IoIosPeople} w={10} h={10} color={'blue.600'} />}
            title={'Pregled svih studenata na predmetu'}
            text={
              'Ovdje možete vidjeti tabelu svih studenata koji su povezani i odobreni na ovaj predmet.'
            }
          />
        </Card>
        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => navigate('pregled-stanja-prisustva')}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={FaHandPaper} w={10} h={10} color={'blue.600'} />}
            title={'Pregled stanja prisustva'}
            text={
              'Ovdje možete vidjeti tabele stanja prisustava na predmetu, podijeljene na vježbe i predavanja.'
            }
          />
        </Card>
        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => navigate('bodovi-predmetne-aktivnosti')}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={FaProjectDiagram} w={10} h={10} color={'blue.600'} />}
            title={'Bodovi na predmetnim aktivnostima'}
            text={
              'Ovdje možete vidjeti stanje bodova dodatnim predmetnim aktivnostima, što uključuje zadaće, projekti itd.'
            }
          />
        </Card>
        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => console.log('test')}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={FaFileAlt} w={10} h={10} color={'blue.600'} />}
            title={'Pregled stanja bodova na ispitima'}
            text={'Ovdje možete vidjeti stanje bodova na ispitima.'}
          />
        </Card>

        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => navigate('konacna-ocjena')}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={FaStar} w={10} h={10} color={'blue.600'} />}
            title={'Konačna ocjena'}
            text={'Ovdje možete vidjeti sve bodove i konačnu ocjenu'}
          />
        </Card>
        <Card
          align={'center'}
          variant={'outline'}
          w={{ base: '95vw', md: '370px' }}
          h={'220px'}
          bg={useColorModeValue('white', 'gray.900')}
          p='10px'
          pt='25px'
          onClick={() => navigate('objave')}
          cursor={'pointer'}
        >
          <Feature
            icon={<Icon as={ChatIcon} w={10} h={10} color={'blue.600'} />}
            title={'Objave'}
            text={
              'Ovdje možete kao profesor objaviti nešto svim studentima, ili kao asistent svojoj grupi.'
            }
          />
        </Card>
      </SimpleGrid>
    </VStack>
  );
}

export default Students;
