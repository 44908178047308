import {
  Avatar,
  Button,
  Center,
  Checkbox,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useProf from '../../../../hooks/useProf.hooks';
import {
  useGetLectures,
  useGetLecturesAttendanceStats,
  useGetLecturesInfo,
} from '../../../../services/queries';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

export const LectureAttendancesStatTable = () => {
  const profContext = useProf();

  const navigate = useNavigate();

  const students = useGetLecturesAttendanceStats({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const lecturesInfoQuery = useGetLecturesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const lecturesQuery = useGetLectures({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  return (
    <>
      <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th colSpan={4} textAlign='center'></Th>
              <Th colSpan={lecturesInfoQuery.data?.numberOfLectures ?? 0} textAlign='center'>
                Datum i broj predavanja
              </Th>
            </Tr>

            <Tr>
              <Th colSpan={4} textAlign='center'>
                podaci od studenta
              </Th>
              {lecturesQuery.data &&
                lecturesQuery.data.map((lecture, index) => (
                  <Th
                    textAlign='center'
                    py={'5px'}
                    style={{
                      writingMode: 'vertical-rl',
                      transform: 'rotate(180deg)',
                    }}
                    key={`lecture-${index + 1}`}
                  >
                    {lecture.date}
                  </Th>
                ))}
            </Tr>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>B. Indexa</Th>
              <Th>Prezime i ime</Th>
              {Array.from({ length: lecturesInfoQuery.data?.numberOfLectures ?? 0 }, (_, index) => (
                <Th textAlign='center' key={`lecture-${index + 1}`}>{`P${index + 1}`}</Th>
              ))}
              <Th textAlign='center'>Br. dolazaka</Th>
              <Th textAlign='center'>Uslov za potpis</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={6 + (lecturesInfoQuery.data?.numberOfLectures ?? 0)}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : students.isError ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <VStack>
                      <Text>{getErrorMessage(students.error)}</Text>
                      <Button
                        fontSize={'sm'}
                        bgColor={'blue.600'}
                        color={'white'}
                        onClick={() => {
                          navigate('/profesori/predavanja');
                        }}
                      >
                        Vrati se na sva vježbe
                      </Button>
                    </VStack>
                  </Center>
                </Td>
              </Tr>
            ) : students.data && students.data.length > 0 ? (
              students.data.map((attendance, index) => (
                <Tr key={attendance.id}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={attendance.profileImage} />
                  </Td>
                  <Td textAlign='center'>{attendance.indexNumber}</Td>
                  <Td>
                    <StudentNameSubjectStats
                      firstName={attendance.firstName}
                      lastName={attendance.lastName}
                      navigateLink={`/profesori/student/${attendance.id}`}
                      hasCompletedSubject={attendance.hasCompletedSubject}
                      repeater={attendance.repeater}
                    />
                  </Td>
                  {attendance.attendanceArray.map((attendance, index) => (
                    <Td textAlign='center' key={`lAttendanceChkBox-${index + 1}`}>
                      {/*  <Tooltip
                        key={`attendance-tooltip-${index}`}
                        label={lecturesQuery.data && lecturesQuery.data[index].date}
                      > */}
                      <Checkbox size={'md'} isChecked={attendance} colorScheme='green'></Checkbox>
                      {/* </Tooltip> */}
                    </Td>
                  ))}
                  <Td
                    textAlign='center'
                    fontSize={'md'}
                    color={
                      lecturesInfoQuery.data?.numberOfLectures === attendance.attendedLecturesCount
                        ? 'green.400'
                        : attendance.fullfiledSignatureCondition
                        ? ''
                        : 'red.400'
                    }
                    fontWeight={'500'}
                  >
                    {`${attendance.attendedLecturesCount}/${lecturesInfoQuery.data?.numberOfLectures}`}
                  </Td>

                  <Td textAlign='center'>
                    <HStack justify={'center'}>
                      {attendance.fullfiledSignatureCondition ? (
                        <Text
                          fontWeight={'700'}
                          color={'green.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          DA
                        </Text>
                      ) : (
                        <Text
                          fontWeight={'700'}
                          color={'red.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          NE
                        </Text>
                      )}
                    </HStack>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={6 + (lecturesInfoQuery.data?.numberOfLectures ?? 0)}>
                  <Center>
                    <Text> Nema prisutnih studenata na predavanju </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {students.data && students.data.length > 8 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>B. Indexa</Th>
                <Th>Prezime i ime</Th>
                {Array.from(
                  { length: lecturesInfoQuery.data?.numberOfLectures ?? 0 },
                  (_, index) => (
                    <Th textAlign='center' key={`lecture-${index + 1}`}>{`P${index + 1}`}</Th>
                  )
                )}
                <Th textAlign='center'>Br. dolazaka</Th>
                <Th textAlign='center'>potpis</Th>
              </Tr>
              <Tr>
                <Th colSpan={4} textAlign='center'>
                  podaci od studenta
                </Th>
                {lecturesQuery.data &&
                  lecturesQuery.data.map((lecture, index) => (
                    <Th
                      textAlign='center'
                      py={'5px'}
                      style={{
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                      }}
                      key={`lecture-${index + 1}`}
                    >
                      {lecture.date}
                    </Th>
                  ))}
              </Tr>
              <Tr>
                <Th colSpan={4} textAlign='center'></Th>
                <Th colSpan={lecturesInfoQuery.data?.numberOfLectures ?? 0} textAlign='center'>
                  Datum i broj predavanja
                </Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
