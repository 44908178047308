import { CloseIcon, DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { ExamResult } from '../../../../models/exams';
import { useGetPartialExamResults } from '../../../../services/queries';
import { CheckExamConditionModal } from './modals/CheckExamConditionModal';
import { ExamScoreInputModal } from './modals/ScoreInputModal';
import {
  useDeleteStudentPartialExamRegistration,
  useToggleStudentPartialExamAttendance,
  useToggleStudentPartialExamNullification,
} from '../../../../services/mutations';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';
import { AddStudentToPartialExamModal } from './modals/AddStudentToPartialExamModal';

interface LoadingStates {
  [key: number]: boolean;
}

type props = {
  maxScore: number;
};

export const PartialExamResultsTable = ({ maxScore }: props) => {
  const authContext = useAuth();
  const profContext = useProf();
  const { partialExamID } = useParams();
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const menuColor = useColorModeValue('blue.900', 'blue.300');

  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const [selectedStudent, setSelectedStudent] = useState<ExamResult | null>(null);

  const {
    isOpen: scoreInputOpen,
    onOpen: onScoreInputOpen,
    onClose: onScoreInputClose,
  } = useDisclosure();

  const {
    isOpen: scanStudentModalOpen,
    onOpen: onScanStudentModalOpen,
    onClose: onScanStudentModalClose,
  } = useDisclosure();

  const {
    isOpen: addStudentModalOpen,
    onOpen: onAddStudentModalOpen,
    onClose: onAddStudentModalClose,
  } = useDisclosure();

  const students = useGetPartialExamResults(Number(partialExamID));
  const toggleAttendance = useToggleStudentPartialExamAttendance(Number(partialExamID));
  const toggleNullification = useToggleStudentPartialExamNullification(Number(partialExamID));
  const deleteRegistration = useDeleteStudentPartialExamRegistration(Number(partialExamID));

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  const filteredStudents = students.data?.filter(
    (student) =>
      student.indexNumber.includes(filter) ||
      student.lastName.toLowerCase().includes(filter.toLowerCase()) ||
      student.firstName.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <CheckExamConditionModal
        isExam={false}
        isOpen={scanStudentModalOpen}
        onClose={onScanStudentModalClose}
      />
      <ExamScoreInputModal
        isOpen={scoreInputOpen}
        onClose={onScoreInputClose}
        student={selectedStudent}
        maxScore={maxScore}
        isExam={false}
      />
      <AddStudentToPartialExamModal
        isOpen={addStudentModalOpen}
        onClose={onAddStudentModalClose}
        partialExamID={Number(partialExamID)}
      />
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center' }}
        align={'center'}
        spacing={2}
      >
        <Input
          w='250px'
          placeholder='Pretraži studente'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <BlueButton
          height='40px'
          width='150px'
          buttonText={'Skeniraj studenta'}
          onClick={() => {
            onScanStudentModalOpen();
          }}
        />
        <BlueButton
          height='40px'
          width='150px'
          buttonText={'Dodaj studenta'}
          onClick={() => {
            onAddStudentModalOpen();
          }}
        />
      </Stack>
      <TableContainer
        mt={'1rem'}
        w={'1000px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>Ime i prezime</Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>broj</Text>
                  <Text>indexa</Text>
                </VStack>
              </Th>
              <Th textAlign='center'>opcije</Th>
              <Th textAlign='center'>
                <VStack spacing={0}>
                  <Text>bodovi /</Text>
                  <Text>max. bodovi</Text>
                </VStack>
              </Th>

              <Th textAlign='center'>položio</Th>
              <Th textAlign='center'>prisutan</Th>
              <Th textAlign='center'>poništen</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={9}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : students.isError ? (
              <Tr>
                <Td colSpan={9}>
                  <Center>
                    <VStack>
                      <Text>{getErrorMessage(students.error)}</Text>
                      <Button
                        fontSize={'sm'}
                        bgColor={'blue.600'}
                        color={'white'}
                        onClick={() => {
                          navigate('/profesori/studenti/pregled-stanja-prisustva');
                        }}
                      >
                        Vrati se na prisustva
                      </Button>
                    </VStack>
                  </Center>
                </Td>
              </Tr>
            ) : filteredStudents && filteredStudents.length > 0 ? (
              filteredStudents.map((attendance, index) => (
                <Tr key={attendance.id}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={attendance.profileImage} />
                  </Td>
                  <Td textAlign='center'>
                    <StudentNameSubjectStats
                      firstName={attendance.firstName}
                      lastName={attendance.lastName}
                      repeater={attendance.repeater}
                      hasCompletedSubject={attendance.hasCompletedSubject}
                      navigateLink={`/profesori/student/${attendance.id}`}
                    />
                  </Td>
                  <Td textAlign='center'>{attendance.indexNumber}</Td>
                  <Td textAlign='center'>
                    <HStack justify={'center'}>
                      <BlueButton
                        height='25px'
                        width='110px'
                        buttonText={'Unesi rezultat'}
                        onClick={() => {
                          setSelectedStudent(attendance);
                          onScoreInputOpen();
                        }}
                        isLoading={loadingStates[attendance.id]}
                      />
                      <Menu>
                        <MenuButton
                          color={menuColor}
                          as={IconButton}
                          aria-label='Options'
                          icon={<HamburgerIcon />}
                          width={'25px'}
                          height={'25px'}
                        />
                        <MenuList>
                          <MenuItem
                            icon={<EditIcon />}
                            onClick={async () => {
                              try {
                                await toggleAttendance({
                                  partialExamID: Number(partialExamID),
                                  studentID: attendance.id,
                                });
                              } catch (error) {}
                            }}
                          >
                            Promjeni prisustvo
                          </MenuItem>
                          <MenuItem
                            icon={<CloseIcon />}
                            onClick={async () => {
                              try {
                                await toggleNullification({
                                  partialExamID: Number(partialExamID),
                                  studentID: attendance.id,
                                });
                              } catch (error) {}
                            }}
                          >
                            Promijeni status poništenja
                          </MenuItem>
                          <MenuItem
                            icon={<DeleteIcon />}
                            onClick={async () => {
                              try {
                                await deleteRegistration({
                                  partialExamID: Number(partialExamID),
                                  studentID: attendance.id,
                                });
                              } catch (error) {}
                            }}
                          >
                            Izbriši prijavu
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </Td>
                  <Td textAlign='center'>
                    {attendance.score} / {maxScore}
                  </Td>
                  <Td textAlign='center'>
                    <HStack justify={'center'}>
                      {attendance.passed ? (
                        <Text
                          fontWeight={'700'}
                          color={'green.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          DA
                        </Text>
                      ) : (
                        <Text
                          fontWeight={'700'}
                          color={'red.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          NE
                        </Text>
                      )}
                    </HStack>
                  </Td>

                  <Td textAlign='center'>
                    {attendance.attended ? (
                      <Text fontWeight={'bold'} color='green.400'>
                        Prisustvovao
                      </Text>
                    ) : (
                      <Text fontWeight={'bold'} color='red.400'>
                        Nije prisustvovao
                      </Text>
                    )}
                  </Td>
                  <Td textAlign='center'>
                    <HStack justify={'center'}>
                      {attendance.nullified ? (
                        <Text
                          fontWeight={'700'}
                          color={'green.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          DA
                        </Text>
                      ) : (
                        <Text
                          fontWeight={'700'}
                          color={'red.400'}
                          border={'2px solid'}
                          borderRadius={'xl'}
                          width={'40px'}
                        >
                          NE
                        </Text>
                      )}
                    </HStack>
                  </Td>

                  {/* {attendance.repeater && attendance.signatureRequirmentMet ? (
                    <Td colSpan={5}>
                      <Center>
                        <Text> Student je ponovac i ima potpis.</Text>
                      </Center>
                    </Td>
                  ) : (
                    <>
                      <Td textAlign='center'>
                        {attendance.lecturesAttendanceSum} / {attendance.lecturesCreated}
                      </Td>
                      <Td textAlign='center'>
                        {attendance.lecturesRequirmentMet ? (
                          <Text fontWeight={'bold'} color='green.400'>
                            Ispunjen
                          </Text>
                        ) : (
                          <Text fontWeight={'bold'} color='red.500'>
                            Nije ispunjen
                          </Text>
                        )}
                      </Td>
                      <Td textAlign='center'>{attendance.groupName}</Td>
                      <Td textAlign='center'>
                        {attendance.exercisesAttendanceSum} / {attendance.createdExercises}
                      </Td>
                      <Td textAlign='center'>
                        {attendance.exercisesRequirmentMet ? (
                          <Text fontWeight={'bold'} color='green.400'>
                            Ispunjen
                          </Text>
                        ) : (
                          <Text fontWeight={'bold'} color='red.500'>
                            Nije ispunjen
                          </Text>
                        )}
                      </Td>

                      <Td textAlign='center'>
                        <Text>{attendance.comment}</Text>
                      </Td>
                      <Td textAlign='center'>{index + 1}</Td>
                    </>
                  )} */}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={9}>
                  <Center>
                    <Text> Nema studenata </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {filteredStudents && filteredStudents.length > 8 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>Ime i prezime</Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>broj</Text>
                    <Text>indexa</Text>
                  </VStack>
                </Th>
                <Th textAlign='center'>opcije</Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>bodovi /</Text>
                    <Text>max. bodovi</Text>
                  </VStack>
                </Th>

                <Th textAlign='center'>položio</Th>
                <Th textAlign='center'>prisutan</Th>
                <Th textAlign='center'>poništen</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
