import { Button } from '@chakra-ui/react';
import React from 'react';

type Props = {
  height?: string;
  width?: string;
  fontSize?: string;
  bgColor?: string;
  color?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  buttonText: string;
  isLoading?: boolean;
};

export const BlueButton = (props: Props) => {
  return (
    <Button
      w={props.width}
      h={props.height}
      fontSize={props.fontSize ?? 'sm'}
      bgColor={'blue.600'}
      color={'white'}
      onClick={props.onClick}
      isDisabled={props.isDisabled ?? false}
      isLoading={props.isLoading ?? false}
      _hover={
        props.isDisabled
          ? {}
          : {
              bg: 'blue.300',
              color: 'blue.900',
            }
      }
    >
      {props.buttonText}
    </Button>
  );
};
