import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import useProf from '../../../../hooks/useProf.hooks';
import { useProfCreatePostMutation } from '../../../../services/mutations';
import { profCreatePostSchema, profCreatePostSchemaDto } from '../../../../validation/post-schema';

export function ProfCreatePost() {
  const profContext = useProf();
  const [isLoading, setLoading] = useState<boolean>(false);
  const createPost = useProfCreatePostMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const handleCreatePost = async (values: profCreatePostSchemaDto, actions: any) => {
    setLoading(true);
    try {
      await createPost(values);
      actions.resetForm();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={5} mx={'auto'} w={'100%'} py={4} px={5}>
      <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
        <Formik
          initialValues={{
            title: '',
            text: '',
            allStudents: true,
            subjectID: profContext.selectedSubjectID,
          }}
          validationSchema={profCreatePostSchema}
          onSubmit={async (values, actions) => {
            handleCreatePost(values, actions);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack spacing={4}>
                <Box>
                  <Field name='title'>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id='title'
                        isRequired
                        isInvalid={form.errors.title && form.touched.title}
                      >
                        <FormLabel>Naslov objave</FormLabel>
                        <Input type='text' {...field} />
                        <ErrorMessage name='title'>
                          {(msg) => (
                            <Text color='red' fontSize='sm'>
                              {msg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box>
                  <Field name='text'>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id='text'
                        isRequired
                        isInvalid={form.errors.text && form.touched.text}
                      >
                        <FormLabel>Tekst objave</FormLabel>
                        <Textarea type='text' {...field} />
                        <ErrorMessage name='text'>
                          {(msg) => (
                            <Text color='red' fontSize='sm'>
                              {msg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>

                <Stack spacing={10} pt={2}>
                  <Button
                    type='submit'
                    isLoading={isLoading}
                    loadingText='Kreiranje u toku...'
                    size='lg'
                    bg='blue.400'
                    color='white'
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Kreiraj objavu
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Stack>
  );
}
