import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useProf from '../../../../hooks/useProf.hooks';
import { useCreateLecturesMutation } from '../../../../services/mutations';
import { createLectureSchema } from '../../../../validation/create-lectures-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';
import { BlueButton } from '../../../../components/general/BlueButton';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateLectureModal = ({ isOpen, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();

  const createLecture = useCreateLecturesMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Dodaj novo predavanje</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Formik
              initialValues={{
                name: '',
                date: new Date(),
                classHours: '',
                subjectID: profContext.selectedSubjectID,
                yearID: profContext.selectedAcademicYearID,
              }}
              validationSchema={createLectureSchema}
              onSubmit={(values, actions) => {
                setIsLoading(true);
                createLecture(values).catch((error) => {});
                setIsLoading(false);
                onClose();
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                    <HorizontalTextField
                      name='name'
                      type='text'
                      label='Naziv predavanja'
                      width='200px'
                    />
                    <HorizontalTextField
                      name='classHours'
                      type='number'
                      label='Broj časova'
                      width='200px'
                    />
                    <Field name='date'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='date'
                          isInvalid={form.errors.date && form.touched.date}
                          isRequired
                        >
                          <HStack spacing={5}>
                            <VStack w={'50%'} align={'end'}>
                              <FormLabel htmlFor='date'>Datum predavanja</FormLabel>
                            </VStack>
                            <VStack w={'50%'} align={'start'}>
                              <DatePicker
                                {...field}
                                selected={field.value && new Date(field.value)}
                                onChange={(date: Date) => setFieldValue('date', date)}
                                dateFormat='dd. MM. yyyy.'
                                placeholderText='Izaberite datum.'
                              />
                              <ErrorMessage name='date'>
                                {(msg) => <Text color='red'>{msg}</Text>}
                              </ErrorMessage>
                            </VStack>
                          </HStack>
                        </FormControl>
                      )}
                    </Field>
                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Dodaj predavanje'
                        loadingText='Predavanje se kreira'
                        isLoading={isLoading}
                      />
                      <RedButton buttonText='Odustani' onClick={onClose} />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
