import { Subject, SubjectInfo, University } from '../models/subject';

export const enum profReducerActionType {
  GET_SUBJECTS = 'GETSUBJECTS',
  SELECTED_SUBJECT = 'SELECTEDSUBJECT',
  GET_SELECTED_SUBJECT_INFO = 'GETSELECTEDSUBJECTINFO',
  GET_ALL_UNIVERSITIES = 'GETALLUNIVERSITIES',
  GET_SELECTED_ACADEMIC_YEAR = 'GETSELECTEDACADEMICYEAR',
}

export type profReducerAction = {
  type: profReducerActionType;
  payload?: any;
};

export type studsReducerAction = {
  type: profReducerActionType;
  payload?: any;
};

export type profContextState = {
  selectedSubjectID?: number;
  selectedAcademicYearID?: number;
  selectedSubjectInfo: SubjectInfo;
  subjects: Subject[];
  universities: University[];
};

export type studentContextState = {
  selectedSubjectID?: number;
  subjects: Subject[];
};
export interface StudentContext {
  selectedSubjectID: number;
  subjects: Subject[];
  setSelectedSubjectID: (value: number) => void;
  getSubjects: () => Promise<void>;
}

export interface ProfContext {
  selectedSubjectID: number;
  selectedAcademicYearID: number;
  selectedSubjectInfo: SubjectInfo;
  subjects: Subject[];
  universities: University[];

  setSelectedSubjectID: (value: number) => void;
  setSelectedAcademicYearID: (value: number | undefined) => void;
  getSelectedSubjectInfo: (value: number) => Promise<void>;
  getSubjects: () => Promise<void>;
  getUniversities: () => Promise<void>;
}

export interface SubjectInfoResponse {
  subjectID: number;
}
