import { Avatar, Center, HStack, Spinner, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import useAuth from '../../../../hooks/useAuth.hooks';
import {
  useSendSingleExerciseReportEmail,
  useSetAllStudentsNotPresentExerciseMutation,
  useSetAllStudentsPresentExerciseMutation,
  useSetStudentsOnExerciseMutation,
} from '../../../../services/mutations';
import { useGetSingleExerciseInfo } from '../../../../services/queries';

export const ExerciseAttendancesInfo = () => {
  const authContext = useAuth();
  const [isPresentModalOpen, setIsPresentModalOpen] = useState(false);
  const [isNotPresentModalOpen, setIsNotPresentModalOpen] = useState(false);
  const [addStudentsLoading, setAddStudentsLoading] = useState(false);
  const [setAsPresentLoading, setSetAsPresentLoading] = useState(false);
  const [setAsNotPresentLoading, setSetAsNotPresentLoading] = useState(false);
  const [sendReportLoading, setSendReportLoading] = useState(false);

  const { exerciseID } = useParams();
  const { exerciseNumber } = useParams();

  const addStudents = useSetStudentsOnExerciseMutation(Number(exerciseID));
  const setAsPresent = useSetAllStudentsPresentExerciseMutation(Number(exerciseID));
  const setAsNotPresent = useSetAllStudentsNotPresentExerciseMutation(Number(exerciseID));
  const sendReport = useSendSingleExerciseReportEmail();

  const exerciseInfo = useGetSingleExerciseInfo(Number(exerciseID));
  const isProfessor = authContext.user?.role === 'PROFESSOR' ? true : false;

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      mt={5}
      w={{ base: '90vw', md: '800px' }}
      justify={'center'}
      align={'center'}
    >
      {exerciseInfo.isLoading ? (
        <Center h={'10vh'}>
          <Spinner />
        </Center>
      ) : exerciseInfo.error ? (
        ''
      ) : (
        <>
          <VStack align={{ base: 'center', md: 'start' }} w={'100%'} spacing={0}>
            <YesNoModal
              isOpen={isPresentModalOpen}
              onClose={() => {
                setIsPresentModalOpen(false);
                setSetAsPresentLoading(false);
              }}
              onConfirm={() => {
                setAsPresent(Number(exerciseID))
                  .catch((err) => {})
                  .finally(() => {
                    setTimeout(() => {
                      setSetAsPresentLoading(false);
                    }, 1000);
                  });
                setIsPresentModalOpen(false);
              }}
              title='Označi sve kao prisutne'
              message='Da li ste sigurni da želite označiti sve studente kao prisutne?'
            />
            <YesNoModal
              isOpen={isNotPresentModalOpen}
              onClose={() => {
                setIsNotPresentModalOpen(false);
                setSetAsNotPresentLoading(false);
              }}
              onConfirm={() => {
                setAsNotPresent(Number(exerciseID))
                  .catch((err) => {})
                  .finally(() => {
                    setTimeout(() => {
                      setSetAsNotPresentLoading(false);
                    }, 1000);
                  });
                setIsNotPresentModalOpen(false);
              }}
              title='Označi sve kao odsutne'
              message='Da li ste sigurni da želite označiti sve studente kao odsutne?'
            />
            <HStack>
              <Text>Redni broj vježbi:</Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {exerciseNumber}
              </Text>
            </HStack>
            <HStack spacing={5}>
              <Text fontSize='md'>
                {exerciseInfo.data && exerciseInfo.data.assistants.length > 1
                  ? 'Asistenti održali vježbe:'
                  : 'Asistent održao vježbe'}
              </Text>
              {exerciseInfo.data?.assistants.map((assistant) => (
                <Tooltip key={assistant.id} label={assistant.firstName + ' ' + assistant.lastName}>
                  <Avatar
                    size={'sm'}
                    name={assistant.firstName + ' ' + assistant.lastName}
                    src={assistant.profileImage}
                  />
                </Tooltip>
              ))}
            </HStack>
            <HStack>
              <Text fontSize='md'>Datum vježbi:</Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {exerciseInfo.data?.date}
              </Text>
            </HStack>
            <HStack>
              <HStack>
                <Text fontSize='md'>Broj prisutnih:</Text>
                <Text fontWeight={'700'} fontSize={'lg'}>
                  {exerciseInfo.data?.presentStudents}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize='md'>, u procentima:</Text>
                <Text fontWeight={'700'} fontSize={'lg'}>
                  {exerciseInfo.data?.attendancePercentage}
                </Text>
              </HStack>
            </HStack>
          </VStack>
          <VStack w={'50%'}>
            <Stack py={{ base: 5, md: 0 }} direction={{ base: 'column', lg: 'row' }}>
              <BlueButton
                buttonText='Dodaj sve studente'
                height={'30px'}
                width='180px'
                onClick={() => {
                  setAddStudentsLoading(true);
                  addStudents(Number(exerciseID))
                    .catch((err) => {})
                    .finally(() => {
                      setTimeout(() => {
                        setAddStudentsLoading(false);
                      }, 1000);
                    });
                }}
                isDisabled={isProfessor}
                isLoading={addStudentsLoading}
              />
              <BlueButton
                width='180px'
                height={'30px'}
                buttonText='Preuzmi izvještaj'
                onClick={() => {
                  setSendReportLoading(true);
                  sendReport(Number(exerciseID))
                    .catch((err) => {})
                    .finally(() => {
                      setTimeout(() => {
                        setSendReportLoading(false);
                      }, 1000);
                    });
                }}
                isLoading={sendReportLoading}
                isDisabled={isProfessor}
              />
            </Stack>
            <Stack py={{ base: 5, md: 0 }} direction={{ base: 'column', lg: 'row' }}>
              <BlueButton
                buttonText='Označi sve kao prisutan'
                height='30px'
                width='180px'
                onClick={() => {
                  setSetAsPresentLoading(true);
                  setIsPresentModalOpen(true);
                }}
                isDisabled={isProfessor}
                isLoading={setAsPresentLoading}
              />
              <BlueButton
                buttonText='Označi sve kao odsutan'
                height='30px'
                width='180px'
                onClick={() => {
                  setSetAsNotPresentLoading(true);
                  setIsNotPresentModalOpen(true);
                }}
                isDisabled={isProfessor}
                isLoading={setAsNotPresentLoading}
              />
            </Stack>
          </VStack>
        </>
      )}
    </Stack>
  );
};
