import { IconButton } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { ChevronUpIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';

export const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
        controls.start('visible');
      } else {
        setIsVisible(false);
        controls.start('hidden');
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [controls]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const variants = {
    visible: { opacity: 1, transition: { duration: 0.3 } },
    hidden: { opacity: 0, transition: { duration: 0.3 } },
  };

  return (
    <motion.div
      initial='hidden'
      animate={controls}
      variants={variants}
      style={{
        position: 'fixed',
        bottom: '2rem',
        right: '50%',
        transform: 'translateX(50%)',
        zIndex: 10, // Ensure it is above other content
      }}
    >
      {isVisible && (
        <IconButton
          aria-label='Vrati se na vrh'
          icon={<ChevronUpIcon />}
          onClick={scrollToTop}
          color='white'
          bgColor={'blue.500'}
          size='lg'
          isRound={true}
        />
      )}
    </motion.div>
  );
};

export default ScrollToTopButton;
