import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useGetAllOrganizations } from '../../../../services/queries';
import { Fragment } from 'react';

export const AllOrganizationsTable = () => {
  //const { data: facultys, isLoading } = useGetFacultysWithPrograms();
  const toast = useToast();

  const { data: universities, isLoading } = useGetAllOrganizations();

  if (isLoading) {
    return (
      <Center h={'15vh'}>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack spacing={8} align='stretch'>
      {universities?.map((university, index) => (
        <Box key={university.id} pb={3}>
          <TableContainer borderRadius='xl' border='2px' borderColor='blue.700'>
            <Table variant='striped' colorScheme='facebook' size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign='center'>RB</Th>
                  <Th textAlign='center'>Univerzitet</Th>
                  <Th textAlign='center'>Skračenica</Th>
                  <Th textAlign='center'>opcije</Th>
                  <Th colSpan={3}></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr key={university.id}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td fontWeight={'700'} textAlign='center'>
                    {university.name}
                  </Td>
                  <Td textAlign='center'>{university.shortName}</Td>
                  <Td textAlign='center'>
                    <HStack spacing={5} justifyContent={'center'}>
                      <HStack spacing={0}>
                        <EditIcon
                          color={'white'}
                          boxSize={5}
                          ml='5px'
                          mr={'5px'}
                          onClick={() => {
                            toast({
                              position: 'top',
                              title: 'Ova funkcionalnost još nije implementirana',
                              status: 'warning',
                              duration: 3000,
                            });
                          }}
                          _hover={{ cursor: 'pointer', color: 'green.500' }}
                        />
                        <DeleteIcon
                          color={'white'}
                          boxSize={5}
                          onClick={() => {
                            toast({
                              position: 'top',
                              title: 'Ova funkcionalnost još nije implementirana',
                              status: 'warning',
                              duration: 3000,
                            });
                          }}
                          _hover={{ cursor: 'pointer', color: 'red.500' }}
                        />
                      </HStack>
                    </HStack>
                  </Td>
                  <Td colSpan={3}></Td>
                </Tr>
              </Tbody>
              {university.facultys.length === 0 && (
                <Tbody>
                  <Tr>
                    <Td colSpan={6}>
                      <Center>Nema fakulteta </Center>
                    </Td>
                  </Tr>
                </Tbody>
              )}
              {university.facultys.map((faculty, index) => (
                <Fragment key={`faculty-${faculty.id}`}>
                  <Thead key={`faculty-thead-${faculty.id}`}>
                    <Tr>
                      <Th></Th>
                      <Th textAlign='center'>RB</Th>
                      <Th textAlign='center'>Fakultet</Th>
                      <Th textAlign='center'>Skračenica</Th>
                      <Th textAlign='center'>opcije</Th>
                      <Th colSpan={1}></Th>
                    </Tr>
                  </Thead>
                  <Tbody key={`faculty-tbody-${faculty.id}`}>
                    <Tr>
                      <Td></Td>
                      <Td textAlign='center'>{index + 1}</Td>
                      <Td fontWeight={'700'}>{faculty.name}</Td>
                      <Td textAlign='center'>{faculty.shortName}</Td>
                      <Td textAlign='center'>
                        <HStack spacing={5} justifyContent={'center'}>
                          <HStack spacing={0}>
                            <EditIcon
                              color={'white'}
                              boxSize={5}
                              ml='5px'
                              mr={'5px'}
                              onClick={() => {
                                toast({
                                  position: 'top',
                                  title: 'Ova funkcionalnost još nije implementirana',
                                  status: 'warning',
                                  duration: 3000,
                                });
                              }}
                              _hover={{ cursor: 'pointer', color: 'green.500' }}
                            />
                            <DeleteIcon
                              color={'white'}
                              boxSize={5}
                              onClick={() => {
                                toast({
                                  position: 'top',
                                  title: 'Ova funkcionalnost još nije implementirana',
                                  status: 'warning',
                                  duration: 3000,
                                });
                              }}
                              _hover={{ cursor: 'pointer', color: 'red.500' }}
                            />
                          </HStack>
                        </HStack>
                      </Td>
                      <Td colSpan={1}></Td>
                    </Tr>
                  </Tbody>
                  <Thead key={index + 599}>
                    <Tr>
                      <Th colSpan={2}></Th>
                      <Th textAlign='center'>RB</Th>
                      <Th>Studijski Program</Th>
                      <Th textAlign='center'>Skraćenica</Th>
                      <Th>Opcije</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {faculty.programs.length === 0 ? (
                      <Tr key={`no-program-${faculty.id}`}>
                        <Td colSpan={6}>
                          <Center>Nema studijskih programa</Center>
                        </Td>
                      </Tr>
                    ) : (
                      faculty.programs.map((program, programIndex) => (
                        <Tr key={`program-thead-${program.id}`}>
                          <Td colSpan={2}></Td>
                          <Td textAlign='center'>{programIndex + 1}</Td>
                          <Td fontWeight={'700'}>{program.name}</Td>
                          <Td textAlign='center'>{program.shortName}</Td>
                          <Td>
                            <HStack spacing={5}>
                              <HStack spacing={0}>
                                <EditIcon
                                  color={'white'}
                                  boxSize={5}
                                  ml='5px'
                                  mr={'5px'}
                                  onClick={() => {
                                    toast({
                                      position: 'top',
                                      title: 'Ova funkcionalnost još nije implementirana',
                                      status: 'warning',
                                      duration: 3000,
                                    });
                                  }}
                                  _hover={{ cursor: 'pointer', color: 'green.500' }}
                                />
                                <DeleteIcon
                                  color={'white'}
                                  boxSize={5}
                                  onClick={() => {
                                    toast({
                                      position: 'top',
                                      title: 'Ova funkcionalnost još nije implementirana',
                                      status: 'warning',
                                      duration: 3000,
                                    });
                                  }}
                                  _hover={{ cursor: 'pointer', color: 'red.500' }}
                                />
                              </HStack>
                            </HStack>
                          </Td>
                        </Tr>
                      ))
                    )}
                  </Tbody>
                  <Tbody>
                    <Tr key={faculty.id} height={'30px'}>
                      <Td position='relative' textAlign='center' colSpan={6}>
                        <Divider borderColor={'white'} borderWidth='3px' />
                      </Td>
                    </Tr>
                  </Tbody>
                </Fragment>
              ))}
            </Table>
          </TableContainer>
        </Box>
      ))}
    </VStack>
  );
};
