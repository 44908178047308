import {
  Center,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useGetSingleExam } from '../../../services/queries';
import { SingleExam } from './comp/Exam';
import { ExamResultsTable } from './comp/ExamResultsTable';
import { useChangeTitle } from '../../../hooks/useChangeTitle.hook';

type Props = {};

export const ExamResults = (props: Props) => {
  const { examID } = useParams();
  const navigate = useNavigate();

  const exam = useGetSingleExam(Number(examID));

  useChangeTitle(exam.data?.name ?? 'Rezultati ispita');

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
      pos={'relative'}
    >
      <Stack pos={'absolute'} top={'20px'} left={{ base: 5 }}>
        <ArrowBackButton
          onClick={() => {
            navigate('/profesori/ispiti/');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Rezultati ispita
        </Heading>
      </HStack>

      {exam.isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : exam.data ? (
        <SingleExam exam={exam.data} />
      ) : (
        <Text>Nedostupni podaci</Text>
      )}

      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5} pt={10}>
          Prijavljeni studenti
        </Heading>
      </HStack>

      <ExamResultsTable maxScore={exam.data?.maxScore ?? 0} />
    </Flex>
  );
};
