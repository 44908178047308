import {
  Avatar,
  Center,
  HStack,
  Input,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import {
  useConfirmStudentExamScheduleResults,
  useDeleteStudentExamScheduleResults,
} from '../../../../services/mutations';
import { useGetExamScheduleResults } from '../../../../services/queries';
import { RedButton } from '../../../../components/general/RedButton';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

interface LoadingStates {
  [key: number]: boolean;
}

export const ExamScheduleResultsTable = () => {
  const { examScheduleID } = useParams();
  const [filter, setFilter] = useState('');

  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

  const scheduleResults = useGetExamScheduleResults(Number(examScheduleID));
  const confirmResults = useConfirmStudentExamScheduleResults(Number(examScheduleID));
  const deleteResutls = useDeleteStudentExamScheduleResults(Number(examScheduleID));

  const filteredStudents = scheduleResults.data?.studentsArray.filter(
    (student) =>
      student.indexNumber.includes(filter) ||
      student.lastName.toLowerCase().includes(filter.toLowerCase()) ||
      student.firstName.toLowerCase().includes(filter.toLowerCase())
  );

  const columnNumber = scheduleResults.data?.examsArray.reduce(
    (acc, exam) => acc + exam.partialExams.length + 1,
    0
  );

  return (
    <>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center' }}
        align={'center'}
        spacing={2}
      >
        <Input
          w='250px'
          placeholder='Pretraži studente'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Stack>
      {scheduleResults.isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : scheduleResults.data ? (
        <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
          <Table variant='striped' colorScheme='facebook' size='sm'>
            <Thead>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>Ime i prezime</Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>broj</Text>
                    <Text>indexa</Text>
                  </VStack>
                </Th>

                {scheduleResults.data.examsArray.length > 0 &&
                  scheduleResults.data.examsArray.map((exam, index) => {
                    return (
                      <Fragment key={`exams-${index}`}>
                        {exam.partialExams.length > 0 &&
                          exam.partialExams.map((partialExam, index) => {
                            return (
                              <Th key={`partialExams-${index}`} textAlign='center'>
                                <VStack spacing={0}>
                                  <Text fontSize={'2xs'}>{partialExam.partialExamName}</Text>
                                  <Text>
                                    {partialExam.minScore} / {partialExam.maxScore} /{' '}
                                    {partialExam.difficultyFactor}
                                  </Text>
                                </VStack>
                              </Th>
                            );
                          })}

                        <Th textAlign='center'>
                          <VStack spacing={0}>
                            <Text>{exam.examName}</Text>
                            <Text>
                              {exam.minScore} / {exam.maxScore} / {exam.difficultyFactor}
                            </Text>
                          </VStack>
                        </Th>
                      </Fragment>
                    );
                  })}
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>Položeni</Text>
                    <Text>ispiti?</Text>
                  </VStack>
                </Th>
                <Th textAlign='center'>
                  <VStack spacing={0}>
                    <Text>Ukupan rezultat</Text>
                    <Text>sa težinskim faktorom</Text>
                  </VStack>
                </Th>
                <Th textAlign='center'>opcije</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredStudents && filteredStudents.length > 0 ? (
                filteredStudents.map((results, index) => (
                  <Tr key={`students-${results.studentID}`}>
                    <Td textAlign='center'>{index + 1}</Td>
                    <Td textAlign='center'>
                      <Avatar size={'sm'} src={results.profileImage} />
                    </Td>
                    <Td textAlign='center'>
                      <StudentNameSubjectStats
                        firstName={results.firstName}
                        lastName={results.lastName}
                        navigateLink={`/profesori/student/${results.studentID}`}
                        hasCompletedSubject={results.completedSubject}
                        repeater={results.repeater}
                      />
                    </Td>
                    <Td textAlign='center'>{results.indexNumber}</Td>

                    {results.scores.length > 0 &&
                      results.scores.map((score, index) => {
                        return (
                          <Fragment key={`scores-${index}`}>
                            {score.partialExams.length > 0 &&
                              score.partialExams.map((partialScore, index) => {
                                return (
                                  <Td key={`partialScores-${index}`} textAlign='center'>
                                    <VStack spacing={0}>
                                      {partialScore.registered ||
                                      (!partialScore.registered && partialScore.passed) ? (
                                        <Text
                                          fontWeight={'semibold'}
                                          color={partialScore.passed ? 'green.400' : 'red.500'}
                                        >
                                          {partialScore.score} / {partialScore.passedScoreDF}
                                        </Text>
                                      ) : (
                                        <Text>Nije prijavio</Text>
                                      )}
                                    </VStack>
                                  </Td>
                                );
                              })}

                            <Td textAlign='center'>
                              <VStack spacing={0}>
                                {score.registered ? (
                                  <Text
                                    fontWeight={'semibold'}
                                    color={score.passed ? 'green.400' : 'red.500'}
                                  >
                                    {score.score} / {score.passedScoreDF}
                                  </Text>
                                ) : (
                                  <Text>Nije prijavio</Text>
                                )}
                              </VStack>
                            </Td>
                          </Fragment>
                        );
                      })}
                    <Td textAlign='center'>
                      {results.passed ? (
                        <Center>
                          <Text
                            fontWeight={'700'}
                            color={'green.500'}
                            border={'2px solid'}
                            borderRadius={'xl'}
                            width={'40px'}
                          >
                            DA
                          </Text>
                        </Center>
                      ) : (
                        <Center>
                          <Text
                            fontWeight={'700'}
                            color={'red.500'}
                            border={'2px solid'}
                            borderRadius={'xl'}
                            width={'40px'}
                          >
                            NE
                          </Text>
                        </Center>
                      )}
                    </Td>
                    <Td>
                      <Center>
                        <Text> {results.passedScoreDF} </Text>
                      </Center>
                    </Td>
                    <Td textAlign='center'>
                      <HStack justify={'center'}>
                        {!results.confirmedResults ? (
                          <BlueButton
                            height='25px'
                            width='120px'
                            buttonText={'Potvrdi rezultate'}
                            onClick={async () => {
                              setLoadingStates((prevState) => ({
                                ...prevState,
                                [results.studentID]: true,
                              }));
                              try {
                                await confirmResults({
                                  examScheduleID: Number(examScheduleID),
                                  studentID: results.studentID,
                                });
                              } catch (error) {
                              } finally {
                                setLoadingStates((prevState) => ({
                                  ...prevState,
                                  [results.studentID]: false,
                                }));
                              }
                            }}
                            isDisabled={!results.passed}
                            isLoading={loadingStates[results.studentID]}
                          />
                        ) : (
                          <RedButton
                            height='25px'
                            width='120px'
                            buttonText='Poništi rezultat'
                            onClick={async () => {
                              setLoadingStates((prevState) => ({
                                ...prevState,
                                [results.studentID]: true,
                              }));
                              try {
                                await deleteResutls({
                                  examScheduleID: Number(examScheduleID),
                                  studentID: results.studentID,
                                });
                              } catch (error) {
                              } finally {
                                setLoadingStates((prevState) => ({
                                  ...prevState,
                                  [results.studentID]: false,
                                }));
                              }
                            }}
                            isLoading={loadingStates[results.studentID]}
                            isDisabled={results.completedSubject}
                          />
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={7 + (columnNumber ?? 0)}>
                    <Center>
                      <Text> Nema studenata </Text>
                    </Center>
                  </Td>
                </Tr>
              )}
            </Tbody>

            {filteredStudents && filteredStudents.length > 8 && (
              <Tfoot>
                <Tr>
                  <Th textAlign='center'>RB</Th>
                  <Th textAlign='center'>Slika</Th>
                  <Th textAlign='center'>Ime i prezime</Th>
                  <Th textAlign='center'>
                    <VStack spacing={0}>
                      <Text>broj</Text>
                      <Text>indexa</Text>
                    </VStack>
                  </Th>
                  <Th textAlign='center'>opcije</Th>
                  <Th textAlign='center'>
                    <VStack spacing={0}>
                      <Text>bodovi /</Text>
                      <Text>max. bodovi</Text>
                    </VStack>
                  </Th>
                </Tr>
              </Tfoot>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Text>Desila se greška</Text>
      )}
    </>
  );
};
