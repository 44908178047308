import { Outlet } from 'react-router-dom';
import SidebarNav from './comp/SidebarAndNav';

export const StudentLayout = () => {
  return (
    <SidebarNav>
      <Outlet />
    </SidebarNav>
  );
};
