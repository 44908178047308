import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Heading,
  IconButton,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, ReactText, useContext } from 'react';
import { FiBell, FiChevronDown, FiMenu } from 'react-icons/fi';
import { NavLink, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { AuthenticationContext } from '../../../auth/auth.context';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { useGetSubjectForStudents } from '../../../services/queries';

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH='100vh' bg={useColorModeValue('white', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>{children}</Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const popoverContentBgColor = useColorModeValue('black', 'white');
  const navigate = useNavigate();
  const { selectedSubjectID, setSelectedSubjectID } = useStudentContext();

  const getSubjectsQuery = useGetSubjectForStudents();

  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <CloseButton
        pos={'absolute'}
        top={5}
        left={5}
        display={{ base: 'flex', md: 'none' }}
        onClick={onClose}
        mr={'auto'}
      />
      <Flex h='20' alignItems='center' mx='8' justifyContent='center'>
        <Img
          h={'40px'}
          src={useColorModeValue('/LOGO W-01.svg', '/LOGO D-01.svg')}
          onClick={() => {
            onClose();
          }}
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <VStack spacing={'3'}>
        <Heading fontSize={'xl'}>Predmeti</Heading>
        {getSubjectsQuery.isLoading ? (
          <Spinner />
        ) : (
          getSubjectsQuery.data?.map((link) => (
            <Button
              fontSize='sm'
              w='80%'
              size='md'
              height={{ base: '60px', lg: link.name.length > 27 ? '60px' : '40px' }}
              bg={selectedSubjectID === link.id ? 'blue.600' : 'grey.800'}
              color={selectedSubjectID === link.id ? 'white' : popoverContentBgColor}
              border='2px'
              borderColor='blue.500'
              key={link.id}
              overflow={'hidden'}
              whiteSpace={'normal'}
              lineHeight={'normal'}
              onClick={async () => {
                setSelectedSubjectID(link.id);
                navigate('/student');
                onClose();
              }}
            >
              {link.name}
            </Button>
          ))
        )}
        {getSubjectsQuery.data?.length === 0 && <Text>Nema predmeta</Text>}
        <Divider />
        <Button
          fontSize='m'
          w='80%'
          size='md'
          height='40px'
          border='2px'
          borderColor='blue.500'
          bg={useColorModeValue('white', 'grey.800')}
          onClick={() => {
            navigate('/student/novi-predmet/opcije');
            onClose();
          }}
        >
          Novi predmet
        </Button>
        <Button
          fontSize='m'
          w='80%'
          size='md'
          height='40px'
          border='2px'
          borderColor='blue.500'
          bg={useColorModeValue('white', 'grey.800')}
          onClick={() => {
            navigate('/student/polozeni-predmeti');
            onClose();
          }}
        >
          Položeni predmeti
        </Button>
      </VStack>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  children: ReactText;
}
const NavItem = ({ children, ...rest }: NavItemProps) => {
  return (
    <Link href='#' style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

export const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const authContext = useContext(AuthenticationContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  const logout = () => {
    authContext?.logout();
    navigate('/');
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <HStack>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant='outline'
          aria-label='open menu'
          icon={<FiMenu />}
        />
        <Button size={'sm'} rounded={'20'} onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
      </HStack>
      <Img
        display={{ base: 'flex', md: 'none' }}
        h={'30px'}
        src={useColorModeValue('/LOGO W-01.svg', '/LOGO D-01.svg')}
        onClick={() => {
          navigate('/student');
        }}
        _hover={{ cursor: 'pointer' }}
      />

      <Flex display={{ base: 'none', md: 'flex' }} mr={'auto'}>
        <DesktopNav />
      </Flex>

      <HStack spacing={{ base: '0', md: '2' }}>
        <IconButton size='lg' variant='ghost' aria-label='open menu' icon={<FiBell />} />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar size={'sm'} src={authContext?.user?.profileImage} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm'>
                    {authContext?.user?.firstName + ' ' + authContext?.user?.lastName}
                  </Text>
                  <Text fontSize='xs' color='gray.600'>
                    {authContext?.user?.title}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem
                onClick={() => {
                  navigate('/student/profil');
                }}
              >
                Profil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate('/student/moj-qr-code');
                }}
              >
                Moj QR Code
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout}>Odjavi se</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const DesktopNavLink = ({ navItem }: { navItem: NavItem }) => {
  const resolved = useResolvedPath(navItem.href);
  const match = useMatch({ path: resolved.pathname, end: navItem.href === '' });

  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('black', 'white');

  return (
    <Box key={navItem.label}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <Link
            as={NavLink}
            m={2}
            to={navItem.href}
            fontSize={'sm'}
            fontWeight={500}
            color={linkColor}
            _hover={{
              textDecoration: 'none',
              color: linkHoverColor,
            }}
            style={{
              borderBottom: match ? `2px solid ${popoverContentBgColor}` : undefined,
              paddingBottom: match ? '1px' : undefined,
            }}
          >
            {navItem.label}
          </Link>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};

const DesktopNav = () => {
  return (
    <Stack direction={'row'} spacing={4} align={'center'}>
      {NAV_ITEMS.map((navItem) => (
        <DesktopNavLink key={navItem.label} navItem={navItem} />
      ))}
    </Stack>
  );
};

interface NavItem {
  label: string;
  href: string;
}

const NAV_ITEMS: Array<NavItem> = [];
