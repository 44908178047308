import React from 'react';
import Select, { StylesConfig, ActionMeta, SingleValue } from 'react-select';
import { useField, useFormikContext } from 'formik';
import {
  Box,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

interface OptionType {
  value: string | number;
  label: string;
}

interface SearchableSelectProps {
  name: string;
  options: OptionType[];
  placeholder: string;
  label: string;
  onChange?: (value: string | number) => void;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  name,
  options,
  placeholder,
  label,
  onChange,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  // Define colors based on the color mode
  const bgColor = useColorModeValue('white', '#2D3748');
  const textColor = useColorModeValue('black', 'white');
  const borderColor = useColorModeValue('#2b6cb0', 'white');
  const hoverBorderColor = useColorModeValue('#2b6cb0', 'white');
  const hoverTextColor = useColorModeValue('white', 'black');

  const customStyles: StylesConfig<OptionType, false> = {
    control: (base) => ({
      ...base,
      backgroundColor: bgColor,
      color: textColor,
      borderColor: borderColor,
      '&:hover': {
        borderColor: hoverBorderColor,
        color: hoverTextColor,
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: textColor,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: bgColor,
      zIndex: 1000,
    }),
    option: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused ? hoverBorderColor : bgColor,
      color: isFocused ? hoverTextColor : textColor,
      '&:hover': {
        borderColor: hoverBorderColor,
        backgroundColor: hoverBorderColor,
        color: hoverTextColor,
      },
    }),
    input: (base) => ({
      ...base,
      color: textColor,
    }),
  };

  const handleChange = (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (newValue) {
      setFieldValue(name, newValue.value);
      if (onChange) {
        onChange(newValue.value);
      }
    } else {
      setFieldValue(name, ''); // Clear the current field value
    }
  };

  return (
    <FormControl isInvalid={!!meta.error && meta.touched} id={name}>
      <VStack align='start'>
        <FormLabel>{label}</FormLabel>
        <Box w='full'>
          <Select
            styles={customStyles}
            options={options}
            value={options.find((option) => option.value === field.value)}
            onChange={handleChange}
            placeholder={placeholder}
            isClearable
          />
        </Box>
        {meta.error && meta.touched && (
          <HStack w={'100%'} justify={'center'}>
            <Text color='red.400' fontSize='md'>
              {meta.error}
            </Text>
          </HStack>
        )}
      </VStack>
    </FormControl>
  );
};

export default SearchableSelect;
