import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';
import useProf from '../../../../hooks/useProf.hooks';
import { useCreateGroupMutation } from '../../../../services/mutations';
import { useGetAssistantsByGroup, useGetExercisesInfo } from '../../../../services/queries';
import { createGroupSchema } from '../../../../validation/exercise-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateGroupModal = ({ isOpen, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();

  const exercisesInfoQuery = useGetExercisesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const createGroup = useCreateGroupMutation(
    exercisesInfoQuery.data?.id ?? null,
    exercisesInfoQuery.data?.academicYearID ?? null
  );

  return (
    <>
      <Modal isCentered size={'md'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Dodaj novu grupu</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Formik
              initialValues={{
                name: '',
                academicYearID: exercisesInfoQuery.data?.academicYearID ?? 0,
                exerciseInfoID: exercisesInfoQuery.data?.id ?? 0,
              }}
              validationSchema={createGroupSchema}
              onSubmit={(values, actions) => {
                console.log(values);
                setIsLoading(true);
                createGroup(values)
                  .catch((error) => {})
                  .finally(() => {
                    setTimeout(() => {
                      setIsLoading(false);
                      onClose();
                    }, 1000);
                  });
              }}
            >
              {() => (
                <Form>
                  <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                    <HorizontalTextField name='name' type='text' label='Ime Grupe' width='200px' />
                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Kreiraj grupu'
                        loadingText='Grupa se kreira'
                        isLoading={isLoading}
                      />
                      <RedButton buttonText='Odustani' onClick={onClose} />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
