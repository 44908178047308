import {
  Avatar,
  Center,
  HStack,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import {
  useAssignAllStudentsToGroup,
  useAssignStudentToGroup,
} from '../../../../services/mutations';
import { useGetExercisesInfo, useGetStudentsWithNoGroup } from '../../../../services/queries';

interface LoadingStates {
  [key: number]: boolean;
}

export const StudentNoGroupTable = () => {
  const { groupID } = useParams();
  const profContext = useProf();
  const authContext = useAuth();
  const [filter, setFilter] = useState('');
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const toast = useToast();

  const exerciseInfoQuery = useGetExercisesInfo({
    yearID: profContext.selectedAcademicYearID,
    subjectID: profContext.selectedSubjectID,
  });

  const students = useGetStudentsWithNoGroup({
    subjectID: profContext.selectedSubjectID,
    academicYearID: exerciseInfoQuery.data?.academicYearID,
  });

  const assignStudent = useAssignStudentToGroup(
    profContext.selectedSubjectID,
    exerciseInfoQuery.data?.academicYearID,
    Number(groupID)
  );

  const assignAllStudents = useAssignAllStudentsToGroup(
    profContext.selectedSubjectID,
    exerciseInfoQuery.data?.academicYearID,
    Number(groupID)
  );

  const handleAssignStudent = (studentID: number) => {
    if (exerciseInfoQuery.data?.academicYearID && groupID && studentID) {
      setLoadingStates((prev) => ({ ...prev, [studentID]: true }));
      assignStudent({
        groupID: Number(groupID),
        studentID: studentID,
        academicYearID: exerciseInfoQuery.data?.academicYearID,
        subjectID: profContext.selectedSubjectID,
      })
        .catch((error) => {})
        .finally(() => {
          setFilter('');
          setTimeout(() => {
            setLoadingStates((prev) => ({ ...prev, [studentID]: false }));
          }, 1000);
        });
    }
  };

  const handleAssignAllStudents = () => {
    assignAllStudents(Number(groupID)).catch((error) => {});
  };

  const filteredStudents = students.data?.filter(
    (student) =>
      student.indexNumber.includes(filter) ||
      student.lastName.toLowerCase().includes(filter.toLowerCase()) ||
      student.firstName.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <HStack w='100%' h='45' justify={'center'} align={'center'}>
        <Input
          w='210px'
          placeholder='Pretraži studente'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <BlueButton
          buttonText='Ubaci sve studente'
          onClick={() => {
            handleAssignAllStudents();
            setFilter('');
          }}
        />
      </HStack>

      <TableContainer mt={'3rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>B.Indexa</Th>
              <Th textAlign='center'>Ime i Prezime</Th>
              <Th textAlign='center'>Ubaci</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={4}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : filteredStudents && filteredStudents.length > 0 ? (
              filteredStudents.map((student) => (
                <Tr key={student.id}>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={student.profileImage} />
                  </Td>
                  <Td textAlign='center'>{student.indexNumber}</Td>
                  <Td textAlign='center'>
                    {student.firstName} {student.lastName}
                  </Td>
                  <Td textAlign='center'>
                    <BlueButton
                      width='50px'
                      height='25px'
                      buttonText='ubaci'
                      onClick={() => {
                        handleAssignStudent(student.id);
                      }}
                      isLoading={loadingStates[student.id]}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Center>Nema studenata bez grupe</Center>
                </Td>
              </Tr>
            )}
          </Tbody>
          {students.data && students.data.length > 10 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>B.Indexa</Th>
                <Th textAlign='center'>Ime i Prezime</Th>
                <Th textAlign='center'>Ubaci</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
