import { ReactNode, useContext } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  useColorMode,
  Stack,
  Popover,
  PopoverTrigger,
  Heading,
  Img,
} from '@chakra-ui/react';
import { FiMenu, FiBell, FiChevronDown } from 'react-icons/fi';
import { ReactText } from 'react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { NewSubjectStepper } from './Stepper';
import { AuthenticationContext } from '../../../auth/auth.context';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';

interface LinkItemProps {
  id: string;
  name: string;
}
const LinkItems: Array<LinkItemProps> = [{ id: 'p1', name: 'Osnove Baza Podataka' }];

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>{children}</Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const navigate = useNavigate();
  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <CloseButton
        pos={'absolute'}
        top={5}
        left={5}
        display={{ base: 'flex', md: 'none' }}
        onClick={onClose}
        mr={'auto'}
      />
      <Flex h='20' alignItems='center' mx='8' justifyContent='center'>
        <Img
          h={'50px'}
          src={useColorModeValue('/ISEUS LOGO-02.png', '/ISEUS LOGO-03.png')}
          onClick={() => {
            navigate('/profesori');
          }}
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <VStack pt={'20px'} spacing={'3'}>
        <Heading pb={'20px'} fontSize={'xl'}>
          Novi predmet
        </Heading>
        {
          //      **** STEPPER ****
        }
        <NewSubjectStepper />
      </VStack>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  children: ReactText;
}
const NavItem = ({ children, ...rest }: NavItemProps) => {
  return (
    <Link href='#' style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const authContext = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const logout = () => {
    authContext?.logout();
    navigate('/');
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <Stack
        display={{ base: 'flex', md: 'none' }}
        pos={{ base: 'absolute', md: 'static' }}
        top={'100px'}
        left={5}
      >
        <ArrowBackButton
          onClick={() => {
            navigate('/profesori');
          }}
        />
      </Stack>
      <HStack>
        <IconButton
          display={{ base: 'none', md: 'none' }}
          onClick={onOpen}
          variant='outline'
          aria-label='open menu'
          icon={<FiMenu />}
        />
        <Button
          display={{ base: 'flex', md: 'none' }}
          size={'sm'}
          rounded={'20'}
          onClick={toggleColorMode}
        >
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
      </HStack>
      <Img
        display={{ base: 'flex', md: 'none' }}
        h={'35px'}
        src={useColorModeValue('/ISEUS LOGO-02.png', '/ISEUS LOGO-03.png')}
        onClick={() => {
          navigate('/');
        }}
        _hover={{ cursor: 'pointer' }}
      />

      <HStack spacing={{ base: '0', md: '2' }}>
        <Button
          display={{ base: 'none', md: 'flex' }}
          size={'sm'}
          rounded={'20'}
          onClick={toggleColorMode}
        >
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>

        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar size={'sm'} src={authContext?.user?.profileImage} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm'>
                    {authContext?.user?.firstName + ' ' + authContext?.user?.lastName}
                  </Text>
                  <Text fontSize='xs' color='gray.600'>
                    {authContext?.user?.title}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem
                onClick={() => {
                  navigate('/profesori/profil');
                }}
              >
                Profil
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout}>Odjavi se</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4} align={'center'}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                as={NavLink}
                end
                m={2}
                to={navItem.href}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
                _activeLink={{ borderBottom: '2px solid white', paddingBottom: '1px' }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

interface NavItem {
  label: string;
  href: string;
}

const NAV_ITEMS: Array<NavItem> = [
  /* {
    label: 'Nastavnici',
    href: '',
  },
   */
];
