import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { ExerciseGroup } from '../../../../models/subject';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Avatar,
  Box,
  Card,
  Center,
  Divider,
  HStack,
  Heading,
  Icon,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAuth from '../../../../hooks/useAuth.hooks';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeleteGroupMutation } from '../../../../services/mutations';
import { useGetExercisesInfo, useGetSubjectActivitiesInfo } from '../../../../services/queries';
import useProf from '../../../../hooks/useProf.hooks';
import { useState } from 'react';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import { SubjectActivitiesInfo } from '../../../../models/subjectActivities';
import { ProfessorAssistent } from '../../../../models/professorAssistent';

type Props = {
  group: {
    id: number;
    groupName: string;
    difficultyFactorUsed: number;
    numberOfSubjectActivities: number;
    assistants: ProfessorAssistent[];
  };
  difficultyFactor: number;
};

export const SubjectActivitieGroups = ({ group, difficultyFactor }: Props) => {
  const [isDelGroupModalOpen, setIsDelGroupModalOpen] = useState(false);
  const authContext = useAuth();
  const profContext = useProf();
  const navigate = useNavigate();
  const toast = useToast();

  const subjectActivitiesQuery = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  return (
    <Box position='relative'>
      <Card
        align={'center'}
        variant={'outline'}
        w={'300px'}
        h={'290px'}
        bg={useColorModeValue('white', 'gray.900')}
      >
        <VStack justify={'center'} w={'100%'} align={'center'} fontSize={'sm'}>
          <VStack pt='10px'>
            <Text>Ime grupe</Text>
            <Text fontWeight={'700'} fontSize={'lg'}>
              {group.groupName}
            </Text>
          </VStack>
          <Center w={'100%'}>
            <Divider orientation='horizontal' />
          </Center>
          <HStack>
            <Text>Raspoloživi težinski faktor: </Text>
            <Text fontWeight={'700'} fontSize={'md'}>
              {difficultyFactor - group.difficultyFactorUsed} / {difficultyFactor}
            </Text>
          </HStack>
          <VStack>
            <HStack>
              <Text>Broj kreiranih aktivnosti </Text>
              <Text fontWeight={'700'} fontSize={'md'}>
                {group.numberOfSubjectActivities}
              </Text>
            </HStack>
          </VStack>
          <Center w={'100%'}>
            <Divider orientation='horizontal' />
          </Center>
          <VStack w='20%'>
            <Heading fontSize={'sm'}>Asistenti:</Heading>
            <HStack>
              {group.assistants.map((assistant) => (
                <Tooltip key={assistant.id} label={assistant.firstName + ' ' + assistant.lastName}>
                  <Avatar
                    size={'sm'}
                    name={assistant.firstName + ' ' + assistant.lastName}
                    src={assistant.profileImage}
                  />
                </Tooltip>
              ))}
            </HStack>
          </VStack>
          <Center w={'100%'}>
            <Divider orientation='horizontal' />
          </Center>
          <HStack align={'center'} py='10px'>
            <BlueButton
              width='130px'
              height={'25px'}
              buttonText='Otvori aktivnosti'
              onClick={() => {
                navigate(`grupa/${group.id}`);
              }}
            />
          </HStack>
        </VStack>
      </Card>
    </Box>
  );
};
