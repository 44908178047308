import { HStack, Select, Spinner } from '@chakra-ui/react';
import { UseQueryResult } from '@tanstack/react-query';
import { ProfContext } from '../../@types/profContext.types';
import { AcademicYear } from '../../models/subject';

type Props = {
  years: UseQueryResult<AcademicYear[], Error>;
  handleOnChangeAcademicYear: (e: any) => void;
  profContext: ProfContext;
};

export const AcademicYearSelectionField = ({
  years,
  handleOnChangeAcademicYear,
  profContext,
}: Props) => {
  return (
    <>
      {years.isLoading ? (
        <HStack w={'100%'} justify={'center'}>
          <Spinner />
        </HStack>
      ) : (
        years.data &&
        years.data.length > 0 && (
          <Select
            w={{ base: '95%', sm: '263px', md: '263px' }}
            id={'AcademicYearSelector'}
            colorScheme='blue'
            onChange={handleOnChangeAcademicYear}
            value={profContext.selectedAcademicYearID}
          >
            {years.data.reverse().map((year: AcademicYear) => (
              <option key={year.id} value={year.id}>
                {year.currentYear ? `${year.year} - tekuća` : year.year}
              </option>
            ))}
          </Select>
        )
      )}
    </>
  );
};
