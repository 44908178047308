import {
  Button,
  HStack,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  Box,
  keyframes,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  text?: string | null;
  navigateTo?: string;
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const SuccessfullScanModal = ({ isOpen, onClose, text: modalText, navigateTo }: Props) => {
  const [showSpinner, setShowSpinner] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => setShowSpinner(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        isCentered
        size={'xs'}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate('/student/predavanja');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Uspješno Skeniranje</ModalHeader>

          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            {showSpinner ? (
              <Spinner size='xl' color='green.500' />
            ) : (
              <Box pb={5} animation={`${fadeIn} 1s ease-in-out`}>
                <CheckIcon color='green.500' w={10} h={10} mb={4} />
                <Text fontSize='xl' mb={4}>
                  {modalText ?? 'Prisustvo je uspješno evidentirano.'}
                </Text>
                <Button
                  colorScheme='green'
                  onClick={() => {
                    onClose();
                    navigate('/student/predavanja');
                  }}
                >
                  U redu
                </Button>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
