import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  VStack,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { RedButton } from '../../../../components/general/RedButton';
import { useStudentContext } from '../../../../hooks/useStudent.hooks';
import { StudentExamPreview } from '../../../../models/exams';
import {
  useCancleExamRegistration,
  useStudentExamRegistration,
  useStudentNullifyExamResults,
} from '../../../../services/mutations';
import { PartialExam } from './PartialExam';

type Props = {
  studentExamPreview: StudentExamPreview;
};

export function StudentsExamPreviews({ studentExamPreview }: Props) {
  const studsContext = useStudentContext();
  const navigate = useNavigate();
  const colorScheme = useColorModeValue('gray.100', 'gray.900');
  const [examRegisterLoading, setExamRegisterLoading] = useState(false);
  const [cancleExamRegistrationLoading, setCancleExamRegistrationLoading] = useState(false);
  const [toggleNullifyExamLoading, setToggleNullifyExamLoading] = useState(false);

  const examRegistration = useStudentExamRegistration(studsContext.selectedSubjectID);
  const cancleExamRegistration = useCancleExamRegistration(studsContext.selectedSubjectID);
  const toggleNullifyExamResults = useStudentNullifyExamResults(studsContext.selectedSubjectID);

  return (
    <Card
      w={'100%'}
      p={{ base: 2, md: 4 }}
      align={'center'}
      variant={'outline'}
      bg={colorScheme}
      border={
        studentExamPreview.passed
          ? `2px solid #6E905F`
          : studentExamPreview.registered
          ? `2px solid #A53434`
          : ''
      }
    >
      <Box as={Container} maxW='100%' p={{ base: 1, md: 4 }}>
        <Stack pos={{ base: 'absolute', md: 'static' }} top={1} right={3}>
          {studentExamPreview.nullified ? (
            <Text color={'red.400'} fontWeight={'bold'}>
              Poništen
            </Text>
          ) : (
            ''
          )}
        </Stack>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          gap={5}
        >
          <GridItem colSpan={1}>
            <VStack alignItems={{ base: 'center', md: 'start' }} spacing='5px'>
              <chakra.h2 fontSize='2xl' fontWeight='700'>
                {studentExamPreview.name}
              </chakra.h2>
              <VStack spacing={0}>
                <Stack
                  w={'100%'}
                  direction={'row'}
                  justify={{ base: 'center', md: 'start' }}
                  spacing={1}
                >
                  <BlueButton
                    fontSize='xs'
                    height='25px'
                    width='100px'
                    buttonText='Detalji'
                    isDisabled={true}
                    onClick={() => {
                      /* navigate(`/student/exams/${studentExamPreview.id}`); */
                    }}
                  />

                  {studentExamPreview.registrationOver ? (
                    studentExamPreview.passed ? (
                      !studentExamPreview.nullificationOver ? (
                        studentExamPreview.nullified ? (
                          <BlueButton
                            fontSize='xs'
                            height='25px'
                            width='120px'
                            buttonText={'Otkaži poništavanje'}
                            isLoading={toggleNullifyExamLoading}
                            isDisabled={studentExamPreview.passedEarlier}
                            onClick={async () => {
                              try {
                                setToggleNullifyExamLoading(true);
                                await toggleNullifyExamResults({ examID: studentExamPreview.id });
                              } catch (error) {
                              } finally {
                                setToggleNullifyExamLoading(false);
                              }
                            }}
                          />
                        ) : (
                          <RedButton
                            fontSize='xs'
                            height='25px'
                            width='100px'
                            buttonText='Poništi ispit'
                            isLoading={toggleNullifyExamLoading}
                            isDisabled={studentExamPreview.passedEarlier}
                            onClick={async () => {
                              try {
                                setToggleNullifyExamLoading(true);
                                await toggleNullifyExamResults({ examID: studentExamPreview.id });
                              } catch (error) {
                              } finally {
                                setToggleNullifyExamLoading(false);
                              }
                            }}
                          />
                        )
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )
                  ) : studentExamPreview.registered ? (
                    <RedButton
                      fontSize='xs'
                      height='25px'
                      width='100px'
                      buttonText='Odjavi ispit'
                      isLoading={cancleExamRegistrationLoading}
                      isDisabled={
                        studentExamPreview.registrationOver || studentExamPreview.passedEarlier
                      }
                      onClick={async () => {
                        try {
                          setCancleExamRegistrationLoading(true);
                          await cancleExamRegistration({ examID: studentExamPreview.id });
                        } catch (error) {
                        } finally {
                          setCancleExamRegistrationLoading(false);
                        }
                      }}
                    />
                  ) : (
                    <BlueButton
                      fontSize='xs'
                      height='25px'
                      width='100px'
                      buttonText={'Prijavi ispit'}
                      isLoading={examRegisterLoading}
                      isDisabled={studentExamPreview.registrationOver || studentExamPreview.passed}
                      onClick={async () => {
                        try {
                          setExamRegisterLoading(true);
                          await examRegistration({ examID: studentExamPreview.id });
                        } catch (error) {
                        } finally {
                          setExamRegisterLoading(false);
                        }
                      }}
                    />
                  )}
                </Stack>
                {studentExamPreview.passedEarlier && (
                  <Text fontSize='sm' color='red.400'>
                    Položili ste ispit ranije.
                  </Text>
                )}
              </VStack>
            </VStack>
          </GridItem>
          <GridItem colSpan={2}>
            <Grid
              templateColumns={{
                base: 'repeat(5, 1fr)',
                md: 'repeat(5, 1fr)',
              }}
              gap={{ base: '1', md: '5' }}
            >
              <GridItem
                colSpan={{
                  base: 3,
                }}
              >
                <VStack align={{ base: 'start', md: 'center' }}>
                  <VStack align={'start'} spacing={0}>
                    <HStack>
                      <Text fontSize={'xs'}>Tip ispita:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {studentExamPreview.type}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text fontSize={'xs'}>
                        {studentExamPreview.partialExamNumber > 0
                          ? 'Broj parcijala:'
                          : 'Ispit nema parcijala'}{' '}
                      </Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {studentExamPreview.partialExamNumber > 0
                          ? studentExamPreview.partialExamNumber
                          : ''}
                      </Text>
                    </HStack>
                    <HStack>
                      {studentExamPreview.conditionExamName ? (
                        <>
                          <Text fontSize={'xs'}>Uslov Ispita:</Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {studentExamPreview.conditionExamName}
                          </Text>
                        </>
                      ) : (
                        <Text fontSize={'xs'}>Nema uslovnog ispita</Text>
                      )}
                    </HStack>
                    <VStack align={'start'} spacing={0} pt={2}>
                      {!studentExamPreview.sameGroupsDateTime ? (
                        <>
                          <Text fontSize={'xs'}>Vrijeme i mjesto ispita:</Text>
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            Grupa {studentExamPreview.groupName}
                          </Text>
                          {studentExamPreview.groupDateTime && studentExamPreview.groupPlace ? (
                            <Text fontSize={'xs'} fontWeight={'bold'}>
                              {studentExamPreview.groupDateTime} u {studentExamPreview.groupPlace}
                            </Text>
                          ) : (
                            <Text fontSize={'xs'} fontWeight={'bold'}>
                              Nije zakazano
                            </Text>
                          )}
                        </>
                      ) : (
                        <>
                          <Text fontSize={'xs'}>Vrijeme i mjesto ispita:</Text>
                          <Text fontSize={'xs'}>
                            {studentExamPreview.dateTime} u {studentExamPreview.place}
                          </Text>
                        </>
                      )}
                    </VStack>
                  </VStack>
                </VStack>
              </GridItem>
              <GridItem
                colSpan={{
                  base: 2,
                }}
              >
                <VStack w={'2fr'} align={'center'}>
                  <VStack align={'start'} spacing={0}>
                    <HStack>
                      <Text fontSize={'xs'}>Broj bodova:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {studentExamPreview.studentScore} / {studentExamPreview.maxScore}
                      </Text>
                    </HStack>

                    <HStack>
                      <Text fontSize={'xs'}>Prijavljen:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {studentExamPreview.registered ? 'DA' : 'NE'}
                      </Text>
                    </HStack>

                    <HStack>
                      <Text fontSize={'xs'}>Prisustvo:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {studentExamPreview.attendence ? 'DA' : 'NE'}
                      </Text>
                    </HStack>

                    <HStack>
                      <Text fontSize={'xs'}>Položen:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {studentExamPreview.passed ? 'DA' : 'NE'}
                      </Text>
                    </HStack>
                    {/*    {studentExamPreview.passed ? (
                      <VStack align={'start'} spacing={0}>
                        <Text fontSize={'xs'}>Rok poništavanja:</Text>

                        <Text fontSize={'xs'} fontWeight={'bold'}>
                          {studentExamPreview.nullificationDeadline}
                        </Text>
                      </VStack>
                    ) : (
                      <VStack align={'start'} spacing={0}>
                        <Text fontSize={'xs'}>Rok prijave:</Text>

                        {studentExamPreview.registrationOver ? (
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            Istekao
                          </Text>
                        ) : (
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {studentExamPreview.registrationDeadline}
                          </Text>
                        )}
                      </VStack>
                    )} */}
                    {!studentExamPreview.registrationOver && (
                      <VStack align={'start'} spacing={0}>
                        <Text fontSize={'xs'}>Rok prijave:</Text>
                        {studentExamPreview.registrationOver ? (
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            Istekao
                          </Text>
                        ) : (
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {studentExamPreview.registrationDeadline}
                          </Text>
                        )}
                      </VStack>
                    )}
                    {studentExamPreview.registrationOver && (
                      <VStack align={'start'} spacing={0}>
                        <Text fontSize={'xs'}>Rok Poništavanja:</Text>
                        {studentExamPreview.nullificationOver ? (
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            Istekao
                          </Text>
                        ) : (
                          <Text fontSize={'xs'} fontWeight={'bold'}>
                            {studentExamPreview.nullificationDeadline}
                          </Text>
                        )}
                      </VStack>
                    )}
                  </VStack>
                </VStack>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        {studentExamPreview.partialExamNumber > 0 &&
          studentExamPreview.partialExams &&
          studentExamPreview.partialExams.length > 0 && (
            <>
              <Divider my={10} />
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  lg: 'repeat(2, 1fr)',
                }}
                gap={{ base: '8', sm: '12', md: '16' }}
              >
                {studentExamPreview.partialExams.map((partialExam) => (
                  <PartialExam
                    key={`partial-exam-preview-${partialExam.id}`}
                    partialExam={partialExam}
                  />
                ))}
              </Grid>
            </>
          )}
      </Box>
    </Card>
  );
}
