import { Flex, HStack, Heading, Spinner, Stack, VStack, useColorModeValue } from '@chakra-ui/react';
import useAuth from '../../../hooks/useAuth.hooks';
import useProf from '../../../hooks/useProf.hooks';
import {
  useGetAssistantsOnSubjectQuery,
  useGetProfessorsOnSubjectQuery,
  useGetStaffWantsToJoinQuery,
} from '../../../services/queries';
import ProfessorsAndAsistents from './comp/ProfessorsAndAsistents';
import ProfessorsWantToJoin from './comp/ProfessorsWantToJoin';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const Professors = () => {
  const navigate = useNavigate();
  const profContext = useProf();
  const { subjects } = useProf();
  const authContext = useAuth();
  const professorsOnSubject = useGetProfessorsOnSubjectQuery(profContext.selectedSubjectID);
  const assistantsOnSubject = useGetAssistantsOnSubjectQuery(profContext.selectedSubjectID);
  const staffWantsToJoin = useGetStaffWantsToJoinQuery(profContext.selectedSubjectID);

  useEffect(() => {
    if (subjects.length === 0) {
      navigate('/profesori/dobrodosli');
    }
  }, [subjects, navigate]);

  return (
    <Flex justify={'center'} p='5' bg={useColorModeValue('gray.100', 'gray.900')} w='100%'>
      <VStack w='1000px' spacing={5}>
        <Heading fontSize={'xl'}>Nastavnici</Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          mb={'5'}
          w='100%'
          align={{ base: 'center', md: 'start' }}
        >
          {professorsOnSubject.isLoading ? (
            <HStack h={{ base: '25vh' }} w={'50%'} justify={'Center'}>
              <Spinner />
            </HStack>
          ) : (
            <VStack spacing={2} w={{ base: '90%', md: '50%' }} align={'center'}>
              <Heading fontSize={'1xl'} pb={5}>
                Profesori
              </Heading>
              <VStack align='center' w={'100%'}>
                {professorsOnSubject.data && professorsOnSubject.data.length > 0 && (
                  <ProfessorsAndAsistents professors={professorsOnSubject.data} />
                )}
                {professorsOnSubject.data && professorsOnSubject.data.length === 0 && (
                  <p>Nema profesora na predmetu</p>
                )}
              </VStack>
            </VStack>
          )}
          {assistantsOnSubject.isLoading ? (
            <HStack h={{ base: '15vh', md: '25vh' }} w={'50%'} justify={'Center'}>
              <Spinner />
            </HStack>
          ) : (
            <VStack spacing={2} w={{ base: '90%', md: '50%' }} align={'center'}>
              <Heading fontSize={'1xl'} pb={5} pt={{ base: 5, md: 0 }}>
                Asistenti
              </Heading>
              <VStack align='center' w={'100%'}>
                {assistantsOnSubject.data && assistantsOnSubject.data.length > 0 && (
                  <ProfessorsAndAsistents professors={assistantsOnSubject.data} />
                )}
                {assistantsOnSubject.data && assistantsOnSubject.data.length === 0 && (
                  <p>Nema asistenata na predmetu</p>
                )}
              </VStack>
            </VStack>
          )}
        </Stack>
        {authContext?.user?.role === 'PROFESSOR' ? (
          staffWantsToJoin.data &&
          staffWantsToJoin.data.length > 0 && (
            <>
              <Heading pt={10} fontSize={'xl'}>
                Zahtjevi za pristup predmetu
              </Heading>
              <VStack>
                <ProfessorsWantToJoin professors={staffWantsToJoin.data} />
              </VStack>
            </>
          )
        ) : (
          <></>
        )}
      </VStack>
    </Flex>
  );
};

export default Professors;
