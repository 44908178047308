import {
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import CreateProfessor from './comp/CreateProfessor';
import CreateAssistant from './comp/CreateAssistant';
import { ProfessorsTable } from './comp/ProfessorsTable';
import { AssistantTable } from './comp/AssistantsTable';
import useAuth from '../../../hooks/useAuth.hooks';
import CreateStudent from './comp/CreateStudent';
import { StudentsTable } from './comp/StudentsTable';
import CreateAdmin from './comp/CreateAdmin';
import { AdminsTable } from './comp/AdminTable';
import { BlueButton } from '../../../components/general/BlueButton';
import { useNavigate } from 'react-router-dom';

export function StaffCreation() {
  const authContext = useAuth();
  const navigate = useNavigate();
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w={'100%'}
    >
      <Tabs w={'100%'} isFitted variant='enclosed'>
        <TabList mb='1em'>
          <Tab fontWeight={'700'}>Profesori</Tab>
          <Tab fontWeight={'700'}>Asistenti</Tab>
          <Tab fontWeight={'700'}>Studenti</Tab>
          {authContext.user?.mainAdmin && <Tab fontWeight={'700'}>Admini</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
              <Heading fontSize={'xl'} pb={2}>
                Kreiraj profesora
              </Heading>
              <CreateProfessor />
              <Heading fontSize={'xl'} py={5}>
                Svi profesori
              </Heading>
              <ProfessorsTable />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
              <Heading fontSize={'xl'} pb={2}>
                Kreiraj asistenta
              </Heading>
              <CreateAssistant />
              <Heading fontSize={'xl'} py={5}>
                Svi asistenti
              </Heading>
              <AssistantTable />
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
              <Heading fontSize={'xl'} pb={5}>
                Kreiraj studenta
              </Heading>
              <CreateStudent />
              <Heading textAlign={'center'} fontSize={'xl'} py={5}>
                Kreiraj više studenata od jednom
              </Heading>
              <BlueButton
                buttonText='Kreiraj studente'
                onClick={() => {
                  navigate('dodaj-studente');
                }}
              />
              <Heading fontSize={'xl'} py={5}>
                Svi studenti
              </Heading>
              <StudentsTable />
            </VStack>
          </TabPanel>
          {authContext.user?.mainAdmin && (
            <TabPanel>
              <VStack spacing={1} w={'100%'} align={'center'} justify={'center'}>
                <Heading fontSize={'xl'} pb={5}>
                  Kreiraj admina
                </Heading>
                <CreateAdmin />
                <Heading fontSize={'xl'} py={5}>
                  Svi Admini
                </Heading>
                <AdminsTable />
              </VStack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
