import { useState } from 'react';
import { useStudentJoinSubject } from '../../../../services/mutations';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  VStack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { BlueButton } from '../../../../components/general/BlueButton';
import { ArrowBackButton } from '../../../../components/general/ArrowBackButton';

export const NewSubjectQRInput = () => {
  const [subjectCode, setSubjectCode] = useState('');
  const [loading, setLoading] = useState(false);
  const joinSubject = useStudentJoinSubject();
  const navigate = useNavigate();
  const toast = useToast();

  const handleJoinSubject = () => {
    setLoading(true);
    if (subjectCode.trim().length === 0) {
      toast({
        position: 'top',
        title: 'Morate napisati šifru predmeta.',
        status: 'warning',
        duration: 2000,
      });
      setLoading(false);
    } else if (subjectCode.trim().length > 8 || subjectCode.trim().length < 8) {
      toast({
        position: 'top',
        title: 'Šifra predmeta mora imati tačno 8 karaktera.',
        status: 'warning',
        duration: 2000,
      });
      setLoading(false);
    } else {
      joinSubject(subjectCode)
        .then(() => {
          setSubjectCode('');
          navigate('/student');
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  };

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={'absolute'} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student/novi-predmet/opcije');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={'10vh'}>
          Unesi šifru predmeta
        </Heading>
      </HStack>

      <HStack justify={'center'}>
        <FormControl isRequired>
          <VStack justify={'space-around'}>
            <VStack pb={5}>
              <HStack justify={'space-around'}>
                <FormLabel>Šifra predmeta</FormLabel>
                <Input
                  w={'150px'}
                  type='text'
                  value={subjectCode}
                  onChange={(e) => setSubjectCode(e.target.value)}
                />
              </HStack>

              <FormHelperText w={{ base: '90%', md: '100%' }} textAlign={'center'}>
                Unesite šifru predmeta koja se nalazi uz QR kod koji vam nastavnik pokaže.
              </FormHelperText>
            </VStack>
            <BlueButton
              isLoading={loading}
              buttonText='Pridruži se'
              fontSize='lg'
              onClick={handleJoinSubject}
            />
          </VStack>
        </FormControl>
      </HStack>
    </Flex>
  );
};
