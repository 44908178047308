import {
  Avatar,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetSubjectActivitiesScoresMainTable } from '../../../../services/queries';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

export const SubjectActivitiesAllStudentsTable = () => {
  const profContext = useProf();

  const navigate = useNavigate();

  const subjectActivitiesData = useGetSubjectActivitiesScoresMainTable({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  return (
    <>
      {subjectActivitiesData.isLoading ? (
        <Center h={'30vh'}>
          <Spinner />
        </Center>
      ) : subjectActivitiesData.data ? (
        <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
          <Table variant='striped' colorScheme='facebook' size='sm'>
            <Thead>
              <Tr>
                <Th colSpan={4} textAlign='center'>
                  podaci od studenta
                </Th>
                {subjectActivitiesData.data &&
                  subjectActivitiesData.data.subjectActivities.map((subjectActivity, index) => (
                    <Th textAlign='center' py={'5px'} key={`subjectA-${index + 1}`}>
                      {subjectActivity.name}
                    </Th>
                  ))}
                <Th colSpan={1} textAlign='center'>
                  Ukupno
                </Th>
              </Tr>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>B. Indexa</Th>
                <Th>Prezime i ime</Th>
                {subjectActivitiesData.data && (
                  <>
                    {subjectActivitiesData.data.subjectActivities.map((subjectActivity, index) => (
                      <Th textAlign='center' key={`subjectA-${index + 1}`}>
                        max: {subjectActivity.maxScore}
                      </Th>
                    ))}
                  </>
                )}

                <Th textAlign='center'>max: {subjectActivitiesData.data?.maxScore}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!subjectActivitiesData.isLoading ? (
                subjectActivitiesData.data &&
                subjectActivitiesData.data.students.map((attendance, index) => (
                  <Tr key={attendance.id}>
                    <Td textAlign='center'>{index + 1}</Td>
                    <Td textAlign='center'>
                      <Avatar size={'sm'} src={attendance.profileImage} />
                    </Td>
                    <Td textAlign='center'>{attendance.indexNumber}</Td>
                    <Td>
                      <StudentNameSubjectStats
                        firstName={attendance.firstName}
                        lastName={attendance.lastName}
                        navigateLink={`/profesori/student/${attendance.id}`}
                        hasCompletedSubject={attendance.hasCompletedSubject}
                        repeater={attendance.repeater}
                      />
                    </Td>
                    {attendance.scores.map((score, index) => (
                      <Td textAlign='center' key={`score-${index + 1}`}>
                        {score}
                      </Td>
                    ))}
                    <Td textAlign='center'>{attendance.sumScores}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={5 + (subjectActivitiesData.data?.subjectActivities.length ?? 0)}>
                    <Center>
                      <Text> Nema studenata na aktivnostima. </Text>
                    </Center>
                  </Td>
                </Tr>
              )}
            </Tbody>

            {subjectActivitiesData.data &&
              subjectActivitiesData.data.subjectActivities.length > 8 && (
                <Tfoot>
                  <Tr>
                    <Th textAlign='center'>RB</Th>
                    <Th textAlign='center'>Slika</Th>
                    <Th textAlign='center'>B. Indexa</Th>
                    <Th>Prezime i ime</Th>
                    {subjectActivitiesData.data && (
                      <>
                        {subjectActivitiesData.data.subjectActivities.map(
                          (subjectActivity, index) => (
                            <Th textAlign='center' key={`subjectA-${index + 1}`}>
                              max: {subjectActivity.maxScore}
                            </Th>
                          )
                        )}
                      </>
                    )}
                    <Th textAlign='center'>max: {subjectActivitiesData.data?.maxScore}</Th>
                  </Tr>
                  <Tr>
                    <Th colSpan={4} textAlign='center'>
                      podaci od studenta
                    </Th>
                    {subjectActivitiesData.data &&
                      subjectActivitiesData.data.subjectActivities.map((subjectActivity, index) => (
                        <Th textAlign='center' py={'5px'} key={`subjectA-${index + 1}`}>
                          {subjectActivity.name}
                        </Th>
                      ))}
                    <Th colSpan={1} textAlign='center'>
                      Ukupno
                    </Th>
                  </Tr>
                </Tfoot>
              )}
          </Table>
        </TableContainer>
      ) : (
        <Center h={'30vh'}>
          <Text> Nema kreiranih dodatnih aktivnosti za sve studente</Text>
        </Center>
      )}
    </>
  );
};
