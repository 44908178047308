import {
  Avatar,
  Center,
  HStack,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RedButton } from '../../../../components/general/RedButton';
import useProf from '../../../../hooks/useProf.hooks';
import {
  useRemoveAllStudentsFromGroup,
  useRemoveStudentFromGroup,
} from '../../../../services/mutations';
import { useGetExercisesInfo, useGetStudentsWithGroup } from '../../../../services/queries';

interface LoadingStates {
  [key: number]: boolean;
}

export const StudentWithGroupTable = () => {
  const { groupID } = useParams();
  const profContext = useProf();
  const [filter, setFilter] = useState('');
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

  const exerciseInfoQuery = useGetExercisesInfo({
    yearID: profContext.selectedAcademicYearID,
    subjectID: profContext.selectedSubjectID,
  });

  const students = useGetStudentsWithGroup(Number(groupID));

  const removeStudent = useRemoveStudentFromGroup(
    profContext.selectedSubjectID,
    exerciseInfoQuery.data?.academicYearID,
    Number(groupID)
  );

  const removeAllStudents = useRemoveAllStudentsFromGroup(
    profContext.selectedSubjectID,
    exerciseInfoQuery.data?.academicYearID,
    Number(groupID)
  );

  const handleRemoveStudent = (studentID: number) => {
    if (exerciseInfoQuery.data?.academicYearID && groupID && studentID) {
      setLoadingStates((prev) => ({ ...prev, [studentID]: true }));
      removeStudent({
        groupID: Number(groupID),
        studentID: studentID,
        academicYearID: exerciseInfoQuery.data?.academicYearID,
        subjectID: profContext.selectedSubjectID,
      })
        .catch((error) => {})
        .finally(() => {
          setFilter('');
          setTimeout(() => {
            setLoadingStates((prev) => ({ ...prev, [studentID]: false }));
          }, 2000);
        });
    }
  };

  const handleRemoveAllStudents = () => {
    removeAllStudents(Number(groupID))
      .catch((error) => {})
      .finally(() => {
        setFilter('');
      });
  };

  const filteredStudents = students.data?.filter(
    (student) =>
      student.indexNumber.includes(filter) ||
      student.lastName.toLowerCase().includes(filter.toLowerCase()) ||
      student.firstName.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      <HStack w='100%' h='45' justify={'center'} align={'center'}>
        <RedButton
          buttonText='Izbaci sve studente'
          onClick={() => {
            handleRemoveAllStudents();
          }}
        />
        <Input
          w='220px'
          placeholder='Pretraži studente'
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </HStack>
      <TableContainer mt={'3rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>Izbaci</Th>
              <Th textAlign='center'>Ime i Prezime</Th>
              <Th textAlign='center'>B.Indexa</Th>
              <Th textAlign='center'>Slika</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={4}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : filteredStudents && filteredStudents.length > 0 ? (
              filteredStudents.map((student) => (
                <Tr key={student.id}>
                  <Td textAlign='center'>
                    <RedButton
                      width='50px'
                      height='25px'
                      buttonText='izbaci'
                      onClick={() => {
                        handleRemoveStudent(student.id);
                      }}
                      isLoading={loadingStates[student.id]}
                    />
                  </Td>
                  <Td textAlign='center'>
                    {student.firstName} {student.lastName}
                  </Td>
                  <Td textAlign='center'>{student.indexNumber}</Td>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={student.profileImage} />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Center>Nema studenata u grupi</Center>
                </Td>
              </Tr>
            )}
          </Tbody>
          {students.data && students.data.length > 10 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>Izbaci</Th>
                <Th textAlign='center'>Ime i Prezime</Th>
                <Th textAlign='center'>B.Indexa</Th>
                <Th textAlign='center'>Slika</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
