import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from '@chakra-ui/react';

const steps = [
  { title: 'Prvi', description: 'Osnovni podaci o predmetu' },
  { title: 'Drugi', description: 'Podaci o predavanjima' },
  { title: 'Treći', description: 'Podaci o vježbama' },
  { title: 'Četvrti', description: 'Podaci o dodatnim aktivnostima' },
  { title: 'Peti', description: 'Podaci o Ispitima' },
];

export function NewSubjectStepper() {
  const { activeStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  return (
    <Stepper index={activeStep} orientation='vertical' height='60vh' gap='0'>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box w={'150px'} flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
}
