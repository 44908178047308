import {
  Avatar,
  Button,
  Center,
  HStack,
  Input,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetActiveStudentsOnSubject } from '../../../../services/queries';
import {
  useDeleteStudentFromSubjectMutation,
  useToggleStudentRepeater,
} from '../../../../services/mutations';
import { RedButton } from '../../../../components/general/RedButton';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import { Student } from '../../../../models/student';
import { BlueButton } from '../../../../components/general/BlueButton';

interface LoadingStates {
  [key: number]: boolean;
}

export const ActiveStudentstable = () => {
  const profContext = useProf();
  const authContext = useAuth();
  const navigate = useNavigate();
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const [loadingToggleStates, setLoadingToggleStates] = useState<LoadingStates>({});
  const [isDelStudModalOpen, setIsDelStudModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [filterValue, setFilterValue] = useState('');

  const students = useGetActiveStudentsOnSubject(profContext.selectedSubjectID);
  const deleteStudent = useDeleteStudentFromSubjectMutation(profContext.selectedSubjectID);
  const toggleRepeater = useToggleStudentRepeater(profContext.selectedSubjectID);

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  const handleDeleteStudent = async (student: Student) => {
    setLoadingStates((prev) => ({ ...prev, [student.id]: true }));
    await deleteStudent({
      studentID: student.id,
      subjectID: profContext.selectedSubjectID,
    })
      .catch((err) => {})
      .finally(() => {
        setTimeout(() => {
          setLoadingStates((prev) => ({ ...prev, [student.id]: false }));
          setIsDelStudModalOpen(false);
        }, 1000);
      });
  };

  const handleToggleRepeater = async (subjectID: number, studentID: number) => {
    setLoadingToggleStates((prev) => ({ ...prev, [studentID]: true }));
    await toggleRepeater({ subjectID, studentID })
      .catch((err) => {})
      .finally(() => {
        setTimeout(() => {
          setLoadingToggleStates((prev) => ({ ...prev, [studentID]: false }));
        }, 1000);
      });
  };

  return (
    <>
      <YesNoModal
        isOpen={isDelStudModalOpen}
        onClose={() => setIsDelStudModalOpen(false)}
        onConfirm={() => {
          if (selectedStudent) {
            handleDeleteStudent(selectedStudent);
          }
        }}
        title='Brisanje studenta'
        message={`Da li ste sigurni da želite izbrisati studenta ${selectedStudent?.lastName} ${selectedStudent?.firstName} (${selectedStudent?.indexNumber})`}
        isLodaing={loadingStates[selectedStudent?.id || -1]}
      />
      <HStack
        w={{ base: '95vw', md: '80%', lg: '800px' }}
        justify={{ base: 'center', md: 'start' }}
      >
        <Input
          placeholder='Filtriraj po prezimenu, imenu ili indeksu'
          w='300px'
          type='text'
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          borderColor={'blue.700'}
        />
      </HStack>
      <TableContainer
        mt={'1rem'}
        w={'900px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>Ime i prezime</Th>
              <Th textAlign='center'>Broj Indexa</Th>
              <Th textAlign='center'>E-mail adresa</Th>
              <Th textAlign='center'>opcije</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : students.isError ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <VStack>
                      <Text>{getErrorMessage(students.error)}</Text>
                      <Button
                        fontSize={'sm'}
                        bgColor={'blue.600'}
                        color={'white'}
                        onClick={() => {
                          navigate('/profesori/predavanja');
                        }}
                      >
                        Vrati se na sva vježbe
                      </Button>
                    </VStack>
                  </Center>
                </Td>
              </Tr>
            ) : students.data && students.data.length > 0 ? (
              students.data
                .filter(
                  (student) =>
                    student.indexNumber.includes(filterValue) ||
                    student.lastName.toLowerCase().includes(filterValue.toLowerCase()) ||
                    student.firstName.toLowerCase().includes(filterValue.toLowerCase())
                )
                .map((attendance, index) => (
                  <Tr key={attendance.id}>
                    <Td textAlign='center'>{index + 1}</Td>
                    <Td textAlign='center'>
                      <Avatar size={'sm'} src={attendance.profileImage} />
                    </Td>
                    <Td textAlign='center'>
                      <VStack spacing={1}>
                        <Text
                          onClick={() => {
                            navigate(`/profesori/student/${attendance.id}`);
                          }}
                          cursor={'pointer'}
                        >
                          {attendance.lastName} {attendance.firstName}
                        </Text>
                        {attendance.repeater && (
                          <Text fontSize={'2xs'} border={'1px solid'} borderRadius={'lg'} px={1}>
                            Ponovac
                          </Text>
                        )}
                      </VStack>
                    </Td>
                    <Td textAlign='center'>{attendance.indexNumber}</Td>
                    <Td textAlign='center'>{attendance.email}</Td>
                    <Td textAlign='center'>
                      <HStack>
                        <RedButton
                          height='25px'
                          buttonText='Ukloni studenta'
                          width='110px'
                          onClick={() => {
                            setSelectedStudent(attendance);
                            setIsDelStudModalOpen(true);
                          }}
                          isLoading={loadingStates[attendance.id]}
                        />
                        <BlueButton
                          height='25px'
                          width='110px'
                          buttonText={attendance.repeater ? 'Ukloni ponovca' : 'Dodaj ponovca'}
                          onClick={() => {
                            handleToggleRepeater(profContext.selectedSubjectID, attendance.id);
                          }}
                          isLoading={loadingToggleStates[attendance.id]}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Text> Nema studenata na predmetu </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {students.data && students.data.length > 8 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>Ime i prezime</Th>
                <Th textAlign='center'>Broj Indexa</Th>
                <Th textAlign='center'>E-mail adresa</Th>
                <Th textAlign='center'>opcije</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
