import { Box, Heading, Stack, Text } from '@chakra-ui/react';

type Props = {};

const Body = (props: Props) => {
  return (
    <>
      <Stack align={'center'} width={{ base: '90vw', md: '50%' }} pb={'2rem'}>
        <Heading fontSize={'2xl'} mt={'2rem'}>
          Kako je sve nastalo?
        </Heading>
        <Box textAlign={'justify'} pt={'2rem'}>
          <Text>
            Upoznajte se s fascinantnom pričom o razvoju ISEUS-a, inovativne aplikacije koja je
            revolucionizirala evidenciju prisustva na univerzitetima.
          </Text>
          <Text pt={'1rem'}>
            ISEUS je plod strasti, upornosti i kreativnosti Allena, studenta suočen s izazovima
            usklađivanja studija i posla, Allen je uočio nedostatke u tradicionalnom sistemu
            evidencije prisustva. Njegova težnja za efikasnijim pristupom rezultirala je idejom koja
            je vremenom evoluirala u aplikaciju koja mijenja pravila igre.
          </Text>
          <Text pt={'1rem'}>
            Projekt ISEUS započeo je kao dio Allenovog angažmana na predmetu Razvoj Informacionih
            Sistema (RIS), pod mentorstvom profesora Denisa Čeke. Ovaj projekt nije bio samo
            akademska obaveza; to je bio Allenov odgovor na stvarne izazove s kojima se suočavaju
            studenti svakodnevno.
          </Text>
          <Text pt={'1rem'}>
            Profesor Čeke, prepoznajući Allenovu strast i sposobnost, pružio mu je priliku da svoje
            ideje pretvori u stvarnost. Tako je nastala ISEUS aplikacija - platforma koja omogućava
            efikasno praćenje prisustva studenata putem QR koda, pružajući jasan i organiziran
            pristup akademskim aktivnostima.
          </Text>
          <Text pt={'1rem'}>
            Nakon nekog vremena, ISEUS timu se pridružuju 2 studenta, Harun i Jasmin, koji su svojim
            znanjem i vještinama doprinijeli daljem razvoju aplikacije. Uz podršku profesora Čeketa,
            tim je uspio da ISEUS dovede do nivoa funkcionalnosti i pouzdanosti koji su ga učinili
            prepoznatljivim u akademskoj zajednici.
          </Text>
          <Text pt={'1rem'}>
            Harun i Jasmin su 2 talentovana, ambiciozna i predana studenta koja dijele Allenovu
            viziju o modernizaciji akademskog iskustva. I sami su bili prvi studenti koju su
            koristili aplikaciju ISEUS, te na osnovu njihovih povratnih informacija, aplikacija je
            dalje prilagođavana i usavršavana.
          </Text>
        </Box>
        <br />
        <Heading textAlign={'center'} fontSize={'2xl'} pt={'2rem'}>
          Posebna zahvalnost Profesoru Denisu Čeketu
        </Heading>{' '}
        <Box textAlign={'justify'}>
          <Text pt={'1rem'}>
            Kada govorimo o razvoju ISEUS-a, ne možemo a da ne spomenemo izuzetan doprinos profesora
            Denisa Čeketa. Njegov profesionalizam, duboko znanje i sposobnost da prepozna potencijal
            u studentima učinili su ga ne samo odličnim edukatorom već i inspiracijom za mnoge.
          </Text>
          <Text pt={'1rem'}>
            Profesor Čeke se ističe svojim pristupom koji kombinuje stručnost i ljudskost. Spreman
            je posvetiti vrijeme studentima, prenoseći znanje na način koji motiviše i inspiriše.
            Njegova podrška nije bila samo u okvirima akademske nastave, već se proširila i na
            praktične aspekte razvoja aplikacije.
          </Text>
          <Text pt={'1rem'}>
            U radu na ISEUS-u, profesor Čeke je pokazao izuzetan smisao za detalje i kvalitetu.
            Njegovi savjeti i sugestije bili su ključni u finom podešavanju aplikacije, što je
            doprinijelo njenoj funkcionalnosti i pouzdanosti. Allen je posebno zahvalan na vremenu
            koje je profesor posvetio testiranju ISEUS-a, pružajući neprocjenjive povratne
            informacije koje su pomogle u usavršavanju aplikacije.
          </Text>
          <Text pt={'1rem'}>
            Profesor Čeke je primjer edukatora koji se istinski trudi da svoje studente ne samo
            nauči, već i motiviše da postignu svoj puni potencijal. Njegova sposobnost da prepozna i
            podrži inovativne ideje, kao i njegov entuzijazam za primjenu tehnologije u obrazovanju,
            učinili su ga nezamjenjivim dijelom procesa razvoja ISEUS-a.
          </Text>
          <Text pt={'1rem'}>
            Za Allena, profesor Čeke nije bio samo mentor, već i uzor, osoba koja je svojim
            pristupom uticala na njegov profesionalni razvoj i ambicije. Zahvaljujući njegovoj
            podršci, ISEUS je postao realnost - aplikacija koja danas pomaže studentima da
            efikasnije upravljaju svojim akademskim obavezama.
          </Text>
        </Box>
        <br />
        <Heading fontSize={'2xl'} pt={'2rem'}>
          ISEUS
        </Heading>{' '}
        <Box textAlign={'justify'}>
          <Text pt={'1rem'}>
            Aplikacija ISEUS predstavlja revolucionarni korak u upravljanju akademskim životom
            studenata i organizaciji nastavnog procesa na univerzitetima. Evo nekoliko razloga zašto
            je ISEUS poseban i zašto bi svaki student, profesor i asistent trebali razmotriti
            njegovo korištenje:
          </Text>
          <br />
          <Text fontWeight={'bold'}> 1. Efikasna Evidencija Prisustva:</Text>
          <Text pt={'1rem'}>
            ISEUS omogućava brzo i jednostavno evidentiranje prisustva skeniranjem ili kucanjem QR
            koda. Ovaj pristup štedi vrijeme i eliminira tradicionalne metode evidentiranja
            prisustva, čineći cijeli proces mnogo efikasnijim i manje podložnim greškama. Čuvajmo
            okoliš, koristimo manje papira!
          </Text>
          <br />
          <Text fontWeight={'bold'}> 2. Personalizovane Obavijesti:</Text>
          <Text pt={'1rem'}>
            Korisnici primaju obavijesti koje su specifično usmjerene na njihove grupe i predmete,
            što osigurava da dobijaju samo relevantne informacije. Ovo pomaže u smanjenju
            preopterećenja informacijama i omogućava lakši fokus na bitne akademske aktivnosti.
          </Text>
          <br />
          <Text fontWeight={'bold'}> 3. Pristup Podacima o Prisustvu:</Text>
          <Text pt={'1rem'}>
            Studenti mogu lako pratiti svoje prisustvo na predavanjima i vježbama putem mobilne
            aplikacije. Ovo uključuje informacije o dozvoljenom broju izostanaka, kao i detaljan
            pregled prisutnosti na svakom predavanju i vježbi.
          </Text>
          <br />
          <Text fontWeight={'bold'}> 4. Transparentnost Rezultata:</Text>
          <Text pt={'1rem'}>
            ISEUS omogućava studentima da prate svoje rezultate zadaća, projekata, seminarskih
            radova i drugih ocjenjivanih aktivnosti. Svi rezultati su ažurirani u realnom vremenu,
            čime se izbjegava potreba za čekanjem da se rezultati svih studenata pregledaju i
            objave.
          </Text>
          <br />
          <Text fontWeight={'bold'}> 5. Anonimnost i Privatnost:</Text>
          <Text pt={'1rem'}>
            Svaki student može anonimno vidjeti samo svoje rezultate. Profesori unose rezultate za
            svakog studenta posebno, a studenti su obavješteni čim su rezultati uneseni.
          </Text>
          <br />
          <Text fontWeight={'bold'}> 6. Uvid u Predloženu Ocjenu:</Text>
          <Text pt={'1rem'}>
            Studenti imaju pristup detaljnom pregledu svih bodova koje su ostvarili na različitim
            akademskim aktivnostima i mogu vidjeti predloženu konačnu ocjenu, što im pomaže da bolje
            razumiju svoj akademski napredak.
          </Text>

          <br />
          <Text fontWeight={'bold'}> 7. Pristup Informacijama o Predmetima:</Text>
          <Text pt={'1rem'}>
            ISEUS pruža pristup važnim informacijama o predmetima, uključujući imena i kontakt
            informacije svih profesora i asistenata koji predaju ili asistiraju na određenom
            predmetu.
          </Text>
          <br />
          <Text fontWeight={'bold'}> 8. Centralizovana Platforma za Učenje:</Text>
          <Text pt={'1rem'}>
            Svi potrebni resursi, materijali za učenje, rasporedi, obavijesti, ocjene i ostale
            informacije su centralizovani na jednom mjestu, što studentima omogućava organizovaniji
            i efikasniji pristup svim potrebnim akademskim resursima.
          </Text>
          <br />
          <Text pt={'1rem'}>
            Ukratko, ISEUS nije samo alat za evidentiranje prisustva, već sveobuhvatna platforma
            koja olakšava i poboljšava akademski život. Njegov cilj je da studentima, profesorima i
            asistentima pruži moderno, efikasno i integrirano rješenje za upravljanje akademskim
            aktivnostima.
          </Text>
          <Text fontWeight={'bold'} pt={'1rem'}>
            ISEUS je više od aplikacije - to je ključ za uspješno akademsko putovanje.
          </Text>
        </Box>
      </Stack>
    </>
  );
};

export default Body;
