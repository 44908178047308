import { Button, Center, Flex, Heading, Image, Stack, useColorModeValue } from '@chakra-ui/react';
import { ProfessorAssistent } from '../../../../models/professorAssistent';
import useProf from '../../../../hooks/useProf.hooks';
import useAuth from '../../../../hooks/useAuth.hooks';
import {
  useDeleteProfessorMutation,
  useDeleteAssistantMutation,
} from '../../../../services/mutations';
import { useState } from 'react';
import { YesNoModal } from '../../../../components/general/YesNoModal';

type Props = {
  staff: ProfessorAssistent;
};
export const StaffOnSubjectCard = ({ staff }: Props) => {
  const [isDelAssModalOpen, setIsDelAssModalOpen] = useState(false);
  const [isDelProfModalOpen, setIsDelProfModalOpen] = useState(false);
  const authContext = useAuth();
  const profContext = useProf();

  const deleteProfessor = useDeleteProfessorMutation(profContext.selectedSubjectID);
  const deleteAssistant = useDeleteAssistantMutation(profContext.selectedSubjectID);

  const handleProfessorDelete = () => {
    setIsDelProfModalOpen(true);
  };

  const handleAssistantDelete = () => {
    setIsDelAssModalOpen(true);
  };

  return (
    <Center pt={1} w='100%'>
      <Stack
        borderWidth='1px'
        borderRadius='lg'
        w={{ base: '95vw', md: '320px' }}
        height={'70px'}
        direction={'row'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={useColorModeValue('xl', '')}
        padding={0}
      >
        <YesNoModal
          isOpen={isDelAssModalOpen}
          onClose={() => setIsDelAssModalOpen(false)}
          onConfirm={() => {
            deleteAssistant({
              assistantID: staff.id,
              subjectID: profContext.selectedSubjectID,
            }).catch((err) => {});

            setIsDelAssModalOpen(false);
          }}
          title='Brisanje asistenta'
          message={`Da li ste sigurni da želite Ukloniti asistenta ${staff.firstName} ${staff.lastName}?`}
        />
        <YesNoModal
          isOpen={isDelProfModalOpen}
          onClose={() => setIsDelProfModalOpen(false)}
          onConfirm={() => {
            deleteProfessor({
              professorID: staff.id,
              subjectID: profContext.selectedSubjectID,
            }).catch((err) => {});
            setIsDelProfModalOpen(false);
          }}
          title='Brisanje profesora'
          message={`Da li ste sigurni da želite Ukloniti profesora ${staff.firstName} ${staff.lastName}?`}
        />

        <Flex flex={1} bg='blue.200' borderTopLeftRadius={'md'} borderBottomLeftRadius={'md'}>
          <Image
            objectFit='cover'
            boxSize='100%'
            src={staff.profileImage}
            alt='#'
            borderTopLeftRadius={'md'}
            borderBottomLeftRadius={'md'}
          />
        </Flex>
        <Stack flex={4} flexDirection='row' justifyContent='center' alignItems='center'>
          <Stack flexDir={'column'} ml={3} w={'60%'}>
            <Heading fontSize={'sm'} fontFamily={'body'}>
              {`${staff.firstName} ${staff.lastName}`}
            </Heading>
            <Heading fontWeight={400} color={'gray.500'} fontSize='xs' m={0}>
              {staff.title}
            </Heading>
          </Stack>

          {authContext?.user?.role === 'PROFESSOR' && (
            <Stack justifyContent='center' align={'center'} w={'40%'} pb={'8px'}>
              {staff.role === authContext?.user?.role && staff.id === authContext?.user?.sub ? (
                <Button
                  w={'50px'}
                  h={'25px'}
                  fontSize={'xs'}
                  rounded={'md'}
                  color={'white'}
                  bg={'gray.700'}
                  isDisabled={true}
                >
                  Ukloni
                </Button>
              ) : staff.role === 'PROFESSOR' ? (
                <Button
                  w={'50px'}
                  h={'25px'}
                  fontSize={'xs'}
                  rounded={'md'}
                  color={'white'}
                  bg={'red.500'}
                  _hover={{
                    bg: 'red.200',
                    color: 'red.900',
                  }}
                  onClick={handleProfessorDelete}
                >
                  Ukloni
                </Button>
              ) : (
                <Button
                  w={'50px'}
                  h={'25px'}
                  fontSize={'xs'}
                  rounded={'md'}
                  color={'white'}
                  bg={'red.500'}
                  _hover={{
                    bg: 'red.200',
                    color: 'red.900',
                  }}
                  onClick={handleAssistantDelete}
                >
                  Ukloni
                </Button>
              )}
            </Stack>
          )}
          {authContext?.user?.role === 'ASSISTANT' && (
            <Button
              w={'50px'}
              h={'25px'}
              fontSize={'xs'}
              rounded={'md'}
              color={'white'}
              bg={'gray.700'}
              isDisabled={true}
            >
              Ukloni
            </Button>
          )}
        </Stack>
      </Stack>
    </Center>
  );
};
