import * as yup from 'yup';

export const createLectureSchema = yup.object({
  subjectID: yup.number().required(),
  yearID: yup.number().required(),
  name: yup.string().trim().required('Ime je obavezno'),
  date: yup.string().trim().required('Datum je obazean'),
  classHours: yup
    .number()
    .required('Broj Časova je obavezan')
    .positive('Broj časova mora biti pozitivan broj.'),
});

export const updateLectureSchema = yup.object({
  lectureID: yup.number().required(),
  name: yup.string().trim().required('Ime je obavezno'),
  date: yup.string().trim().required('Datum je obazean'),
  classHours: yup
    .number()
    .required('Broj Časova je obavezan')
    .positive('Broj časova mora biti pozitivan broj.'),
});

export type createLectureDto = yup.InferType<typeof createLectureSchema>;
export type updateLectureDto = yup.InferType<typeof updateLectureSchema>;
