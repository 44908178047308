import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Grid, GridItem, HStack, Stack, Text, Tooltip, VStack, chakra } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { RedButton } from '../../../../components/general/RedButton';
import { useStudentContext } from '../../../../hooks/useStudent.hooks';
import { StudentPartialExamPreview } from '../../../../models/exams';
import {
  useCanclePartialExamRegistration,
  useStudentNullifyPartialExamResults,
  useStudentPartialExamRegistration,
} from '../../../../services/mutations';

interface PartialExamProps {
  partialExam: StudentPartialExamPreview;
}

export const PartialExam = ({ partialExam }: PartialExamProps) => {
  const studsContext = useStudentContext();
  const navigate = useNavigate();
  const [partExamRegisterLoading, setPartExamRegisterLoading] = useState(false);
  const [canclePartExamRegistrationLoading, setCanclePartExamRegistrationLoading] = useState(false);
  const [toggleNullifyPartExamLoading, setToggleNullifyPartExamLoading] = useState(false);

  const partExamRegistration = useStudentPartialExamRegistration(studsContext.selectedSubjectID);
  const canclePartExamRegistration = useCanclePartialExamRegistration(
    studsContext.selectedSubjectID
  );
  const toggleNullifyPartExamResults = useStudentNullifyPartialExamResults(
    studsContext.selectedSubjectID
  );

  return (
    <GridItem>
      <VStack alignItems={'center'} spacing='5px'>
        <HStack spacing={3}>
          <chakra.h2 fontSize='lg' fontWeight='700'>
            {partialExam.name}
          </chakra.h2>
          {partialExam.passed ? (
            <Tooltip label='Položen' aria-label='Položen'>
              <chakra.span color='green.400'>
                {partialExam.registered && <CheckIcon fontSize={'xl'} />}
              </chakra.span>
            </Tooltip>
          ) : (
            <Tooltip label='Nije položen' aria-label='Nije položen'>
              <chakra.span color='red.500'>
                {partialExam.registered && <CloseIcon fontSize={'xl'} />}
              </chakra.span>
            </Tooltip>
          )}
        </HStack>
        <Stack w={'100%'} direction={'row'} justify={'center'} spacing={1}>
          <BlueButton
            fontSize='xs'
            height='25px'
            width='100px'
            buttonText='Detalji'
            isDisabled={true}
            onClick={() => {
              /* navigate(`/student/exams/${studentExamPreview.id}`); */
            }}
          />

          {partialExam.registrationOver ? (
            partialExam.passed ? (
              !partialExam.nullificationOver ? (
                partialExam.nullified ? (
                  <BlueButton
                    fontSize='xs'
                    height='25px'
                    width='120px'
                    buttonText={'Otkaži poništavanje'}
                    isLoading={toggleNullifyPartExamLoading}
                    onClick={async () => {
                      try {
                        setPartExamRegisterLoading(true);
                        await toggleNullifyPartExamResults({ partialExamID: partialExam.id });
                      } catch (error) {
                      } finally {
                        setPartExamRegisterLoading(false);
                      }
                    }}
                  />
                ) : (
                  <RedButton
                    fontSize='xs'
                    height='25px'
                    width='100px'
                    buttonText='Poništi ispit'
                    isLoading={toggleNullifyPartExamLoading}
                    onClick={async () => {
                      try {
                        setPartExamRegisterLoading(true);
                        await toggleNullifyPartExamResults({ partialExamID: partialExam.id });
                      } catch (error) {
                      } finally {
                        setPartExamRegisterLoading(false);
                      }
                    }}
                  />
                )
              ) : (
                ''
              )
            ) : (
              ''
            )
          ) : partialExam.registered ? (
            <RedButton
              fontSize='xs'
              height='25px'
              width='100px'
              buttonText='Odjavi ispit'
              isLoading={canclePartExamRegistrationLoading}
              isDisabled={partialExam.registrationOver}
              onClick={async () => {
                try {
                  setPartExamRegisterLoading(true);
                  await canclePartExamRegistration({ partialExamID: partialExam.id });
                } catch (error) {
                } finally {
                  setPartExamRegisterLoading(false);
                }
              }}
            />
          ) : (
            <BlueButton
              fontSize='xs'
              height='25px'
              width='100px'
              buttonText={'Prijavi ispit'}
              isLoading={partExamRegisterLoading}
              isDisabled={partialExam.registrationOver}
              onClick={async () => {
                try {
                  setPartExamRegisterLoading(true);
                  await partExamRegistration({ partialExamID: partialExam.id });
                } catch (error) {
                } finally {
                  setPartExamRegisterLoading(false);
                }
              }}
            />
          )}
        </Stack>
        <Grid
          pt={5}
          templateColumns={{
            base: 'repeat(5, 1fr)',
            lg: 'repeat(5, 1fr)',
          }}
          gap={{ base: '1', md: '5' }}
        >
          <GridItem colSpan={3}>
            <VStack align={{ base: 'start', md: 'end' }}>
              <VStack align={'start'} spacing={0}>
                <HStack>
                  <Text fontSize={'xs'}>Ime ispita:</Text>
                  <Text fontSize={'xs'} fontWeight={'bold'}>
                    {partialExam.name}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontSize={'xs'}>Tip ispita:</Text>
                  <Text fontSize={'xs'} fontWeight={'bold'}>
                    {partialExam.type}
                  </Text>
                </HStack>
                <HStack>
                  {partialExam.conditionPartialExamName ? (
                    <>
                      <Text fontSize={'xs'}>Uslov Ispita:</Text>
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {partialExam.conditionPartialExamName}
                      </Text>
                    </>
                  ) : (
                    <Text fontSize={'xs'}>Nema uslovnog ispita</Text>
                  )}
                </HStack>
              </VStack>
              <VStack spacing={0} pt={2} align={'start'}>
                {partialExam.sameGroupsDateTime ? (
                  <>
                    <Text fontSize={'xs'}>Vrijeme i mjesto ispita:</Text>
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      {partialExam.groupName}
                    </Text>
                    {partialExam.groupDateTime && partialExam.groupPlace ? (
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        {partialExam.groupDateTime} u {partialExam.groupPlace}
                      </Text>
                    ) : (
                      <Text fontSize={'xs'} fontWeight={'bold'}>
                        Nije zakazano
                      </Text>
                    )}
                  </>
                ) : (
                  <>
                    <Text fontSize={'xs'}>Vrijeme i mjesto ispita:</Text>
                    <Text fontSize={'xs'}>
                      {partialExam.dateTime} u {partialExam.place}
                    </Text>
                  </>
                )}
              </VStack>
            </VStack>
          </GridItem>
          <GridItem colSpan={2}>
            <VStack align={'end'}>
              <VStack align={'start'} spacing={0}>
                <HStack>
                  <Text fontSize={'xs'}>Broj bodova:</Text>
                  <Text fontSize={'xs'} fontWeight={'bold'}>
                    {partialExam.studentScore} / {partialExam.maxScore}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontSize={'xs'}>Prijavljen:</Text>
                  <Text fontSize={'xs'} fontWeight={'bold'}>
                    {partialExam.registered ? 'DA' : 'NE'}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontSize={'xs'}>Prisustvo:</Text>
                  <Text fontSize={'xs'} fontWeight={'bold'}>
                    {partialExam.attendence ? 'DA' : 'NE'}
                  </Text>
                </HStack>
                <HStack>
                  <Text fontSize={'xs'}>položen:</Text>
                  <Text fontSize={'xs'} fontWeight={'bold'}>
                    {partialExam.passed ? 'DA' : 'NE'}
                  </Text>
                </HStack>

                <VStack align={'start'} spacing={0}>
                  <Text fontSize={'xs'}>Rok prijave:</Text>
                  {partialExam.registrationOver ? (
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      Istekao
                    </Text>
                  ) : (
                    <Text fontSize={'xs'} fontWeight={'bold'}>
                      {partialExam.registrationDeadline}
                    </Text>
                  )}
                </VStack>
              </VStack>
            </VStack>
          </GridItem>
        </Grid>
      </VStack>
    </GridItem>
  );
};
