import {
  Button,
  HStack,
  Select,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { AcademicYear } from '../../../../models/subject';
import { useGetAcademicYears, useGetExamInfo } from '../../../../services/queries';
import { CreateExamScheduleModal } from './modals/CreateExamScheduleModal';
import { AcademicYearSelectionField } from '../../../../components/general/academicYearSelectionField';

type Props = {};

export const ExamsInfo = (props: Props) => {
  const profContext = useProf();
  const authContext = useAuth();
  const years = useGetAcademicYears(profContext.selectedSubjectID);
  const navigate = useNavigate();
  const {
    isOpen: isCreateExamScheduleOpen,
    onOpen: onCreateExamScheduleOpen,
    onClose: onCreateExamScheduleClose,
  } = useDisclosure();

  const examInfoQuery = useGetExamInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w={{ base: '90vw', md: '85%', lg: '700px' }}
      justify='space-around'
      pb={10}
    >
      <CreateExamScheduleModal
        isOpen={isCreateExamScheduleOpen}
        onClose={onCreateExamScheduleClose}
      />
      <VStack align={'start'} spacing={0}>
        <AcademicYearSelectionField
          years={years}
          handleOnChangeAcademicYear={handleOnChangeAcademicYear}
          profContext={profContext}
        />

        {examInfoQuery.isLoading ? (
          <HStack w='263px' h='132px' justify={'center'}>
            <Spinner />
          </HStack>
        ) : examInfoQuery.data ? (
          <>
            <HStack align={'center'}>
              <Text>Broj kreiranih rokova: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {examInfoQuery.data.numberOfExamSchedules}
              </Text>
            </HStack>
          </>
        ) : (
          <VStack pt={3} align={'start'}>
            <Text>Kreirana je nova akademska godina.</Text>
            <Text>Da li želite mjenjati podatke o dodatnim aktivnostima?</Text>
            <Button w={'100%'} bgColor={'blue.600'}>
              Uvezi iz prošle godine
            </Button>
            <Button w={'100%'} bgColor={'blue.600'}>
              Unesi nove podatke
            </Button>
          </VStack>
        )}
      </VStack>
      <Stack pt={{ base: 5, md: 0 }} direction={{ base: 'row', md: 'column' }} justify={'center'}>
        <BlueButton
          buttonText='Dodaj rok'
          onClick={() => {
            onCreateExamScheduleOpen();
          }}
        />
      </Stack>
    </Stack>
  );
};
