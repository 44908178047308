import {
  Box,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  QRCode: string;
  title: string;
  link: string;
};

export const QRCodeModal = ({ isOpen, onClose, QRCode, title, link }: Props) => {
  const basePath = window.location.origin;
  const [qrSize, setQrSize] = useState(200);

  const updateSize = () => {
    if (window.innerWidth < 768) {
      setQrSize(200); // Size for small devices
    } else if (window.innerWidth < 992) {
      setQrSize(400); // Size for medium devices
    } else {
      setQrSize(600); // Size for large devices
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize(); // Initial size update

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <Modal isCentered size={'7xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify={'center'}
              mb={'2rem'}
              align={'center'}
              justifyContent={'center'}
            >
              <HStack justify={'center'} pr={5}>
                <Box borderRadius='3xl' overflow='hidden'>
                  <QRCodeSVG
                    /* value={
                      basePath + `/student/${predavanja ? 'predavanja' : 'vjezbe'}/scan/` + QRCode
                    } */
                    value={`${basePath}/${link}/scan/${QRCode}`}
                    size={qrSize}
                    fgColor={'#000000'}
                    level={'L'}
                    includeMargin={true}
                  />
                </Box>
              </HStack>
              <Heading fontSize={{ base: '6xl', md: '6xl', lg: '8xl' }} pl={{ base: 0, md: 10 }}>
                {QRCode}
              </Heading>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
