import {
  Avatar,
  Card,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { useGetStudentsForFinalGrade } from '../../../services/queries';
import useProf from '../../../hooks/useProf.hooks';
import { BlueButton } from '../../../components/general/BlueButton';

export const FinalGradePage = () => {
  const profContext = useProf();
  const navigate = useNavigate();
  const colorScheme = useColorModeValue('gray.100', 'gray.900');

  const students = useGetStudentsForFinalGrade(profContext.selectedSubjectID);
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
      pos={'relative'}
    >
      <Stack pos={'absolute'} top={'20px'} left={{ base: 5 }}>
        <ArrowBackButton
          onClick={() => {
            navigate('/profesori/studenti/');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Konačna ocjena
        </Heading>
      </HStack>
      <VStack justify={'center'}>
        <Text fontSize='sm' color='gray.500' textAlign={'center'}>
          Studenti koji imaju potpis i potvrđen rezultat ispita pojaviti će se ovdje.
        </Text>

        {students.isLoading ? (
          <Center h={'10vh'}>
            <Spinner />
          </Center>
        ) : students.data && students.data.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} pt={5}>
            {students.data.map((student) => (
              <Card
                key={`studentCard-${student.id}`}
                align={'center'}
                variant={'outline'}
                bg={colorScheme}
              >
                <VStack justify={'center'} align={'center'} fontSize={'sm'} p={5} spacing={0}>
                  <VStack justify={'center'} pt='10px'>
                    <Avatar size={'2xl'} src={student.profileImage} />
                  </VStack>

                  <VStack pt='10px'>
                    <Text fontWeight={'700'} fontSize={'lg'}>
                      {student.firstName + ' ' + student.lastName}
                    </Text>
                  </VStack>

                  <VStack>
                    <Text fontWeight={'700'} fontSize={'md'}>
                      {student.indexNumber}
                    </Text>

                    <Text fontWeight={'700'} fontSize={'sm'}>
                      {student.email}
                    </Text>
                  </VStack>

                  <HStack align={'center'} pt={5}>
                    <BlueButton
                      width='120px'
                      height={'25px'}
                      buttonText='Otvori studenta'
                      onClick={() => {
                        navigate(`/profesori/student/${student.id}`);
                      }}
                    />
                  </HStack>
                </VStack>
              </Card>
            ))}
          </SimpleGrid>
        ) : (
          <Center h={'10vh'}>
            <Text>Nema studenata koji imaju uslov da se ocjene</Text>
          </Center>
        )}
      </VStack>
    </Flex>
  );
};
