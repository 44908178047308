import { Box, Button, Center, Spinner, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import useAuth from '../../../../hooks/useAuth.hooks';
import { useAdminCreateStudentMutation } from '../../../../services/mutations';
import {
  useFacultysQuery,
  useGetUniversityID,
  useStudyProgramsQuery,
} from '../../../../services/queries';
import {
  adminCreateStudentDto,
  adminCreateStudentSchema,
} from '../../../../validation/admin-schema';
import { HorizontalTextField } from '../../../Professor/NewSubject/comp/HorzintalTextField';
import { SelectionField } from '../../../Professor/NewSubject/comp/selectionField';

export default function CreateStudent() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [facultyID, setFacultyID] = useState(0);

  const universityID = useGetUniversityID();
  const facultyQuery = useFacultysQuery(universityID.data?.universityID);
  const studyProgramsQuery = useStudyProgramsQuery(facultyID);

  const handleFacultyChange = (facultyID: number) => {
    setFacultyID(facultyID);
  };

  const createStudent = useAdminCreateStudentMutation();

  const handleCreateStudent = async (values: adminCreateStudentDto) => {
    setLoading(true);
    try {
      createStudent(values).catch((err) => {});
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={5} mx={'auto'} py={4} px={6} w={{ base: '95vw', md: '500px' }}>
      <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
        <Formik
          initialValues={{
            studyProgramID: 0,
            indexNumber: '',
            firstName: '',
            lastName: '',
            email: '',
          }}
          validationSchema={adminCreateStudentSchema}
          onSubmit={(values, actions) => {
            handleCreateStudent(values);
            actions.resetForm();
          }}
        >
          {() => (
            <Form>
              <Stack spacing={4}>
                <HorizontalTextField
                  name='indexNumber'
                  type='text'
                  label='Broj Indexa'
                  width='150px'
                />
                <HorizontalTextField name='firstName' type='text' label='Ime' width='150px' />
                <HorizontalTextField name='lastName' type='text' label='Prezime' width='150px' />
                <HorizontalTextField name='email' type='email' label='Email' width='150px' />
                {facultyQuery.data?.length !== 0 ? (
                  facultyQuery.isLoading ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    //  ****  Faculty selection field *****  //
                    <SelectionField
                      label='Fakultet'
                      nameID='facultyID'
                      optionValueObj={facultyQuery.data}
                      onChange={handleFacultyChange}
                      placeholder='Izaberite Fakultet'
                      width='250px'
                    />
                  )
                ) : (
                  <Center>
                    <Text>Izaberite univerzitet</Text>
                  </Center>
                )}
                {studyProgramsQuery.data?.length !== 0 ? (
                  studyProgramsQuery.isLoading ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    //  ****  study program selection field *****  //
                    <SelectionField
                      label='Studijski Program'
                      nameID='studyProgramID'
                      optionValueObj={studyProgramsQuery.data}
                      placeholder='Izaberite studijski program'
                      width='250px'
                    />
                  )
                ) : (
                  <Center>
                    <Text>Izaberite Fakultet</Text>
                  </Center>
                )}
                <Stack spacing={10} pt={2}>
                  <Button
                    type='submit'
                    isLoading={isLoading}
                    loadingText='Kreiranje u toku...'
                    size='lg'
                    bg='blue.400'
                    color='white'
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Kreiraj Studenta
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Stack>
  );
}
