import {
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BluesubmitButton } from '../../../../../components/general/BlueSubmitButton';
import { DateTimeInput } from '../../../../../components/general/DateTimeInput';
import { ExamGroupDatePlaceDto } from '../../../../../models/exams';
import {
  useSetGroupExamDatePlaceMutation,
  useUpdateAllExamDatePlaceMutation,
  useUpdateAllPartialExamDatePlaceMutation,
} from '../../../../../services/mutations';
import {
  editExamDatePlaceSchema,
  editPartialExamDatePlaceSchema,
  setExamGroupDatePlaceSchema,
} from '../../../../../validation/exam-schema';
import { HorizontalTextField } from '../../../NewSubject/comp/HorzintalTextField';

type Props = {
  dateTime: string;
  place: string;
  isOpen: boolean;
  onClose: () => void;
};

export const EditPartialExamDatePlaceModal = ({ isOpen, onClose, dateTime, place }: Props) => {
  const { partialExamID } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const editDatePlace = useUpdateAllPartialExamDatePlaceMutation(Number(partialExamID));

  return (
    <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Izmjena datuma, vremena i mjesta ispita</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          <Formik
            initialValues={{
              partialExamID: Number(partialExamID),
              dateTime: new Date(dateTime),
              place: place,
            }}
            validationSchema={editPartialExamDatePlaceSchema}
            onSubmit={(values, actions) => {
              console.log(values);
              setIsLoading(true);
              editDatePlace(values).catch((error) => {});
              setIsLoading(false);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Stack p={5} spacing={2} justifyContent={'center'} alignItems={'center'}>
                  <HorizontalTextField name='place' type='text' label='Mjesto' width='150px' />

                  <DateTimeInput
                    name='dateTime'
                    label='Datum i vrijeme'
                    placeholder='Izaberite datum'
                    setFieldValue={setFieldValue}
                  />

                  <HStack justify={'center'}>
                    <BluesubmitButton
                      fontSize='sm'
                      height='25px'
                      buttonText='Sačuvaj promjene'
                      loadingText='u toku...'
                      isLoading={isLoading}
                      isDisabled={values.place === place && values.dateTime === new Date(dateTime)}
                    />
                  </HStack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
