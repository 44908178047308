import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { useAssistEditPostMutation, useProfEditPostMutation } from '../../../../services/mutations';
import { staffEditPostSchema } from '../../../../validation/post-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  post: {
    id: number;
    title: string;
    text: string;
  };
};

export const EditPostModal = ({ isOpen, onClose, post }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();
  const authContext = useAuth();

  const profEditPost = useProfEditPostMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const assistEditPost = useAssistEditPostMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    if (authContext.user?.role === 'PROFESSOR') {
      profEditPost(values).catch((err) => {});
    } else {
      assistEditPost(values).catch((err) => {});
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isCentered size={'lg'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Uredi Objavu</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          <Formik
            initialValues={{
              title: post.title,
              text: post.text,
              postID: post.id,
            }}
            validationSchema={staffEditPostSchema}
            onSubmit={async (values) => {
              handleSubmit(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={4}>
                  <Box>
                    <Field name='title'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='title'
                          isRequired
                          isInvalid={form.errors.title && form.touched.title}
                        >
                          <FormLabel>Naslov objave</FormLabel>
                          <Input type='text' {...field} />
                          <ErrorMessage name='title'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name='text'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='text'
                          isRequired
                          isInvalid={form.errors.text && form.touched.text}
                        >
                          <FormLabel>Tekst objave</FormLabel>
                          <Textarea type='text' {...field} />
                          <ErrorMessage name='text'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>

                  <HStack py={5} justify={'center'}>
                    <BluesubmitButton
                      buttonText='Sačuvaj izmjene'
                      loadingText='Izmjene se čuvaju...'
                      isLoading={isLoading}
                    />
                    <RedButton buttonText='Odustani' onClick={onClose} />
                  </HStack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
