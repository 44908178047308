import { FormControl, FormLabel, HStack, Select, Stack, Text, VStack } from '@chakra-ui/react';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { Faculty, StudyProgram, University } from '../../../../models/subject';

type Props = {
  nameID: string;
  optionValueObj?: University[] | Faculty[] | StudyProgram[];
  placeholder?: string;
  label: string;
  onChange?: (universityID: number) => void;
  width?: string;
};

export const SelectionField = (props: Props) => {
  const optionValueObj = props.optionValueObj ?? [];
  return (
    <Field name={props.nameID}>
      {({ field, form }: { field: any; form: any }) => (
        <FormControl
          id={props.nameID}
          isRequired
          isInvalid={form.errors[props.nameID] && form.touched[props.nameID]}
        >
          <VStack justify={'center'}>
            <Stack
              align={'center'}
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: 0, md: 5 }}
            >
              <VStack w={{ base: '100%', md: '50%' }} align={{ base: 'start', md: 'end' }}>
                <FormLabel>{props.label}</FormLabel>
              </VStack>
              <VStack w={{ base: '100%', md: '50%' }} align={'start'}>
                <Select
                  placeholder={props.placeholder}
                  w={props.width ? props.width : '300px'}
                  {...field}
                  onChange={(e) => {
                    form.setFieldValue(props.nameID, Number(e.target.value));
                    if (props.onChange) {
                      props.onChange(Number(e.target.value));
                    }
                  }}
                >
                  {optionValueObj.length > 0 &&
                    optionValueObj.map((data, index) => (
                      <option
                        value={data.id}
                        key={index}
                      >{`${data.name} -  ${data.shortName}`}</option>
                    ))}
                </Select>
              </VStack>
            </Stack>
            <ErrorMessage name={props.nameID}>
              {(msg) => (
                <Text color='red' fontSize='sm'>
                  {msg}
                </Text>
              )}
            </ErrorMessage>
          </VStack>
        </FormControl>
      )}
    </Field>
  );
};
