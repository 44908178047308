import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth.hooks';
import { loginSchema } from '../../../validation/auth-schema';

export function AdminLogin() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { adminLogin } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <Flex
      minH={'87vh'}
      align={'flex-start'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={5} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'3xl'}>Prijava</Heading>
          <Text fontSize={'md'} color={'gray.600'}>
            za <Link color={'blue.400'}>administratore</Link> 👨‍💻
          </Text>
        </Stack>
        <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={async (values) => {
              try {
                setLoading(true);
                await adminLogin(values.email, values.password);
                setLoading(false);
                navigate('/admin');
              } catch (error: any) {
                setLoading(false);

                if (error.data.status === 401) {
                  toast({
                    position: 'top',
                    title: error.data.message,
                    status: 'error',
                    duration: 3000,
                  });
                } else {
                  toast({
                    position: 'top',
                    title: 'Desila se greška',
                    status: 'error',
                    duration: 3000,
                  });
                }
              }
            }}
          >
            {({ getFieldProps, touched, errors }) => (
              <Form>
                <Stack spacing={4}>
                  <Field name='email'>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id='email'
                        isRequired
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel>E-mail adresa</FormLabel>
                        <Input type='email' {...field} />
                        <ErrorMessage name='email'>
                          {(msg) => (
                            <Text color='red' fontSize='sm'>
                              {msg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='password'>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id='password'
                        isRequired
                        isInvalid={form.errors.password && form.touched.password}
                      >
                        <FormLabel>Šifra</FormLabel>
                        <InputGroup>
                          <Input type={showPassword ? 'text' : 'password'} {...field} />
                          <InputRightElement h={'full'}>
                            <Button
                              variant={'ghost'}
                              onClick={() => setShowPassword((showPassword) => !showPassword)}
                            >
                              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <ErrorMessage name='password'>
                          {(msg) => (
                            <Text color='red' fontSize='sm'>
                              {msg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing={10}>
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      align={'start'}
                      justify={'space-between'}
                    >
                      <Link as={NavLink} to={'/zaboravljena-sifra'} color={'blue.400'}>
                        Zaboravljena šifra?
                      </Link>
                    </Stack>
                    <Button
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                    >
                      Prijavi se
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}
