import { InferType, boolean, number, object, ref, string } from 'yup';

export const createExamScheduleSchema = object({
  examInfoID: number().required(),
  name: string()
    .required('Naziv ispitnog roka je obavezan.')
    .max(30, 'Naziv ispitnog roka ne može biti duži od 30 karaktera.')
    .trim(),
  startingDate: string().required('Datum početka je obavezan.'),
  endingDate: string().required('Datum završetka je obavezan.'),
});

export const editExamScheduleSchema = object({
  examScheduleID: number().required(),
  name: string()
    .required('Naziv ispitnog roka je obavezan.')
    .max(30, 'Naziv ispitnog roka ne može biti duži od 30 karaktera.')
    .trim(),
  startingDate: string().required('Datum početka je obavezan.'),
  endingDate: string().required('Datum završetka je obavezan.'),
});

export const createExamSchema = object({
  examScheduleID: number().required().positive(),
  conditionExamID: number().min(-1).nullable(),
  transferResultsExamID: number().positive().nullable(),
  name: string()
    .required('Ime ispita je obavezno')
    .max(30, 'Ime ispita ne može biti duže od 30 karaktera.')
    .trim(),
  partialExamNumber: number()
    .required('Broj parcijala je obavezan')
    .test(
      'valid-range',
      'Broj parcijala mora biti 0 ili između 2 i 5',
      (value) => value === 0 || (value >= 2 && value <= 5)
    ),
  type: string()
    .required('Tip ispita je obavezan')
    .max(30, 'Tip ispita ne može biti duži od 30 karaktera.')
    .trim(),
  maxScore: number()
    .required('Maksimalan broj bodova je obavezan.')
    .positive('Broj bodova mora biti pozitivan.')
    .max(100, 'Maksimalan broj bodova je 100.'),
  minPassingScore: number()
    .required('Broj bodova za prolaz je obavezan')
    .positive('Broj bodova mora biti pozitivan broj')
    .max(100, 'Maksimalan broj bodova je 100.')
    .when('maxScore', {
      is: (maxScore: number | undefined) => maxScore != null, // check if maxScore is not undefined
      then: (schema) =>
        schema.max(
          ref('maxScore'),
          'Broj bodova za prolaz ne može biti veći od maksimalnog broja bodova.'
        ),
      otherwise: (schema) => schema,
    }),
  dateTime: string().nullable(),
  place: string().max(30, 'Mjesto ne može biti duže od 30 karaktera').nullable().trim(),
  registrationDeadline: string().required('Rok za prijavu je obavezan.'),
  nullificationDeadline: string().required('Rok za poništavanje ispita je obavezan.'),
  validUntil: string().nullable(),
  difficultyFactor: number()
    .required('Težinski faktor je obavezan')
    .positive('Težinski faktor mora biti pozitivan broj.'),
  /*sameGroupsDateTime: boolean().required(),
  groups: array()
    .of(
      object({
        groupID: number().required().positive(),
        dateTime: string().required(),
        place: string().required().max(20),
      })
    )
    .nullable(), */
});

export const editExamMainInfoSchema = object({
  examID: number().required().positive(),
  name: string()
    .required('Ime ispita je obavezno')
    .max(30, 'Ime ispita ne može biti duže od 30 karaktera.')
    .trim(),
  type: string()
    .required('Tip ispita je obavezan')
    .max(30, 'Tip ispita ne može biti duži od 30 karaktera.')
    .trim(),
  partialExamNumber: number()
    .required('Broj parcijala je obavezan')
    .test(
      'valid-range',
      'Broj parcijala mora biti 0 ili između 2 i 5',
      (value) => value === 0 || (value >= 2 && value <= 5)
    ),
  sameGroupsDateTime: boolean().required(),
  dateTime: string().nullable(),
  place: string().max(30, 'Mjesto ne može biti duže od 30 karaktera').nullable().trim(),
});

export const editExamScoreInfoSchema = object({
  examID: number().required().positive(),
  maxScore: number()
    .required('Maksimalan broj bodova je obavezan.')
    .positive('Broj bodova mora biti pozitivan.')
    .max(100, 'Maksimalan broj bodova je 100.'),
  minPassingScore: number()
    .required('Broj bodova za prolaz je obavezan')
    .positive('Broj bodova mora biti pozitivan broj')
    .max(100, 'Maksimalan broj bodova je 100.')
    .when('maxScore', {
      is: (maxScore: number | undefined) => maxScore != null, // check if maxScore is not undefined
      then: (schema) =>
        schema.max(
          ref('maxScore'),
          'Broj bodova za prolaz ne može biti veći od maksimalnog broja bodova.'
        ),
      otherwise: (schema) => schema,
    }),

  difficultyFactor: number()
    .required('Težinski faktor je obavezan')
    .positive('Težinski faktor mora biti pozitivan broj.'),
});

export const editExamDatesInfoSchema = object({
  examID: number().required().positive(),
  registrationDeadline: string().required('Rok za prijavu je obavezan.'),
  nullificationDeadline: string().required('Rok za poništavanje ispita je obavezan.'),
  validUntil: string().nullable(),
});

export const editPartialExamMainInfoSchema = object({
  partialExamID: number().required().positive(),
  name: string()
    .required('Ime ispita je obavezno')
    .max(30, 'Ime ispita ne može biti duže od 30 karaktera.')
    .trim(),
  type: string()
    .required('Tip ispita je obavezan')
    .max(30, 'Tip ispita ne može biti duži od 30 karaktera.')
    .trim(),
  sameGroupsDateTime: boolean().required(),
  dateTime: string().nullable(),
  place: string().max(30, 'Mjesto ne može biti duže od 30 karaktera').nullable(),
  //signatureNeeded: boolean().required(),
});

export const editPartialExamScoreInfoSchema = object({
  partialExamID: number().required().positive(),
  maxScore: number()
    .required('Maksimalan broj bodova je obavezan.')
    .positive('Broj bodova mora biti pozitivan.')
    .max(100, 'Maksimalan broj bodova je 100.'),
  minPassingScore: number()
    .required('Broj bodova za prolaz je obavezan')
    .positive('Broj bodova mora biti pozitivan broj')
    .max(100, 'Maksimalan broj bodova je 100.')
    .when('maxScore', {
      is: (maxScore: number | undefined) => maxScore != null, // check if maxScore is not undefined
      then: (schema) =>
        schema.max(
          ref('maxScore'),
          'Broj bodova za prolaz ne može biti veći od maksimalnog broja bodova.'
        ),
      otherwise: (schema) => schema,
    }),
  difficultyFactor: number()
    .required('Težinski faktor je obavezan')
    .positive('Težinski faktor mora biti pozitivan broj.'),
});

export const editPartialExamDatesInfoSchema = object({
  partialExamID: number().required().positive(),
  registrationDeadline: string().required('Rok za prijavu je obavezan.'),
  nullificationDeadline: string().required('Rok za poništavanje parcijalnog ispita je obavezan.'),
  validUntil: string().nullable(),
});

export const repeatExamSchema = object({
  examID: number().required().positive(),
  conditionExamID: number().min(-1).nullable(),
  registrationDeadline: string().required('Rok za prijavu je obavezan.'),
  nullificationDeadline: string().required('Rok za poništavanje ispita je obavezan.'),
  validUntil: string().nullable(),
  sameGroupsDateTime: boolean().required(),
  dateTime: string().nullable(),
  place: string().max(30, 'Mjesto ne može biti duže od 30 karaktera').nullable(),
  //repeatPartials: boolean().required(),
});

export const createPartialExamSchema = object({
  examID: number().required().positive(),
  conditionPartialExamID: number().min(-1).nullable(),
  transferResultsPartialExamID: number().positive().nullable(),
  name: string()
    .required('Ime parcijalnog ispita je obavezno')
    .max(30, 'Ime parcijalnog ispita ne može biti duže od 30 karaktera.'),
  type: string()
    .required('Tip parcijalnog ispita je obavezan')
    .max(30, 'Tip parcijalnog ispita ne može biti duži od 30 karaktera.'),
  maxScore: number()
    .required('Maksimalan broj bodova je obavezan.')
    .positive('Broj bodova mora biti pozitivan.')
    .max(100, 'Maksimalan broj bodova je 100.'),
  minPassingScore: number()
    .required('Broj bodova za prolaz je obavezan')
    .positive('Broj bodova mora biti pozitivan broj')
    .max(100, 'Maksimalan broj bodova je 100.')
    .when('maxScore', {
      is: (maxScore: number | undefined) => maxScore != null, // check if maxScore is not undefined
      then: (schema) =>
        schema.max(
          ref('maxScore'),
          'Broj bodova za prolaz ne može biti veći od maksimalnog broja bodova.'
        ),
      otherwise: (schema) => schema,
    }),
  dateTime: string().nullable(),
  place: string().max(30, 'Mjesto ne može biti duže od 30 karaktera').nullable(),
  registrationDeadline: string().required('Rok za prijavu je obavezan.'),
  nullificationDeadline: string().required('Rok za poništavanje ispita je obavezan.'),
  validUntil: string().nullable(),
  difficultyFactor: number()
    .required('Težinski faktor je obavezan')
    .positive('Težinski faktor mora biti pozitivan broj.'),
  /*sameGroupsDateTime: boolean().required(),
    groups: array()
    .of(
      object({
        groupID: number().required().positive(),
        dateTime: string().required(),
        place: string().required().max(20),
      })
    )
    .nullable(), */
});

export const setExamGroupDatePlaceSchema = object({
  examID: number().required().positive(),
  groupID: number().required().positive(),
  place: string()
    .required('Mjesto ispita je obavezno')
    .max(30, 'Mjesto ne može biti duže od 30 karaktera.'),
  dateTime: string().required('Datum ispita je obavezan.'),
});

export const setPartExamGroupDatePlaceSchema = object({
  partialExamID: number().required().positive(),
  groupID: number().required().positive(),
  place: string()
    .required('Mjesto ispita je obavezno')
    .max(30, 'Mjesto ne može biti duže od 30 karaktera.'),
  dateTime: string().required('Datum ispita je obavezan.'),
});

export const setExamStudentScoreSchema = object({
  studentID: number().required().positive(),
  examID: number().required().positive(),
  score: number().required().positive('Broj bodova mora biti pozitivan broj.'),
});

export const setPartialExamStudentScoreSchema = object({
  studentID: number().required().positive(),
  partialExamID: number().required().positive(),
  score: number().required().positive('Broj bodova mora biti pozitivan broj.'),
});

export const editExamDatePlaceSchema = object({
  examID: number().required().positive(),
  place: string()
    .required('Mjesto ispita je obavezno')
    .max(30, 'Mjesto ne može biti duže od 30 karaktera.')
    .trim(),
  dateTime: string().required('Datum ispita je obavezan.'),
});

export const editPartialExamDatePlaceSchema = object({
  partialExamID: number().required().positive(),
  place: string()
    .required('Mjesto ispita je obavezno')
    .max(30, 'Mjesto ne može biti duže od 30 karaktera.')
    .trim(),
  dateTime: string().required('Datum ispita je obavezan.'),
});

export const addStudentToExamSchema = object({
  examID: number().required().positive(),
  studentID: number()
    .required('Morate prvo izabrati studenta.')
    .positive('Morate prvo izabrati studenta.'),
});

export const addStudentToPartialExamSchema = object({
  partialExamID: number().required().positive(),
  studentID: number()
    .required('Morate prvo izabrati studenta.')
    .positive('Morate prvo izabrati studenta.'),
});

export type createExamScheduleDto = InferType<typeof createExamScheduleSchema>;
export type editExamScheduleDto = InferType<typeof editExamScheduleSchema>;
export type createExamDto = InferType<typeof createExamSchema>;
export type editExamMainInfoDto = InferType<typeof editExamMainInfoSchema>;
export type editExamScoreInfoDto = InferType<typeof editExamScoreInfoSchema>;
export type editExamDatesInfoDto = InferType<typeof editExamDatesInfoSchema>;
export type editPartialExamMainInfoDto = InferType<typeof editPartialExamMainInfoSchema>;
export type editPartialExamScoreInfoDto = InferType<typeof editPartialExamScoreInfoSchema>;
export type editPartialExamDatesInfoDto = InferType<typeof editPartialExamDatesInfoSchema>;
export type repeatExamDto = InferType<typeof repeatExamSchema>;
export type createPartialExamDto = InferType<typeof createPartialExamSchema>;
export type setExamGroupDatePlaceDto = InferType<typeof setExamGroupDatePlaceSchema>;
export type setPartExamGroupDatePlaceDto = InferType<typeof setPartExamGroupDatePlaceSchema>;
export type setExamStudentScoreDto = InferType<typeof setExamStudentScoreSchema>;
export type setPartialExamStudentScoreDto = InferType<typeof setPartialExamStudentScoreSchema>;
export type editExamDatePlaceDto = InferType<typeof editExamDatePlaceSchema>;
export type editPartialExamDatePlaceDto = InferType<typeof editPartialExamDatePlaceSchema>;
export type addStudentToExamDto = InferType<typeof addStudentToExamSchema>;
export type addStudentToPartialExamDto = InferType<typeof addStudentToPartialExamSchema>;
