export interface IAuthUser {
  sub: number;
  firstName: string;
  lastName: string;
  profileImage: string;
  email: string;
  title: string;
  mainAdmin?: boolean;
  studyProgramID?: number;
  indexNumber?: string;
  role: 'PROFESSOR' | 'ASSISTANT' | 'ADMIN' | 'STUDENT';
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface refreshResponse {
  accessToken: string;
}

export interface AuthContextState {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  user?: IAuthUser;
}

export enum AuthContextActionTypes {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface AuthContextAction {
  type: AuthContextActionTypes;
  payload?: IAuthUser;
}

export interface AuthContext {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  user?: IAuthUser;
  login: (email: string, password: string) => Promise<void>;
  adminLogin: (email: string, password: string) => Promise<void>;
  studentLogin: (email: string, password: string) => Promise<void>;
  register: (
    firstName: string,
    lastName: string,
    email: string,
    title: string,
    role: StaffEnum,
    password: string
  ) => Promise<void>;
  logout: () => void;
}

export enum StaffEnum {
  PROFESSOR = 'PROFESSOR',
  ASSISTANT = 'ASSISTANT',
}

export enum AdminEnum {
  ADMIN = 'ADMIN',
}
