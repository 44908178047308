import { QueryFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { subjectPost } from '../models/posts';
import {
  AllOrganizations,
  AssBySubjectAndGroup,
  FacultysWithPrograms,
  GetAllStudentsAdmin,
  ProfessorAssistent,
  UniversityWithAdmins,
  addStudentsExcelTable,
  adminProfessorsAssistants,
  toggleAssistantOnGroupParams,
  universityID,
} from '../models/professorAssistent';
import { StudentResultInfo } from '../models/results';
import {
  GroupExerciseStats,
  Student,
  StudentAttendanceData,
  StudentCompletedSubjects,
  StudentLectureAttandanceStats,
  StudentSubjectInfo,
} from '../models/student';
import {
  AcademicYear,
  Exercise,
  ExerciseAttendance,
  ExerciseAttendanceInfo,
  ExerciseGroup,
  ExercisesInfo,
  ExercisesPerGrupInfo,
  Faculty,
  Lecture,
  LectureAttendance,
  LectureAttendanceInfo,
  LecturesInfo,
  StudentSignatureStat,
  StudyProgram,
  Subject,
  SubjectDifficultyFactors,
  SubjectInfo,
  University,
} from '../models/subject';
import {
  StudentSubjectActivitiesInfo,
  SubjectActivitiesInfo,
  SubjectActivitiesPerGroup,
  SubjectActivitiesScorePerGroupTable,
  SubjectActivitiesScoreTable,
  SubjectActivity,
  SubjectActivityWithStudentScore,
} from '../models/subjectActivities';
import { axiosInstance } from '../utils/axiosInstance';
import {
  adminCreateAdminDto,
  adminCreateFacultyDto,
  adminCreateStudentDto,
  adminCreateStudyProgramDto,
  adminCreateUniversityDto,
  editStudentDto,
  staffIDDto,
} from '../validation/admin-schema';
import { CreateStaffDto } from '../validation/auth-schema';
import { createLectureDto, updateLectureDto } from '../validation/create-lectures-schema';
import {
  assignStudentToSubjectDto,
  createExerciseDto,
  createGroupDto,
  updateExerciseDto,
} from '../validation/exercise-schema';
import {
  assistCreatePostSchemaDto,
  postIDSchemaDto,
  profCreatePostSchemaDto,
  staffEditPostSchemaDto,
} from '../validation/post-schema';
import {
  staffcreateStudentDto,
  studentChangePasswordDto,
  studentIDDto,
} from '../validation/student-schema';
import {
  createSubjectActivityDto,
  editSubjectActivityDto,
  setStudentScoreSubjectActivityDto,
} from '../validation/subjectActivities';
import {
  ApprovedAssistantParams,
  ApprovedProfessorParams,
  LectureIDParams,
  attendanceResponse,
  exerciseIDAndStudentIDsParams,
  lectureIDAndStudentIDsParams,
  subjectStudentIDParams,
} from './types';
import {
  deleteSubjectDto,
  editDifficultyFactorsDto,
  editLecturesExercisesInfoDto,
  editSubjectMainInfoDto,
  setStudentAdditionalPointsDto,
} from '../validation/subject-schema';
import {
  CheckStudentExamStatus,
  Exam,
  ExamGroupDatePlaceDto,
  ExamInfo,
  ExamResult,
  ExamSchedule,
  ExamScheduleResults,
  ExamStats,
  ExamsPreview,
  PartialExam,
  StudentExamPreview,
  messageExamScheduleIDResponse,
} from '../models/exams';
import {
  createExamDto,
  createExamScheduleDto,
  createPartialExamDto,
  editExamDatePlaceDto,
  editExamDatesInfoDto,
  editExamMainInfoDto,
  editExamScheduleDto,
  editExamScoreInfoDto,
  editPartialExamDatePlaceDto,
  editPartialExamDatesInfoDto,
  editPartialExamMainInfoDto,
  editPartialExamScoreInfoDto,
  repeatExamDto,
  setExamGroupDatePlaceDto,
  setExamStudentScoreDto,
  setPartExamGroupDatePlaceDto,
  setPartialExamStudentScoreDto,
} from '../validation/exam-schema';
import { studentCompleteReport } from '../models/student-complete-report';

export interface DeleteAssistantParams {
  assistantID: number;
  subjectID: number;
}

export interface DeleteProfessorParams {
  professorID: number;
  subjectID: number;
}

type SuccessResponse = {
  message: string;
};

//              **** ORGANIZATION ****            //

export const getUniversities: QueryFunction<University[]> = async ({ queryKey }) => {
  const res = await axiosInstance.get('organization/all-universities');
  return res.data as University[];
};

export const getFacultys: QueryFunction<Faculty[]> = async ({ queryKey }) => {
  const [universityID] = queryKey;
  const res = await axiosInstance.post('organization/get-facultys', {
    universityID,
  });
  return res.data as Faculty[];
};

export const getStudyPrograms: QueryFunction<StudyProgram[]> = async ({ queryKey }) => {
  const [facultyID] = queryKey;
  const res = await axiosInstance.post('organization/get-study-programs', {
    facultyID,
  });
  return res.data as StudyProgram[];
};

//              **** SUBJECT ****            //

export const editMainSubjectInfo = async ({
  subjectID,
  name,
  ECTSpoints,
  semester,
}: editSubjectMainInfoDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `subject/edit-main-subject-info`,
      {
        subjectID,
        name,
        ECTSpoints,
        semester,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSubjectDifficultyFactors: QueryFunction<SubjectDifficultyFactors> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('subject/get-difficulty-factors', {
    subjectID,
  });
  return res.data as SubjectDifficultyFactors;
};

export const editSubjectDifficultyFactors = async ({
  subjectID,
  lecturesDF,
  exercisesDF,
  subjectActivitiesAllDF,
  subjectActivitiesGroupDF,
  examsDF,
}: editDifficultyFactorsDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `subject/edit-difficulty-factors`,
      {
        subjectID,
        lecturesDF,
        exercisesDF,
        subjectActivitiesAllDF,
        subjectActivitiesGroupDF,
        examsDF,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCurrentLecturesInfo: QueryFunction<editLecturesExercisesInfoDto> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('subject/get-current-lectures-info', {
    subjectID,
  });
  return res.data as editLecturesExercisesInfoDto;
};

export const editCurrentLecturesInfo = async ({
  subjectID,
  attendanceReqForSignature,
  classHours,
  maxAllowedAbsence,
}: editLecturesExercisesInfoDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `subject/edit-current-lectures-info`,
      {
        subjectID,
        attendanceReqForSignature,
        classHours,
        maxAllowedAbsence,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCurrentExercisesInfo: QueryFunction<editLecturesExercisesInfoDto> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('subject/get-current-exercises-info', {
    subjectID,
  });
  return res.data as editLecturesExercisesInfoDto;
};

export const editCurrentExercisesInfo = async ({
  subjectID,
  attendanceReqForSignature,
  classHours,
  maxAllowedAbsence,
}: editLecturesExercisesInfoDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `subject/edit-current-exercises-info`,
      {
        subjectID,
        attendanceReqForSignature,
        classHours,
        maxAllowedAbsence,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSignatureStats: QueryFunction<StudentSignatureStat[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('subject/get-student-signature-stats', {
    subjectID,
  });
  return res.data as StudentSignatureStat[];
};

export const toggleStudentSignature = async ({
  subjectID,
  studentID,
  comment,
}: {
  subjectID: number;
  studentID: number;
  comment: string | null;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `subject/toggle-student-signature`,
      {
        subjectID,
        studentID,
        comment,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const setStudentAdditionalPoints = async ({
  additionalPoints,
  additionalPointsComment,
  studentID,
  subjectID,
}: setStudentAdditionalPointsDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `student/set-student-additional-points`,
      { additionalPoints, additionalPointsComment, studentID, subjectID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

//              **** PROFESSOR ****            //

export const deleteSubjectMutation = async ({
  subjectID,
  QRCode,
}: deleteSubjectDto): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
    `subject/delete-subject`,
    {
      data: { subjectID, QRCode },
    }
  );
  return response.data;
};

export const getSubjects: QueryFunction<Subject[]> = async ({ queryKey }) => {
  const res = await axiosInstance.get('professor/get-subjects');
  return res.data as Subject[];
};

export const getSelectedSubjectInfo: QueryFunction<SubjectInfo> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('professor/get-selected-subject-info', {
    subjectID,
  });
  return res.data as SubjectInfo;
};

export const getProfessorsOnSubject: QueryFunction<ProfessorAssistent[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('professor/get-professors-on-subject', {
    subjectID,
  });
  return res.data as ProfessorAssistent[];
};

export const deleteProfessorMutation = async ({
  professorID,
  subjectID,
}: DeleteProfessorParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
    `professor/remove-prof-subject`,
    {
      data: { professorID, subjectID },
    }
  );
  return response.data;
};

export const getAssistantsOnSubject: QueryFunction<ProfessorAssistent[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('professor/get-assistants-on-subject', {
    subjectID,
  });
  return res.data as ProfessorAssistent[];
};

export const deleteAssistantMutation = async ({
  assistantID,
  subjectID,
}: DeleteAssistantParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
    `professor/remove-assistant-subject`,
    {
      data: { assistantID, subjectID },
    }
  );
  return response.data;
};

export const joinSubjectMutation = async (QRCode: string): Promise<SuccessResponse | undefined> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `subject/join-subject`,
      { QRCode }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const approveAssistantMutation = async ({
  assistantID,
  subjectID,
}: ApprovedAssistantParams): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `professor/approve-assistant-on-subject`,
      { assistantID, subjectID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const approveProfessorMutation = async ({
  professorID,
  subjectID,
}: ApprovedProfessorParams): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `professor/approve-professor-on-subject`,
      { professorID, subjectID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStaffWantsToJoinSubject: QueryFunction<ProfessorAssistent[]> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('professor/staff-for-approval', {
    subjectID,
  });
  return res.data as ProfessorAssistent[];
};

export const getAcademicYears: QueryFunction<AcademicYear[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('organization/get-years-from-created', {
    subjectID,
  });
  return res.data as AcademicYear[];
};

export const getAssistantsBySubjectAndGroup: QueryFunction<AssBySubjectAndGroup[]> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { subjectID, groupID } = keyData as { subjectID: number; groupID: number };
  const res = await axiosInstance.post('professor/get-assistants-by-subject-and-group', {
    subjectID,
    groupID,
  });
  return res.data as AssBySubjectAndGroup[];
};

export const getAssistantsByGroup: QueryFunction<AssBySubjectAndGroup[]> = async ({ queryKey }) => {
  const [groupID] = queryKey;
  const res = await axiosInstance.post('professor/get-assistants-by-group', {
    groupID,
  });
  return res.data as AssBySubjectAndGroup[];
};

export const toggleAsistantOnGroupMutation = async ({
  assistantID,
  groupID,
}: toggleAssistantOnGroupParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `professor/toggle-assistant-on-group`,
    {
      assistantID,
      groupID,
    }
  );
  return response.data;
};

export const professorChangePasswordMutation = async ({
  newPassword,
  oldPassword,
  repeatedPassword,
}: studentChangePasswordDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `professor/professor-change-password`,
      { oldPassword, newPassword, repeatedPassword }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const assistantChangePasswordMutation = async ({
  newPassword,
  oldPassword,
  repeatedPassword,
}: studentChangePasswordDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `professor/assistant-change-password`,
      { oldPassword, newPassword, repeatedPassword }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const addStudentsExcelTableMutation = async ({
  subjectID,
  file,
}: {
  subjectID: number;
  file: File;
}): Promise<addStudentsExcelTable[]> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('subjectID', subjectID.toString());

  try {
    const response: AxiosResponse<addStudentsExcelTable[]> = await axiosInstance.post(
      `professor/add-students-excel-table`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const changeProfileImage = async ({ file }: { file: File }): Promise<SuccessResponse> => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `file/upload-profile-image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const toggleStudentRepeater = async ({
  subjectID,
  studentID,
}: {
  subjectID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `professor/toggle-student-repeater`,
      {
        subjectID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStudentsForFinalGrade: QueryFunction<Student[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-students-for-final-grade', {
    subjectID,
  });
  return res.data as Student[];
};

export const getStudentsCompleteReport: QueryFunction<studentCompleteReport> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { subjectID, studentID } = keyData as { subjectID: number; studentID: number };
  const res = await axiosInstance.post('student/get-students-complete-report', {
    subjectID,
    studentID,
  });
  return res.data as studentCompleteReport;
};

export const toggleStudentHasCompletedSubject = async ({
  subjectID,
  studentID,
}: {
  subjectID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `student/set-has-completed-subject`,
      {
        subjectID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

//              **** LECTURES ****            //

export const getLecturesInfo: QueryFunction<LecturesInfo> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post('lecture/get-lectures-info', {
    subjectID,
    yearID,
  });
  return res.data as LecturesInfo;
};

export const getLectures: QueryFunction<Lecture[]> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post('lecture/get-lectures', {
    subjectID,
    yearID,
  });
  return res.data as Lecture[];
};

export const getSingleLecture: QueryFunction<Lecture> = async ({ queryKey }) => {
  const [lectureID] = queryKey;
  const res = await axiosInstance.post('lecture/get-single-lecture', {
    lectureID,
  });
  return res.data as Lecture;
};

export const createLectureMutation = async ({
  subjectID,
  yearID,
  name,
  date,
  classHours,
}: createLectureDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `lecture/create-lecture`,
      { subjectID, yearID, name, date, classHours }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getAttendancesOnLecture: QueryFunction<LectureAttendance[]> = async ({ queryKey }) => {
  const [lectureID] = queryKey;
  const res = await axiosInstance.post('lecture/get-attedances-on-lecture', {
    lectureID,
  });
  return res.data as LectureAttendance[];
};

export const getSingleLectureInfo: QueryFunction<LectureAttendanceInfo> = async ({ queryKey }) => {
  const [lectureID] = queryKey;
  const res = await axiosInstance.post('lecture/get-single-lecture-info', {
    lectureID,
  });
  return res.data as LectureAttendanceInfo;
};

export const updateLectureMutation = async ({
  lectureID,
  name,
  classHours,
  date,
}: updateLectureDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `lecture/update-single-lecture`,
      { lectureID, name, date, classHours }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteLectureMutation = async (lectureID: number): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `lecture/delete-single-lecture`,
      { data: { lectureID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const studentsOnSubjectMutation = async ({
  lectureID,
}: LectureIDParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `lecture/set-students-lecture-attandance`,
    { lectureID }
  );
  return response.data;
};

export const setAllStudentsPresentMutation = async ({
  lectureID,
}: LectureIDParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `lecture/set-all-present`,
    {
      lectureID,
    }
  );
  return response.data;
};

export const setAllStudentsNotPresentMutation = async ({
  lectureID,
}: LectureIDParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `lecture/set-all-not-present`,
    {
      lectureID,
    }
  );
  return response.data;
};

export const toggleStudentPresence = async ({
  lectureID,
  studentID,
}: lectureIDAndStudentIDsParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `lecture/toggle-student-attendance`,
    {
      lectureID,
      studentID,
    }
  );
  return response.data;
};

export const toggleLectureScanner = async (lectureID: number): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `lecture/toggle-lecture-scanner`,
    {
      lectureID,
    }
  );
  return response.data;
};

export const sendSingleLectureReportToEmail = async (
  lectureID: number
): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `lecture/send-single-lecture-report-email`,
    {
      lectureID,
    }
  );
  return response.data;
};

//              **** EXERCISES ****            //

export const getExercisesInfo: QueryFunction<ExercisesInfo> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post('exercise/get-exercises-info', {
    subjectID,
    yearID,
  });
  return res.data as ExercisesInfo;
};

export const getExerciseGroups: QueryFunction<ExerciseGroup[]> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { academicYearID, exerciseInfoID } = keyData as {
    academicYearID: number;
    exerciseInfoID: number;
  };
  const res = await axiosInstance.post('exercise/get-exercise-groups', {
    academicYearID,
    exerciseInfoID,
  });
  return res.data as ExerciseGroup[];
};

export const createGroupMutation = async ({
  academicYearID,
  exerciseInfoID,
  name,
}: createGroupDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exercise/create-exercise-group`,
      { academicYearID, exerciseInfoID, name }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteGroupMutation = async (groupID: number): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `exercise/delete-exercise-group`,
      { data: { groupID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getExercises: QueryFunction<Exercise[]> = async ({ queryKey }) => {
  const [groupID] = queryKey;
  const res = await axiosInstance.post('exercise/get-exercises', {
    groupID,
  });
  return res.data as Exercise[];
};

export const getExercisesPerGroupInfo: QueryFunction<ExercisesPerGrupInfo> = async ({
  queryKey,
}) => {
  const [groupID] = queryKey;
  const res = await axiosInstance.post('exercise/get-exercise-per-group-info', {
    groupID,
  });
  return res.data as ExercisesPerGrupInfo;
};

export const getAttendancesOnExercise: QueryFunction<ExerciseAttendance[]> = async ({
  queryKey,
}) => {
  const [exerciseID] = queryKey;
  const res = await axiosInstance.post('exercise/get-attendances-on-exercise', {
    exerciseID,
  });
  return res.data as ExerciseAttendance[];
};

export const getSingleExerciseInfo: QueryFunction<ExerciseAttendanceInfo> = async ({
  queryKey,
}) => {
  const [exerciseID] = queryKey;
  const res = await axiosInstance.post('exercise/get-single-exercise-info', {
    exerciseID,
  });
  return res.data as ExerciseAttendanceInfo;
};

export const getStudentsWithNoGroup: QueryFunction<Student[]> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { academicYearID, subjectID } = keyData as {
    subjectID: number;
    academicYearID: number;
  };
  const res = await axiosInstance.post('exercise/get-students-with-no-group', {
    subjectID,
    academicYearID,
  });
  return res.data as Student[];
};

export const getStudentsWithGroup: QueryFunction<Student[]> = async ({ queryKey }) => {
  const [groupID] = queryKey;
  const res = await axiosInstance.post('exercise/get-students-with-group', {
    groupID,
  });
  return res.data as Student[];
};

export const assignStudentToGroupMutation = async ({
  groupID,
  studentID,
  academicYearID,
  subjectID,
}: assignStudentToSubjectDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exercise/assign-student-to-group`,
      { groupID, studentID, academicYearID, subjectID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const removeStudentFromGroupMutation = async ({
  groupID,
  studentID,
  academicYearID,
  subjectID,
}: assignStudentToSubjectDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exercise/remove-student-from-group`,
      { groupID, studentID, academicYearID, subjectID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSingleExercise: QueryFunction<Exercise> = async ({ queryKey }) => {
  const [exerciseID] = queryKey;
  const res = await axiosInstance.post('exercise/get-single-exercise', {
    exerciseID,
  });
  return res.data as Exercise;
};

export const updateExerciseMutation = async ({
  exerciseID,
  name,
  classHours,
  date,
}: updateExerciseDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exercise/update-single-exercise`,
      { exerciseID, name, date, classHours }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const assignAllStudentsToGroupMutation = async (
  groupID: number
): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exercise/assign-all-students-to-group`,
      { groupID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const removeAllStudentsFromGroupMutation = async (
  groupID: number
): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exercise/remove-all-student-from-group`,
      { groupID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createExerciseMutation = async ({
  groupID,
  name,
  date,
  classHours,
  assistants,
}: createExerciseDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exercise/create-exercise`,
      { groupID, name, date, classHours, assistants }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteExerciseMutation = async (exerciseID: number): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `exercise/delete-single-exercise`,
      { data: { exerciseID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const studentsOnExerciseMutation = async (exerciseID: number): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `exercise/set-students-exercise-attandance`,
    { exerciseID }
  );
  return response.data;
};

export const setAllStudentsPresentExerciseMutation = async (
  exerciseID: number
): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `exercise/set-all-present-on-exercise`,
    {
      exerciseID,
    }
  );
  return response.data;
};

export const setAllStudentsNotPresentExerciseMutation = async (
  exerciseID: number
): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `exercise/set-all-not-present-on-exercise`,
    {
      exerciseID,
    }
  );
  return response.data;
};

export const toggleStudentExercisePresence = async ({
  exerciseID,
  studentID,
}: exerciseIDAndStudentIDsParams): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `exercise/toggle-student-attendance`,
    {
      exerciseID,
      studentID,
    }
  );
  return response.data;
};

export const toggleExerciseScanner = async (exerciseID: number): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `exercise/toggle-exercise-scanner`,
    {
      exerciseID,
    }
  );
  return response.data;
};

export const sendSingleExerciseReportToEmail = async (
  exerciseID: number
): Promise<SuccessResponse> => {
  const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
    `exercise/send-single-exercise-report-email`,
    {
      exerciseID,
    }
  );
  return response.data;
};

//              **** STUDENTS ****            //

export const staffCreateStudent = async ({
  subjectID,
  indexNumber,
  firstName,
  lastName,
  email,
}: staffcreateStudentDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `student/staff-create-student`,
      { subjectID, indexNumber, firstName, lastName, email }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const staffAddStudent = async ({
  indexNumber,
  subjectID,
}: staffcreateStudentDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `student/staff-add-student`,
      { indexNumber, subjectID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getActiveStudentsOnSubject: QueryFunction<Student[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-active-students-on-subject', {
    subjectID,
  });
  return res.data as Student[];
};

export const getFinishedStudentsOnSubject: QueryFunction<Student[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-finished-students-on-subject', {
    subjectID,
  });
  return res.data as Student[];
};

export const getLecturesAttendanceStats: QueryFunction<StudentLectureAttandanceStats[]> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { yearID, subjectID } = keyData as {
    subjectID: number;
    yearID: number;
  };
  const res = await axiosInstance.post('student/get-students-lectures-attendance-statisctics', {
    subjectID,
    yearID,
  });
  return res.data as StudentLectureAttandanceStats[];
};

export const getExercisesAttendancesStats: QueryFunction<GroupExerciseStats[]> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { yearID, subjectID } = keyData as {
    subjectID: number;
    yearID: number;
  };
  const res = await axiosInstance.post('student/get-students-exercises-attendance-statisctics', {
    subjectID,
    yearID,
  });
  return res.data as GroupExerciseStats[];
};

export const getSubjectsForStudents: QueryFunction<Subject[]> = async () => {
  const res = await axiosInstance.get('student/get-subjects');
  return res.data as Subject[];
};

export const studentJoinSubjectMutation = async (QRCode: string): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `student/student-join-subject`,
      { QRCode }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStudentsLectureAttendances: QueryFunction<StudentAttendanceData> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-students-lecture-info', {
    subjectID,
  });
  return res.data as StudentAttendanceData;
};

export const studentAttendLectureMutation = async (QRCode: string): Promise<attendanceResponse> => {
  try {
    const response: AxiosResponse<attendanceResponse> = await axiosInstance.post(
      `student/student-attend-lecture`,
      { QRCode }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const studentAttendExerciseMutation = async (
  QRCode: string
): Promise<attendanceResponse> => {
  try {
    const response: AxiosResponse<attendanceResponse> = await axiosInstance.post(
      `student/student-attend-exercise`,
      { QRCode }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const studentChangePasswordMutation = async ({
  newPassword,
  oldPassword,
  repeatedPassword,
}: studentChangePasswordDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `student/student-change-password`,
      { oldPassword, newPassword, repeatedPassword }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStudentsExerciseAttendances: QueryFunction<StudentAttendanceData> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-students-exercise-info', {
    subjectID,
  });
  return res.data as StudentAttendanceData;
};

export const getStudentsWantToJoinSubject: QueryFunction<Student[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-students-wants-to-join-subjects', {
    subjectID,
  });
  return res.data as Student[];
};

export const approveStudentOnSubjectMutation = async ({
  subjectID,
  studentID,
}: subjectStudentIDParams): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `student/approve-student-on-subject`,
      { subjectID, studentID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const rejectStudentOnSubjectMutation = async ({
  subjectID,
  studentID,
}: subjectStudentIDParams): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `student/reject-student-on-subject`,
      { subjectID, studentID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteStudentFromSubjectMutation = async ({
  subjectID,
  studentID,
}: subjectStudentIDParams): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `student/delete-student-from-subject`,
      { data: { subjectID, studentID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminResetStudentPasswordMutation = async ({
  studentID,
}: studentIDDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `admin/reset-student-password`,
      { studentID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStudentsSubjectActivitieInfo: QueryFunction<StudentSubjectActivitiesInfo> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-student-subject-activities-info', {
    subjectID,
  });
  return res.data as StudentSubjectActivitiesInfo;
};

export const getStudentsResultInfo: QueryFunction<StudentResultInfo> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-student-results-info', {
    subjectID,
  });
  return res.data as StudentResultInfo;
};

export const getStudentsSubjectInfo: QueryFunction<StudentSubjectInfo> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-student-subject-info', {
    subjectID,
  });
  return res.data as StudentSubjectInfo;
};

export const getStudentsExamPreviews: QueryFunction<StudentExamPreview[]> = async ({
  queryKey,
}) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('student/get-upcoming-exams', {
    subjectID,
  });
  return res.data as StudentExamPreview[];
};

export const examRegistrationMutation = async ({
  examID,
}: {
  examID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `student/exam-registration`,
      { examID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const staffRegisterStudentExamMutation = async ({
  examID,
  studentID,
}: {
  examID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `student/staff-register-student-on-exam`,
      { examID, studentID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const partialExamRegistrationMutation = async ({
  partialExamID,
}: {
  partialExamID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `student/partial-exam-registration`,
      { partialExamID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const cancleExamRegistrationMutation = async ({
  examID,
}: {
  examID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `student/cancle-exam-registration`,
      {
        data: {
          examID,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const nullifyExamResultsMutation = async ({
  examID,
}: {
  examID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `student/nullify-exam-result`,
      { examID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const canclePartialExamRegistrationMutation = async ({
  partialExamID,
}: {
  partialExamID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `student/cancle-partial-exam-registration`,
      {
        data: {
          partialExamID,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const nullifyPartialExamResultsMutation = async ({
  partialExamID,
}: {
  partialExamID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `student/nullify-partial-exam-result`,
      { partialExamID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

//              **** ADMINS ****            //

export const adminCreateProfessor = async ({
  firstName,
  lastName,
  email,
  title,
  role,
}: CreateStaffDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/create-professor`,
      {
        firstName,
        lastName,
        email,
        title,
        role,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminCreateAssistant = async ({
  firstName,
  lastName,
  email,
  title,
  role,
}: CreateStaffDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/create-assistant`,
      {
        firstName,
        lastName,
        email,
        title,
        role,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminCreateStudent = async ({
  firstName,
  lastName,
  email,
  indexNumber,
  studyProgramID,
}: adminCreateStudentDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/create-student`,
      {
        firstName,
        lastName,
        email,
        indexNumber,
        studyProgramID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createStudentsExcelTableMutation = async ({
  studyProgramID,
  file,
}: {
  studyProgramID: number;
  file: File;
}): Promise<addStudentsExcelTable[]> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('studyProgramID', studyProgramID.toString());

  try {
    const response: AxiosResponse<addStudentsExcelTable[]> = await axiosInstance.post(
      `admin/create-students-excel-table`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminEditStudent = async ({
  firstName,
  lastName,
  email,
  indexNumber,
  studentID,
}: editStudentDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `admin/edit-student`,
      {
        firstName,
        lastName,
        email,
        indexNumber,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminCreateAdmin = async ({
  firstName,
  lastName,
  email,
  universityID,
}: adminCreateAdminDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/create-admin`,
      {
        firstName,
        lastName,
        email,
        universityID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminCreateUniversity = async ({
  name,
  shortName,
}: adminCreateUniversityDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/create-university`,
      {
        name,
        shortName,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminCreateFaculty = async ({
  universityID,
  name,
  shortName,
}: adminCreateFacultyDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/create-faculty`,
      {
        universityID,
        name,
        shortName,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminCreateStudyProgram = async ({
  facultyID,
  name,
  shortName,
}: adminCreateStudyProgramDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/create-study-program`,
      {
        facultyID,
        name,
        shortName,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getProfessors: QueryFunction<adminProfessorsAssistants[]> = async () => {
  const res = await axiosInstance.get('admin/get-all-professors');
  return res.data as adminProfessorsAssistants[];
};

export const getAssistants: QueryFunction<adminProfessorsAssistants[]> = async () => {
  const res = await axiosInstance.get('admin/get-all-assistants');
  return res.data as adminProfessorsAssistants[];
};

export const adminToggleProfessor = async ({ staffID }: staffIDDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/toggle-professor`,
      {
        staffID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminToggleAssistant = async ({ staffID }: staffIDDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/toggle-assistant`,
      {
        staffID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const adminToggleAdmin = async ({ staffID }: staffIDDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `admin/toggle-admin`,
      {
        staffID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getUniversityID: QueryFunction<universityID> = async () => {
  const res = await axiosInstance.get('admin/get-university-id');
  return res.data as universityID;
};

export const adminGetAllStudents: QueryFunction<GetAllStudentsAdmin[]> = async () => {
  const res = await axiosInstance.get('admin/get-all-students');
  return res.data as GetAllStudentsAdmin[];
};

export const adminGetFacultysWithPrograms: QueryFunction<FacultysWithPrograms[]> = async () => {
  const res = await axiosInstance.get('admin/get-facultys-with-programs');
  return res.data as FacultysWithPrograms[];
};

export const adminGetAllOrganizations: QueryFunction<AllOrganizations[]> = async () => {
  const res = await axiosInstance.get('admin/get-all-organizations');
  return res.data as AllOrganizations[];
};

export const adminGetAllUniversitiesWithAdmins: QueryFunction<
  UniversityWithAdmins[]
> = async () => {
  const res = await axiosInstance.get('admin/get-all-universities-with-admins');
  return res.data as UniversityWithAdmins[];
};

//              **** POSTS ****            //

export const getSubjectPostsProf: QueryFunction<subjectPost[]> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post('post/get-subject-posts', {
    subjectID,
    yearID,
  });
  return res.data as subjectPost[];
};

export const getStudentSubjectPosts: QueryFunction<subjectPost[]> = async ({ queryKey }) => {
  const [subjectID] = queryKey;
  const res = await axiosInstance.post('post/get-student-subject-posts', {
    subjectID,
  });
  return res.data as subjectPost[];
};

export const profCreatePost = async ({
  allStudents,
  subjectID,
  text,
  title,
}: profCreatePostSchemaDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `post/prof-new-post`,
      { subjectID, allStudents, text, title }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const profEditPost = async ({
  postID,
  text,
  title,
}: staffEditPostSchemaDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `post/prof-edit-post`,
      { postID, text, title }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const profDeletePost = async ({ postID }: postIDSchemaDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `post/prof-delete-post`,
      { data: { postID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const assistCreatePost = async ({
  allStudents,
  subjectID,
  text,
  title,
  groupID,
}: assistCreatePostSchemaDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `post/assistant-new-post`,
      { subjectID, allStudents, text, title, groupID }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const assistEditPost = async ({
  postID,
  text,
  title,
}: staffEditPostSchemaDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `post/assistant-edit-post`,
      { postID, text, title }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const assistDeletePost = async ({ postID }: postIDSchemaDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `post/assistant-delete-post`,
      { data: { postID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

//              **** SUBJECT ACTIVITIES ****            //

export const getSubjectActivitiesInfo: QueryFunction<SubjectActivitiesInfo> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post('subject-activity/get-subject-activities-info', {
    subjectID,
    yearID,
  });
  return res.data as SubjectActivitiesInfo;
};

export const getSubjectActivitiesAllStudents: QueryFunction<SubjectActivity[]> = async ({
  queryKey,
}) => {
  const [subjectActivityInfoID] = queryKey;
  const res = await axiosInstance.post('subject-activity/get-subject-activities-all-students', {
    subjectActivityInfoID,
  });
  return res.data as SubjectActivity[];
};

export const getSubjectActivitiesPerGroup: QueryFunction<SubjectActivitiesPerGroup> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { subjectActivityInfoID, groupID } = keyData as {
    subjectActivityInfoID: number;
    groupID: number;
  };
  const res = await axiosInstance.post('subject-activity/get-subject-activities-per-group', {
    subjectActivityInfoID,
    groupID,
  });
  return res.data as SubjectActivitiesPerGroup;
};

export const createSubjectActivityMutation = async ({
  deadlineDate,
  dateTime,
  difficultyFactor,
  maxScore,
  minPassingScore,
  name,
  subjectActivityInfoID,
  text,
  type,
  groupID,
}: createSubjectActivityDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `subject-activity/create-subject-activity`,
      {
        groupID,
        name,
        text,
        type,
        subjectActivityInfoID,
        deadlineDate,
        dateTime,
        difficultyFactor,
        maxScore,
        minPassingScore,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editSubjectActivityMutation = async ({
  deadlineDate,
  dateTime,
  difficultyFactor,
  maxScore,
  minPassingScore,
  name,
  subjectActivityID,
  text,
  type,
}: editSubjectActivityDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `subject-activity/edit-subject-activity`,
      {
        subjectActivityID,
        name,
        text,
        type,
        deadlineDate,
        dateTime,
        difficultyFactor,
        maxScore,
        minPassingScore,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteSubjectActivity = async ({
  subjectActivityID,
}: {
  subjectActivityID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `subject-activity/delete-subject-activity`,
      { data: { subjectActivityID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const setSubjectActivityStudentScore = async ({
  subjectActivityID,
  score,
  studentID,
}: setStudentScoreSubjectActivityDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `subject-activity/set-subject-activity-student-score`,
      {
        subjectActivityID,
        score,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getSubjectActivitiesWithStudentScores: QueryFunction<
  SubjectActivityWithStudentScore
> = async ({ queryKey }) => {
  const [subjectActivityID] = queryKey;
  const res = await axiosInstance.post('subject-activity/get-subject-activities-students-score', {
    subjectActivityID,
  });
  return res.data as SubjectActivityWithStudentScore;
};

export const getSubjectActivitiesScoreMainTable: QueryFunction<
  SubjectActivitiesScoreTable
> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post(
    'subject-activity/get-subject-activities-all-students-scores',
    {
      subjectID,
      yearID,
    }
  );
  return res.data as SubjectActivitiesScoreTable;
};

export const getSubjectActivitiesScorePerGroupTable: QueryFunction<
  SubjectActivitiesScorePerGroupTable[]
> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post('subject-activity/get-subject-activities-per-group-scores', {
    subjectID,
    yearID,
  });
  return res.data as SubjectActivitiesScorePerGroupTable[];
};

export const addStudentsToSubjectActivity = async ({
  subjectActivityID,
}: {
  subjectActivityID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `subject-activity/add-all-students-to-subject-activity`,
      {
        subjectActivityID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

//              **** EXAMS ****            //

export const getExamInfo: QueryFunction<ExamInfo> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { subjectID, yearID } = keyData as { subjectID: number; yearID: number };
  const res = await axiosInstance.post('exam/get-exam-info', {
    subjectID,
    yearID,
  });
  return res.data as ExamInfo;
};

export const getExamSchedules: QueryFunction<ExamSchedule[]> = async ({ queryKey }) => {
  const [examInfoID] = queryKey;
  const res = await axiosInstance.post('exam/get-exam-schedules', {
    examInfoID,
  });
  return res.data as ExamSchedule[];
};

export const getExamsPerSchedule: QueryFunction<ExamsPreview[]> = async ({ queryKey }) => {
  const [examScheduleID] = queryKey;
  const res = await axiosInstance.post('exam/get-exams-preview', {
    examScheduleID,
  });
  return res.data as ExamsPreview[];
};

export const getSingleExamInfo: QueryFunction<Exam> = async ({ queryKey }) => {
  const [examID] = queryKey;
  const res = await axiosInstance.post('exam/get-single-exam', {
    examID,
  });
  return res.data as Exam;
};

export const getSinglePartialExamInfo: QueryFunction<PartialExam> = async ({ queryKey }) => {
  const [partialExamID] = queryKey;
  const res = await axiosInstance.post('exam/get-single-partial-exam', {
    partialExamID,
  });
  return res.data as PartialExam;
};

export const getExamResults: QueryFunction<ExamResult[]> = async ({ queryKey }) => {
  const [examID] = queryKey;
  const res = await axiosInstance.post('exam/get-exam-results', {
    examID,
  });
  return res.data as ExamResult[];
};

export const getPartialExamResults: QueryFunction<ExamResult[]> = async ({ queryKey }) => {
  const [partialExamID] = queryKey;
  const res = await axiosInstance.post('exam/get-partial-exam-results', {
    partialExamID,
  });
  return res.data as ExamResult[];
};

export const getExamGroupsDatePlaceInfo: QueryFunction<ExamGroupDatePlaceDto> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { examID, groupID } = keyData as { examID: number; groupID: number };
  const res = await axiosInstance.post('exam/get-exam-groups-date-place-info', {
    examID,
    groupID,
  });
  return res.data as ExamGroupDatePlaceDto;
};

export const getPartExamGroupsDatePlaceInfo: QueryFunction<ExamGroupDatePlaceDto> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { partialExamID, groupID } = keyData as { partialExamID: number; groupID: number };
  const res = await axiosInstance.post('exam/get-part-exam-group-date-place-info', {
    partialExamID,
    groupID,
  });
  return res.data as ExamGroupDatePlaceDto;
};

export const setExamGroupDatePlaceMutation = async ({
  examID,
  groupID,
  place,
  dateTime,
}: setExamGroupDatePlaceDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/set-exam-groups-date-place-info`,
      { examID, groupID, place, dateTime }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateAllDatePlaceMutation = async ({
  examID,
  place,
  dateTime,
}: editExamDatePlaceDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/update-all-exam-date-place`,
      { examID, place, dateTime }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const updateAllPartialExamDatePlaceMutation = async ({
  partialExamID,
  place,
  dateTime,
}: editPartialExamDatePlaceDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/update-all-partial-exam-date-place`,
      { partialExamID, place, dateTime }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const setPartExamGroupDatePlaceMutation = async ({
  partialExamID,
  groupID,
  place,
  dateTime,
}: setPartExamGroupDatePlaceDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/set-part-exam-group-date-place-info`,
      { partialExamID, groupID, place, dateTime }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createExamScheduleMutation = async ({
  examInfoID,
  name,
  endingDate,
  startingDate,
}: createExamScheduleDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exam/create-exam-schedule`,
      {
        examInfoID,
        name,
        endingDate,
        startingDate,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editExamScheduleMutation = async (
  examScheduleData: editExamScheduleDto
): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/edit-exam-schedule`,
      {
        ...examScheduleData,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteExamSchedule = async ({
  examScheduleID,
}: {
  examScheduleID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `exam/delete-exam-schedules`,
      { data: { examScheduleID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const createExamMutation = async (exam: createExamDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(`exam/create-exam`, {
      ...exam,
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editExamMainInfoMutation = async (
  examInfo: editExamMainInfoDto
): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/edit-exam-main-info`,
      {
        ...examInfo,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editExamScoreInfoMutation = async (
  examInfo: editExamScoreInfoDto
): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/edit-exam-score-info`,
      {
        ...examInfo,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editExamDatesInfoMutation = async (
  examInfo: editExamDatesInfoDto
): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/edit-exam-dates-info`,
      {
        ...examInfo,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editPartialExamMainInfoMutation = async (
  partialExamInfo: editPartialExamMainInfoDto
): Promise<messageExamScheduleIDResponse> => {
  try {
    const response: AxiosResponse<messageExamScheduleIDResponse> = await axiosInstance.patch(
      `exam/edit-partial-exam-main-info`,
      {
        ...partialExamInfo,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editPartialExamScoreInfoMutation = async (
  partialExamInfo: editPartialExamScoreInfoDto
): Promise<messageExamScheduleIDResponse> => {
  try {
    const response: AxiosResponse<messageExamScheduleIDResponse> = await axiosInstance.patch(
      `exam/edit-partial-exam-score-info`,
      {
        ...partialExamInfo,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const editPartialExamDatesInfoMutation = async (
  partialExamInfo: editPartialExamDatesInfoDto
): Promise<messageExamScheduleIDResponse> => {
  try {
    const response: AxiosResponse<messageExamScheduleIDResponse> = await axiosInstance.patch(
      `exam/edit-partial-exam-dates-info`,
      {
        ...partialExamInfo,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const repeatExam = async (
  examInfo: repeatExamDto
): Promise<messageExamScheduleIDResponse> => {
  try {
    const response: AxiosResponse<messageExamScheduleIDResponse> = await axiosInstance.post(
      `exam/repeat-exam`,
      {
        ...examInfo,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteExam = async ({ examID }: { examID: number }): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `exam/delete-exam`,
      { data: { examID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const checkStudentExamStatus: QueryFunction<CheckStudentExamStatus> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { examID, studentID, subjectID } = keyData as {
    examID: number;
    studentID: number;
    subjectID: number;
  };
  const res = await axiosInstance.post('exam/check-student-exam-status', {
    examID,
    studentID,
    subjectID,
  });
  return res.data as CheckStudentExamStatus;
};

export const checkStudentPartialExamStatus: QueryFunction<CheckStudentExamStatus> = async ({
  queryKey,
}) => {
  const [keyData] = queryKey;
  const { partialExamID, studentID, subjectID } = keyData as {
    partialExamID: number;
    studentID: number;
    subjectID: number;
  };
  const res = await axiosInstance.post('exam/check-student-partial-exam-status', {
    partialExamID,
    studentID,
    subjectID,
  });
  return res.data as CheckStudentExamStatus;
};

export const createPartialExamMutation = async (
  partialExam: createPartialExamDto
): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exam/create-partial-exam`,
      {
        ...partialExam,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deletePartialExam = async ({
  partialExamID,
}: {
  partialExamID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `exam/delete-partial-exam`,
      { data: { partialExamID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const setExamStudentScore = async ({
  examID,
  score,
  studentID,
}: setExamStudentScoreDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/update-exam-results`,
      {
        examID,
        score,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const setPartialExamStudentScore = async ({
  partialExamID,
  score,
  studentID,
}: setPartialExamStudentScoreDto): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/update-partial-exam-results`,
      {
        partialExamID,
        score,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getExamScheduleResults: QueryFunction<ExamScheduleResults> = async ({ queryKey }) => {
  const [examScheduleID] = queryKey;
  const res = await axiosInstance.post('exam/get-exam-schedule-results', {
    examScheduleID,
  });
  return res.data as ExamScheduleResults;
};

export const confirmStudentExamScheduleResults = async ({
  examScheduleID,
  studentID,
}: {
  examScheduleID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/confirm-student-exam-schedule-result`,
      {
        examScheduleID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteStudentExamScheduleResults = async ({
  examScheduleID,
  studentID,
}: {
  examScheduleID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/delete-student-exam-schedule-result`,
      {
        examScheduleID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const toggleStudentExamAttendance = async ({
  examID,
  studentID,
}: {
  examID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/toggle-student-exam-attendance`,
      {
        examID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const toggleStudentExamNullification = async ({
  examID,
  studentID,
}: {
  examID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/toggle-student-exam-nullification`,
      {
        examID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const addStudentToExam = async ({
  examID,
  studentID,
}: {
  examID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exam/add-student-to-exam`,
      {
        examID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteStudentExamRegistration = async ({
  examID,
  studentID,
}: {
  examID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `exam/delete-student-exam-registration`,
      { data: { examID, studentID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const toggleStudentPartialExamAttendance = async ({
  partialExamID,
  studentID,
}: {
  partialExamID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/toggle-student-partial-exam-attendance`,
      {
        partialExamID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const toggleStudentPartialExamNullification = async ({
  partialExamID,
  studentID,
}: {
  partialExamID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.patch(
      `exam/toggle-student-partial-exam-nullification`,
      {
        partialExamID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const addStudentToPartialExam = async ({
  partialExamID,
  studentID,
}: {
  partialExamID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.post(
      `exam/add-student-to-partial-exam`,
      {
        partialExamID,
        studentID,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const deleteStudentPartialExamRegistration = async ({
  partialExamID,
  studentID,
}: {
  partialExamID: number;
  studentID: number;
}): Promise<SuccessResponse> => {
  try {
    const response: AxiosResponse<SuccessResponse> = await axiosInstance.delete(
      `exam/delete-student-partial-exam-registration`,
      { data: { partialExamID, studentID } }
    );
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getLatestExamScheduleInfo: QueryFunction<ExamSchedule[]> = async ({ queryKey }) => {
  const [keyData] = queryKey;
  const { examInfoID, examID } = keyData as { examInfoID: number; examID: number };
  const res = await axiosInstance.post('exam/get-latest-exam-schedule-info', {
    examInfoID,
    examID,
  });
  return res.data as ExamSchedule[];
};

export const getExamStudentsStats: QueryFunction<ExamStats> = async ({ queryKey }) => {
  const [examID] = queryKey;
  const res = await axiosInstance.post('exam/get-exam-students-stats', {
    examID,
  });
  return res.data as ExamStats;
};

export const getCompletedSubjects: QueryFunction<StudentCompletedSubjects[]> = async ({
  queryKey,
}) => {
  const res = await axiosInstance.get('student/get-completed-subjects');
  return res.data as StudentCompletedSubjects[];
};
