import {
  HStack,
  Text,
  VStack,
  Button,
  Center,
  Spinner,
  Tooltip,
  Avatar,
  Stack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useGetSingleLectureInfo } from '../../../../services/queries';
import {
  useSendSingleLectureReportEmail,
  useSetAllStudentsNotPresent,
  useSetAllStudentsPresent,
  useSetStudentsOnLecturesMutation,
} from '../../../../services/mutations';
import { useState } from 'react';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import { setAllStudentsPresentMutation } from '../../../../services/api';
import useAuth from '../../../../hooks/useAuth.hooks';
import { BlueButton } from '../../../../components/general/BlueButton';

const LecturesAttendancesInfo = () => {
  const authContext = useAuth();
  const [isPresentModalOpen, setIsPresentModalOpen] = useState(false);
  const [isNotPresentModalOpen, setIsNotPresentModalOpen] = useState(false);
  const [addStudentsLoading, setAddStudentsLoading] = useState(false);
  const [setAsPresentLoading, setSetAsPresentLoading] = useState(false);
  const [setAsNotPresentLoading, setSetAsNotPresentLoading] = useState(false);
  const [sendReportLoading, setSendReportLoading] = useState(false);

  const { lectureID } = useParams();
  const { lectureNumber } = useParams();
  const addStudents = useSetStudentsOnLecturesMutation(Number(lectureID));
  const setAsPresent = useSetAllStudentsPresent(Number(lectureID));
  const setAsNotPresent = useSetAllStudentsNotPresent(Number(lectureID));
  const sendReport = useSendSingleLectureReportEmail();

  const lectureInfo = useGetSingleLectureInfo(Number(lectureID));
  const isProfessor = authContext.user?.role === 'PROFESSOR' ? false : true;

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      mt={5}
      w='800px'
      justify={'center'}
      align={'center'}
    >
      {lectureInfo.isLoading ? (
        <Center h={'10vh'}>
          <Spinner />
        </Center>
      ) : lectureInfo.error ? (
        ''
      ) : (
        <>
          <VStack align={'start'} w={'50%'} spacing={0}>
            <YesNoModal
              isOpen={isPresentModalOpen}
              onClose={() => {
                setIsPresentModalOpen(false);
                setSetAsPresentLoading(false);
              }}
              onConfirm={() => {
                setAsPresent({ lectureID: Number(lectureID) })
                  .catch((err) => {})
                  .finally(() => {
                    setTimeout(() => {
                      setSetAsPresentLoading(false);
                    }, 1000);
                  });
                setIsPresentModalOpen(false);
              }}
              title='Označi sve kao prisutne'
              message='Da li ste sigurni da želite označiti sve studente kao prisutne?'
            />
            <YesNoModal
              isOpen={isNotPresentModalOpen}
              onClose={() => {
                setIsNotPresentModalOpen(false);
                setSetAsNotPresentLoading(false);
              }}
              onConfirm={() => {
                setAsNotPresent({ lectureID: Number(lectureID) })
                  .catch((err) => {})
                  .finally(() => {
                    setTimeout(() => {
                      setSetAsNotPresentLoading(false);
                    }, 1000);
                  });
                setIsNotPresentModalOpen(false);
              }}
              title='Označi sve kao odsutne'
              message='Da li ste sigurni da želite označiti sve studente kao odsutne?'
            />
            <HStack>
              <Text>Redni broj predavanja:</Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {lectureNumber}
              </Text>
            </HStack>
            <HStack spacing={5}>
              <Text fontSize='md'>Profesor održao predavanje:</Text>
              <Tooltip
                key={lectureInfo.data?.id}
                label={lectureInfo.data?.firstName + ' ' + lectureInfo.data?.lastName}
              >
                <Avatar
                  size={'sm'}
                  name={lectureInfo.data?.firstName + ' ' + lectureInfo.data?.lastName}
                  src={lectureInfo.data?.profileImage}
                />
              </Tooltip>
            </HStack>
            <HStack>
              <Text fontSize='md'>Datum predavanja:</Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {lectureInfo.data?.date}
              </Text>
            </HStack>
            <HStack>
              <HStack>
                <Text fontSize='md'>Broj prisutnih:</Text>
                <Text fontWeight={'700'} fontSize={'lg'}>
                  {lectureInfo.data?.presentStudents}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize='md'>, u procentima:</Text>
                <Text fontWeight={'700'} fontSize={'lg'}>
                  {lectureInfo.data?.attendancePercentage}
                </Text>
              </HStack>
            </HStack>
          </VStack>
          <VStack w={'50%'}>
            <Stack py={{ base: 5, md: 0 }} direction={{ base: 'column', lg: 'row' }}>
              <BlueButton
                width='180px'
                height={'30px'}
                buttonText='Preuzmi izvještaj'
                isDisabled={isProfessor}
                onClick={() => {
                  setSendReportLoading(true);
                  sendReport(Number(lectureID))
                    .catch((err) => {})
                    .finally(() => {
                      setTimeout(() => {
                        setSendReportLoading(false);
                      }, 1000);
                    });
                }}
                isLoading={sendReportLoading}
              />
              <BlueButton
                buttonText='Dodaj sve studente'
                height={'30px'}
                width='180px'
                onClick={() => {
                  setAddStudentsLoading(true);
                  addStudents({ lectureID: Number(lectureID) })
                    .catch((err) => {})
                    .finally(() => {
                      setTimeout(() => {
                        setAddStudentsLoading(false);
                      }, 1000);
                    });
                }}
                isDisabled={isProfessor}
                isLoading={addStudentsLoading}
              />
            </Stack>
            <Stack direction={{ base: 'column', lg: 'row' }}>
              <BlueButton
                buttonText='Označi sve kao prisutan'
                height='30px'
                width='180px'
                onClick={() => {
                  setSetAsPresentLoading(true);
                  setIsPresentModalOpen(true);
                }}
                isDisabled={isProfessor}
                isLoading={setAsPresentLoading}
              />
              <BlueButton
                buttonText='Označi sve kao odsutan'
                height='30px'
                width='180px'
                onClick={() => {
                  setSetAsNotPresentLoading(true);
                  setIsNotPresentModalOpen(true);
                }}
                isDisabled={isProfessor}
                isLoading={setAsNotPresentLoading}
              />
            </Stack>
          </VStack>
        </>
      )}
    </Stack>
  );
};

export default LecturesAttendancesInfo;
