import * as yup from 'yup';

export const profCreatePostSchema = yup.object({
  subjectID: yup.number().required('Predmet je obavezan'),
  title: yup
    .string()
    .trim()
    .required('Prezime je obavezno')
    .max(100, 'Naslov ne smije biti duži od 100 karaktera'),
  text: yup
    .string()
    .trim()
    .required('Tekst je obavezan')
    .max(1000, 'Tekst ne smije biti duži od 1000 karaktera'),
  allStudents: yup.boolean().required('Obavezan odabir'),
});

export const assistCreatePostSchema = yup.object({
  subjectID: yup.number().required('Predmet je obavezan'),
  groupID: yup.number().nullable(),
  title: yup
    .string()
    .trim()
    .required('Prezime je obavezno')
    .max(100, 'Naslov ne smije biti duži od 100 karaktera'),
  text: yup
    .string()
    .trim()
    .required('Tekst je obavezan')
    .max(1000, 'Tekst ne smije biti duži od 1000 karaktera'),
  allStudents: yup.boolean().required('Obavezan odabir'),
});

export const staffEditPostSchema = yup.object({
  postID: yup.number().required('Objava je obavezna'),
  title: yup
    .string()
    .trim()
    .required('Prezime je obavezno')
    .max(100, 'Naslov ne smije biti duži od 100 karaktera'),
  text: yup
    .string()
    .trim()
    .required('Tekst je obavezan')
    .max(1000, 'Tekst ne smije biti duži od 1000 karaktera'),
});

export const postIDSchema = yup.object({
  postID: yup.number().required('Objava je obavezna'),
});

export type profCreatePostSchemaDto = yup.InferType<typeof profCreatePostSchema>;
export type assistCreatePostSchemaDto = yup.InferType<typeof assistCreatePostSchema>;
export type staffEditPostSchemaDto = yup.InferType<typeof staffEditPostSchema>;
export type postIDSchemaDto = yup.InferType<typeof postIDSchema>;
