import {
  Center,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../../components/general/BlueSubmitButton';
import { DateTimeInput } from '../../../../../components/general/DateTimeInput';
import { RedButton } from '../../../../../components/general/RedButton';
import {
  useGetExamInfo,
  useGetLatestExamScheduleInfo,
  useGetSingleExam,
} from '../../../../../services/queries';
import { HorizontalTextField } from '../../../NewSubject/comp/HorzintalTextField';
import useProf from '../../../../../hooks/useProf.hooks';
import { useRepeatExamMutation } from '../../../../../services/mutations';
import { repeatExamSchema } from '../../../../../validation/exam-schema';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  examID: number;
};

export const RepeatExamModal = ({ isOpen, onClose, examID }: Props) => {
  const exam = useGetSingleExam(examID);
  const profContext = useProf();

  const examInfoQuery = useGetExamInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const repeatExam = useRepeatExamMutation(examInfoQuery.data?.id, examID);
  const examsQuery = useGetLatestExamScheduleInfo(examInfoQuery.data?.id, examID);

  const [isLoading, setIsLoading] = useState(false);
  const [repeatPartials, setRepeatPartials] = useState(false);
  const [sameGroupsDateTime, setSameGroupsDateTime] = useState(false);

  //const editExamDatesInfo = useEditExamDatesInfoMutation(examInfo.data?.examScheduleID, examID);

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Ponovi ispit - {exam.data?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            {exam.isLoading ? (
              <Spinner />
            ) : exam.data ? (
              <Formik
                initialValues={{
                  examID,
                  registrationDeadline: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
                  nullificationDeadline: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14),
                  validUntil: exam.data.rawValidUntil ? new Date() : null,
                  sameGroupsDateTime: exam.data.sameGroupsDateTime,
                  dateTime: new Date(),
                  place: exam.data.place,
                  conditionExamID: null as number | null,
                }}
                validationSchema={repeatExamSchema}
                onSubmit={async (values, actions) => {
                  console.log(values);
                  setIsLoading(true);
                  try {
                    await repeatExam({ ...values, repeatPartials });
                    onClose();
                  } catch (error) {
                  } finally {
                    setTimeout(() => {
                      setIsLoading(false);
                    }, 1000);
                  }
                }}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <Stack
                      spacing={4}
                      justifyContent={{ base: 'start', md: 'center' }}
                      alignItems={'center'}
                    >
                      <Text fontSize='sm' color='gray.500'>
                        Ispit će biti kopiran u zadnji ispitni rok zajedno sa položenim rezultatima
                        studenata
                      </Text>
                      <HStack>
                        <Text>Da li želite ponoviti parcijalne ispite?</Text>
                        <Switch
                          isChecked={repeatPartials}
                          onChange={() => {
                            setRepeatPartials(!repeatPartials);
                          }}
                          isDisabled={true}
                          // treba dodati logiku za ponavljanje parcijalnih ispita.
                        />
                      </HStack>

                      <DateTimeInput
                        name='registrationDeadline'
                        label='Rok za prijavu ispita'
                        setFieldValue={setFieldValue}
                        placeholder='izaberite datum i vrijeme'
                        isRequired={true}
                      />
                      <DateTimeInput
                        name='nullificationDeadline'
                        label='Rok za poništavanje ispita'
                        setFieldValue={setFieldValue}
                        placeholder='izaberite datum i vrijeme'
                        isRequired={true}
                      />
                      <DateTimeInput
                        name='validUntil'
                        label='Položen ispit važi do'
                        setFieldValue={setFieldValue}
                        placeholder='Neograničeno'
                      />

                      {examsQuery.isLoading ? (
                        <Center>
                          <Spinner />
                        </Center>
                      ) : (
                        examsQuery.data &&
                        examsQuery.data.length > 0 && (
                          <Field name={'conditionExamID'}>
                            {({ field, form }: { field: any; form: any }) => (
                              <FormControl
                                id={'conditionExamID'}
                                isInvalid={
                                  form.errors['conditionExamID'] && form.touched['conditionExamID']
                                }
                              >
                                <VStack justify={'center'}>
                                  <Stack
                                    align={'center'}
                                    direction={{ base: 'column', md: 'row' }}
                                    spacing={{ base: 0, md: 5 }}
                                  >
                                    <VStack
                                      w={{ base: '100%', md: '50%' }}
                                      align={{ base: 'start', md: 'end' }}
                                    >
                                      <FormLabel>Uslovni ispit</FormLabel>
                                    </VStack>
                                    <VStack w={{ base: '100%', md: '50%' }} align={'start'}>
                                      <Select
                                        placeholder={'Izaberite ispit'}
                                        w={'250px'}
                                        {...field}
                                        onChange={(e) => {
                                          setFieldValue('conditionExamID', Number(e.target.value));
                                        }}
                                      >
                                        <option value={-1}>Nema</option>
                                        {examsQuery.data.length > 0 &&
                                          examsQuery.data.map((data, index) => (
                                            <option
                                              value={data.id}
                                              key={index}
                                            >{`${data.name}`}</option>
                                          ))}
                                      </Select>
                                    </VStack>
                                  </Stack>
                                  <ErrorMessage name={'conditionExamID'}>
                                    {(msg) => (
                                      <Text color='red' fontSize='sm'>
                                        {msg}
                                      </Text>
                                    )}
                                  </ErrorMessage>
                                </VStack>
                              </FormControl>
                            )}
                          </Field>
                        )
                      )}

                      <Divider />
                      <HStack>
                        <Text>Da li će ispit biti u isto vrijeme za sve studente?</Text>
                        <Switch
                          isChecked={sameGroupsDateTime}
                          onChange={() => {
                            setSameGroupsDateTime(!sameGroupsDateTime);
                          }}
                        />
                      </HStack>

                      {sameGroupsDateTime ? (
                        <VStack>
                          <DateTimeInput
                            name='dateTime'
                            label='Datum i vrijeme ispita'
                            setFieldValue={setFieldValue}
                            placeholder='izaberite datum i vrijeme'
                          />
                          <HorizontalTextField
                            name='place'
                            type='text'
                            label='Mjesto ispita'
                            width='250px'
                          />
                        </VStack>
                      ) : (
                        <Text fontSize='sm' color='gray.500'>
                          Nakon kreiranja ispita, moći će te odrediti datum i vrijeme za svaku grupu
                          posebno.
                        </Text>
                      )}

                      <Divider />

                      <HStack py={5} justify={'center'}>
                        <BluesubmitButton
                          buttonText='Ponovi ispit'
                          loadingText='ponavljanje u toku'
                          isLoading={isLoading}
                          /*  isDisabled={
                            values.nullificationDeadline ===
                            new Date(examInfo.data.rawNullificationDeadline)
                          } */
                        />
                        <RedButton buttonText='Odustani' onClick={onClose} />
                      </HStack>
                    </Stack>
                  </Form>
                )}
              </Formik>
            ) : (
              <Text>Nedostupni podaci</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
