import {
  Center,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../components/general/BlueButton';
import { useGetStudentsLectureInfo } from '../../../services/queries';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { StudLectureAttendancesTable } from './comp/attendancesTable';

type Props = {};

export const StudentLecturesPage = (props: Props) => {
  const studentContext = useStudentContext();
  const data = useGetStudentsLectureInfo(studentContext.selectedSubjectID);
  const navigate = useNavigate();
  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={10}>
          Predavanja
        </Heading>
      </HStack>
      <VStack pb={5}>
        <BlueButton
          fontSize='md'
          height='60px'
          width='250px'
          buttonText='Dodajte novo prisustvo'
          onClick={() => {
            navigate('opcije');
          }}
        />
      </VStack>
      <VStack pb={10} w={'100%'}>
        {data.isLoading ? (
          <Center h={'30vh'}>
            <Spinner />
          </Center>
        ) : data.data ? (
          <VStack spacing={0}>
            <Text fontSize={'lg'}>
              Ukupno prisustava: {data.data.numberOfAttendances} /{' '}
              {data.data.numberOfLecturesExercises}
            </Text>

            <HStack>
              <Text fontSize={'lg'}>Uslov za potpis trenutno: </Text>
              {data.data.fullfiledSignatureCondition ? (
                <Text
                  fontWeight={'700'}
                  color={'green.400'}
                  border={'2px solid'}
                  borderRadius={'xl'}
                  width={'40px'}
                  textAlign={'center'}
                >
                  DA
                </Text>
              ) : (
                <Text
                  fontWeight={'700'}
                  color={'red.400'}
                  border={'2px solid'}
                  borderRadius={'xl'}
                  width={'40px'}
                  textAlign={'center'}
                >
                  NE
                </Text>
              )}
            </HStack>
            {data.data.absenceAllowed !== 0 && (
              <Text fontSize={'lg'}>Broj dozvoljenih odsustava: {data.data.absenceAllowed}</Text>
            )}
            <Text
              pb={data.data.numberOfAttendances === data.data.numberOfLecturesExercises ? 0 : 5}
              fontSize={'lg'}
            >
              Broj bodova od predavanja: {data.data.points ?? 0}
            </Text>
            {data.data.absenceAllowed !== 0 && data.data.absenceAllowed <= 1 && (
              <>
                <Text fontSize={'lg'} fontWeight={'700'} color={'red.500'}>
                  OPREZ !
                </Text>
                <Text pb={5} fontSize={'lg'} fontWeight={'700'} color={'red.500'}>
                  Blizu ste da ostanete bez potpisa
                </Text>
              </>
            )}
            {data.data.numberOfAttendances === data.data.numberOfLecturesExercises &&
              data.data.numberOfLecturesExercises !== 0 && (
                <Text pb={5} fontSize={'lg'} fontWeight={'700'} color={'green.500'}>
                  Svaka čast na redovnom dolasku
                </Text>
              )}
            <StudLectureAttendancesTable attendances={data.data.attendances} />
          </VStack>
        ) : (
          <Text fontSize={'xl'}>Nema informacija</Text>
        )}
      </VStack>
    </Flex>
  );
};
