import {
  Avatar,
  Center,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { BlueButton } from '../../../../components/general/BlueButton';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import {
  useGetAssistantsBySubjectAndGroup,
  useGetExercisesInfo,
} from '../../../../services/queries';
import { useToggleAssistantOnGroupMutation } from '../../../../services/mutations';
import { useState } from 'react';

interface LoadingStates {
  [key: number]: boolean;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  groupID: number;
};

export const AssignAssistantsToGroupsModal = ({ isOpen, onClose, groupID }: Props) => {
  const profContext = useProf();
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const authContext = useAuth();
  const toast = useToast();

  const assistantsOnSubject = useGetAssistantsBySubjectAndGroup({
    subjectID: profContext.selectedSubjectID,
    groupID,
  });

  const exercisesInfoQuery = useGetExercisesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const toggleAssistant = useToggleAssistantOnGroupMutation(
    groupID,
    profContext.selectedSubjectID,
    exercisesInfoQuery.data?.academicYearID,
    exercisesInfoQuery.data?.id
  );

  const handleAssignAssistant = (assistantID: number) => {
    console.log(assistantID);
    setLoadingStates((prev) => ({ ...prev, [assistantID]: true }));
    toggleAssistant({ assistantID, groupID })
      .catch(() => {})
      .finally(() => {
        setTimeout(() => {
          setLoadingStates((prev) => ({ ...prev, [assistantID]: false }));
        }, 1000);
      });
  };

  return (
    <>
      <Modal isCentered size={'md'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Odobri asistente na grupi</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            textAlign={'center'}
            justifyContent={'center'}
            alignContent={'center'}
            px={7}
            pb={6}
          >
            <TableContainer mt={'0rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
              <Table variant='striped' colorScheme='facebook' size='sm'>
                <Thead>
                  <Tr>
                    <Th textAlign='center'>Slika</Th>
                    <Th textAlign='center'>Ime i Prezime</Th>
                    <Th textAlign='center'>Odobren</Th>
                    <Th textAlign='center'>Opcije</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {assistantsOnSubject.isLoading ? (
                    <Tr>
                      <Td colSpan={4}>
                        <Center>
                          <Spinner />
                        </Center>
                      </Td>
                    </Tr>
                  ) : assistantsOnSubject.data && assistantsOnSubject.data.length > 0 ? (
                    assistantsOnSubject.data.map((assistant) => (
                      <Tr key={assistant.id}>
                        <Td textAlign='center'>
                          <Avatar size={'sm'} src={assistant.profileImage} />
                        </Td>
                        <Td textAlign='center'>
                          {assistant.firstName} {assistant.lastName}
                        </Td>
                        <Td textAlign='center'>
                          <Checkbox isChecked={assistant.connected} colorScheme='green'></Checkbox>
                        </Td>
                        <Td textAlign='center'>
                          <BlueButton
                            width='55px'
                            height='25px'
                            buttonText={assistant.connected ? 'ukloni' : 'odobri'}
                            isLoading={loadingStates[assistant.id]}
                            onClick={() => {
                              handleAssignAssistant(assistant.id);
                            }}
                          />
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={3}>
                        <Center>Nema asistenata na predmetu</Center>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Text px={5} pt={4} fontSize={'sm'} color={'gray.200'}>
              Ovdje možete odobriti dodatne asistente za vašu grupu. Važno je naglasiti da će
              odobreni asistenti imati ista ovlaštenja kao i vi na ovoj grupi.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
