import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Heading,
  Stack,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { EditDifficultyFactor } from './comp/EditDifficultyFactor';
import { EditSubjectInfo } from './comp/EditSubjectInfo';
import { EditLecturesInfo } from './comp/EditLecturesInfo';
import { EditExercisesInfo } from './comp/EditExercisesInfo';
import { RedButton } from '../../../components/general/RedButton';
import { DeleteSubjectModal } from './comp/DeleteSubjectModal';

type Props = {};

export const OptionsPage = (props: Props) => {
  const {
    isOpen: isDeleteSubjectOpen,
    onOpen: onDeleteSubjectOpen,
    onClose: onDeleteSubjectClose,
  } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
      pos={'relative'}
    >
      <DeleteSubjectModal isOpen={isDeleteSubjectOpen} onClose={onDeleteSubjectClose} />
      <Stack pos={{ base: 'absolute' }} top={{ base: '20px' }} left={{ base: 4 }}>
        <ArrowBackButton
          onClick={() => {
            navigate('/profesori');
          }}
        />
      </Stack>
      <HStack pb={10} justify={'center'}>
        <Heading pb={4} fontSize={'xl'}>
          Postavke predmeta
        </Heading>
      </HStack>
      <HStack w={{ base: '90vw', md: '500px' }} justify={'center'}>
        <Accordion w={'100%'} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  Osnovne informacije od predmeta
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <EditSubjectInfo />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  Težinski faktor
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <EditDifficultyFactor />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  Podaci od predavanja
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <EditLecturesInfo />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  Podaci od vježbi
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <EditExercisesInfo />
            </AccordionPanel>
          </AccordionItem>
          {
            //*********  EDIT EXAMS INFO  *********}
            /*
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  Podaci od ispita
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}></AccordionPanel>
          </AccordionItem> */
          }
        </Accordion>
      </HStack>
      <VStack pt={5} justify={'center'}>
        <RedButton buttonText='Izbriši predmet' onClick={onDeleteSubjectOpen} />
      </VStack>
    </Flex>
  );
};
