import * as yup from 'yup';

export const createSubjectActivitySchema = yup.object({
  subjectActivityInfoID: yup.number().required(),
  name: yup
    .string()
    .trim()
    .required('Naziv aktivnosti je obavezan')
    .max(30, 'Naziv ne može biti duži od 30 karaktera'),
  text: yup.string().trim().required('Opis aktivnosti je obavezan'),
  type: yup
    .string()
    .trim()
    .required('Tip aktivnosti je obavezan')
    .max(30, 'Tip ne može biti duži od 30 karaktera'),
  deadlineDate: yup.string().nullable(),
  dateTime: yup.string().nullable(),
  maxScore: yup
    .number()
    .required('Maksimalni broj bodova je obavezan')
    .min(2, 'Maksimalni broj bodova mora biti pozitivan broj, minimalno 2 boda!')
    .integer('Maksimalni broj bodova mora biti cijeli broj!'),
  minPassingScore: yup
    .number()
    .required('Minimalni broj bodova za prolaz je obavezan')
    .min(0, 'Minimalni broj bodova za prolaz mora biti pozitivan broj!')
    .integer('Maksimalni broj bodova mora biti cijeli broj!')
    .test(
      'is-less-than-maxScore',
      'Broj bodova za prolaz ne može biti veći od maksimalnog broja bodova',
      function (value) {
        const { maxScore } = this.parent;
        return value <= maxScore;
      }
    ),
  difficultyFactor: yup
    .number()
    .required('Težinski faktor je obavezan')
    .min(0, 'Težinski faktor mora biti pozitivan broj!')
    .max(100, 'Težinski faktor ne može biti veći od 100!')
    .integer('Maksimalni broj bodova mora biti cijeli broj!'),
  groupID: yup.number().nullable(),
});

export const editSubjectActivitySchema = yup.object({
  subjectActivityID: yup.number().required().integer(),
  name: yup
    .string()
    .trim()
    .required('Naziv aktivnosti je obavezan')
    .max(30, 'Naziv ne može biti duži od 30 karaktera'),
  text: yup.string().trim().required('Opis aktivnosti je obavezan'),
  type: yup
    .string()
    .trim()
    .required('Tip aktivnosti je obavezan')
    .max(30, 'Tip ne može biti duži od 30 karaktera'),
  deadlineDate: yup.string().nullable(),
  dateTime: yup.string().nullable(),
  maxScore: yup
    .number()
    .required('Maksimalni broj bodova je obavezan')
    .min(2, 'Maksimalni broj bodova mora biti pozitivan broj, minimalno 2 boda!')
    .integer('Maksimalni broj bodova mora biti cijeli broj!'),
  minPassingScore: yup
    .number()
    .required('Minimalni broj bodova za prolaz je obavezan')
    .min(0, 'Minimalni broj bodova za prolaz mora biti pozitivan broj!')
    .integer('Maksimalni broj bodova mora biti cijeli broj!')
    .test(
      'is-less-than-maxScore',
      'Broj bodova za prolaz ne može biti veći od maksimalnog broja bodova',
      function (value) {
        const { maxScore } = this.parent;
        return value <= maxScore;
      }
    ),
  difficultyFactor: yup
    .number()
    .required('Težinski faktor je obavezan')
    .min(0, 'Težinski faktor mora biti pozitivan broj!')
    .max(100, 'Težinski faktor ne može biti veći od 100!')
    .integer('Maksimalni broj bodova mora biti cijeli broj!'),
});

export const setStudentScoreSubjectActivitySchema = yup.object({
  studentID: yup.number().required(),
  subjectActivityID: yup.number().required(),
  score: yup.number().required(),
});

export type createSubjectActivityDto = yup.InferType<typeof createSubjectActivitySchema>;
export type editSubjectActivityDto = yup.InferType<typeof editSubjectActivitySchema>;
export type setStudentScoreSubjectActivityDto = yup.InferType<
  typeof setStudentScoreSubjectActivitySchema
>;
