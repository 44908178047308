import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { studentChangePasswordSchema } from '../../../validation/student-schema';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { BluesubmitButton } from '../../../components/general/BlueSubmitButton';
import { useStudentChangePasswordMutation } from '../../../services/mutations';

type Props = {};

export const StudentPasswordPage = (props: Props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const changePassword = useStudentChangePasswordMutation();

  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleRepeatPasswordVisibility = () => setShowRepeatPassword(!showRepeatPassword);

  return (
    <Flex
      flexDirection='column'
      p='5'
      bg={useColorModeValue('white', 'gray.900')}
      w={'100%'}
      justify={'center'}
    >
      <Stack display={{ md: 'none' }} pos={'absolute'} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student/profil');
          }}
        />
      </Stack>

      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={10}>
          Promjena šifre
        </Heading>
      </HStack>
      <VStack justify={'center'}>
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            repeatedPassword: '',
          }}
          validationSchema={studentChangePasswordSchema}
          onSubmit={async (values, actions) => {
            setIsLoading(true);
            changePassword(values).catch((err) => {});
            setIsLoading(false);
          }}
        >
          {() => (
            <Form>
              <Stack spacing={4}>
                <Field name='oldPassword'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='oldPassword'
                      isRequired
                      isInvalid={form.errors.oldPassword && form.touched.oldPassword}
                    >
                      <FormLabel>Trenutna šifra</FormLabel>
                      <InputGroup>
                        <Input type={showOldPassword ? 'text' : 'password'} {...field} />
                        <InputRightElement>
                          <IconButton
                            aria-label={showOldPassword ? 'Hide password' : 'Show password'}
                            icon={showOldPassword ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={toggleOldPasswordVisibility}
                            size='sm'
                          />
                        </InputRightElement>
                      </InputGroup>
                      <ErrorMessage name='oldPassword'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name='newPassword'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='newPassword'
                      isRequired
                      isInvalid={form.errors.newPassword && form.touched.newPassword}
                    >
                      <FormLabel>Nova šifra</FormLabel>
                      <InputGroup>
                        <Input type={showNewPassword ? 'text' : 'password'} {...field} />
                        <InputRightElement>
                          <IconButton
                            aria-label={showNewPassword ? 'Hide password' : 'Show password'}
                            icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={toggleNewPasswordVisibility}
                            size='sm'
                          />
                        </InputRightElement>
                      </InputGroup>
                      <ErrorMessage name='newPassword'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name='repeatedPassword'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      id='repeatedPassword'
                      isRequired
                      isInvalid={form.errors.repeatedPassword && form.touched.repeatedPassword}
                    >
                      <FormLabel>Ponovite novu šifru</FormLabel>
                      <InputGroup>
                        <Input type={showRepeatPassword ? 'text' : 'password'} {...field} />
                        <InputRightElement>
                          <IconButton
                            aria-label={showRepeatPassword ? 'Hide password' : 'Show password'}
                            icon={showRepeatPassword ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={toggleRepeatPasswordVisibility}
                            size='sm'
                          />
                        </InputRightElement>
                      </InputGroup>
                      <ErrorMessage name='repeatedPassword'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                      <FormHelperText mt={5}>
                        Biti ćete izlogovani iz svih uređaja nakon promjene šifre.
                      </FormHelperText>
                    </FormControl>
                  )}
                </Field>

                <Stack spacing={10} pt={10}>
                  <BluesubmitButton
                    buttonText='Promijeni šifru'
                    loadingText='Šifra se mijenja...'
                    isLoading={isLoading}
                  />
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </VStack>
    </Flex>
  );
};
