import {
  Box,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useStudentAttendLecture, useStudentJoinSubject } from '../../../../services/mutations';
import { useStudentContext } from '../../../../hooks/useStudent.hooks';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../../components/general/ArrowBackButton';
import { SuccessfullScanModal } from '../../comp/SuccessfulScanModal';
import { useState } from 'react';
import FailedScanModal from '../../comp/failedScanModal';
import { QRScanner } from '../../../../services/QRScanner';

export const NewAttendanceQRScan = () => {
  const [text, setText] = useState(null);
  const [permissionDenied, setPermissionDenied] = useState<boolean>(false);
  const {
    isOpen: isFailedScanOpen,
    onOpen: onFailedScanOpen,
    onClose: onFailedScanClose,
  } = useDisclosure();
  const {
    isOpen: isSuccessScanOpen,
    onOpen: onSuccessScanOpen,
    onClose: onSuccessScanClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const studentContext = useStudentContext();
  const joinLecture = useStudentAttendLecture(studentContext.selectedSubjectID);
  const toast = useToast();

  const handleScan = async (data: string) => {
    /*  toast.promise(joinLecture(data), {
      success: { title: 'Uspješno', description: 'Uspješno evidentirano prisustvo' },
      error: { title: 'Neuspješno', description: 'došlo je do greške.' },
      loading: { title: 'Provjeravamo...', description: 'Molimo vas sačekajte' },
    });
    navigate('/student/predavanja'); */
    navigate('/student/predavanja');
    try {
      await joinLecture(data);
      //onSuccessScanOpen();
    } catch (error: any) {}
  };

  const handlePermissionDenied = () => {
    setPermissionDenied(true);
  };

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <SuccessfullScanModal
        isOpen={isSuccessScanOpen}
        onClose={onSuccessScanClose}
        navigateTo='/student/predavanja'
        text={text}
      />
      <FailedScanModal
        isOpen={isFailedScanOpen}
        onClose={onFailedScanClose}
        navigateTo='/student/predavanja'
        text={text}
      />

      <Stack pos={'absolute'} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student/predavanja/opcije');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={'10vh'}>
          Skeniraj QR kod
        </Heading>
      </HStack>
      <HStack justify={'center'}>
        <Box>
          <QRScanner onScan={handleScan} />
          {permissionDenied && <Text color='red.500'>Molim vas da odobrite pristup kameri.</Text>}
        </Box>
      </HStack>
    </Flex>
  );
};
