import { Flex, HStack, Heading, VStack, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import useAuth from '../../../hooks/useAuth.hooks';
import useProf from '../../../hooks/useProf.hooks';
import { StudentsWantToJoinSubjectTable } from './comp/StudentsWantToJoinSubjectTable';

type Props = {};

export const ApproveStudentsToSubjects = (props: Props) => {
  const navigate = useNavigate();
  const profContext = useProf();
  const authContext = useAuth();

  const handleBackClick = () => {
    navigate('/profesori/studenti');
  };

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      justify={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <HStack w={'100%'} justify={'space-between'}>
        <HStack>
          <ArrowBackButton onClick={handleBackClick} />
        </HStack>
        <Heading pb={4} fontSize={'xl'} textAlign={'center'}>
          Odobravanje studenata na predmetu
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      <VStack w={'100%'}>
        <StudentsWantToJoinSubjectTable />
      </VStack>
    </Flex>
  );
};
