import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';
import useProf from '../../../../hooks/useProf.hooks';
import { useStaffCreateStudentMutation } from '../../../../services/mutations';
import { staffCreateStudentSchema } from '../../../../validation/student-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateStudentModal = ({ isOpen, onClose }: Props) => {
  const profContext = useProf();
  const [isLoading, setIsLoading] = useState(false);

  const staffCreateStudent = useStaffCreateStudentMutation();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);

    try {
      await staffCreateStudent(values);
      onClose();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Dodaj Studenta na platformu</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Formik
              initialValues={{
                subjectID: profContext.selectedSubjectID,
                indexNumber: '',
                firstName: '',
                lastName: '',
                email: '',
              }}
              validationSchema={staffCreateStudentSchema}
              onSubmit={(values, actions) => {
                handleSubmit(values);
              }}
            >
              {() => (
                <Form>
                  <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                    <HorizontalTextField
                      name='indexNumber'
                      type='text'
                      label='Broj Indexa'
                      width='200px'
                    />
                    <HorizontalTextField name='firstName' type='text' label='Ime' width='200px' />
                    <HorizontalTextField
                      name='lastName'
                      type='text'
                      label='Prezime'
                      width='200px'
                    />
                    <HorizontalTextField name='email' type='email' label='Email' width='200px' />

                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Kreiraj studenta'
                        loadingText='student se kreira'
                        isLoading={isLoading}
                      />
                      <RedButton buttonText='Odustani' onClick={onClose} />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
