import { Button, HStack, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AcademicYearSelectionField } from '../../../../components/general/academicYearSelectionField';
import { BlueButton } from '../../../../components/general/BlueButton';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetAcademicYears, useGetSubjectActivitiesInfo } from '../../../../services/queries';

type Props = {};

export const SubjectActivitesGroupInfo = (props: Props) => {
  const profContext = useProf();
  const years = useGetAcademicYears(profContext.selectedSubjectID);
  const navigate = useNavigate();
  const subjectActivitiesQuery = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w={{ base: '90vw', md: '85%', lg: '700px' }}
      justify='space-around'
      pb={10}
    >
      {/*  <CreateGroupModal isOpen={isCreateGroupOpen} onClose={onCreateGroupClose} /> */}
      <VStack align={'start'} spacing={0}>
        <AcademicYearSelectionField
          years={years}
          handleOnChangeAcademicYear={handleOnChangeAcademicYear}
          profContext={profContext}
        />

        {subjectActivitiesQuery.isLoading ? (
          <HStack w='263px' h='132px' justify={'center'}>
            <Spinner />
          </HStack>
        ) : subjectActivitiesQuery.data ? (
          <>
            <HStack pt={'10px'} align={'center'}>
              <Text>Težinski faktor po grupi: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {subjectActivitiesQuery.data.difficultyFactorGroup}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Broj Grupa: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {subjectActivitiesQuery.data.groups.length}
              </Text>
            </HStack>
          </>
        ) : (
          <VStack align={'start'}>
            <Text>Kreirana je nova akademska godina.</Text>
            <Text>Da li želite mjenjati podatke o vježbama?</Text>
            <Button w={'100%'} bgColor={'blue.600'}>
              Uvezi iz prošle godine
            </Button>
            <Button w={'100%'} bgColor={'blue.600'}>
              Unesi nove podatke
            </Button>
          </VStack>
        )}
      </VStack>
      <Stack pt={{ base: 5, md: 0 }} direction={{ base: 'row', md: 'column' }} justify={'center'}>
        <BlueButton
          buttonText='Dodaj Grupu'
          onClick={() => {
            navigate('/profesori/vjezbe');
          }}
        />
      </Stack>
      subj
    </Stack>
  );
};
