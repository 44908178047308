import {
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetExerciseGroups, useGetExercisesInfo } from '../../../../services/queries';
import { CreateGroupModal } from './CreateGroupModal';
import Groups from './Groups';

const ExerciseGroups = () => {
  const authContext = useAuth();
  const profContext = useProf();
  const {
    isOpen: isCreateGroupOpen,
    onOpen: onCreateGroupOpen,
    onClose: onCreateGroupClose,
  } = useDisclosure();

  const exercisesInfoQuery = useGetExercisesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const exerciseGroupsQuery = useGetExerciseGroups({
    academicYearID: exercisesInfoQuery.data?.academicYearID,
    exerciseInfoID: exercisesInfoQuery.data?.id,
  });

  return (
    <VStack w='100%' maxW={'950px'}>
      <CreateGroupModal isOpen={isCreateGroupOpen} onClose={onCreateGroupClose} />
      <Heading fontSize={'xl'} pb={10}>
        Grupe
      </Heading>
      <VStack w='100%'>
        {exerciseGroupsQuery.isLoading ? (
          <Center h={'15vh'}>
            <Spinner />
          </Center>
        ) : exerciseGroupsQuery.data && exerciseGroupsQuery.data.length > 0 ? (
          <SimpleGrid
            columns={{
              sm: 1,
              md: exerciseGroupsQuery.data.length >= 2 ? 2 : 1,
              xl:
                exerciseGroupsQuery.data.length === 2
                  ? 2
                  : exerciseGroupsQuery.data.length === 1
                  ? 1
                  : 3,
            }}
            spacing={'30px'}
          >
            {exerciseGroupsQuery.data.map((exerciseGroup, index) => (
              <Center key={exerciseGroup.groupID}>
                <Groups group={exerciseGroup} />
              </Center>
            ))}
          </SimpleGrid>
        ) : (
          <Center h={'15vh'} w='50vh'>
            <VStack>
              <Text>Predmet nema grupa, dodajte novu grupu</Text>
            </VStack>
          </Center>
        )}
      </VStack>
    </VStack>
  );
};

export default ExerciseGroups;
