import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { partialExamPreview } from "../../../../../models/exams";
import CreatePartialExamMultiStepForm from "./CreatePartialExamMultiStepForm";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  examID: number | null;
  examScheduleID: number | null;
  partialExams: partialExamPreview[];
};

export const CreatePartialExamModal = ({
  isOpen,
  onClose,
  examID,
  partialExams,
  examScheduleID,
}: Props) => {
  return (
    <>
      <Modal isCentered size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>
            Dodaj novi parcijalni ispit
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            textAlign={"center"}
            justifyContent={"center"}
            alignContent={"center"}>
            <CreatePartialExamMultiStepForm
              examScheduleID={examScheduleID}
              examID={examID}
              partialExams={partialExams}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
