import { Outlet } from 'react-router-dom';
import { AdminSidebarNav } from './comp/SidebarNav';

type Props = {};

export const AdminLayout = (props: Props) => {
  return (
    <AdminSidebarNav>
      <Outlet />
    </AdminSidebarNav>
  );
};
