// ImageUpload.tsx
import { CloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { FaCamera, FaCheck } from 'react-icons/fa';
import useAuth from '../../hooks/useAuth.hooks';
import { useChangeProfileImgMutation } from '../../services/mutations';

interface ImageUploadProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ImageUploadModal: React.FC<ImageUploadProps> = ({ isOpen, onClose }) => {
  const authContext = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState<string | null>(authContext.user?.profileImage || null);
  const [file, setFile] = useState<File | null>(null); // [1]
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const changeProfileImage = useChangeProfileImgMutation();

  const handleFileChange = (): void => {
    if (file) {
      setIsLoading(true);
      changeProfileImage({ file: file })
        .catch(() => {
          setIsLoading(false);
        })
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
            onClose();
            setFile(null);
            setPreview(authContext.user?.profileImage || null);
          }, 1000);
        });
    } else {
      toast({
        title: 'Niste odabrali sliku!',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const previewHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      setPreview(URL.createObjectURL(file));
      setFile(file); // [2]
    }
  };

  return (
    <Modal
      isCentered
      size={'xl'}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setFile(null);
        setPreview(authContext.user?.profileImage || null);
        setIsLoading(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Promjena profilne slike</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          <VStack align={'center'} spacing={4}>
            {preview ? (
              <Avatar pb={2} boxSize={'250px'} src={preview} />
            ) : (
              <Avatar pb={2} boxSize={'250px'} name={authContext.user?.profileImage} />
            )}
            <Input
              type='file'
              ref={fileInputRef}
              onChange={previewHandler}
              accept='image/*'
              hidden
            />

            <HStack>
              <Button
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                <Icon as={FaCamera} />
              </Button>
              <Button
                onClick={() => {
                  handleFileChange();
                }}
                bg={'green.600'}
                isDisabled={!file}
                isLoading={isLoading}
                loadingText='Slika se uploaduje...'
              >
                <Icon as={FaCheck} />
              </Button>
              <Button
                onClick={() => {
                  onClose();
                  setFile(null);
                  setPreview(authContext.user?.profileImage || null);
                  setIsLoading(false);
                }}
                bg={'red.600'}
              >
                <Icon as={CloseIcon} />
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
