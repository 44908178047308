import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetExercises, useGetLectures } from '../../../../services/queries';
import { QRCodeModal } from '../../comp/QRCodeModal';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import useAuth from '../../../../hooks/useAuth.hooks';
import { EditExerciseModal } from './EditExerciseModal';
import {
  useDeleteExerciseMutation,
  useToggleExerciseScanner,
} from '../../../../services/mutations';

interface LoadingStates {
  [key: number]: boolean;
}

export const ExercisesTable = () => {
  const profContext = useProf();
  const authContext = useAuth();
  const { groupID } = useParams();
  const toast = useToast();

  const exercisesQuery = useGetExercises(Number(groupID));

  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isQRCodeOpen, onOpen: onQRCodeOpen, onClose: onQRCodeClose } = useDisclosure();
  const [deleteExerciseOpen, setDeleteExerciseOpen] = useState(false);
  const [QRCode, setQRCode] = useState('');
  const [exerciseID, setExerciseID] = useState(Number || null);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const deleteExercise = useDeleteExerciseMutation(Number(groupID));

  const switchScanner = useToggleExerciseScanner(Number(groupID));

  const handleOpenQRCodeModal = (QRCode: string) => {
    setQRCode(QRCode);
    onQRCodeOpen();
  };

  const handleOpenEditModal = (exerciseID: number) => {
    setExerciseID(exerciseID);
    onEditOpen();
  };

  const deleteExerciseHandler = (exerciseID: number) => {
    setExerciseID(exerciseID);
    setDeleteExerciseOpen(true);
  };

  return (
    <>
      <QRCodeModal
        isOpen={isQRCodeOpen}
        onClose={onQRCodeClose}
        QRCode={QRCode}
        title='QR kod vježbi'
        link='student/vjezbe'
      />
      <EditExerciseModal isOpen={isEditOpen} onClose={onEditClose} exerciseID={exerciseID} />
      <YesNoModal
        isOpen={deleteExerciseOpen}
        onClose={() => setDeleteExerciseOpen(false)}
        onConfirm={() => {
          deleteExercise(exerciseID).catch((err) => {});
          setDeleteExerciseOpen(false);
        }}
        title='Brisanje vježbi'
        message='Da li ste sigurni da želite izbrisati vježbe?'
      />
      <TableContainer
        mt={'3rem'}
        w={'1100px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center' w={'5%'}>
                RB
              </Th>
              <Th textAlign='center' w={'30%'}>
                Naslov vježbi
              </Th>
              <Th textAlign='center' w={'5%'}>
                Broj Časova
              </Th>
              <Th textAlign='center' w={'15%'}>
                Datum vježbi
              </Th>
              <Th textAlign='center' w={'10%'}>
                Skeniranje
              </Th>
              <Th textAlign='center' w={'10%'}>
                Prisustva
              </Th>
              <Th textAlign='center' w={'10%'}>
                QR
              </Th>
              <Th textAlign='center' w={'10%'}>
                Opcije
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {exercisesQuery.isLoading ? (
              <Tr>
                <Td colSpan={8}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : exercisesQuery.data && exercisesQuery.data.length > 0 ? (
              exercisesQuery.data.map((exercise, index) => (
                <Tr key={exercise.id}>
                  <Td textAlign='center' w={'5%'}>
                    {index + 1}
                  </Td>
                  <Td textAlign='center' w={'35%'}>
                    {exercise.name}
                  </Td>
                  <Td textAlign='center' w={'5%'}>
                    {exercise.classHours}
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    {exercise.date}
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <Switch
                      size='sm'
                      isDisabled={
                        authContext.user?.role === 'PROFESSOR' || loadingStates[exercise.id]
                      }
                      isChecked={exercise.scannerActive}
                      onChange={() => {
                        try {
                          setLoadingStates((prev) => ({ ...prev, [exercise.id]: true }));
                          switchScanner(exercise.id);
                        } catch (error) {
                        } finally {
                          setTimeout(() => {
                            setLoadingStates((prev) => ({ ...prev, [exercise.id]: false }));
                          }, 1000);
                        }
                      }}
                    />
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <Button
                      as={NavLink}
                      to={`${index + 1}/${exercise.id}`}
                      size='xs'
                      colorScheme='blue'
                    >
                      Prisustva
                    </Button>
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <Button
                      size='xs'
                      colorScheme='blue'
                      onClick={() => {
                        handleOpenQRCodeModal(exercise.QRCode);
                      }}
                    >
                      QR kod
                    </Button>
                  </Td>
                  <Td textAlign='center' w={'10%'}>
                    <EditIcon
                      color={'white'}
                      boxSize={5}
                      mr={'10px'}
                      onClick={() => {
                        if (authContext.user?.role === 'ASSISTANT') {
                          handleOpenEditModal(exercise.id);
                        } else {
                          toast({
                            position: 'top',
                            title: 'Nemate ovlaštenje za ovu akciju',
                            status: 'warning',
                            duration: 3000,
                          });
                        }
                      }}
                      _hover={{ cursor: 'pointer', color: 'green.500' }}
                    />
                    <DeleteIcon
                      color={'white'}
                      boxSize={5}
                      onClick={() => {
                        if (authContext.user?.role === 'ASSISTANT') {
                          deleteExerciseHandler(exercise.id);
                        } else {
                          toast({
                            position: 'top',
                            title: 'Nemate ovlaštenje za ovu akciju',
                            status: 'warning',
                            duration: 3000,
                          });
                        }
                      }}
                      _hover={{ cursor: 'pointer', color: 'red.500' }}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <Center>Nema vježbi</Center>
                </Td>
              </Tr>
            )}
          </Tbody>
          {exercisesQuery.data && exercisesQuery.data.length > 5 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center' w={'5%'}>
                  RB
                </Th>
                <Th textAlign='center' w={'35%'}>
                  Naslov predavanja
                </Th>
                <Th textAlign='center' w={'5%'}>
                  Broj Časova
                </Th>
                <Th textAlign='center' w={'10%'}>
                  Datum predavanja
                </Th>
                <Th textAlign='center' w={'10%'}>
                  Skeniranje
                </Th>
                <Th textAlign='center' w={'10%'}>
                  prisustva
                </Th>
                <Th textAlign='center' w={'10%'}>
                  QR
                </Th>
                <Th textAlign='center' w={'10%'}>
                  Opcije
                </Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
