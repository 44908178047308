import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Select,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import {
  useFacultysQuery,
  useStudyProgramsQuery,
  useUniversitiesQuery,
} from '../../../services/queries';
import { axiosInstance } from '../../../utils/axiosInstance';
import { newSubjectDto, newSubjectSchema } from '../../../validation/newSubject.schema';
import { HorizontalTextField } from './comp/HorzintalTextField';
import { SelectionField } from './comp/selectionField';

const NewSubject = () => {
  const [universityID, setUniversityID] = useState(0);
  const [facultyID, setFacultyID] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  const universityQuery = useUniversitiesQuery();
  const facultyQuery = useFacultysQuery(universityID);
  const studyProgramsQuery = useStudyProgramsQuery(facultyID);

  const handleUniversityChange = (universityID: number) => {
    setUniversityID(universityID);
    setFacultyID(0);
  };

  const handleFacultyChange = (facultyID: number) => {
    setFacultyID(facultyID);
  };

  const sumDF = (values: newSubjectDto) => {
    let sum = 100;
    sum -= values.lecturesInfo.difficultyFactor;
    sum -= values.exercisesInfo.difficultyFactor;
    sum -= values.subjectActivitiesInfo.difficultyFactor;
    sum -= values.subjectActivitiesInfo.difficultyFactorGroup;
    sum -= values.examInfo.difficultyFactor;
    return sum;
  };

  return (
    <>
      <Flex py={5} px={6} minH={'84vh'} align={'flex-start'} justify={'center'}>
        <Formik
          initialValues={{
            subjectMainInfo: {
              subjectName: '',
              ECTSpoints: 1,
              Semester: 1,
              universityID: 0,
              facultyID: 0,
              studyProgramID: 0,
            },
            lecturesInfo: {
              classHours: 0,
              difficultyFactor: 0,
              attendanceReqForSignature: false,
              maxAllowedAbsence: 0,
            },
            exercisesInfo: {
              classHours: 0,
              difficultyFactor: 0,
              attendanceReqForSignature: false,
              maxAllowedAbsence: 0,
            },
            subjectActivitiesInfo: {
              difficultyFactor: 0,
              difficultyFactorGroup: 0,
            },
            examInfo: {
              difficultyFactor: 0,
            },
          }}
          validationSchema={newSubjectSchema}
          onSubmit={async (values) => {
            setLoading(true);
            try {
              const response = await axiosInstance.post('subject/create-subject', values);
              if (response.status === 201) {
                toast({
                  position: 'top',
                  title: 'Uspješno ste kreirali novi predmet',
                  status: 'success',
                  duration: 3000,
                });
                navigate('/profesori');
              }
            } catch (error: any) {
              console.log(error);
              toast({
                position: 'top',
                title: error.data.message,
                status: 'error',
                duration: 3000,
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <Stack spacing={5} w={{ base: '90vw', md: '50%', lg: '70%' }}>
                <Stack
                  py={{ base: '5vh', md: '15vh' }}
                  minH={{ base: '50vh', md: '70vh' }}
                  spacing={5}
                  align={'center'}
                >
                  <Heading pb={3} fontSize={'2xl'}>
                    Osnovni podaci o predmetu
                  </Heading>
                  <HorizontalTextField
                    name={'subjectMainInfo.subjectName'}
                    type='text'
                    label='Naziv predmeta'
                  />
                  <HorizontalTextField
                    name={'subjectMainInfo.ECTSpoints'}
                    type='number'
                    label='ECTS bodovi'
                  />
                  <HorizontalTextField
                    name={'subjectMainInfo.Semester'}
                    type='number'
                    label='Semestar'
                  />
                  {universityQuery.isLoading ? (
                    <Spinner />
                  ) : (
                    //  ****  university selection field *****  //
                    <SelectionField
                      label='Univerzitet'
                      nameID='subjectMainInfo.universityID'
                      optionValueObj={universityQuery.data}
                      onChange={handleUniversityChange}
                      placeholder='Izaberite univerzitet'
                    />
                  )}
                  {facultyQuery.data?.length !== 0 ? (
                    facultyQuery.isLoading ? (
                      <Spinner />
                    ) : (
                      //  ****  Faculty selection field *****  //
                      <SelectionField
                        label='Fakultet'
                        nameID='subjectMainInfo.facultyID'
                        optionValueObj={facultyQuery.data}
                        onChange={handleFacultyChange}
                        placeholder='Izaberite Fakultet'
                      />
                    )
                  ) : (
                    <Text>Izaberite univerzitet</Text>
                  )}
                  {studyProgramsQuery.data?.length !== 0 ? (
                    studyProgramsQuery.isLoading ? (
                      <Spinner />
                    ) : (
                      //  ****  study program selection field *****  //
                      <SelectionField
                        label='Studijski Program'
                        nameID='subjectMainInfo.studyProgramID'
                        optionValueObj={studyProgramsQuery.data}
                        placeholder='Izaberite studijski program'
                      />
                    )
                  ) : (
                    <Text>Izaberite Fakultet</Text>
                  )}
                </Stack>
                <Divider border={'1px solid gray.400'} />
                <Stack
                  py={{ base: '7vh', md: '15vh' }}
                  minH={{ base: '40vh', md: '60vh' }}
                  spacing={5}
                  align={'center'}
                >
                  <Heading pb={3} fontSize={'2xl'}>
                    Podaci o predavanjima
                  </Heading>

                  <HorizontalTextField
                    name='lecturesInfo.classHours'
                    type='number'
                    label='Ukupan broj sati predavanja'
                  />
                  <HorizontalTextField
                    name='lecturesInfo.difficultyFactor'
                    type='number'
                    label='Težinski faktor'
                  />
                  <Text
                    color={sumDF(values) === 0 ? 'green.400' : 'red.400'}
                    w={'80%'}
                    align={'right'}
                    fontSize={'md'}
                  >
                    Rasploživi težinski faktor: {sumDF(values)}
                  </Text>
                  <Stack>
                    <Field name='lecturesInfo.attendanceReqForSignature'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='lecturesInfo.attendanceReqForSignature'
                          isInvalid={
                            form.errors.lecturesInfo?.attendanceReqForSignature &&
                            form.touched.lecturesInfo?.attendanceReqForSignature
                          }
                        >
                          <FormLabel>Da li je prisustvo uslov za potpis?</FormLabel>
                          <Select
                            w={'300px'}
                            {...field}
                            onChange={(e) => {
                              if (e.target.value === 'true')
                                form.setFieldValue('lecturesInfo.attendanceReqForSignature', true);
                              else
                                form.setFieldValue('lecturesInfo.attendanceReqForSignature', false);
                            }}
                          >
                            <option value={'false'}>NE</option>
                            <option value={'true'}>DA</option>
                          </Select>
                          <ErrorMessage name='lecturesInfo.attendanceReqForSignature'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Box>
                    {values.lecturesInfo.attendanceReqForSignature && (
                      <HorizontalTextField
                        name='lecturesInfo.maxAllowedAbsence'
                        type='number'
                        label='Dozvoljeno odsustvo sa predavanja'
                      />
                    )}
                  </Box>
                </Stack>
                <Divider />
                <Stack
                  py={{ base: '7vh', md: '15vh' }}
                  minH={{ base: '40vh', md: '60vh' }}
                  spacing={5}
                  align={'center'}
                >
                  <Heading pb={3} fontSize={'2xl'}>
                    Podaci o vježbama
                  </Heading>

                  <HorizontalTextField
                    name='exercisesInfo.classHours'
                    type='number'
                    label='Ukupan broj sati vježbi'
                  />
                  <HorizontalTextField
                    name='exercisesInfo.difficultyFactor'
                    type='number'
                    label='Težinski faktor'
                  />
                  <Text
                    color={sumDF(values) === 0 ? 'green.400' : 'red.400'}
                    w={'80%'}
                    align={'right'}
                    fontSize={'md'}
                  >
                    Rasploživi težinski faktor: {sumDF(values)}
                  </Text>
                  <Stack>
                    <Field name='exercisesInfo.attendanceReqForSignature'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='exercisesInfo.attendanceReqForSignature'
                          isInvalid={
                            form.errors.exercisesInfo?.attendanceReqForSignature &&
                            form.touched.exercisesInfo?.attendanceReqForSignature
                          }
                        >
                          <FormLabel>Da li je prisustvo uslov za potpis?</FormLabel>
                          <Select
                            w={'300px'}
                            {...field}
                            onChange={(e) => {
                              if (e.target.value === 'true')
                                form.setFieldValue('exercisesInfo.attendanceReqForSignature', true);
                              else
                                form.setFieldValue(
                                  'exercisesInfo.attendanceReqForSignature',
                                  false
                                );
                            }}
                          >
                            <option value={'false'}>NE</option>
                            <option value={'true'}>DA</option>
                          </Select>
                          <ErrorMessage name='exercisesInfo.attendanceReqForSignature'>
                            {(msg) => (
                              <Text color='red' fontSize='sm'>
                                {msg}
                              </Text>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  <Box>
                    {values.exercisesInfo.attendanceReqForSignature && (
                      <HorizontalTextField
                        name='exercisesInfo.maxAllowedAbsence'
                        type='number'
                        label='Dozvoljeno odsustvo sa vježbi'
                      />
                    )}
                  </Box>
                </Stack>

                <Divider />

                <Stack
                  py={{ base: '7vh', md: '15vh' }}
                  minH={{ base: '30vh', md: '50vh' }}
                  spacing={5}
                  align={'center'}
                >
                  <Heading textAlign={'center'} pb={3} fontSize={'2xl'}>
                    Podaci o dodatnim aktivnostima
                  </Heading>

                  <HorizontalTextField
                    name='subjectActivitiesInfo.difficultyFactor'
                    type='number'
                    label='Težinski faktor'
                  />
                  <Text
                    color={sumDF(values) === 0 ? 'green.400' : 'red.400'}
                    w={'80%'}
                    align={'right'}
                    fontSize={'md'}
                  >
                    Rasploživi težinski faktor: {sumDF(values)}
                  </Text>
                  <FormControl>
                    <FormHelperText textAlign={'center'} pb={'20px'}>
                      Ovdje alocirate težinski faktor za dodatne aktivnost namjenjene za sve
                      studente.
                    </FormHelperText>
                  </FormControl>
                  <HorizontalTextField
                    name='subjectActivitiesInfo.difficultyFactorGroup'
                    type='number'
                    label='Težinski faktor za grupe'
                  />
                  <Text
                    color={sumDF(values) === 0 ? 'green.400' : 'red.400'}
                    w={'80%'}
                    align={'right'}
                    fontSize={'md'}
                  >
                    Rasploživi težinski faktor: {sumDF(values)}
                  </Text>
                  <FormControl>
                    <FormHelperText textAlign={'center'}>
                      Ovdje alocirate težinski faktor za dodatne aktivnost za grupe. Nakon kreiranja
                      predmeta asistenti će moći kreirati dodatne aktivnosti po njihovoj želji.
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <Divider />

                <Stack
                  py={{ base: '7vh', md: '15vh' }}
                  minH={{ base: '40vh', md: '50vh' }}
                  spacing={5}
                  align={'center'}
                >
                  <Heading pb={3} fontSize={'2xl'}>
                    Podaci o ispitima
                  </Heading>

                  <HorizontalTextField
                    name={'examInfo.difficultyFactor'}
                    type='number'
                    label='Težinski faktor'
                  />
                  <Text
                    color={sumDF(values) === 0 ? 'green.400' : 'red.400'}
                    w={'80%'}
                    align={'right'}
                    fontSize={'md'}
                  >
                    Rasploživi težinski faktor: {sumDF(values)}
                  </Text>
                  <FormControl>
                    <FormHelperText textAlign={'center'}>
                      Nakon kreiranja predmeta moći će te dodati ispite.
                    </FormHelperText>
                  </FormControl>
                </Stack>
                <Stack alignItems={'center'}>
                  <Button
                    w={'50%'}
                    mb={{ base: '5vh', md: '10vh' }}
                    type='submit'
                    colorScheme='blue'
                    isLoading={isLoading}
                    loadingText='Kreiranje novog predmeta u toku...'
                  >
                    Kreiraj novi predmet
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Flex>
    </>
  );
};

export default NewSubject;
