import {
  Box,
  Center,
  HStack,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useGetFacultysWithPrograms } from '../../../../services/queries';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

export const OrganizationsTable = () => {
  const { data: facultys, isLoading } = useGetFacultysWithPrograms();
  const toast = useToast();

  if (isLoading) {
    return (
      <Center h={'15vh'}>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack spacing={8} align='stretch'>
      {facultys?.map((faculty, index) => (
        <Box key={faculty.id} pb={3} w={{ base: '90vw', md: '100%' }}>
          <TableContainer borderRadius='xl' border='2px' borderColor='blue.700'>
            <Table variant='striped' colorScheme='facebook' size='sm'>
              <Thead>
                <Tr>
                  <Th>RB</Th>
                  <Th textAlign='center'>Fakultet</Th>
                  <Th textAlign='center'>Skračenica</Th>
                  <Th colSpan={2} textAlign='center'>
                    Opcije
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr key={faculty.id}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td fontWeight={'700'}>{faculty.name}</Td>
                  <Td textAlign='center'>{faculty.shortName}</Td>
                  <Td colSpan={5} textAlign='center'>
                    <HStack spacing={5} justifyContent={'center'}>
                      <HStack spacing={0}>
                        <EditIcon
                          color={'white'}
                          boxSize={5}
                          ml='5px'
                          mr={'5px'}
                          onClick={() => {
                            toast({
                              position: 'top',
                              title: 'Ova funkcionalnost još nije implementirana',
                              status: 'warning',
                              duration: 3000,
                            });
                          }}
                          _hover={{ cursor: 'pointer', color: 'green.500' }}
                        />
                        <DeleteIcon
                          color={'white'}
                          boxSize={5}
                          onClick={() => {
                            toast({
                              position: 'top',
                              title: 'Ova funkcionalnost još nije implementirana',
                              status: 'warning',
                              duration: 3000,
                            });
                          }}
                          _hover={{ cursor: 'pointer', color: 'red.500' }}
                        />
                      </HStack>
                    </HStack>
                  </Td>
                </Tr>
              </Tbody>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th textAlign='center'>RB</Th>
                  <Th>Studijski program</Th>
                  <Th>Skračenica</Th>
                  <Th textAlign='center'>opcije</Th>
                </Tr>
              </Thead>
              <Tbody>
                {faculty.programs.length === 0 && (
                  <Tr>
                    <Td colSpan={5}>
                      <Center>Nema studijskih programa</Center>
                    </Td>
                  </Tr>
                )}
                {faculty.programs.map((program, index) => (
                  <Tr key={program.id}>
                    <Td></Td>
                    <Td textAlign='center'>{index + 1}</Td>
                    <Td fontWeight={'700'}>{program.name}</Td>
                    <Td textAlign='center'>{program.shortName}</Td>
                    <Td textAlign='center'>
                      <HStack spacing={5}>
                        <HStack spacing={0}>
                          <EditIcon
                            color={'white'}
                            boxSize={5}
                            ml='5px'
                            mr={'5px'}
                            onClick={() => {
                              toast({
                                position: 'top',
                                title: 'Ova funkcionalnost još nije implementirana',
                                status: 'warning',
                                duration: 3000,
                              });
                            }}
                            _hover={{ cursor: 'pointer', color: 'green.500' }}
                          />
                          <DeleteIcon
                            color={'white'}
                            boxSize={5}
                            onClick={() => {
                              toast({
                                position: 'top',
                                title: 'Ova funkcionalnost još nije implementirana',
                                status: 'warning',
                                duration: 3000,
                              });
                            }}
                            _hover={{ cursor: 'pointer', color: 'red.500' }}
                          />
                        </HStack>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </VStack>
  );
};
