import { Heading, Icon, Spinner, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { useStudentJoinSubject } from '../../../services/mutations';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

type Props = {};

export const QRCodeAutoScanAddSubjectPage = (props: Props) => {
  const { QRCode } = useParams();
  const navigate = useNavigate();
  const joinSubject = useStudentJoinSubject();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (QRCode) {
          await joinSubject(QRCode);
          setSuccess(true);
        }
      } catch (error) {
        // Handle error here
        setSuccess(false);
      } finally {
        setLoading(false);
        setTimeout(() => {
          navigate('/student');
        }, 1000);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Clean up any ongoing processes or resources
    };
  }, [QRCode, joinSubject, navigate]);

  //student/dodaj-predavanje/70F3y29T
  return (
    <Stack align='center' justify='center' h='80vh' w={'100vw'}>
      {loading ? (
        <>
          <Heading pb={5} textAlign={'center'} fontSize='3xl' color={'blue.500'}>
            Provjeravamo predmet...
          </Heading>
          <Spinner size={'xl'} color={'blue.500'} />
        </>
      ) : success ? (
        <>
          <Heading pb={5} fontSize='3xl' color='green.500'>
            Uspješan scan!
          </Heading>
          <Icon as={CheckIcon} w={16} h={16} color='green.500' />
        </>
      ) : (
        <>
          <Heading pb={5} fontSize='3xl' color='red.500'>
            Neuspješan scan!
          </Heading>
          <Icon as={CloseIcon} w={16} h={16} color='red.500' />
        </>
      )}
    </Stack>
  );
};
