import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BluesubmitButton } from '../../../../../components/general/BlueSubmitButton';
import { DateTimeInput } from '../../../../../components/general/DateTimeInput';
import {
  editExamDatePlaceSchema,
  editExamScheduleSchema,
} from '../../../../../validation/exam-schema';
import { HorizontalTextField } from '../../../NewSubject/comp/HorzintalTextField';
import { ExamSchedule } from '../../../../../models/exams';
import { DateInput } from '../../../../../components/general/DateInput';
import { RedButton } from '../../../../../components/general/RedButton';
import useProf from '../../../../../hooks/useProf.hooks';
import { useGetExamInfo } from '../../../../../services/queries';
import { useEditExamScheduleMutation } from '../../../../../services/mutations';

type Props = {
  examSchedule: ExamSchedule;
  isOpen: boolean;
  onClose: () => void;
};

export const EditExamScheduleModal = ({ isOpen, onClose, examSchedule }: Props) => {
  const profContext = useProf();
  const [isLoading, setIsLoading] = useState(false);

  const examInfo = useGetExamInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const editExamSchedule = useEditExamScheduleMutation(examInfo.data?.id);

  return (
    <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Uredi ispitni rok</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          <Formik
            initialValues={{
              examScheduleID: examSchedule.id,
              name: examSchedule.name,
              startingDate: new Date(examSchedule.rawStartingDate),
              endingDate: new Date(examSchedule.rawEndingDate),
            }}
            validationSchema={editExamScheduleSchema}
            onSubmit={async (values, actions) => {
              setIsLoading(true);
              try {
                await editExamSchedule(values);
              } catch (error) {
              } finally {
                setTimeout(() => {
                  setIsLoading(false);
                  onClose();
                }, 1000);
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Stack p={5} spacing={2} justifyContent={'center'} alignItems={'center'}>
                  <HorizontalTextField name='name' type='text' label='Ime roka' width='180px' />

                  <DateInput
                    name='startingDate'
                    label='Datum početka roka'
                    placeholder='Izaberite datum'
                    setFieldValue={setFieldValue}
                    isRequired={true}
                  />

                  <DateInput
                    name='endingDate'
                    label='Datum kraja roka'
                    placeholder='Izaberite datum'
                    setFieldValue={setFieldValue}
                    isRequired={true}
                  />

                  <HStack py={5} justify={'center'}>
                    <BluesubmitButton
                      buttonText='Sačuvaj promjene'
                      loadingText='Čuvanje..'
                      isLoading={isLoading}
                    />
                    <RedButton buttonText='Odustani' onClick={onClose} />
                  </HStack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
