import {
  Avatar,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExamResult } from '../../../../../models/exams';
import {
  useSetExamStudentScore,
  useSetPartialExamStudentScore,
} from '../../../../../services/mutations';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  student: ExamResult | null;
  maxScore: number;
  isExam: boolean;
};

export const ExamScoreInputModal = ({ isOpen, onClose, student, maxScore, isExam }: Props) => {
  const [score, setScore] = useState(0);
  const toast = useToast();
  const { examID } = useParams();
  const { partialExamID } = useParams();

  const setScoreMutation = useSetExamStudentScore(Number(examID));
  const setPartExamScore = useSetPartialExamStudentScore(Number(partialExamID));

  const handleEditScore = () => {
    if (score < 0 || score > maxScore) {
      toast({
        position: 'top',
        title: `Broj bodova mora biti između 0 i ${maxScore}. `,
        status: 'warning',
        duration: 2000,
      });
    } else {
      if (student) {
        if (isExam) {
          setScoreMutation({
            score,
            studentID: student.id,
            examID: Number(examID),
          })
            .then(() => {
              setScore(0);
              onClose();
            })
            .catch((err) => {});
        } else {
          setPartExamScore({
            score,
            studentID: student.id,
            partialExamID: Number(partialExamID),
          })
            .then(() => {
              setScore(0);
              onClose();
            })
            .catch((err) => {});
        }
      }
    }
  };

  return (
    <>
      <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Unos rezultata</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify={'center'}
              mb={'2rem'}
              align={'center'}
              justifyContent={'center'}
            >
              <Stack w={'100%'} direction={{ base: 'column', md: 'row' }}>
                <VStack w={{ base: '100%', md: '50%' }} align={'center'}>
                  <Heading pb={2} size={'md'}>
                    Student
                  </Heading>
                  <VStack spacing={0}>
                    <Avatar size={'xl'} src={student?.profileImage} />
                    <Text>
                      {student?.firstName} {student?.lastName}
                    </Text>
                    <Text fontSize={'lg'} fontWeight={'bold'}>
                      {student?.indexNumber}
                    </Text>
                  </VStack>
                </VStack>
                <VStack w={{ base: '100%', md: '50%' }} align={'center'}>
                  <Heading pt={{ base: 5, md: 0 }} pb={{ base: 2, md: 5 }} size={'md'}>
                    Rezultat
                  </Heading>
                  <Text fontSize={'lg'}>
                    {student?.score} od {maxScore}
                  </Text>
                  <FormControl isRequired>
                    <HStack justify={'space-around'}>
                      <VStack>
                        <HStack justify={'space-around'}>
                          <Input
                            w={'100px'}
                            type='number'
                            onChange={(e) => setScore(Number(e.target.value))}
                          />{' '}
                          <Button
                            fontSize={'xs'}
                            mt={4}
                            colorScheme='blue'
                            onClick={handleEditScore}
                          >
                            Sačuvaj
                          </Button>
                        </HStack>

                        <FormHelperText>
                          Unesite broj bodova između 0 i {maxScore}, ovdje također možete korigovati
                          broj bodova
                        </FormHelperText>
                      </VStack>
                    </HStack>
                  </FormControl>
                </VStack>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
