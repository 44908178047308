import {
  Card,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { BlueButton } from '../../../../components/general/BlueButton';
import { PartialExam } from '../../../../models/exams';
import { PartialExamGroupDateTime } from './PartialExamGroupDateTime';
import { EditPartialExamDatePlaceModal } from './modals/EditPartialExamDatePlaceModal';

interface Props {
  exam: PartialExam;
}

export const SinglePartialExam = ({ exam }: Props) => {
  const colorScheme = useColorModeValue('gray.100', 'gray.900');

  const {
    isOpen: editDatePlaceOpen,
    onOpen: onEditDatePlaceOpen,
    onClose: onEditDatePlaceClose,
  } = useDisclosure();

  return (
    <VStack w={'100%'}>
      <Stack
        pb={5}
        w={'100%'}
        direction={{ base: 'column', md: 'row' }}
        spacing={8}
        justify={'center'}
      >
        <EditPartialExamDatePlaceModal
          dateTime={exam.rawDateTime ?? ''}
          place={exam.place ?? ''}
          isOpen={editDatePlaceOpen}
          onClose={onEditDatePlaceClose}
        />

        <VStack align={'center'}>
          <Heading pb={5} fontSize={'md'}>
            Opšti podaci
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <VStack align={'start'} spacing={0}>
              <HStack>
                <Text fontSize={'sm'}>Osnovni ispit:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.examName}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Ime parcijale:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.name}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Tip parcijale:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.type}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Broj bodova:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.maxScore}
                </Text>
              </HStack>

              <HStack>
                <Text fontSize={'sm'}>Bodovi za prolaz:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.minPassingScore}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Težinski faktor:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.difficultyFactor}
                </Text>
              </HStack>
            </VStack>

            <VStack align={'start'} spacing={0}>
              <HStack>
                {exam.conditionPartialExamID ? (
                  <>
                    <Text fontSize={'sm'}>Uslovni Ispit:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {exam.conditionPartialExamName}
                    </Text>
                  </>
                ) : (
                  <Text fontSize={'sm'}>Nema uslovnog ispita</Text>
                )}
              </HStack>
              <HStack>
                <Tooltip label='Skriven označava da li je ispit vidljiv kod studenata.'>
                  <Text fontSize={'sm'}>Skriven:</Text>
                </Tooltip>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.visible ? 'NE' : 'DA'}
                </Text>
              </HStack>

              <HStack>
                <Text fontSize={'sm'}>Rok poništavanja:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.nullificationDeadline}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Rok prijave:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.registrationDeadline}
                </Text>
              </HStack>
              <HStack>
                {exam.validUntil ? (
                  <>
                    <Text fontSize={'sm'}>položen ispit važi do:</Text>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {exam.validUntil}
                    </Text>
                  </>
                ) : (
                  <Text fontSize={'sm'}>Ispit važi neograničeno. </Text>
                )}
              </HStack>
              <HStack>
                <Text fontSize={'sm'}>Kreiran:</Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {exam.createdDateTime}
                </Text>
              </HStack>
              {exam.edited && (
                <HStack>
                  <Text fontSize={'sm'}>Uređen:</Text>
                  <Text fontSize={'sm'} fontWeight={'bold'}>
                    {exam.editedDateTime}
                  </Text>
                </HStack>
              )}
            </VStack>
          </SimpleGrid>
        </VStack>
      </Stack>
      <Stack align={'center'} direction={{ base: 'column', md: 'row' }}>
        <VStack>
          <Heading pb={5} fontSize={'md'}>
            Datum i mjesto održavanja
          </Heading>
          {exam.sameGroupsDateTime ? (
            <Card
              w={'100%'}
              p={{ base: 2, md: 4 }}
              align={'center'}
              variant={'outline'}
              bg={colorScheme}
            >
              <VStack justify={'center'} spacing={0}>
                <Text fontSize={'sm'}>Za sve studente</Text>
                {exam.dateTime && exam.place ? (
                  <>
                    <Text fontSize={'sm'} fontWeight={'bold'}>
                      {exam.dateTime}
                    </Text>
                    <Text pb={2} fontSize={'sm'} fontWeight={'bold'}>
                      {exam.place}
                    </Text>
                    <BlueButton
                      buttonText='Uredi'
                      height='25px'
                      onClick={() => {
                        onEditDatePlaceOpen();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Text pb={2} fontSize={'sm'}>
                      Nije zakazano
                    </Text>
                    <BlueButton
                      buttonText='Zakaži'
                      height='25px'
                      onClick={() => {
                        onEditDatePlaceOpen();
                      }}
                    />
                  </>
                )}
              </VStack>
            </Card>
          ) : (
            <PartialExamGroupDateTime />
          )}
        </VStack>
      </Stack>
    </VStack>
  );
};
