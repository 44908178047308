import {
  Box,
  Card,
  HStack,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { subjectPost } from '../../../../models/posts';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import useAuth from '../../../../hooks/useAuth.hooks';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import {
  useAssistDeletePostMutation,
  useProfDeletePostMutation,
} from '../../../../services/mutations';
import useProf from '../../../../hooks/useProf.hooks';
import { EditPostModal } from './EditPostModal';

interface BlogAuthorProps {
  dateCreated: string;
  dateEdited: string;
  name: string;
  img: string;
  edited: boolean;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack spacing='2' display='flex' alignItems='center'>
      <Image borderRadius='full' boxSize='50px' src={props.img} alt={`Avatar of ${props.name}`} />
      <VStack align={'start'} spacing={0}>
        <Text fontWeight='medium'>{props.name}</Text>
        <Stack fontSize={'sm'} direction={'row'} align={'center'} spacing={1}>
          <Text> {props.dateCreated}</Text>
          {props.edited && <Text>(Uređeno: {props.dateEdited})</Text>}
        </Stack>
      </VStack>
    </HStack>
  );
};

type Props = {
  post: subjectPost;
};

export const StaffPost = ({ post }: Props) => {
  const authContext = useAuth();
  const profContext = useProf();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [deletePostOpen, setDeletePostOpen] = useState(false);

  const profDeletePost = useProfDeletePostMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const assistDeletePost = useAssistDeletePostMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  return (
    <>
      <YesNoModal
        isOpen={deletePostOpen}
        onClose={() => setDeletePostOpen(false)}
        onConfirm={() => {
          if (authContext.user?.role === 'PROFESSOR') {
            profDeletePost({ postID: post.id }).catch((err) => {});
          } else {
            assistDeletePost({ postID: post.id }).catch((err) => {});
          }
          setDeletePostOpen(false);
        }}
        title='Brisanje objave'
        message={`Jeste li sigurni da želite obrisati objavu sa naslovom ${post.title} koja je objavljena ${post.dateTimeCreated}, id objave je ${post.id} `}
      />
      <EditPostModal
        isOpen={isEditOpen}
        onClose={onEditClose}
        post={{ id: post.id, text: post.text, title: post.title }}
      />
      <Card w={{ base: '90vw', md: '100%' }} p={{ base: 3, md: 5 }}>
        <Box display='flex' flex='1' flexDirection='column' justifyContent='center'>
          <HStack justify={'space-between'}>
            <BlogAuthor
              name={`${post.author.title} ${post.author.firstName} ${post.author.lastName}`}
              dateCreated={post.dateTimeCreated}
              dateEdited={post.dateTimeEdited}
              img={post.author.profileImage}
              edited={post.edited}
            />
            <Menu>
              <MenuButton
                display={authContext.user?.sub === post.author.id ? 'flex' : 'none'}
                position={'absolute'}
                right={'0px'}
                top={'0px'}
                color={'blue.300'}
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon />}
                variant={'outline'}
                width={'30px'}
                height={'30px'}
              />
              <MenuList>
                <MenuItem
                  icon={<EditIcon />}
                  onClick={() => {
                    onEditOpen();
                  }}
                >
                  Uredi objavu
                </MenuItem>
                <MenuItem
                  icon={<DeleteIcon />}
                  onClick={() => {
                    setDeletePostOpen(true);
                  }}
                >
                  Izbriši objavu
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <Heading textAlign={'center'} size={'md'} marginTop='1'>
            {post.title} {post.group && <>({post.group.name})</>}
          </Heading>
          <Text
            textAlign={'justify'}
            as='p'
            marginTop='2'
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize='lg'
            whiteSpace={'pre-line'}
          >
            {post.text}
          </Text>
        </Box>
      </Card>
    </>
  );
};
