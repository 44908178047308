import * as yup from 'yup';

export const createStaffSchema = yup.object().shape({
  firstName: yup.string().trim().required('Ime je obavezno polje'),
  lastName: yup.string().trim().required('Prezime je obavezno polje'),
  role: yup
    .string()
    .trim()
    .required('Morate izabrati rolu.')
    .oneOf(['PROFESSOR', 'ASSISTANT'], 'Rola može biti "PROFESSOR" ili "ASSISTANT"'),
  title: yup.string().trim().required('Titula je je obavezno polje'),
  email: yup
    .string()
    .trim()
    .email('Unesite ispravnu E-mail adresu')
    .transform((value) => value.toLowerCase())
    .required('E-mail je obavezno polje'),
});

export const registrationSchema = yup.object().shape({
  firstName: yup.string().trim().required('Ime je obavezno polje'),
  lastName: yup.string().trim().required('Prezime je obavezno polje'),
  role: yup
    .string()
    .trim()
    .required('Morate izabrati rolu.')
    .oneOf(['PROFESSOR', 'ASSISTANT'], 'Rola može biti "PROFESSOR" ili "ASSISTANT"'),
  title: yup.string().trim().required('Titula je je obavezno polje'),
  email: yup
    .string()
    .trim()
    .email('Unesite ispravnu E-mail adresu')
    .transform((value) => value.toLowerCase())
    .required('E-mail je obavezno polje'),
  password: yup
    .string()
    .trim()
    .min(8, 'Šifra mora sadržavati 8 simbola')
    .required('Šifra je je obavezno polje'),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('E-mail je obavezno polje')
    .transform((value) => value.toLowerCase())
    .email('Unesite ispravnu E-mail adresu'),
  password: yup
    .string()
    .trim()
    .required('Šifra je obavezno polje')
    .min(8, 'Šifra mora sadržavati minimalno 8 simbola'),
});

export type CreateStaffDto = yup.InferType<typeof createStaffSchema>;
export type LoginDto = yup.InferType<typeof loginSchema>;
export type RegistrationDto = yup.InferType<typeof registrationSchema>;
