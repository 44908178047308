import {
  Center,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import useProf from '../../../../hooks/useProf.hooks';
import { useEditCurrentLecturesInfo } from '../../../../services/mutations';
import { useGetCurrentLecturesInfo } from '../../../../services/queries';
import { editLecturesExercisesInfoSchema } from '../../../../validation/subject-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {};

export const EditLecturesInfo = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();

  const lecturesInfo = useGetCurrentLecturesInfo(profContext.selectedSubjectID);
  const editLecturesInfo = useEditCurrentLecturesInfo(profContext.selectedSubjectID);

  return lecturesInfo.isLoading ? (
    <Center>
      <Spinner />
    </Center>
  ) : lecturesInfo.data ? (
    <VStack pt={5} w={'100%'}>
      <Formik
        initialValues={{
          subjectID: profContext.selectedSubjectID,
          classHours: lecturesInfo.data.classHours,
          attendanceReqForSignature: lecturesInfo.data.attendanceReqForSignature,
          maxAllowedAbsence: lecturesInfo.data.maxAllowedAbsence,
        }}
        validationSchema={editLecturesExercisesInfoSchema}
        onSubmit={(values, actions) => {
          setIsLoading(true);
          editLecturesInfo(values).catch((error) => {});
          setIsLoading(false);
        }}
      >
        {({ values }) => (
          <Form>
            <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
              <HorizontalTextField
                name='classHours'
                type='number'
                label='Ukupan broj sati.'
                width='200px'
              />

              <Field name='attendanceReqForSignature'>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    id='attendanceReqForSignature'
                    isInvalid={
                      form.errors.lecturesInfo?.attendanceReqForSignature &&
                      form.touched.lecturesInfo?.attendanceReqForSignature
                    }
                  >
                    <VStack>
                      <FormLabel>Da li je prisustvo uslov za potpis?</FormLabel>
                      <Select
                        w={'200px'}
                        {...field}
                        onChange={(e) => {
                          if (e.target.value === 'true')
                            form.setFieldValue('attendanceReqForSignature', true);
                          else form.setFieldValue('attendanceReqForSignature', false);
                        }}
                      >
                        <option value={'false'}>NE</option>
                        <option value={'true'}>DA</option>
                      </Select>
                      <ErrorMessage name='attendanceReqForSignature'>
                        {(msg) => (
                          <Text color='red' fontSize='sm'>
                            {msg}
                          </Text>
                        )}
                      </ErrorMessage>
                    </VStack>
                  </FormControl>
                )}
              </Field>

              {values.attendanceReqForSignature && (
                <HorizontalTextField
                  name='maxAllowedAbsence'
                  type='number'
                  label='Dozvoljeno odsustvo.'
                  width='200px'
                />
              )}

              <HStack py={5} justify={'center'}>
                <BluesubmitButton
                  buttonText='Sačuvaj promjene'
                  loadingText='u toku...'
                  isLoading={isLoading}
                  isDisabled={
                    values.classHours === lecturesInfo.data.classHours &&
                    values.attendanceReqForSignature ===
                      lecturesInfo.data.attendanceReqForSignature &&
                    values.maxAllowedAbsence === lecturesInfo.data.maxAllowedAbsence
                  }
                />
              </HStack>
            </Stack>
          </Form>
        )}
      </Formik>
    </VStack>
  ) : (
    <Text>Greška prilikom učitavanja podataka</Text>
  );
};
