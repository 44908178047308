import {
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Switch,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../components/general/BlueButton';
import useProf from '../../../hooks/useProf.hooks';
import { QRCodeModal } from '../../../pages/Professor/comp/QRCodeModal';
import { useGetSelectedSubjectInfo } from '../../../services/queries';
import './styles.css';

type Props = {
  onClick: () => void;
  toggleState: boolean;
};

const SubjectInfo = (props: Props) => {
  const navigate = useNavigate();
  const { selectedSubjectID } = useProf();
  const selectedSubjectInfo = useGetSelectedSubjectInfo(selectedSubjectID);
  const { isOpen: isStaffQROpen, onOpen: onStaffQROpen, onClose: onStaffQRClose } = useDisclosure();
  const {
    isOpen: isStudentQROpen,
    onOpen: onStudentQROpen,
    onClose: onStudentQRClose,
  } = useDisclosure();

  return (
    <Flex
      justify={'center'}
      borderBottomWidth='1px'
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      h={props.toggleState ? '' : '60px'}
    >
      <QRCodeModal
        isOpen={isStaffQROpen}
        onClose={onStaffQRClose}
        QRCode={selectedSubjectInfo.data?.QRCode ?? ''}
        title='QR za osoblje'
        link={'profesori/novi-predmet'}
      />
      <QRCodeModal
        isOpen={isStudentQROpen}
        onClose={onStudentQRClose}
        QRCode={selectedSubjectInfo.data?.QRCode ?? ''}
        title='QR za studente'
        link={'student/novi-predmet'}
      />
      <VStack w='100%'>
        <HStack justify={'space-between'} width='100%'>
          <HStack>
            <Icon
              as={EditIcon}
              _hover={{ cursor: 'pointer', color: 'green.500' }}
              fontSize={'xl'}
              onClick={() => {
                navigate('postavke');
              }}
            />
            {/* <Icon as={InfoOutlineIcon} /> */}
          </HStack>
          <Heading fontSize={'xl'}>Podaci o predmetu</Heading>
          <Stack align='center' direction='row'>
            <Switch colorScheme='blue' onChange={props.onClick} size='sm' />
          </Stack>
        </HStack>
        {props.toggleState && (
          <Stack
            direction={{ base: 'column', md: 'row' }}
            pt='3'
            spacing={{ base: '30', md: '70' }}
            align={'space-between'}
            w='100%'
            justify={'center'}
            alignItems={'center'}
          >
            <HStack fontSize={{ base: 'xs', md: 'md' }}>
              <VStack align={'end'} mr={{ base: '2', md: '5' }} fontWeight='bold' spacing='0'>
                <p>Šifra predmeta</p>
                <p>Naziv predmeta</p>
                <p>ECTS bodovi</p>
                <p>Semestar</p>
                <p>Studijski program</p>
                <p>Fakultet</p>
                <p>Univerzitet</p>
              </VStack>
              <VStack align='start' ml={{ base: '2', md: '5' }} spacing='0'>
                <p>{selectedSubjectInfo.data?.QRCode}</p>
                <p>{selectedSubjectInfo.data?.name}</p>
                <p>{selectedSubjectInfo.data?.ECTSpoints}</p>
                <p>{selectedSubjectInfo.data?.latinSemester}</p>
                <p>{selectedSubjectInfo.data?.studyProgramName}</p>
                <p>{selectedSubjectInfo.data?.facultyName}</p>
                <p>{selectedSubjectInfo.data?.universityName}</p>
              </VStack>
            </HStack>
            <VStack align='center' ml='10px'>
              <BlueButton
                height='30px'
                width='130px'
                buttonText='QR za osoblje'
                onClick={() => {
                  onStaffQROpen();
                }}
              />
              <BlueButton
                height='30px'
                width='130px'
                buttonText='QR za studente'
                onClick={() => {
                  onStudentQROpen();
                }}
              />
              {/* <Box borderRadius='xl' overflow='hidden'>
                <QRCodeSVG
                  value={basePath + '/student/novi-predmet/' + selectedSubjectInfo.data?.QRCode}
                  size={150}
                  fgColor={'#000000'}
                  level={'L'}
                  includeMargin={true}
                />
              </Box>
              <p>QR kod predmeta</p> */}
            </VStack>
          </Stack>
        )}
      </VStack>
    </Flex>
  );
};

export default SubjectInfo;
