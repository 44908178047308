import { Flex, HStack, Heading, SimpleGrid, VStack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { CreateFaculty } from './comp/CreateFaculty';
import { OrganizationsTable } from './comp/OrganizationsTable';
import { CreateStudyProgram } from './comp/CreateStudyProgram';

type Props = {};

const Universities = (props: Props) => {
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w={'100%'}
    >
      <Heading fontSize={'2xl'} py={3} pb={5}>
        Ustanove
      </Heading>
      <SimpleGrid
        columns={{
          sm: 1,
          md: 2,
        }}
        spacing={'50px'}
        pb={10}
      >
        <VStack>
          <Heading pb={3} fontSize={'xl'}>
            Kreiraj fakultet
          </Heading>
          <CreateFaculty />
        </VStack>
        <VStack>
          <Heading fontSize={'xl'} pb={3}>
            Kreiraj studijski program{' '}
          </Heading>
          <CreateStudyProgram />
        </VStack>
      </SimpleGrid>
      <Heading fontSize={'xl'} py={5}>
        Sve organizacije
      </Heading>
      <OrganizationsTable />
    </Flex>
  );
};

export default Universities;
