import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Center,
  Checkbox,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BlueButton } from '../../../../components/general/BlueButton';
import { useToggleProfessorsMutation } from '../../../../services/mutations';
import { useGetProfessors } from '../../../../services/queries';

type Props = {};

interface LoadingStates {
  [key: number]: boolean;
}

export const ProfessorsTable = (props: Props) => {
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const professors = useGetProfessors();
  const toast = useToast();
  const toggleProfessorApproved = useToggleProfessorsMutation();

  return (
    <TableContainer mt={'0rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
      <Table variant='striped' colorScheme='facebook' size='sm'>
        <Thead>
          <Tr>
            <Th textAlign='center'>Slika</Th>
            <Th textAlign='center'>Titula, Ime i Prezime</Th>
            <Th textAlign='center'>E-mail</Th>
            <Th textAlign='center'>Odobren</Th>
            <Th textAlign='center'>Opcije</Th>
          </Tr>
        </Thead>
        <Tbody>
          {professors.isLoading ? (
            <Tr>
              <Td colSpan={5}>
                <Center>
                  <Spinner />
                </Center>
              </Td>
            </Tr>
          ) : professors.data && professors.data.length > 0 ? (
            professors.data.map((professor) => (
              <Tr key={professor.id}>
                <Td textAlign='center'>
                  <Avatar size={'sm'} src={professor.profileImage} />
                </Td>
                <Td>
                  {professor.title} {professor.firstName} {professor.lastName}
                </Td>
                <Td>{professor.email}</Td>
                <Td textAlign='center'>
                  <Checkbox isChecked={professor.approved} colorScheme='green'></Checkbox>
                </Td>
                <Td textAlign='center'>
                  <HStack spacing={5}>
                    <BlueButton
                      width='55px'
                      height='25px'
                      buttonText={professor.approved ? 'blokiraj' : 'odobri'}
                      isLoading={loadingStates[professor.id]}
                      onClick={() => {
                        setLoadingStates((prev) => ({ ...prev, [professor.id]: true }));
                        toggleProfessorApproved({
                          staffID: professor.id,
                        })
                          .catch((err) => {})
                          .finally(() => {
                            setTimeout(() => {
                              setLoadingStates((prev) => ({ ...prev, [professor.id]: false }));
                            }, 1000);
                          });
                      }}
                    />
                    <HStack spacing={0}>
                      <EditIcon
                        color={'white'}
                        boxSize={5}
                        ml='5px'
                        mr={'5px'}
                        onClick={() => {
                          toast({
                            position: 'top',
                            title: 'Ova funkcionalnost još nije implementirana',
                            status: 'warning',
                            duration: 3000,
                          });
                        }}
                        _hover={{ cursor: 'pointer', color: 'green.500' }}
                      />
                      <DeleteIcon
                        color={'white'}
                        boxSize={5}
                        onClick={() => {
                          toast({
                            position: 'top',
                            title: 'Ova funkcionalnost još nije implementirana',
                            status: 'warning',
                            duration: 3000,
                          });
                        }}
                        _hover={{ cursor: 'pointer', color: 'red.500' }}
                      />
                    </HStack>
                  </HStack>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={5}>
                <Center>Nema profesora na platformi</Center>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
