import {
  Center,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetSubjectActivitiesInfo,
  useGetSubjectActivitiesPerGroup,
} from '../../../services/queries';
import useProf from '../../../hooks/useProf.hooks';
import { BlueButton } from '../../../components/general/BlueButton';
import { SubjectActivitieGroups } from './comp/groups';
import { SubjectActivitiesGroup } from './comp/SubjectActivitiesGroup';
import { CreateSubjectActivityAllModal } from './comp/createSubjectActivityAllModal';

type Props = {};

export const SubjectActivitiesPerGroupPage = (props: Props) => {
  const { groupID } = useParams();
  const navigate = useNavigate();
  const profContext = useProf();
  const {
    isOpen: isCreateSubjectActivityOpen,
    onOpen: onCreateSubjectActivityOpen,
    onClose: onCreateSubjectActivityClose,
  } = useDisclosure();

  const subjectActivitiesInfo = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const subjectActivities = useGetSubjectActivitiesPerGroup(
    subjectActivitiesInfo.data?.id,
    Number(groupID)
  );
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <CreateSubjectActivityAllModal
        isOpen={isCreateSubjectActivityOpen}
        onClose={onCreateSubjectActivityClose}
        selectedGroupID={Number(groupID)}
      />
      <HStack w={'100%'} justify={'space-between'} align={'center'} pb={5}>
        <HStack>
          <ArrowBackButton
            onClick={() => {
              navigate('/profesori/dodatne-aktivnosti/');
            }}
          />
        </HStack>
        <Heading textAlign={'center'} fontSize={'xl'}>
          Aktivnosti grupe {subjectActivities.data?.groupName}
        </Heading>
        <HStack w='16px'></HStack>
      </HStack>
      {subjectActivities.isLoading ? (
        <HStack h={{ base: '15vh', md: '25vh' }} w={'50%'} justify={'Center'}>
          <Spinner />
        </HStack>
      ) : subjectActivities.data ? (
        <VStack w={{ base: '90vw', md: '100%' }}>
          <Stack
            w={{ base: '90vw', md: '100%', lg: '800px' }}
            direction={{ base: 'column', md: 'row' }}
            justify={'space-around'}
            align={'center'}
            pb={5}
          >
            <VStack align={{ base: 'center', md: 'start' }} spacing={0}>
              <Text>Naziv Grupe: {subjectActivities.data.groupName}</Text>
              <Text>
                Raspoloživi težinski faktor:{' '}
                {subjectActivities.data.difficultyFactor -
                  subjectActivities.data.usedDifficultyFactor}{' '}
                / {subjectActivities.data.difficultyFactor}
              </Text>
              <Text>
                Broj kreiranih aktivnosti: {subjectActivities.data.subjectActivities.length}
              </Text>
            </VStack>
            <VStack pt={{ base: 5, md: 0 }} spacing={0}>
              <BlueButton
                buttonText='Dodaj Aktivnost'
                onClick={onCreateSubjectActivityOpen}
                isDisabled={
                  subjectActivities.data?.difficultyFactor ===
                  subjectActivities.data?.usedDifficultyFactor
                }
              />
              {subjectActivities.data?.difficultyFactor ===
                subjectActivities.data?.usedDifficultyFactor && (
                <Center>
                  <Text color={'red.400'}>Raspoloživi težinski faktor je 0.</Text>
                </Center>
              )}
            </VStack>
          </Stack>
          <SubjectActivitiesGroup subjectActivities={subjectActivities.data.subjectActivities} />
        </VStack>
      ) : (
        <VStack>
          <Heading pt={10} pb={3} fontSize={'md'}>
            Dodatne aktivnosti od grupe nisu pronađene ili nemate pristup istim.
          </Heading>
          <BlueButton
            buttonText='Vrati se na dodatne aktivnosti'
            onClick={() => {
              navigate('/profesori/dodatne-aktivnosti');
            }}
          />
        </VStack>
      )}
    </Flex>
  );
};
