import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Heading,
  IconButton,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  Popover,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, ReactText, useContext, useState } from 'react';
import { FiBell, FiChevronDown, FiMenu } from 'react-icons/fi';
import { NavLink, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { AuthenticationContext } from '../../../auth/auth.context';
import useProf from '../../../hooks/useProf.hooks';
import { useGetAcademicYears, useGetSubjectsQuery } from '../../../services/queries';
import NewSubjectModal from './NewSubjectModal';

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>{children}</Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const profContext = useProf();
  const popoverContentBgColor = useColorModeValue('black', 'white');
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    selectedSubjectID,
    getSelectedSubjectInfo,
    setSelectedSubjectID,
    setSelectedAcademicYearID,
  } = useProf();

  const academicYearsQuery = useGetAcademicYears(selectedSubjectID);

  const getSubjectsQuery = useGetSubjectsQuery();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <CloseButton
        pos={'absolute'}
        top={5}
        left={5}
        display={{ base: 'flex', md: 'none' }}
        onClick={onClose}
        mr={'auto'}
      />
      <Flex h='20' alignItems='center' mx='8' justifyContent='center'>
        <Img
          h={'50px'}
          src={useColorModeValue('/ISEUS LOGO-02.png', '/ISEUS LOGO-03.png')}
          onClick={() => {
            navigate('/');
          }}
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <VStack spacing={'3'}>
        <Heading fontSize={'xl'}>Predmeti</Heading>
        {getSubjectsQuery.isLoading ? (
          <Spinner />
        ) : (
          getSubjectsQuery.data?.map((link) => (
            <Button
              fontSize='sm'
              w='80%'
              size='md'
              height={link.name.length > 27 ? '60px' : '40px'}
              bg={selectedSubjectID === link.id ? 'blue.600' : 'grey.800'}
              color={selectedSubjectID === link.id ? 'white' : popoverContentBgColor}
              border='2px'
              borderColor='blue.500'
              key={link.id}
              overflow={'hidden'}
              whiteSpace={'normal'}
              lineHeight={'normal'}
              onClick={async () => {
                setSelectedSubjectID(link.id);
                getSelectedSubjectInfo(link.id);
                setSelectedAcademicYearID(undefined);
                if (academicYearsQuery.data && academicYearsQuery.data.length !== 0) {
                  const currentAcademicYear = academicYearsQuery.data.find(
                    (year) => year.currentYear === true
                  );
                  setSelectedAcademicYearID(currentAcademicYear?.id);
                }

                navigate('/profesori');
                onClose();
              }}
            >
              {link.name}
            </Button>
          ))
        )}
        {getSubjectsQuery.data?.length === 0 && <Text>Nema predmeta</Text>}
        <Divider />
        <Button
          fontSize='m'
          w='80%'
          size='md'
          height='40px'
          border='2px'
          borderColor='blue.500'
          bg={useColorModeValue('white', 'grey.800')}
          onClick={openModal}
        >
          Novi predmet
        </Button>
        <Modal isCentered size={'xl'} isOpen={isModalOpen} onClose={closeModal}>
          <NewSubjectModal onClose={closeModal} />
        </Modal>

        <Flex
          display={{
            base: profContext.subjects.length > 0 ? 'flex' : 'none',
            md: 'none',
          }}
        >
          <VStack>
            <Heading pt={5} fontSize={'xl'}>
              Meni
            </Heading>
            <DesktopNav onClose={onClose} />
          </VStack>
        </Flex>
      </VStack>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  children: ReactText;
}
const NavItem = ({ children, ...rest }: NavItemProps) => {
  return (
    <Link href='#' style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

export const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const authContext = useContext(AuthenticationContext);
  const profContext = useProf();
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  const logout = () => {
    authContext?.logout();
    navigate('/');
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <HStack>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant='outline'
          aria-label='open menu'
          icon={<FiMenu />}
        />
        <Button
          display={{ base: 'flex', md: 'none' }}
          size={'sm'}
          rounded={'20'}
          onClick={toggleColorMode}
        >
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
      </HStack>

      <Img
        display={{ base: 'flex', md: 'none' }}
        h={'35px'}
        src={useColorModeValue('/ISEUS LOGO-02.png', '/ISEUS LOGO-03.png')}
        onClick={() => {
          navigate('/');
        }}
        _hover={{ cursor: 'pointer' }}
      />

      <Flex
        display={{
          base: 'none',
          md: profContext.subjects.length > 0 ? 'flex' : 'none',
        }}
        mr={'auto'}
      >
        <DesktopNav onClose={() => {}} />
      </Flex>

      <HStack spacing={{ base: '0', md: '2' }}>
        <Button
          display={{ base: 'none', md: 'flex' }}
          size={'sm'}
          rounded={'20'}
          onClick={toggleColorMode}
        >
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
        <IconButton size='lg' variant='ghost' aria-label='open menu' icon={<FiBell />} />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar size={'sm'} src={authContext?.user?.profileImage} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems='flex-start'
                  spacing='1px'
                  ml='2'
                >
                  <Text fontSize='sm'>
                    {authContext?.user?.firstName + ' ' + authContext?.user?.lastName}
                  </Text>
                  <Text fontSize='xs' color='gray.600'>
                    {authContext?.user?.title}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem
                onClick={() => {
                  navigate('/profesori/profil');
                }}
              >
                Moj profil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate('/profesori/postavke');
                }}
              >
                Postavke predmeta
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout}>Odjavi se</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const DesktopNavLink = ({ navItem, onClose }: { navItem: NavItem; onClose: () => void }) => {
  const resolved = useResolvedPath(navItem.href);
  const match = useMatch({ path: resolved.pathname, end: navItem.href === '' });

  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('black', 'white');

  return (
    <Box key={navItem.label} w='max-content'>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <Link
            as={NavLink}
            m={2}
            to={navItem.href}
            fontSize={'sm'}
            fontWeight={500}
            color={linkColor}
            _hover={{
              textDecoration: 'none',
              color: linkHoverColor,
            }}
            style={{
              borderBottom: match ? `2px solid ${popoverContentBgColor}` : undefined,
              paddingBottom: match ? '1px' : undefined,
            }}
            onClick={onClose}
          >
            {navItem.label}
          </Link>
        </PopoverTrigger>
      </Popover>
    </Box>
  );
};

const DesktopNav = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack
      pt={{ base: 5, md: 0 }}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      align={'center'}
      justify={'center'}
      w={'full'}
    >
      {NAV_ITEMS.map((navItem) => (
        <DesktopNavLink onClose={onClose} key={navItem.label} navItem={navItem} />
      ))}
    </Stack>
  );
};

interface NavItem {
  label: string;
  href: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Nastavnici',
    href: '',
  },
  {
    label: 'Predavanja',
    href: 'predavanja',
  },
  {
    label: 'Vježbe',
    href: 'vjezbe',
  },
  {
    label: 'Dodatne aktivnosti',
    href: 'dodatne-aktivnosti',
  },
  {
    label: 'Ispiti',
    href: 'ispiti',
  },
  {
    label: 'Studenti',
    href: 'studenti',
  },
];
