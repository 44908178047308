import { Flex, HStack, Heading, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { ExamScheduleResultsTable } from './comp/ExamScheduleResultsTable';

export const ExamScheduleResultsPage = () => {
  const { examScheduleID } = useParams();
  const navigate = useNavigate();
  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <Stack pos={'absolute'} top={'160px'} left={{ base: 5, md: '260px' }}>
        <ArrowBackButton
          onClick={() => {
            navigate('/profesori/ispiti/');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Rezultati cijelog ispitnog roka
        </Heading>
      </HStack>
      <VStack justify={'center'}>
        <Heading fontSize={'md'}>Legenda rezultata</Heading>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={5} py={5}>
          <VStack align='center'>
            <Heading fontSize={'sm'}>Naslov</Heading>
            <VStack>
              <VStack spacing={0}>
                <Text>PRAKTIČNI ISPIT</Text>
                <HStack>
                  <Text color={'green.400'}>40 /</Text>
                  <Text color={'orange.400'}>100 /</Text>
                  <Text color={'red.400'}>15</Text>
                </HStack>
                <VStack w={'250px'} align={'start'} spacing={0}>
                  <Text fontSize={'sm'}>- Ime ispita</Text>
                  <Text color={'green.400'} fontSize={'sm'}>
                    - Minimalan broj bodova potreban da se položi ispit.
                  </Text>
                  <Text color={'orange.400'} fontSize={'sm'}>
                    - Maximalan broj bodova na ispitu.
                  </Text>
                  <Text color={'red.400'} fontSize={'sm'}>
                    - Težinski faktor ispita.
                  </Text>
                </VStack>
              </VStack>
            </VStack>
          </VStack>
          <VStack align='center'>
            <Heading fontSize={'sm'}>Student rezultati</Heading>
            <VStack spacing={0}>
              <Text></Text>
              <HStack>
                <Text color={'green.400'}>60 /</Text>
                <Text color={'orange.400'}>15</Text>
              </HStack>
              <VStack w={'250px'} align={'start'} spacing={0}>
                <Text color={'green.400'} fontSize={'sm'}>
                  - Broj bodova koji je student osvojio.
                </Text>
                <Text color={'orange.400'} fontSize={'sm'}>
                  - Broj bodova pomnožem sa težinskim faktorom. Ovaj rezultat studenti vide na
                  svojim profilima.
                </Text>
              </VStack>
            </VStack>
          </VStack>
        </Stack>
      </VStack>
      <ExamScheduleResultsTable />
    </Flex>
  );
};
