import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Center,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlueButton } from '../../../../components/general/BlueButton';
import { YesNoModal } from '../../../../components/general/YesNoModal';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { SubjectActivity } from '../../../../models/subjectActivities';
import { useDeleteSubjectActivityMutation } from '../../../../services/mutations';
import {
  useGetSubjectActivitiesAllStudents,
  useGetSubjectActivitiesInfo,
} from '../../../../services/queries';
import { EditSubjectActivityAllModal } from './editSubjectActivityModal';

type Props = {};

const SubjectActivitiesAllStudents = (props: Props) => {
  const profContext = useProf();
  const authContext = useAuth();
  const navigate = useNavigate();
  const [isDelSubjectActModalOpen, setIsDelSubjectActModalOpen] = useState(false);
  const {
    isOpen: isEditSubjectActivityOpen,
    onOpen: onEditSubjectActivityOpen,
    onClose: onEditSubjectActivityClose,
  } = useDisclosure();
  const [selectedActivity, setSelectedActivity] = useState<SubjectActivity | null>(null);

  const subjectActivityInfo = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const subjectActivities = useGetSubjectActivitiesAllStudents(
    subjectActivityInfo.data?.id || null
  );

  const deleteSubjectAct = useDeleteSubjectActivityMutation(
    subjectActivityInfo.data?.id,
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID,
    null
  );

  return (
    <VStack w={{ base: '95vw', md: '70%', lg: '800px' }}>
      <EditSubjectActivityAllModal
        isOpen={isEditSubjectActivityOpen}
        onClose={onEditSubjectActivityClose}
        subjectActivity={selectedActivity}
        selectedGroupID={null}
      />
      <YesNoModal
        isOpen={isDelSubjectActModalOpen}
        onClose={() => setIsDelSubjectActModalOpen(false)}
        onConfirm={() => {
          if (selectedActivity) {
            deleteSubjectAct({ subjectActivityID: selectedActivity.id })
              .catch((err) => {})
              .finally(() => {
                setIsDelSubjectActModalOpen(false);
                setSelectedActivity(null);
              });
          }
        }}
        title='Brisanje aktivnosti'
        message={`Da li ste sigurni da želite da obrišete aktivnost ${selectedActivity?.name}, ova akcija je nepovratna i svi rezultati će biti obrisani.`}
      />
      {subjectActivities.isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : subjectActivities.data && subjectActivities.data.length === 0 ? (
        <Center>
          <Text>Nema dodatnih aktivnosti</Text>
        </Center>
      ) : (
        subjectActivities.data &&
        subjectActivities.data.map((activity) => (
          <Accordion key={`subjectActivityAll-${activity.id}`} w={'100%'} allowMultiple>
            <AccordionItem>
              <AccordionButton>
                <HStack w={'100%'} justify={'space-between'}>
                  <Box as='span' flex='1' textAlign='left'>
                    <Heading fontSize={'lg'}>{activity.name}</Heading>
                    <Text fontSize={'xs'}>Težinski faktor: {activity.difficultyFactor} </Text>
                    {activity.deadlineDate && (
                      <Text fontSize={'xs'}>Rok predaje: {activity.deadlineDate}</Text>
                    )}
                    {activity.dateTime && (
                      <Text fontSize={'xs'}>Datum održavanja: {activity.dateTime}</Text>
                    )}
                  </Box>
                  <HStack pr={3}>
                    <Avatar size={'sm'} name={'Profesor'} src={activity.creator.profileImage} />
                    <Text fontSize={'xs'}>
                      {activity.creator.lastName} {activity.creator.firstName}
                    </Text>
                  </HStack>
                </HStack>

                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <VStack w={'100%'}>
                  <Stack w={'100%'} direction={'row'} justify={{ base: 'end' }} spacing={1}>
                    <BlueButton
                      fontSize='xs'
                      height='25px'
                      width='100px'
                      buttonText='Rezultati'
                      onClick={() => {
                        navigate(`${activity.id}`);
                      }}
                    />

                    {authContext.user?.role === activity.creator.role &&
                      authContext.user?.sub === activity.creator.id && (
                        <Menu>
                          <MenuButton
                            color={'blue.300'}
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon />}
                            width={'25px'}
                            height={'25px'}
                          />
                          <MenuList>
                            <MenuItem
                              icon={<EditIcon />}
                              onClick={() => {
                                setSelectedActivity(activity);
                                onEditSubjectActivityOpen();
                              }}
                            >
                              Uredi aktivnost
                            </MenuItem>
                            <MenuItem
                              icon={<DeleteIcon />}
                              onClick={() => {
                                setSelectedActivity(activity);
                                setIsDelSubjectActModalOpen(true);
                              }}
                            >
                              Izbriši aktivnost
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      )}
                  </Stack>
                  <VStack fontSize={'sm'} w={'100%'} align={'start'} spacing={0}>
                    <Text>Datum kreiranja: {activity.createdAt}</Text>
                    {activity.edited ? <Text>Datum editovanja: {activity.editedAt}</Text> : ''}
                    <Text>Broj Bodova: {activity.maxScore}</Text>
                    <Text>Broj Bodova za prolaz: {activity.minPassingScore}</Text>
                    <Text>Tip aktivnosti: {activity.type}</Text>
                  </VStack>
                  <Heading size={'md'}>Opis aktivnosti</Heading>
                  <Text
                    w={{ base: '100%', md: '80%' }}
                    whiteSpace={'pre-line'}
                    textAlign={'justify'}
                  >
                    {activity.text}
                  </Text>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))
      )}
    </VStack>
  );
};

export default SubjectActivitiesAllStudents;
