import { Text } from '@chakra-ui/react';

type Props = {
  height?: string;
  width?: string;
  fontSize?: string;
  text: string;
};

export const HelperText = (props: Props) => {
  return (
    <Text w={props.width} h={props.height} fontSize={props.fontSize ?? 'sm'} color={'gray.400'}>
      {props.text}
    </Text>
  );
};
