import {
  Avatar,
  Button,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth.hooks';
import useProf from '../../../../hooks/useProf.hooks';
import { useGetFinishedStudentsOnSubject } from '../../../../services/queries';
import { StudentNameSubjectStats } from '../../comp/StudentNameSubjectStats';

interface LoadingStates {
  [key: number]: boolean;
}

export const FinishedStudentstable = () => {
  const profContext = useProf();
  const authContext = useAuth();
  const navigate = useNavigate();
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});

  const students = useGetFinishedStudentsOnSubject(profContext.selectedSubjectID);

  const getErrorMessage = (error: any) => {
    if (error.data && error.data.message) {
      return error.data.message;
    }
    return 'Desila se greška';
  };

  return (
    <>
      <TableContainer
        mt={'1rem'}
        w={'800px'}
        borderRadius={'xl'}
        border={'2px'}
        borderColor='blue.700'
      >
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Slika</Th>
              <Th textAlign='center'>Ime i prezime</Th>
              <Th textAlign='center'>Broj Indexa</Th>
              <Th textAlign='center'>E-mail adresa</Th>
              <Th textAlign='center'>opcije</Th>
            </Tr>
          </Thead>
          <Tbody>
            {students.isLoading ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : students.isError ? (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <VStack>
                      <Text>{getErrorMessage(students.error)}</Text>
                      <Button
                        fontSize={'sm'}
                        bgColor={'blue.600'}
                        color={'white'}
                        onClick={() => {
                          navigate('/profesori/predavanja');
                        }}
                      >
                        Vrati se na sva vježbe
                      </Button>
                    </VStack>
                  </Center>
                </Td>
              </Tr>
            ) : students.data && students.data.length > 0 ? (
              students.data.map((attendance, index) => (
                <Tr key={attendance.id}>
                  <Td textAlign='center'>{index + 1}</Td>
                  <Td textAlign='center'>
                    <Avatar size={'sm'} src={attendance.profileImage} />
                  </Td>
                  <Td textAlign='center'>
                    <StudentNameSubjectStats
                      firstName={attendance.firstName}
                      lastName={attendance.lastName}
                      navigateLink={`/profesori/student/${attendance.id}`}
                      hasCompletedSubject={attendance.hasCompletedSubject}
                      repeater={attendance.repeater}
                    />
                  </Td>
                  <Td textAlign='center'>{attendance.indexNumber}</Td>
                  <Td textAlign='center'>{attendance.email}</Td>
                  <Td textAlign='center'>
                    <Button
                      size='xs'
                      colorScheme='blue'
                      onClick={() => {
                        setLoadingStates((prev) => ({ ...prev, [attendance.id]: true }));
                        /*  toggleStudentPresence({
                          exerciseID: Number(exerciseID),
                          studentID: attendance.id,
                        })
                          .catch((err) => {})
                          .finally(() => {
                            setTimeout(() => {
                              setLoadingStates((prev) => ({ ...prev, [attendance.id]: false }));
                            }, 1000);
                          }); */
                      }}
                      isDisabled={authContext.user?.role === 'ASSISTANT' ? false : true}
                      isLoading={loadingStates[attendance.id]}
                    >
                      {/*  {attendance.presence ? 'Označi kao odsutan' : 'Označi kao prisutan'} */}
                      dugme
                    </Button>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  <Center>
                    <Text> Nema studenata koji su položili predmet </Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>

          {students.data && students.data.length > 8 && (
            <Tfoot>
              <Tr>
                <Th textAlign='center'>RB</Th>
                <Th textAlign='center'>Slika</Th>
                <Th textAlign='center'>Ime i prezime</Th>
                <Th textAlign='center'>Broj Indexa</Th>
                <Th textAlign='center'>E-mail adresa</Th>
                <Th textAlign='center'>opcije</Th>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
