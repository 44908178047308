export const PATH_PUBLIC = {
  home: '/',
  aboutUs: 'onama',
  contactUs: 'kontakt',
  news: 'novosti',
  bugReport: 'prijavagresaka',
  register: 'register',
  login: 'prijava',
  unauthorized: 'neovlastenpristup',
  notFound: '404',
};

export const PATH_STAFF = {
  home: 'profesori',
  lectures: 'predavanja',
  exercises: 'vjezbe',
  exams: 'ispiti',
  students: 'studenti',
};

export const PATH_ADMIN = {
  home: 'admin',
  organisations: 'ustanove',
  posts: 'objave',
};

export const PATH_STUDENT = {
  home: 'student',
};
