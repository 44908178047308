import {
  Card,
  Center,
  Flex,
  GridItem,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { useGetSelectedSubjectInfo } from '../../services/queries';
import { useStudentContext } from '../../hooks/useStudent.hooks';
import { BlueButton } from '../../components/general/BlueButton';
import { useNavigate } from 'react-router-dom';
import { GreetingPage } from './comp/GreetingPage';
import { Feature } from '../Landing/home/comp/Features';
import { MdAssignment, MdGroupAdd, MdInfoOutline } from 'react-icons/md';
import { ChatIcon } from '@chakra-ui/icons';
import {
  FaBookReader,
  FaChalkboardTeacher,
  FaPencilAlt,
  FaProjectDiagram,
  FaStar,
} from 'react-icons/fa';

type Props = {};

export const StudentHome = (props: Props) => {
  const studContext = useStudentContext();
  const subjectInfo = useGetSelectedSubjectInfo(studContext.selectedSubjectID);
  const theme = useColorModeValue('white', 'gray.900');
  const navigate = useNavigate();

  return (
    <Flex
      justify={'center'}
      align={'center'}
      flexDirection='column'
      p='5'
      bg={useColorModeValue('white', 'gray.900')}
      w='100%'
    >
      {subjectInfo.isLoading ? (
        <Center h={'50vh'}>
          <Spinner />
        </Center>
      ) : studContext.subjects.length > 0 ? (
        <>
          <HStack justify={'center'}>
            <Heading textAlign={'center'} fontSize={'xl'} pb={5}>
              {subjectInfo.data?.name}
            </Heading>
          </HStack>

          <SimpleGrid w={{ base: '100%', md: '500px' }} columns={{ base: 2 }} spacing={2}>
            <GridItem colSpan={2}>
              <Center>
                <Card
                  align={'center'}
                  variant={'outline'}
                  w='100%'
                  bg={theme}
                  p='10px'
                  pt='25px'
                  onClick={() => {
                    navigate('objave');
                  }}
                  cursor={'pointer'}
                >
                  <VStack>
                    <Icon as={ChatIcon} w={10} h={10} color={'blue.600'} />
                    <Heading fontSize={'md'}>Objave</Heading>
                  </VStack>
                </Card>
              </Center>
            </GridItem>
            <GridItem colSpan={1}>
              <Center>
                <Card
                  align={'center'}
                  variant={'outline'}
                  w='100%'
                  bg={theme}
                  p='10px'
                  pt='25px'
                  onClick={() => {
                    navigate('predavanja');
                  }}
                  cursor={'pointer'}
                >
                  <VStack>
                    <Icon as={FaChalkboardTeacher} w={10} h={10} color={'blue.600'} />
                    <Heading fontSize={'md'}>Predavanja</Heading>
                  </VStack>
                </Card>
              </Center>
            </GridItem>
            <GridItem colSpan={1}>
              <Center>
                <Card
                  align={'center'}
                  variant={'outline'}
                  w='100%'
                  bg={theme}
                  p='10px'
                  pt='25px'
                  onClick={() => {
                    navigate('vjezbe');
                  }}
                  cursor={'pointer'}
                >
                  <VStack>
                    <Icon as={FaBookReader} w={10} h={10} color={'blue.600'} />
                    <Heading fontSize={'md'}>Vježbe</Heading>
                  </VStack>
                </Card>
              </Center>
            </GridItem>
            <GridItem colSpan={2}>
              <Center>
                <Card
                  align={'center'}
                  variant={'outline'}
                  w='100%'
                  bg={theme}
                  p='10px'
                  pt='25px'
                  onClick={() => {
                    navigate('dodatne-aktivnosti');
                  }}
                  cursor={'pointer'}
                >
                  <VStack>
                    <Icon as={FaProjectDiagram} w={10} h={10} color={'blue.600'} />
                    <Heading fontSize={'md'}>Dodatne aktivnosti</Heading>
                  </VStack>
                </Card>
              </Center>
            </GridItem>
            <GridItem colSpan={2}>
              <Center>
                <Card
                  align={'center'}
                  variant={'outline'}
                  w='100%'
                  bg={theme}
                  p='10px'
                  pt='25px'
                  onClick={() => {
                    navigate('ispiti');
                  }}
                  cursor={'pointer'}
                >
                  <VStack>
                    <Icon as={MdAssignment} w={10} h={10} color={'blue.600'} />
                    <Heading fontSize={'md'}>Ispiti</Heading>
                  </VStack>
                </Card>
              </Center>
            </GridItem>
            <GridItem colSpan={2}>
              <Center>
                <Card
                  align={'center'}
                  variant={'outline'}
                  w='100%'
                  bg={theme}
                  p='10px'
                  pt='25px'
                  onClick={() => {
                    navigate('rezultati');
                  }}
                  cursor={'pointer'}
                >
                  <VStack>
                    <Icon as={FaStar} w={10} h={10} color={'blue.600'} />
                    <Heading fontSize={'md'}>Zbir bodova i konačna ocjena</Heading>
                  </VStack>
                </Card>
              </Center>
            </GridItem>
            <GridItem colSpan={2}>
              <Center>
                <Card
                  align={'center'}
                  variant={'outline'}
                  w='100%'
                  bg={theme}
                  p='10px'
                  pt='25px'
                  onClick={() => {
                    navigate('podaci-od-predmeta');
                  }}
                  cursor={'pointer'}
                >
                  <VStack>
                    <Icon as={MdInfoOutline} w={10} h={10} color={'blue.600'} />
                    <Heading fontSize={'md'}>Podaci od predmeta</Heading>
                  </VStack>
                </Card>
              </Center>
            </GridItem>
          </SimpleGrid>

          {/*  <SimpleGrid
            w={{ base: '100%', md: '500px' }}
            columns={{ base: 1, xl: 2 }}
            spacing={'30px'}
          >
            <Center>
              <BlueButton
                fontSize='xl'
                height={'60px'}
                width='85%'
                buttonText='Objave'
                onClick={() => {
                  navigate('objave');
                }}
              />
            </Center>
            <Center>
              <BlueButton
                fontSize='xl'
                height='60px'
                width='85%'
                buttonText='Predavanja'
                onClick={() => {
                  navigate('predavanja');
                }}
              />
            </Center>
            <Center>
              <BlueButton
                fontSize='xl'
                height='60px'
                width='85%'
                buttonText='Vježbe'
                onClick={() => {
                  navigate('vjezbe');
                }}
              />
            </Center>
            <Center>
              <BlueButton
                fontSize='xl'
                height='60px'
                width='85%'
                buttonText='Dodatne aktivnosti'
                onClick={() => {
                  navigate('dodatne-aktivnosti');
                }}
              />
            </Center>
            <Center>
              <BlueButton
                fontSize='xl'
                height='60px'
                width='85%'
                buttonText='Zbir bodova i ocjena'
                onClick={() => {
                  navigate('rezultati');
                }}
              />
            </Center>
            <Center>
              <BlueButton
                fontSize='xl'
                height='60px'
                width='85%'
                buttonText='Podaci od predmeta'
                onClick={() => {
                  navigate('podaci-od-predmeta');
                }}
              />
            </Center>
          </SimpleGrid> */}
        </>
      ) : (
        <GreetingPage />
      )}
    </Flex>
  );
};
