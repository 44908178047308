import { createContext, useReducer, useCallback, useEffect } from 'react';
import {
  AuthContextActionTypes,
  AuthContext,
  AuthContextAction,
  AuthContextState,
  LoginResponse,
  refreshResponse,
  StaffEnum,
} from '../@types/auth.types';
import { getAccessToken, getRefreshToken, setSession } from './auth.utils';
import { useNavigate } from 'react-router-dom';
import {
  LOGIN_URL,
  REGISTER_URL,
  REFRESH_URL,
  PATH_AFTER_REGISTER,
  ADMIN_LOGIN_URL,
  PATH_ADMIN_AFTER_LOGIN,
  PATH_AFTER_LOGOUT,
  STUDENT_LOGIN_URL,
  PATH_STUDENT_AFTER_LOGIN,
} from '../utils/globalConfig';
import { axiosInstance } from '../utils/axiosInstance';
import { jwtDecode } from 'jwt-decode';
import { useToast } from '@chakra-ui/react';

const authReducer = (state: AuthContextState, action: AuthContextAction) => {
  if (action.type === AuthContextActionTypes.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      isAuthLoading: false,
      user: action.payload,
    };
  }

  if (action.type === AuthContextActionTypes.LOGOUT) {
    return { ...state, isAuthenticated: false, isAuthLoading: false, user: undefined };
  }

  return state;
};

// initial state object for the useReducer hook.
const initialAuthState: AuthContextState = {
  isAuthenticated: false,
  isAuthLoading: true,
  user: undefined,
};

type StaffContextProviderType = {
  children: React.ReactNode;
};

export const AuthenticationContext = createContext<AuthContext | null>(null);

export const AuthContextProvider = ({ children }: StaffContextProviderType) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const toast = useToast();

  //initialize method
  const InitializeAuthContext = useCallback(async () => {
    try {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const response = await axiosInstance.post<refreshResponse>(
          REFRESH_URL,
          {
            refreshToken,
          },
          { headers: { Authorization: 'Bearer ' + getAccessToken() } }
        );
        const { accessToken } = response.data;

        setSession(accessToken, refreshToken);
        dispatch({
          type: AuthContextActionTypes.LOGIN,
          payload: jwtDecode(accessToken),
        });
      } else {
        setSession(null, null);
        dispatch({
          type: AuthContextActionTypes.LOGOUT,
        });
      }
    } catch (error) {
      setSession(null, null);
      dispatch({
        type: AuthContextActionTypes.LOGOUT,
      });
    }
  }, []);

  useEffect(() => {
    InitializeAuthContext()
      .then(() => {})
      .catch((error) => console.log(error));
  }, []);

  // register method
  const register = useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      title: string,
      role: StaffEnum,
      password: string
    ) => {
      const response = await axiosInstance.post(REGISTER_URL, {
        firstName,
        lastName,
        email,
        role,
        title,
        password,
      });
      navigate(`/${PATH_AFTER_REGISTER}`);
    },
    []
  );

  //LOGIN METHOD
  const login = useCallback(async (email: string, password: string) => {
    const response = await axiosInstance.post<LoginResponse>(LOGIN_URL, {
      email,
      password,
    });
    toast({
      position: 'top',
      title: 'Prijava je uspješna!',
      status: 'success',
      duration: 1500,
    });
    const { accessToken, refreshToken } = response.data;
    setSession(accessToken, refreshToken);
    dispatch({
      type: AuthContextActionTypes.LOGIN,
      payload: jwtDecode(accessToken),
    });
    //navigate(PATH_STAFF_AFTER_LOGIN);
  }, []);

  const adminLogin = useCallback(async (email: string, password: string) => {
    const response = await axiosInstance.post<LoginResponse>(ADMIN_LOGIN_URL, {
      email,
      password,
    });
    toast({
      position: 'top',
      title: 'Prijava je uspješna!',
      status: 'success',
      duration: 3000,
    });
    const { accessToken, refreshToken } = response.data;
    setSession(accessToken, refreshToken);
    dispatch({
      type: AuthContextActionTypes.LOGIN,
      payload: jwtDecode(accessToken),
    });
    navigate(PATH_ADMIN_AFTER_LOGIN);
  }, []);

  const studentLogin = useCallback(async (email: string, password: string) => {
    const response = await axiosInstance.post<LoginResponse>(STUDENT_LOGIN_URL, {
      email,
      password,
    });
    toast({
      position: 'top',
      title: 'Prijava je uspješna!',
      status: 'success',
      duration: 3000,
    });
    const { accessToken, refreshToken } = response.data;
    setSession(accessToken, refreshToken);
    dispatch({
      type: AuthContextActionTypes.LOGIN,
      payload: jwtDecode(accessToken),
    });
    navigate(PATH_STUDENT_AFTER_LOGIN);
  }, []);

  // LOGOUT METHOD
  const logout = useCallback(() => {
    setSession(null, null);
    dispatch({
      type: AuthContextActionTypes.LOGOUT,
    });
    navigate(PATH_AFTER_LOGOUT);
  }, []);

  const valuesObject = {
    isAuthenticated: state.isAuthenticated,
    isAuthLoading: state.isAuthLoading,
    user: state.user,
    register,
    login,
    adminLogin,
    studentLogin,
    logout,
  };

  return (
    <AuthenticationContext.Provider value={valuesObject}>{children}</AuthenticationContext.Provider>
  );
};
