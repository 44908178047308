import {
  Avatar,
  Box,
  Center,
  Checkbox,
  HStack,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BlueButton } from '../../../../components/general/BlueButton';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useGetUniversitiesWithAdmins } from '../../../../services/queries';
import { useToggleAdminMutation } from '../../../../services/mutations';

interface LoadingStates {
  [key: number]: boolean;
}

export const AdminsTable = () => {
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({});
  const { data, isLoading } = useGetUniversitiesWithAdmins();
  const toast = useToast();
  const toggleAdminActive = useToggleAdminMutation();

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack spacing={8} align='stretch'>
      {data?.map((university) => (
        <Box w={{ base: '90vw', md: '100%' }} key={university.id}>
          <Heading size='md' mb={4}>
            {university.name}
          </Heading>
          <TableContainer mt={'0rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
            <Table variant='striped' colorScheme='facebook' size='sm'>
              <Thead>
                <Tr>
                  <Th textAlign='center'>Slika</Th>
                  <Th textAlign='center'>Ime i prezime</Th>
                  <Th textAlign='center'>Email</Th>
                  <Th textAlign='center'>Odobren</Th>
                  <Th textAlign='center'>Options</Th>
                </Tr>
              </Thead>
              <Tbody>
                {university.admin.length === 0 ? (
                  <Tr>
                    <Td colSpan={5}>
                      <Center>Nema admina</Center>
                    </Td>
                  </Tr>
                ) : (
                  university.admin.map((admin) => (
                    <Tr key={admin.id}>
                      <Td textAlign='center'>
                        <Avatar size='sm' src={admin.profileImage} />
                      </Td>
                      <Td>
                        {admin.firstName} {admin.lastName}
                      </Td>
                      <Td>{admin.email}</Td>
                      <Td textAlign='center'>
                        <Checkbox isChecked={admin.active} colorScheme='green'></Checkbox>
                      </Td>
                      <Td textAlign='center'>
                        <HStack justify={'center'} spacing={5}>
                          {admin.mainAdmin ? (
                            <HStack spacing={0}>
                              <EditIcon
                                color={'white'}
                                boxSize={5}
                                ml='5px'
                                mr={'5px'}
                                onClick={() => {
                                  toast({
                                    position: 'top',
                                    title: 'Ova funkcionalnost još nije implementirana',
                                    status: 'warning',
                                    duration: 3000,
                                  });
                                }}
                                _hover={{ cursor: 'pointer', color: 'green.500' }}
                              />
                            </HStack>
                          ) : (
                            <>
                              <BlueButton
                                width='55px'
                                height='25px'
                                buttonText={admin.active ? 'blokiraj' : 'odobri'}
                                isLoading={loadingStates[admin.id]}
                                onClick={() => {
                                  setLoadingStates((prev) => ({ ...prev, [admin.id]: true }));
                                  toggleAdminActive({
                                    staffID: admin.id,
                                  })
                                    .catch((err) => {})
                                    .finally(() => {
                                      setTimeout(() => {
                                        setLoadingStates((prev) => ({
                                          ...prev,
                                          [admin.id]: false,
                                        }));
                                      }, 1000);
                                    });
                                }}
                              />
                              <HStack spacing={0}>
                                <EditIcon
                                  color={'white'}
                                  boxSize={5}
                                  ml='5px'
                                  mr={'5px'}
                                  onClick={() => {
                                    toast({
                                      position: 'top',
                                      title: 'Ova funkcionalnost još nije implementirana',
                                      status: 'warning',
                                      duration: 3000,
                                    });
                                  }}
                                  _hover={{ cursor: 'pointer', color: 'green.500' }}
                                />
                                <DeleteIcon
                                  color={'white'}
                                  boxSize={5}
                                  onClick={() => {
                                    toast({
                                      position: 'top',
                                      title: 'Ova funkcionalnost još nije implementirana',
                                      status: 'warning',
                                      duration: 3000,
                                    });
                                  }}
                                  _hover={{ cursor: 'pointer', color: 'red.500' }}
                                />
                              </HStack>
                            </>
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </VStack>
  );
};
