import { Flex, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useProf from '../../hooks/useProf.hooks';
import { useGetSelectedSubjectInfo } from '../../services/queries';
import SidebarNav from './comp/SidebarAndNav';
import SubjectInfo from './comp/SubjectInfo';

const Professors = () => {
  const [toggleSubjectInfo, setToggleSubjectInfo] = useState(false);
  const { selectedSubjectID, subjects } = useProf();
  const selectedSubjectInfoQuery = useGetSelectedSubjectInfo(selectedSubjectID);
  const navigate = useNavigate();

  const handleToggle = () => {
    setToggleSubjectInfo(!toggleSubjectInfo);
  };

  return (
    <>
      <SidebarNav>
        {selectedSubjectInfoQuery.isLoading ? (
          <Flex justifyContent='center' alignItems='center' h={toggleSubjectInfo ? '30vh' : '8vh'}>
            <Spinner />
          </Flex>
        ) : (
          <>
            {selectedSubjectInfoQuery.data?.id && (
              <SubjectInfo onClick={handleToggle} toggleState={toggleSubjectInfo} />
            )}
            <Outlet />
          </>
        )}
      </SidebarNav>
    </>
  );
};

export default Professors;
