import {
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  Img,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function SplitScreen() {
  const navigate = useNavigate();
  return (
    <Stack minH={'85vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={6} w={'full'} maxW={'lg'}>
          <HStack justify={'center'}>
            <Img
              w={{ base: '150px', md: '250px' }}
              src={useColorModeValue('/LOGO DARK-01.png', '/LOGO WHITE-01.png')}
            />
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
              {/* <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '20%', md: '35%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'blue.600',
                  zIndex: -1,
                }}
              >
                ISEUS
              </Text> */}
            </Heading>
          </HStack>
          <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
            <VStack spacing={0} align={'start'}>
              <Text fontSize={{ base: '3xl' }} color={'blue.400'} as={'span'}>
                Univerzitetski informacioni sistem
              </Text>
              <Text fontSize={{ base: '3xl' }} color={'blue.400'} as={'span'}>
                za evidenciju uspjeha studenata
              </Text>
            </VStack>
          </Heading>
          <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
            Savremeni i moderni informacioni sistem za automatizovanu evidenciju uspjeha i
            prisustva, te automatizovanu detekciju uslova ispita studenata po predmetima.
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Button
              rounded={'full'}
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={() => {
                navigate('/prijava');
              }}
            >
              Prijava za osoblje
            </Button>
            <Button
              rounded={'full'}
              onClick={() => {
                navigate('/prijava-student');
              }}
            >
              Prijava za studente
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={'https://static.klix.ba/media/images/vijesti/231021039.4_xxl.jpg?v=1'}
        />
      </Flex>
    </Stack>
  );
}
