import {
  Center,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { Navigate, useNavigate } from 'react-router-dom';
import { useGetStudentsExamPreview } from '../../../services/queries';
import { StudentsExamPreviews } from './comp/Exam';
import { useStudentContext } from '../../../hooks/useStudent.hooks';

type Props = {};

export const StudentsExamsPage = (props: Props) => {
  const navigate = useNavigate();
  const studsContext = useStudentContext();

  const examsPreview = useGetStudentsExamPreview(studsContext.selectedSubjectID);

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={10}>
          Ispiti
        </Heading>
      </HStack>
      <VStack pb={10}>
        <Heading fontSize={'lg'}>Aktivni ispiti</Heading>

        {examsPreview.isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : examsPreview.data && examsPreview.data.length > 0 ? (
          <VStack w={'100%'} pt={5} spacing={5}>
            {examsPreview.data.map((exam) => (
              <StudentsExamPreviews key={`exam-preview-${exam.id}`} studentExamPreview={exam} />
            ))}
          </VStack>
        ) : (
          <Center>
            <Text>Nema aktivnih ispita</Text>
          </Center>
        )}
      </VStack>
      <VStack pb={10}>
        <Heading fontSize={'lg'} pb={10}>
          Završeni ispiti
        </Heading>
      </VStack>
    </Flex>
  );
};
