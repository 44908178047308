import { FormControl, FormLabel, Input, Stack, Text, VStack } from '@chakra-ui/react';
import { ErrorMessage, Field } from 'formik';

type Props = {
  name: string;
  type: string;
  label: string;
  isRequired?: boolean;
  width?: string;
};

export const HorizontalTextField = ({ name, type, label, isRequired = true, width }: Props) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: any }) => (
        <FormControl
          id={name}
          isRequired={isRequired}
          isInvalid={form.errors.firstName && form.touched.firstName}
        >
          <VStack justify={'center'}>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              align={{ md: 'center' }}
              spacing={{ base: 0, md: 5 }}
            >
              <VStack w={{ base: '100%', md: '50%' }} align={{ base: 'start', md: 'end' }}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
              </VStack>
              <VStack w={'50%'} align={'start'}>
                <Input type={type} {...field} w={width ?? '300px'} />
              </VStack>
            </Stack>
            <ErrorMessage name={name}>
              {(msg) => (
                <Text color='red.400' fontWeight={'bold'} fontSize='sm'>
                  {msg}
                </Text>
              )}
            </ErrorMessage>
          </VStack>
        </FormControl>
      )}
    </Field>
  );
};
