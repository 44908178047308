import { Flex, HStack, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { SignatureStatsTable } from './comp/SignatureStatsTable';

type Props = {};

export const SignaturePage = (props: Props) => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDirection='column'
      align={'center'}
      p='5'
      bg={useColorModeValue('gray.100', 'gray.900')}
      w='100%'
    >
      <Stack pos={'absolute'} top={'160px'} left={{ base: 5, md: '260px' }}>
        <ArrowBackButton
          onClick={() => {
            navigate('/profesori/studenti/pregled-stanja-prisustva');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={'5vh'}>
          Podjela potpisa
        </Heading>
      </HStack>
      <SignatureStatsTable />
    </Flex>
  );
};
