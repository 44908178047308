import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';
import { Student } from '../../../../models/student';
import { useAdminEditStudentMutation } from '../../../../services/mutations';
import { editStudentSchema } from '../../../../validation/admin-schema';
import { HorizontalTextField } from '../../../Professor/NewSubject/comp/HorzintalTextField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  student: Student | null;
};

export const EditStudentModal = ({ isOpen, onClose, student }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateStudent = useAdminEditStudentMutation();

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      await updateStudent(values);
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Modal isCentered size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Uredi Studenta</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          <Formik
            initialValues={{
              studentID: student?.id,
              firstName: student?.firstName,
              lastName: student?.lastName,
              indexNumber: student?.indexNumber,
              email: student?.email,
            }}
            validationSchema={editStudentSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values);
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                  <HorizontalTextField name='firstName' type='text' label='Ime' width='300px' />
                  <HorizontalTextField name='lastName' type='text' label='Prezime' width='300px' />
                  <HorizontalTextField
                    name='indexNumber'
                    type='text'
                    label='Broj indexa'
                    width='300px'
                  />
                  <HorizontalTextField name='email' type='text' label='Email' width='300px' />

                  <HStack py={5} justify={'center'}>
                    <BluesubmitButton
                      buttonText='Sačuvaj izmjene'
                      loadingText='Izmjene se čuvaju...'
                      isLoading={isLoading}
                    />
                    <RedButton buttonText='Odustani' onClick={onClose} />
                  </HStack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
