import {
  Checkbox,
  HStack,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { lecturesInfoCR } from '../../../../models/student-complete-report';

type Props = {
  lectureInfo: lecturesInfoCR;
};

export const LecturesInfo = ({ lectureInfo }: Props) => {
  return (
    <VStack justify={'center'}>
      <HStack justify={'center'}>
        <Heading fontSize={'lg'}>Predavanja</Heading>
      </HStack>
      <HStack spacing={4}>
        <Text fontSize={'md'}>
          Ukupno dolazaka: {lectureInfo.attendedLectures} / {lectureInfo.totalLectures}
        </Text>
        <Text fontSize={'md'}>| Bodovi: {lectureInfo.scoreDF} |</Text>
      </HStack>
      <TableContainer mt={'1rem'} borderRadius={'xl'} border={'2px'} borderColor='blue.700'>
        <Table variant='striped' colorScheme='facebook' size='sm'>
          <Thead>
            <Tr>
              <Th textAlign='center'>RB</Th>
              <Th textAlign='center'>Datum</Th>
              <Th textAlign='center'>Prisustvo</Th>
            </Tr>
          </Thead>
          <Tbody>
            {lectureInfo.lectures.map((attendance, index) => (
              <Tr key={`lecture-${index}`}>
                <Td textAlign='center'>{index + 1}</Td>
                <Td textAlign='center'>{attendance.date}</Td>
                <Td textAlign='center'>
                  <Checkbox
                    size={'md'}
                    isChecked={attendance.attendance}
                    colorScheme='green'
                  ></Checkbox>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
