import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Link,
  HStack,
} from '@chakra-ui/react';
import { FaFacebook, FaGithub, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallWithSocial() {
  const navigate = useNavigate();
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container
        as={Stack}
        maxW={'100%'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-arround' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justify={{ base: 'center', md: 'center' }}
          align={{ base: 'center' }}
        >
          <Text>2023 - 2024 © ISEUS. </Text>
          <HStack>
            <Text> Developed by </Text>
            <Link color={'blue.400'} href='https://www.linkedin.com/in/alshamaliallen/' isExternal>
              Allen Al-Shamali
            </Link>
          </HStack>
          <HStack>
            <Text>| Mentor V. prof. dr.</Text>
            <Link
              color={'blue.400'}
              href='https://www.linkedin.com/in/denis-%C4%8Deke-b3b30989/'
              isExternal
            >
              Denis Čeke
            </Link>
          </HStack>
        </Stack>
        {/* <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Facebook'} href={'#'}>
            <FaFacebook />
          </SocialButton>
          <SocialButton label={'YouTube'} href={'#'}>
            <FaYoutube />
          </SocialButton>
          <SocialButton label={'Github'} href={'#'}>
            <FaGithub />
          </SocialButton>
        </Stack> */}
      </Container>
    </Box>
  );
}
