import {
  Avatar,
  Card,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { ArrowBackButton } from '../../../components/general/ArrowBackButton';
import { useNavigate } from 'react-router-dom';
import { useStudentContext } from '../../../hooks/useStudent.hooks';
import { useGetSubjectInfo } from '../../../services/queries';

type Props = {};

export const StudentSubjectInfoPage = (props: Props) => {
  const navigate = useNavigate();
  const studentContext = useStudentContext();

  const subjectInfo = useGetSubjectInfo(studentContext.selectedSubjectID);

  return (
    <Flex flexDirection='column' p='5' bg={useColorModeValue('white', 'gray.900')} w='100%'>
      <Stack pos={{ base: 'absolute', md: 'static' }} top={'100px'} left={5}>
        <ArrowBackButton
          onClick={() => {
            navigate('/student');
          }}
        />
      </Stack>
      <HStack justify={'center'}>
        <Heading fontSize={'xl'} pb={5}>
          Podaci od predmeta
        </Heading>
      </HStack>
      {subjectInfo.isLoading ? (
        <Stack h={'20vh'} justify={'center'} align={'center'}>
          <Spinner />
        </Stack>
      ) : subjectInfo.data ? (
        <Stack justify={'center'} align={'center'}>
          <Heading fontSize={'2xl'} py={3}>
            Podaci za index
          </Heading>
          <VStack spacing={0}>
            <Text fontSize={'xl'}>Broj predavanja: {subjectInfo.data.lecturesInfo.classHours}</Text>
            <Text fontSize={'xl'}>Broj vježbi: {subjectInfo.data.exercisesInfo.classHours}</Text>
            <Text fontSize={'xl'}>ECTS bodovi: {subjectInfo.data.ECTSpoints}</Text>
          </VStack>
          <Heading fontSize={'xl'} py={10}>
            Profesori
          </Heading>
          <VStack>
            {subjectInfo.data.professors.map((professor) => (
              <Card key={professor.id} variant={'outline'} p={5} w={{ base: '90vw', md: '250px' }}>
                <VStack spacing={0}>
                  <Avatar size={'2xl'} src={professor.profileImage}></Avatar>
                  <Heading pt={2} fontSize={'xl'}>
                    {professor.firstName} {professor.lastName}
                  </Heading>
                  <Text>{professor.title}</Text>
                  <Text>{professor.email}</Text>
                </VStack>
              </Card>
            ))}
          </VStack>
          <Heading fontSize={'xl'} py={10}>
            Asistenti
          </Heading>
          <VStack>
            {subjectInfo.data.assistants.map((professor) => (
              <Card key={professor.id} variant={'outline'} p={5} w={{ base: '90vw', md: '250px' }}>
                <VStack spacing={0}>
                  <Avatar size={'2xl'} src={professor.profileImage}></Avatar>
                  <Heading pt={2} fontSize={'xl'}>
                    {professor.firstName} {professor.lastName}
                  </Heading>
                  <Text>{professor.title}</Text>
                  <Text>{professor.email}</Text>
                </VStack>
              </Card>
            ))}
          </VStack>
        </Stack>
      ) : (
        <Stack justify={'center'} align={'center'}>
          <Heading fontSize={'xl'} pb={10}>
            Nema podataka
          </Heading>
        </Stack>
      )}
    </Flex>
  );
};
