import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import useProf from '../../../../hooks/useProf.hooks';
import { useAssistCreatePostMutation } from '../../../../services/mutations';
import { useGetExerciseGroups, useGetExercisesInfo } from '../../../../services/queries';
import {
  assistCreatePostSchema,
  assistCreatePostSchemaDto,
} from '../../../../validation/post-schema';

export function AssistCreatePost() {
  const profContext = useProf();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [groupID, setGroupID] = useState<number | null>(null);
  const [allStudents, setAllStudents] = useState<boolean>(false);
  const exerciseInfo = useGetExercisesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });
  const groups = useGetExerciseGroups({
    academicYearID: exerciseInfo.data?.academicYearID,
    exerciseInfoID: exerciseInfo.data?.id,
  });

  const createPost = useAssistCreatePostMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const handleCreatePost = async (values: assistCreatePostSchemaDto, actions: any) => {
    setLoading(true);
    try {
      await createPost({
        title: values.title,
        text: values.text,
        subjectID: values.subjectID,
        groupID,
        allStudents,
      });
      actions.resetForm();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleGroupChange = (groupID: number) => {
    setGroupID(groupID);
  };

  return (
    <Stack spacing={5} mx={'auto'} w={'100%'} py={4} px={5}>
      <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
        <Formik
          initialValues={{
            title: '',
            text: '',
            allStudents: allStudents,
            subjectID: profContext.selectedSubjectID,
            groupID: groupID,
          }}
          validationSchema={assistCreatePostSchema}
          onSubmit={async (values, actions) => {
            handleCreatePost(values, actions);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Stack spacing={4}>
                <Box>
                  <Field name='title'>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id='title'
                        isRequired
                        isInvalid={form.errors.title && form.touched.title}
                      >
                        <FormLabel>Naslov objave</FormLabel>
                        <Input type='text' {...field} />
                        <ErrorMessage name='title'>
                          {(msg) => (
                            <Text color='red' fontSize='sm'>
                              {msg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box>
                  <Field name='text'>
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        id='text'
                        isRequired
                        isInvalid={form.errors.text && form.touched.text}
                      >
                        <FormLabel>Tekst objave</FormLabel>
                        <Textarea type='text' {...field} />
                        <ErrorMessage name='text'>
                          {(msg) => (
                            <Text color='red' fontSize='sm'>
                              {msg}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <HStack>
                  <FormLabel>Objaviti objavu svim studentima?</FormLabel>
                  <Checkbox
                    colorScheme='green'
                    isChecked={allStudents}
                    onChange={(e) => {
                      setAllStudents(!allStudents);
                      console.log(allStudents);
                    }}
                  ></Checkbox>
                </HStack>

                {groups.data && groups.data?.length !== 0 ? (
                  groups.isLoading ? (
                    <Spinner />
                  ) : (
                    //  ****  Group selection field *****  //
                    <Field name={'groupID'}>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id={'groupID'}
                          isRequired
                          isInvalid={form.errors.groupID && form.touched.groupID}
                        >
                          <VStack align={'start'}>
                            {allStudents ? null : (
                              <>
                                <FormLabel>Izaberite grupu kojoj želite poslati objavu</FormLabel>
                                <VStack>
                                  <Select
                                    w={'100%'}
                                    placeholder={'Izaberite Grupu'}
                                    {...field}
                                    onChange={(e) => {
                                      form.setFieldValue('groupID', Number(e.target.value));
                                      handleGroupChange(Number(e.target.value));
                                    }}
                                  >
                                    {groups.data.length > 0 &&
                                      groups.data.map((data, index) => (
                                        <option
                                          value={data.groupID}
                                          key={index}
                                        >{`${data.groupName}`}</option>
                                      ))}
                                  </Select>
                                </VStack>{' '}
                              </>
                            )}

                            <ErrorMessage name={'universityID'}>
                              {(msg) => (
                                <Text color='red' fontSize='sm'>
                                  {msg}
                                </Text>
                              )}
                            </ErrorMessage>
                          </VStack>
                        </FormControl>
                      )}
                    </Field>
                  )
                ) : (
                  <Center w='100%'>
                    <Text>Nema grupa</Text>
                  </Center>
                )}

                <Stack spacing={10} pt={2}>
                  <Button
                    type='submit'
                    isLoading={isLoading}
                    loadingText='Kreiranje u toku...'
                    size='lg'
                    bg='blue.400'
                    color='white'
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Kreiraj objavu
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Stack>
  );
}
