import { Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useGetExercisesPerGroupInfo } from '../../../../services/queries';

type Props = {};

export const ExercisePerGroupInfo = (props: Props) => {
  const { groupID } = useParams();

  const exerciseInfoQuery = useGetExercisesPerGroupInfo(Number(groupID));

  return (
    <>
      <VStack>
        {exerciseInfoQuery.isLoading ? (
          <Center h={'20vh'}>
            <Spinner />
          </Center>
        ) : exerciseInfoQuery.data ? (
          <VStack align={'start'}>
            <HStack align={'center'}>
              <Text>Broj održanih vježbi: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {exerciseInfoQuery.data.numberOfExercises}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Preostali broj časova:</Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {exerciseInfoQuery.data.classHours - exerciseInfoQuery.data.finishedClassHours} /
                {exerciseInfoQuery.data.classHours}
              </Text>
            </HStack>
          </VStack>
        ) : (
          <VStack align={'start'}>
            <Text>Nema informacija o vježbama</Text>
          </VStack>
        )}
      </VStack>
    </>
  );
};
