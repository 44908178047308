import { Flex, Heading, HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import React from 'react';
import { useGetCompletedSubjects } from '../../../services/queries';
import { BlueButton } from '../../../components/general/BlueButton';
import { useNavigate } from 'react-router-dom';

export const CompletedSubjectsPage = () => {
  const navigate = useNavigate();

  const completedSubjects = useGetCompletedSubjects();
  return (
    <Flex
      justify={'center'}
      align={'center'}
      flexDirection='column'
      p='5'
      bg={useColorModeValue('white', 'gray.900')}
      w='100%'
    >
      <HStack justify={'center'}>
        <Heading textAlign={'center'} fontSize={'xl'} pb={5}>
          Položeni predmeti
        </Heading>
      </HStack>

      <VStack w={'100%'}>
        {completedSubjects.isLoading ? (
          <Heading>Učitavanje...</Heading>
        ) : completedSubjects.data && completedSubjects.data.length > 0 ? (
          completedSubjects.data.map((subject) => (
            <BlueButton
              key={subject.id}
              buttonText={subject.name}
              width='100%'
              onClick={() => {
                navigate(`/student/polozeni-predmeti/${subject.id}`);
              }}
            />
          ))
        ) : (
          <Text pt={5} fontSize={'lg'}>
            Nemate položenih predmeta na ISEUS-u
          </Text>
        )}
      </VStack>
    </Flex>
  );
};
