import {
  Button,
  Center,
  HStack,
  Select,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BlueButton } from '../../../../components/general/BlueButton';
import useProf from '../../../../hooks/useProf.hooks';
import { AcademicYear } from '../../../../models/subject';
import { useGetAcademicYears, useGetSubjectActivitiesInfo } from '../../../../services/queries';
import { CreateSubjectActivityAllModal } from './createSubjectActivityAllModal';
import useAuth from '../../../../hooks/useAuth.hooks';
import { AcademicYearSelectionField } from '../../../../components/general/academicYearSelectionField';

type Props = {};

export const SubjectActivitesAllInfo = (props: Props) => {
  const profContext = useProf();
  const authContext = useAuth();

  const years = useGetAcademicYears(profContext.selectedSubjectID);

  const {
    isOpen: isCreateSubjectActivityOpen,
    onOpen: onCreateSubjectActivityOpen,
    onClose: onCreateSubjectActivityClose,
  } = useDisclosure();

  const subjectActivitiesQuery = useGetSubjectActivitiesInfo({
    subjectID: profContext.selectedSubjectID,
    yearID: profContext.selectedAcademicYearID,
  });

  const handleOnChangeAcademicYear = (e: any) => {
    const yearID = Number(e.target.value);
    profContext.setSelectedAcademicYearID(yearID);
  };

  const toast = useToast();

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w={{ base: '90vw', md: '85%', lg: '700px' }}
      justify='space-around'
      pb={10}
    >
      <CreateSubjectActivityAllModal
        isOpen={isCreateSubjectActivityOpen}
        onClose={onCreateSubjectActivityClose}
        selectedGroupID={null}
      />
      <VStack align={'start'} spacing={0}>
        <AcademicYearSelectionField
          years={years}
          handleOnChangeAcademicYear={handleOnChangeAcademicYear}
          profContext={profContext}
        />

        {subjectActivitiesQuery.isLoading ? (
          <HStack w='263px' h='132px' justify={'center'}>
            <Spinner />
          </HStack>
        ) : subjectActivitiesQuery.data ? (
          <>
            <HStack pt={'10px'} align={'center'}>
              <Text>Raspoloživi težinski faktor: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {subjectActivitiesQuery.data.difficultyFactor -
                  subjectActivitiesQuery.data.difficultyFactorUsed}{' '}
                / {subjectActivitiesQuery.data.difficultyFactor}
              </Text>
            </HStack>
            <HStack align={'center'}>
              <Text>Broj kreiranih aktivnosti: </Text>
              <Text fontWeight={'700'} fontSize={'lg'}>
                {subjectActivitiesQuery.data.numberOfSubjectActivities}
              </Text>
            </HStack>
          </>
        ) : (
          <VStack pt={3} align={'start'}>
            <Text>Kreirana je nova akademska godina.</Text>
            <Text>Da li želite mjenjati podatke o dodatnim aktivnostima?</Text>
            <Button w={'100%'} bgColor={'blue.600'}>
              Uvezi iz prošle godine
            </Button>
            <Button w={'100%'} bgColor={'blue.600'}>
              Unesi nove podatke
            </Button>
          </VStack>
        )}
      </VStack>
      <Stack spacing={0} pt={{ base: 5, md: 0 }} direction={'column'} justify={'center'}>
        <BlueButton
          buttonText='Dodaj aktivnost'
          onClick={() => {
            if (authContext.user?.role !== 'PROFESSOR') {
              toast({
                title: 'Samo profesor može dodavati aktivnosti za sve studente.',
                status: 'warning',
                duration: 3000,
                isClosable: true,
                position: 'top',
              });
            } else {
              onCreateSubjectActivityOpen();
            }
          }}
          isDisabled={
            subjectActivitiesQuery.data?.difficultyFactor ===
            subjectActivitiesQuery.data?.difficultyFactorUsed
          }
        />
        {subjectActivitiesQuery.data?.difficultyFactor ===
          subjectActivitiesQuery.data?.difficultyFactorUsed && (
          <Center>
            <Text color={'red.400'}>Raspoloživi težinski faktor je 0.</Text>
          </Center>
        )}
      </Stack>
    </Stack>
  );
};
