import { SpinnerIcon } from '@chakra-ui/icons';
import {
  Center,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BluesubmitButton } from '../../../../components/general/BlueSubmitButton';
import { RedButton } from '../../../../components/general/RedButton';
import useProf from '../../../../hooks/useProf.hooks';
import { useUpdateLectureMutation } from '../../../../services/mutations';
import { useGetSingleLecture } from '../../../../services/queries';
import { updateLectureSchema } from '../../../../validation/create-lectures-schema';
import { HorizontalTextField } from '../../NewSubject/comp/HorzintalTextField';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  lectureID: number;
};

export const EditLectureModal = ({ isOpen, onClose, lectureID }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const profContext = useProf();
  const singleLectureQuery = useGetSingleLecture(lectureID);

  const updateLecture = useUpdateLectureMutation(
    profContext.selectedSubjectID,
    profContext.selectedAcademicYearID
  );

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    updateLecture(values).catch((err) => {});
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isCentered size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>Uredi predavanje</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'} justifyContent={'center'} alignContent={'center'}>
          {singleLectureQuery.isLoading ? (
            <Center w={''}>
              <SpinnerIcon />
            </Center>
          ) : singleLectureQuery.data ? (
            <Formik
              initialValues={{
                lectureID: singleLectureQuery.data.id,
                name: singleLectureQuery.data.name,
                date: new Date(singleLectureQuery.data.date),
                classHours: singleLectureQuery.data.classHours,
                subjectID: profContext.selectedSubjectID,
                yearID: profContext.selectedAcademicYearID,
              }}
              validationSchema={updateLectureSchema}
              onSubmit={(values, actions) => {
                handleSubmit(values);
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
                    <HorizontalTextField
                      name='name'
                      type='text'
                      label='Naziv predavanja'
                      width='300px'
                    />
                    <HorizontalTextField
                      name='classHours'
                      type='number'
                      label='Broj časova'
                      width='300px'
                    />
                    <Field name='date'>
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          id='date'
                          isInvalid={form.errors.date && form.touched.date}
                          isRequired
                        >
                          <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={{ bae: 0, md: 5 }}
                          >
                            <VStack
                              w={{ base: '100%', md: '50%' }}
                              align={{ base: 'start', md: 'end' }}
                            >
                              <FormLabel htmlFor='date'>Datum predavanja</FormLabel>
                            </VStack>
                            <VStack w={'50%'} align={'start'}>
                              <DatePicker
                                {...field}
                                selected={field.value && new Date(field.value)}
                                onChange={(date: Date) => setFieldValue('date', date)}
                                dateFormat='dd. MM. yyyy.'
                                placeholderText='Izaberite datum.'
                              />
                              <ErrorMessage name='date'>
                                {(msg) => <Text color='red'>{msg}</Text>}
                              </ErrorMessage>
                            </VStack>
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                    <HStack py={5} justify={'center'}>
                      <BluesubmitButton
                        buttonText='Sačuvaj izmjene'
                        loadingText='Izmjene se čuvaju...'
                        isLoading={isLoading}
                      />
                      <RedButton buttonText='Odustani' onClick={onClose} />
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          ) : (
            <Center>
              <Text> Predavanje ne postoji </Text>
            </Center>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
